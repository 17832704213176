import { UIFlightSegment, UIFlightCarrier, UICarrier } from "@flights/types";
import { useMemo } from "react";

interface CSSGridPosition {
  gridColumn: string;
  gridRow: string;
}

const CSS_GRID_MAPS = {
  1: {
    0: { gridColumn: "col1 / span 2", gridRow: "row1 / span 2" }
  },

  2: {
    0: { gridColumn: "col1", gridRow: "row1" },
    1: { gridColumn: "col2", gridRow: "row2" }
  },

  3: {
    0: { gridColumn: "col1", gridRow: "row1" },
    1: { gridColumn: "col1", gridRow: "row2" },
    2: { gridColumn: "col2", gridRow: "row2" }
  },

  4: {
    0: { gridColumn: "col1", gridRow: "row1" },
    1: { gridColumn: "col1", gridRow: "row2" },
    2: { gridColumn: "col2", gridRow: "row2" },
    3: { gridColumn: "col2", gridRow: "row1" }
  }
};

interface AirlineGridPosition extends Pick<UICarrier, "logo"> {
  name: string;
  cssGridPosition: CSSGridPosition;
}

/* This hook is copied from useAirlineLogos, except this hook takes multiple segments as input
   to collect all carrier names and logos for a given flights. */

const useAirlineLogosGrouped = (segments: UIFlightSegment[]) => {
  const airlinesWithGridPosition = useMemo(() => {
    const segmentsCarrierLogos: AirlineGridPosition[][] = [];

    segments.forEach((segment) => {
      const uniqueLogos: { name: string; logo: string }[] = [];
      const segmentLogos: AirlineGridPosition[] = [];
      segment.legs.forEach((leg) => {
        const { carriersData, flightInfo } = leg;
        if (flightInfo.carrierInfo) {
          const { marketingCarrier } = flightInfo.carrierInfo;
          const carrier = carriersData.find((carrier) => carrier.code === marketingCarrier);
          // Ignore logos that we already added for non direct flights
          const isCarrierExisting = uniqueLogos.find((carrierInList) => carrierInList.name === carrier?.name);
          if (carrier && !isCarrierExisting) {
            uniqueLogos.push({ logo: carrier.logo, name: carrier.name });
          }
        }
      });

      const howManyAirlines = uniqueLogos.length > 4 ? 4 : uniqueLogos.length;
      let index = 0;
      uniqueLogos.forEach((uniqueLogo) => {
        if (index > 4) return;

        segmentLogos.push({
          logo: uniqueLogo.logo,
          name: uniqueLogo.name,
          // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
          cssGridPosition: CSS_GRID_MAPS[howManyAirlines][index]
        });

        index++;
      });

      segmentsCarrierLogos.push(segmentLogos);
    });
    return segmentsCarrierLogos;
  }, [segments]);

  const selectFlightCarriers = useMemo(() => {
    const carrierDataForSegments: UIFlightCarrier[] = [];
    const carrierDataForSegment: UIFlightCarrier[] = [];
    segments.forEach((segment) => {
      segment.legs.forEach((leg) => {
        const carrierForLeg: UIFlightCarrier = {};
        if (leg.carriers && leg.carriersData) {
          const operatingCarrierCode = leg.flightInfo.carrierInfo?.operatingCarrier;
          const marketingCarrierCode = leg.flightInfo.carrierInfo?.marketingCarrier;
          const operatingCarrierDisclosureText = leg.flightInfo.carrierInfo?.operatingCarrierDisclosureText;

          carrierForLeg.carrierData = leg.carriersData.find((o) => o.code === marketingCarrierCode);

          if (operatingCarrierCode !== marketingCarrierCode)
            carrierForLeg.operatingCarrierData = leg.carriersData.find(
              (carrier) => carrier.code === operatingCarrierCode
            );

          if (operatingCarrierDisclosureText) {
            carrierForLeg.operatingCarrierDisclosureText = operatingCarrierDisclosureText;
          }
        }
        !carrierDataForSegment.find((o) => o?.carrierData?.code === carrierForLeg.carrierData?.code) &&
          carrierDataForSegment.push(carrierForLeg);
      });
      carrierDataForSegments.push(...carrierDataForSegment);
    });
    return carrierDataForSegments;
  }, [segments]);

  return {
    selectFlightCarriers,
    airlinesWithGridPosition
  };
};

export default useAirlineLogosGrouped;
