import { UIWhitelabelAncillary } from "@flights/ancillaries-universal";
import { clientFetch } from "@flights/client-fetch";
import { UIFlightSegment, UISearchSegment } from "@flights/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "store";

interface IWhitelabelAncillaryRequestData {
  contextName: string;
  geniusLevel?: string;
  pointOfSale?: string;
  orderStatus?: string;
  tripType?: string;
  segments: UISearchSegment[] | UIFlightSegment[];
}

interface IWhitelabelAncillaryResponse {
  white_labels: UIWhitelabelAncillary[];
}

export const whitelabelThunkCreator = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();

const CONTEXT_PAGE_MAPPING = {
  index: "index",
  search_results: "search_results",
  flight_details: "flight_details",
  checkout_ticket_type: "checkout_ticket_type",
  checkout_fare: "checkout_fare",
  checkout1: "checkout_passenger",
  checkout2: "checkout_ancillaries",
  checkout3: "checkout_payments",
  confirmation: "confirmation",
  pb_order_details: "pb_order_details"
};

export const whitelabelAncillaryService = {
  getWhitelabels: whitelabelThunkCreator(
    "ancillaries/getWhitelabels",
    async ({
      contextName,
      geniusLevel,
      pointOfSale,
      segments,
      orderStatus,
      tripType
    }: IWhitelabelAncillaryRequestData) => {
      const res = await clientFetch<IWhitelabelAncillaryResponse>("/api/ancillaries/getWhitelabels", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Flights-Context-Name": CONTEXT_PAGE_MAPPING[contextName as keyof typeof CONTEXT_PAGE_MAPPING]
        },
        body: JSON.stringify({ geniusLevel, pointOfSale, segments, orderStatus, tripType })
      });
      return res;
    },
    {
      condition: (arg, { getState }) => {
        const { whitelabelAncillaries } = getState();
        return !whitelabelAncillaries.whitelabelAncillariesLoading;
      }
    }
  )
};
