import { createTrackingUtils } from "../index";
import useMdotValueTrackDefaultStages, { MDotValueStage } from "./default_stages";
import useUserAgent from "../../../hooks/useUserAgent";
import { useEffect } from "react";

const EXP_NAME = "flights_mdotvalue_sr_sticky_search_box";

// Defined in module top level to use as a global state
const expState = {
  isSearchBoxSticky: false
};

export const tracking = createTrackingUtils({
  name: EXP_NAME,
  defaultStage: 1,
  stages: {
    eligible: 1, // tracked on render (default stage)
    on_view: 2, // user viewed the search box in sticky mode
    scroll_to_third: 3, // user scrolled to the 3rd card
    scroll_to_fifth: 4, // user scrolled to the 5th card
    reached_page_end: 5,
    // -------> included in mdotvalue default stages <-------
    [MDotValueStage.PAID_TRAFFIC]: 6, // meta + ppc
    [MDotValueStage.US_TRAFFIC]: 7,
    [MDotValueStage.KEY_MARKET_TRAFFIC]: 8, // traffic from 'us', 'fr', 'in', 'it', 'de', 'gb', 'es', 'br'
    [MDotValueStage.DIRECT_TRAFFIC]: 9
    // ------------------------------------------------------
  },
  goals: {
    flights_search_box_open: 1,
    flights_sticky_search_box_open: 2,
    reached_page_end: 3
  }
});

export function useStickySearchBoxExp(isSearchboxSticky = expState.isSearchBoxSticky) {
  const { isMobile } = useUserAgent();
  const trackDefaultStages = useMdotValueTrackDefaultStages();

  useEffect(() => {
    if (!isMobile || isSearchboxSticky === expState.isSearchBoxSticky) {
      return;
    }

    expState.isSearchBoxSticky = isSearchboxSticky;
    if (isSearchboxSticky && isMobile) {
      tracking.stages.on_view();
      trackDefaultStages(tracking.stages);
    }
  }, [isMobile, isSearchboxSticky, trackDefaultStages]);

  const trackScrollToThird = () => {
    if (expState.isSearchBoxSticky && isMobile) {
      tracking.stages.scroll_to_third();
    }
  };

  const trackScrollToFifth = () => {
    if (expState.isSearchBoxSticky && isMobile) {
      tracking.stages.scroll_to_fifth();
    }
  };

  const getVariant = () => {
    if (isMobile) {
      return tracking.trackWithDefaultStage();
    }
    return 0;
  };

  const trackFlightsSearchBoxOpen = () => {
    if (!isMobile) {
      return;
    }

    tracking.goals.flights_search_box_open();
    if (expState.isSearchBoxSticky) {
      tracking.goals.flights_sticky_search_box_open();
    }
  };

  const trackReachedEndOfPage = () => {
    if (!isMobile) {
      return;
    }

    tracking.goals.reached_page_end();
    tracking.stages.reached_page_end();
  };

  return {
    trackScrollToThird,
    trackScrollToFifth,
    getVariant,
    trackFlightsSearchBoxOpen,
    trackReachedEndOfPage
  };
}
