import React, { useMemo } from "react";
import { CardAirline } from "../CardAirline/index.js";
import cxx from "../../__utils/cxx.js";
import styles from "./styles.module.css";
export function CardAirlineGroup(props) {
    const { codes = [], size = 24, variant = "grid", attributes, className } = props;
    const list = useMemo(() => [...codes.filter(Boolean).slice(0, 4)], [codes]);
    const length = useMemo(() => list.length, [list]);
    const isGrid = useMemo(() => variant === "grid", [variant]);
    const calculatedSize = useMemo(() => (length === 1 || !isGrid ? size : size / 2), [size, isGrid, length]);
    const classNames = useMemo(() => cxx(styles.group, isGrid ? styles.group__grid : styles.group__inline, length === 1 && styles.group__inline_no_gap, className), [isGrid, length, className]);
    if (!codes.length)
        return undefined;
    return (React.createElement("div", { ...attributes, className: classNames }, codes.map((code, i) => {
        const isLast = isGrid && i === length - 1;
        return (React.createElement(CardAirline, { key: code + i, code: code, size: calculatedSize, className: cxx(isLast && styles.group__grid__last) }));
    })));
}
