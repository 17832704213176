import { Box, Stack, Title, Text } from "@bookingcom/bui-react";
import React from "react";
import styles from "./FlightDetailsInfoList.module.css";
export const FlightDetailsInfoList = ({ title, hasBorder, isLoading, loadingComponent, attributes, variant, items }) => {
    if (isLoading && loadingComponent) {
        return loadingComponent;
    }
    return (React.createElement(Box, { borderColor: hasBorder ? "neutral_alt" : undefined, borderRadius: hasBorder ? 200 : undefined, padding: hasBorder ? 4 : 0, attributes: attributes },
        title,
        React.createElement(Stack, { gap: 4 }, items.map((item, index) => (React.createElement(Item, { key: index, variant: variant, hideDivider: index === 0, header: item.header, subheader: item.subheader, content: item.content, titleAttributes: item.titleAttributes }))))));
};
const Item = ({ content, header, subheader, titleAttributes, variant, hideDivider = false }) => {
    return (React.createElement(Stack, { className: hideDivider ? undefined : styles.divider, direction: variant === "two-columns" ? "row" : "column", gap: variant === "one-column" ? 2 : 0, alignItems: "stretch" },
        header && (React.createElement(Title, { className: variant === "two-columns" ? styles.titleTwoColumn : undefined, title: header, subtitle: subheader && (React.createElement(Text, { color: "neutral_alt", variant: "body_2" }, subheader)), variant: "strong_1", titleTagName: "h2", attributes: titleAttributes })),
        React.createElement(Stack.Item, { grow: true },
            React.createElement("div", { className: styles.contentWrapper }, content))));
};
