import React from "react";
import { UIFlightCarrier, UIFlightSegment } from "@flights/types";
import { Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import useUserAgent from "../../../../hooks/useUserAgent";
import useAirlineLogos from "../../../../hooks/useAirlineLogos";
import Frame from "components/elements/Frame";
import styles from "./FlightCardCarriers.module.css";
import { mcn } from "utils/mergeClassnames";
import flights_apex_web_collapse_fd_itinerary_mdot from "utils/experiments/apex/flights_apex_web_collapse_fd_itinerary_mdot";

const getCarrierText = (carrier: UIFlightCarrier, i18n: I18nChildContext) => {
  if (!carrier.carrierData) return "";
  if (carrier.operatingCarrierDisclosureText) {
    return i18n.trans(
      t("flights_sr_marketing_operating_airline", {
        variables: {
          marketing_carrier: carrier.carrierData.name,
          operating_carrier: carrier.operatingCarrierDisclosureText
        }
      })
    );
  } else if (carrier.operatingCarrierData) {
    return i18n.trans(
      t("flights_sr_marketing_operating_airline", {
        variables: {
          marketing_carrier: carrier.carrierData.name,
          operating_carrier: carrier.operatingCarrierData.name
        }
      })
    );
  } else {
    return carrier.carrierData.name;
  }
};

export const CarrierNames = (props: { carriers: UIFlightCarrier[]; idx: number }) => {
  const { carriers } = props;
  const i18n = useI18n() as I18nChildContext;
  return (
    <Frame direction="row" wrap="wrap" attributes={{ "data-testid": `flight_card_carrier_${props.idx}` }}>
      {carriers.map((carrier: UIFlightCarrier, index) => {
        return (
          <Frame direction="row" key={index} wrap="wrap">
            <Text variant="small_1" className={styles.carriers}>
              {getCarrierText(carrier, i18n)}
            </Text>
            {index !== carriers.length - 1 && (
              <Text variant="small_1" className={styles.separator}>
                {", "}
              </Text>
            )}
          </Frame>
        );
      })}
    </Frame>
  );
};

type CarrierLogosProps = {
  segment: UIFlightSegment;
  /* start - flights_alternative_fares_for_sold_out_errors_web */
  isAlternativeFareCard?: boolean;
  /* end - flights_alternative_fares_for_sold_out_errors_web */
};

const CarrierLogos = (props: CarrierLogosProps) => {
  const { segment, isAlternativeFareCard = false } = props;
  const { isMobile: _isMobile } = useUserAgent();
  const { airlinesWithGridPosition } = useAirlineLogos(segment);

  const isMobile = isAlternativeFareCard || _isMobile;

  const getLogosForDesktop = () => {
    return (
      <div className={airlinesWithGridPosition.length > 1 ? styles.grid : styles.singleLogo}>
        {airlinesWithGridPosition.map((airline, i) => {
          return (
            <div
              className={airlinesWithGridPosition.length > 1 ? styles.carrierLogo : styles.singleLogo}
              key={i}
              style={{ ...airline.cssGridPosition, backgroundImage: airline && `url(${airline.logo})` }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      {isMobile && !flights_apex_web_collapse_fd_itinerary_mdot.trackWithDefaultStage() ? (
        <Frame direction="row" alignItems="center">
          {airlinesWithGridPosition.map((airline, i) => (
            <div
              key={i}
              style={{ backgroundImage: `url(${airline.logo})` }}
              className={mcn(styles.carrierLogo, styles.logoMargin)}
            />
          ))}
        </Frame>
      ) : (
        getLogosForDesktop()
      )}
    </>
  );
};

type FlightCardCarriersProps = {
  segment: UIFlightSegment;
  idx: number;
  /* start - flights_alternative_fares_for_sold_out_errors_web */
  isAlternativeFareCard?: boolean;
  /* end - flights_alternative_fares_for_sold_out_errors_web */
};

const FlightCardCarriers = (props: FlightCardCarriersProps) => {
  const { segment, isAlternativeFareCard = false } = props;
  const { selectFlightCarriers: carriers } = useAirlineLogos(segment);
  const { isMobile: _isMobile } = useUserAgent();

  const isMobile = isAlternativeFareCard || _isMobile;

  return (
    <Frame direction={isMobile ? "row" : "column"}>
      {isMobile && !flights_apex_web_collapse_fd_itinerary_mdot.trackWithDefaultStage() ? (
        <Frame direction="row" wrap="wrap">
          <CarrierLogos segment={segment} isAlternativeFareCard={isAlternativeFareCard} />
          <CarrierNames carriers={carriers} idx={props.idx} />
        </Frame>
      ) : (
        <CarrierLogos segment={segment} isAlternativeFareCard={isAlternativeFareCard} />
      )}
    </Frame>
  );
};

export default FlightCardCarriers;
