import React, { useEffect } from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import useEvents from "hooks/useEvents";

const AppleCardSR = () => {
  const { grumble } = useEvents();

  const { Component, error } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/PromotionalBanner/Flights/SearchResults",
    type: "capla"
  });

  useEffect(() => {
    if (error) {
      grumble("Remote component error: Apple card on SR page", { error });
    }
  }, [error, grumble]);

  return <Component />;
};

export default AppleCardSR;
