import React from "react";
import { Stack } from "@bookingcom/bui-react";
import { Breakdown } from "./Breakdown";
import { Installments, Atol } from "./banners";
import {
  useUnifiedPriceBreakdownTicketTypeController,
  type UnifiedPriceBreakdownTicketTypeControllerProps
} from "./useUnifiedPriceBreakdown.TicketType.controller";

export type UnifiedPriceBreakdownTicketTypeProps = UnifiedPriceBreakdownTicketTypeControllerProps;

export function UnifiedPriceBreakdownTicketType(props: UnifiedPriceBreakdownTicketTypeProps) {
  const { addedItems, isHungaryDepartingRyanAir, isWithDiscount, unifiedPriceBreakdown } =
    useUnifiedPriceBreakdownTicketTypeController(props);

  if (!unifiedPriceBreakdown) return null;

  return (
    <Stack gap={4}>
      <Breakdown
        {...unifiedPriceBreakdown}
        addedItems={addedItems}
        isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
        isWithDiscount={isWithDiscount} // flights_web_unified_price_breakdown
      />
      <Atol />
      <Installments />
    </Stack>
  );
}
