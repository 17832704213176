import { UIFlightSegment } from "@flights/types";
import Frame from "components/elements/Frame";
import { FC } from "react";
import React from "react";
import { Stack, Text } from "@bookingcom/bui-react";
import { useCardCarriersCombinedController } from "./useCardCarriersCombinedController";
import { CardAirlineGroup } from "@flights/search-ui";
import styles from "./CardCarriersCombined.module.css";

export const CardCarriersCombined: FC<{ segments: UIFlightSegment[] }> = ({ segments }) => {
  const { carrierLogoCodes, carriersText } = useCardCarriersCombinedController(segments);

  return (
    <>
      <Stack direction="row" gap={2}>
        <CardAirlineGroup
          codes={carrierLogoCodes}
          variant="inline"
          className={styles.logo}
          size={12}
        ></CardAirlineGroup>

        {carrierLogoCodes.length === 1 && <Text variant="small_1">{carriersText}</Text>}
      </Stack>
      {carrierLogoCodes.length > 1 && (
        <Frame direction="row" wrap="wrap">
          <Text variant="small_1">{carriersText}</Text>
        </Frame>
      )}
    </>
  );
};
