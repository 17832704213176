//README:docs/architecture/internal-events.jpg
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { SBSortingOrder } from "@bookingcom/flights-searchbox";
import { type AvroSchemaMapping } from "@flights/internal-events-types";
import useTrackerContext from "./useTrackerContext";
import {
  UICabinClass,
  UILandingSegment,
  UIOrderStatus,
  UIRewardStatus,
  UISanctionScreeningDataTracking
} from "@flights/types";

import { InternalEventsUtils } from "@flights/web-api-utils-universal";
import { clientFetch } from "@flights/client-fetch";

import {
  GOOGLE_TAG_MANAGER_CONTAINER_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_LABEL,
  SKYSCANNER_EXT_ORIGIN_PARAM_VALUES
} from "../constants";
import useGlobalContext from "./useGlobalContext";
import usePointOfSale from "./usePointOfSale";
import { UIXComponentType } from "cross-sell/types";
import { getMarketingTrackingVariable } from "@flights/web-api-utils-universal";

const debug = require("debug")("useEventTracking");

const UNKNOWN_VALUE = "unknown";
let LAST_SEEN_CONTEXT_NAME: keyof AvroSchemaMapping;

type SearchFields = {
  input: string;
  type: string;
  order?: number;
  code: string;
  segment: number;
  query: string;
  iata: string;
  position: number;
};

type CalendarFields = {
  date: string;
  color: string;
};

type FlightDetails = {
  search_list_item_order: number;
  segments: Segment[];
  price_total: number;
  ancillary_included: {
    ancillary: string;
    details: string;
  }[];
};
type Segment = {
  sequence: number;
  trip_time: number;
  stop_over_count: number;
  origin: string;
  destination: string;
  departure_time: string;
  arrvial_time: string;
  airline: string;
};

type StopFilter = {
  stop_count: number;
  min_price: number;
  selected: number;
};
type AirlineFilter = {
  airline_name: string;
  min_price: number;
  selected: 1 | 0;
};
type TimeOfDayFilter = {
  departure_arrival: string;
  segment_number: number;
  segment_time_min: number;
  segment_time_max: number;
};

interface SearchBoxEvents {
  click_origin: never;
  click_destination: never;
  click_calendar: never;
  click_passenger: never;
  click_passenger_done: never;
  click_submit_search: never;
  click_swap: never;
  type_origin: string;
  select_origin: SearchFields[];
  type_destination: string;
  select_destination: SearchFields[];
  select_calendar_inbound: string;
  select_calendar_outbound: string;
  select_calendar_with_fare: CalendarFields[];
  select_cabin_class: UICabinClass;
  select_journey_type: "ONEWAY" | "ROUNDTRIP" | "MULTISTOP";
  select_direct_flights_only: never;
  click_calendar_done: never;
  select_travelers: {
    number_adults: number;
    number_children: number;
    children_ages: number[];
  };
}

export interface FlightStatusAffectedFlights {
  segment_index: number;
  leg_index: number;
  issue: string;
  delay_in_minutes: number;
  airline_code: string;
  time_to_departure_minutes: number;
}

type BYeahEventBaseParams = {
  lang: string;
  affiliateId: string;
  userLocation: string; // countrycode
  label: string; //
  userAgent: string;
  cookieId: string;
  customerIp: string;
};

export type BYeahSearchResultsEventParams = {
  searchParams: string;
} & BYeahEventBaseParams;

export type BYeahOrderConfirmationEventParams = {
  offerPrice: number;
  offerCurrency: string;
  orderReference: string | number;
  customerEmail: string;
  customerPhoneNumber: string | number;
} & BYeahEventBaseParams;

export interface FlightStatusWarningEvent {
  order_id: string;
  affected_flights: FlightStatusAffectedFlights[];
}

//Action events are mapped with UI components
// A lot of action events in this interface are V1, and not tracked anymore.
// This needs to be carefully cleaned up, this structure should only hold
// actual action events that are tracked with V2.
/*eslint-disable-next-line flights/no-unassigned-todo-comments*/
// TODO(data):
export interface ActionEventMapping {
  search_box: SearchBoxEvents;
  header_and_footer: {
    click_language: never;
    select_language: string;
    click_help_center_icon: never;
    click_feedback: never;
    click_help_center_link: never;
    click_about_booking: never;
    click_terms_conditions: never;
    click_privacy_statement: never;
    click_ccpa: never;
    click_swap: never;
  };
  route: {
    click_find_flights_price_trend: {
      period: string;
      price: number;
      currency: string;
      segment: number;
    };
    click_find_flights_airport_map: {
      airport: string;
      distance: number;
      unit: string;
    };
    click_find_flights_airline_all: never;
    click_find_flights_departure_time: string;
    click_destination_recommandation: string;
    click_destination_recommandation_more: never;
  };
  // search_loading: never;
  search_results: {
    click_loading_change_search_link: never;
    click_select_flight: FlightDetails;
    click_sort_by_link: SBSortingOrder;
    click_refresh_search: null;
    click_filter_stops_item: StopFilter;
    click_filter_duration: number;
    click_filter_airline_item: AirlineFilter;
    click_filter_time_of_day: TimeOfDayFilter;
    click_filter_duration_reset: null;
    click_filter_stops_reset: null;
    click_filter_airline_reset: null;
    select_filters: { [key: string]: boolean };
    click_change_search: never;
    show_empty_search_results: never;
    click_filters: never;
  };
  feedback: {
    click_feedback_trigger_link: never;
  };
  timeline: {
    click_show_full_itinerary_button: number;
    click_hide_full_itinerary_button: number;
  };
  price_breakdown: {
    click_show_price_breakdown: never;
    click_hide_price_breakdown: never;
    click_view_price_breakdown: never;
    click_price_breakdown: never;
    click_price_details: never;
  };
  flight_details: {
    click_select_flights_button: never;
    click_back_button: never;
    landing_in_preselected_ancillaries: never;
    google_flights_landing_shallow: {
      isShallow: boolean;
      /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
      airlineIATA: string[]; //TODO: check with data team
    };
    click_price_breakdown: never;
    select_flight_details_next: never;
    show_error_page: never;
  };

  checkout_start: {
    no_ticket_type_or_branded_fare_available: never;
  };

  checkout_fare: {
    click_back_button: never;

    // clicks on branded fare options
    click_branded_fare: {
      flight_offer_id: string;
      fare_name: string;
      is_base_fare: boolean;
    };

    // on submit of branded fares checkout screen
    select_branded_fare_next: {
      flight_offer_id: string;
      fare_name: string;
      is_base_fare: boolean;
    };
    click_price_breakdown: never;
    click_back: never;
  };

  checkout_ticket_type: {
    click_back_button: never;

    // clicks on ticket type options
    click_ticket_type: {
      is_flexible: boolean;
    };

    // on submit of ticket type checkout screen
    select_ticket_type_next: {
      is_flexible: boolean;
    };
    click_price_breakdown: never;
    click_back: never;
  };

  checkout1: {
    click_next_step_button: never;
    click_back_button: never;
    click_back: never;
    click_price_breakdown_trigger_link: never;
    input_children_dob_type: never; //TDOD "type", "select", "mixed
    select_extra_ancillary: {
      ancillary: string;
      cost: number;
    };
  };

  checkout2: {
    click_next_step_button: never;
    click_back_button: never;
    click_back: never;
    select_extra_ancillary: {
      ancillary: string;
      cost: number;
      currency?: string;
      quantity?: number;
    };
    no_seatmap_available: never;
    select_ancillaries_next: never;
    skip_checkout_ancillaries_page: never;
    select_ancillaries_final: {
      flight_offer_id: string;
      ancillary_type: string[];
    };
  };

  checkout_sirf: {
    click_next_step_button: never;
    click_back_button: never;
    click_back: never;
    fill_sirf_form: never;
  };

  checkout3: {
    click_back_button: never;
    click_back: never;
    show_pay_now: never;
    click_travel_insurance_details: never;
    click_meal_details: never;
    click_view_details_seats: {
      segment: number;
    };
    click_pay_now: {
      order_id: string;
      payment_id: string;
    };
    payment_success_callback: never;
    payment_failure_callback: never;
    payment_pending_callback: never;
    payment_component_loading_failed: never;
    price_changed: {
      orderId: string;
      cartId: string;
    };
    order_finalized: {
      aid: number;
      label: string;
      orderId: string;
    };
    order_finalization_failed: {
      aid: number;
      label: string;
      orderId: string;
      code: string;
    };
  };

  pricechange: {
    click_helpcenter: never;
    click_new_search_price_changed: {
      orderId: string;
      cartId?: string;
      oldPrice: string | null;
      newPrice?: string | null;
      currency: string;
      type?: string;
    };
    click_accept_price_changed: {
      orderId: string;
      cartId?: string;
      oldPrice: string | null;
      newPrice?: string | null;
      currency: string;
      type?: string;
    };
    show_price_changed: {
      orderId: string;
      cartId?: string;
      oldPrice: string | null;
      newPrice?: string | null;
      currency: string;
      type?: string;
    };
  };

  seatmap: {
    click_back_button: never;
    click_skip_seatmap: never;
    click_back: never;
    click_next: never;
    select_seatmap_next: {
      seat_selected: boolean;
    };
  };

  cookie_banner: {
    click_cookie_banner_accept_button: never;
    click_cookie_banner_reject_button: never;
    click_cookie_banner_manage_link: never;
    view_cookie_banner: never;
  };

  cookie_manage: {
    click_cookie_manage_save_button: {
      analytical?: boolean;
      marketing?: boolean;
      functional?: boolean;
    };
    click_cookie_manage_close_button: never;
    click_cookie_manage_close_icon: never;
    view_cookie_manage: never;
  };

  confirmation: {
    click_helpcenter: never;
    click_cancel_booking: never;
    click_bookingdetails: never;
    click_booking_details: never;
    click_resend_conf: never;
    click_resend_email_more_options: never;
  };

  pb_orderdetails: {
    click_flightsdetails: number; //segmentIndex
    click_viewmodify_passenger: never;
    click_baggage_details: never;
    click_viewmodify_luggage: never;
    click_viewmodify_luggage_selfservice: never;
    click_viewdetails_luggage: never;
    click_resend_email_more_options: never;
    click_viewdetails_seatpreference: never;
    click_viewdetails_mealchoice: never;
    click_viewdetails_flexticket: never;
    click_viewdetails_insurance: never;
    click_update_contact: never;
    click_helpcenter: never;
    click_cancelbooking: never;
    click_banner_addluggage: never;
    show_banner_addluggage: never;
    click_cancel_booking: never;
    click_view_meal_plan: never;
    click_view_insurance_details: never;
    click_view_flexible_ticket: never;
    click_flights_details: number;
    show_banner_add_luggage: never;
    click_banner_add_luggage: never;
    click_view_add_luaggage: number;
    click_modify_passenger: never;
    show_banner_seat: never;
    click_banner_select_seat: never;
    click_seat_selection: never;
    click_change_seats_selfservice: never;
    show_checkin_flow: {
      order_id: string;
    };
    click_launch_checkin_flow: {
      order_id: string;
    };
    click_bookagain: {
      last_trip_orderid: string;
    };
    show_bookagain: {
      last_trip_orderid: string;
    };
    show_flights_status_warning: FlightStatusWarningEvent;
  };
  pb_add_seat: {
    click_seat_selection_next: never;
  };
  pb_add_baggage: {
    show_cabinluggage_option: never;
    show_checkedluggage_option: never;
    click_cabinluggage: never;
    click_checkedluggage: never;
    click_luggage_next: never;
    click_luggage_next_success: never;
    click_luggage_next_fail: never;
    show_cabin_luggage_option: never;
    show_checked_luggage_option: never;
    click_luggagge_pay_now: never;
  };

  pb_cancel_order: {
    click_view_cancellation_policy: never;
    click_not_cancel: never;
    click_cancel: never;
    click_confirm_cancellation: never;
    show_pb_cancellation_error: never;
    show_pb_cancellation_confirmation: never;
  };

  pb_checkout_payment: {
    click_paynow: never;
    click_pb_ancillary_pay_now: {
      addon_order_id: string;
      ancillaries: string[];
    };
    show_pb_payments_page_seat: never;
    show_pb_payments_page_luggage: never;
    show_pb_payments_page_fasttrack: never;
  };
  pb_checkout_confirmation: {
    show_pb_confirmation_page_seat: never;
    show_pb_confirmation_page_luggage: never;
  };

  pb_flightdetails: {
    has_selected_seats: number; //segmentIndex
    click_view_seats: number; //segmentIndex
    click_change_seats: number; //segmentIndex
    click_view_add_seats: number; //segmentIndex
  };

  pb_webcheckin: {
    click_checkin: {
      order_id: string;
      segment: number;
      legs: number[];
    };
    click_skip_checkin: {
      order_id: string;
      segment: number;
      legs: number[];
    };
  };
  xsell: {
    xsell_button_click: {
      orderid: string;
      button: string;
      url: string;
      request_id: string;
      componentType: UIXComponentType;
      soylentId: string;
    };
    xsell_recommendation_click: {
      orderid: string;
      rec_results_id: string;
      position: number;
      url: string;
      request_id: string;
      componentType: UIXComponentType;
      soylentId: string;
    };
    xsell_rendered: {
      rec_results_id: string[];
      orderid: string;
      num_rec: number;
      request_id: string;
      componentType: UIXComponentType;
      soylentId: string;
    };
    xsell_view: {
      rec_results_id: string[];
      orderid: string;
      num_rec: number;
      request_id: string;
      componentType: UIXComponentType;
      soylentId: string;
    };
    click_connector_close: {
      orderid: string;
    };
    click_connector_survey_answer: {
      answer: number;
      orderid: string;
    };
  };

  sanction_screening: {
    fetch_sanction_screening: UISanctionScreeningDataTracking[];
    passenger_details_click_next: {
      is_correct: boolean;
    };
  };

  page_load: {
    show_error_page: never;
    landing_in: {
      referrer?: string;
      prefilled_routes?: string;
      prefilled_language?: string;
      pageload_event_id?: string;
      prefilled_search_params?: {
        segments?: UILandingSegment[];
      };
    };
  };

  checkout_progress_bar: {
    click_view_flights_details: {};
    click_back_button: {
      button_location: "browser" | "component";
      exp_variant: number;
    };
  };

  pb_flight_details: {
    show_flights_status_warning: FlightStatusWarningEvent;
  };

  reward: {
    show_reward_message: {
      orderid: string;
      order_status: UIOrderStatus;
      signin_status: "signed in" | "not signed in";
      reward_status: UIRewardStatus;
    };
  };

  checkout_footer: {
    click_back: never;
    click_next: never;
  };
  fasttrack: {
    click_fasttrack_view_qrcode: {
      orderId: string;
      status: string;
    };
    show_fasttrack_qrcode_modal: {
      orderId: string;
      status: string;
    };
    click_fasttrack_download_qrcode: {
      orderId: string;
    };
    click_fasttrack_view_details: {
      orderId: string;
      status: string;
    };
    show_pb_banner_fasttrack: {
      orderId: string;
    };
    click_pb_add_fasttrack: {
      orderId: string;
    };
    show_pb_add_fasttrack_modal: {
      orderId: string;
    };
    click_pb_added_fasttrack: {
      orderId: string;
    };
    show_pb_payments_modal_fasttrack: {
      orderId: string;
    };
    show_pb_confirmation_modal_fasttrack: {
      orderId: string;
      addon_order_id: string;
    };
  };
  fly_anywhere_click_card: string;
  weekend_getaway_click_card: string;
  esim: {
    show_banner_esim: {
      orderId: string | null;
      order_status: string | null;
      genius_level: number | null;
      destination: string | null;
      search_requestid: string | null;
      action: "clickable" | "non-clickable";
      valid_until: string | null;
      placement: "banner" | "bottomsheet";
    };
    click_banner_esim: {
      orderId: string | null;
      order_status: string | null;
      genius_level: number | null;
      destination: string | null;
      action: "clickable" | "non-clickable";
      valid_until: string | null;
    };
    sent_email_esim: {
      orderId: string | null;
    };
    click_email_esim: {
      orderId: string | null;
      order_status: string | null;
      genius_level: number | null;
      destination: string | null;
      action: "clickable" | "non-clickable";
      valid_until: string | null;
    };
  };
}

// This method is used only to track PAGE_LOAD events via useTrackPageLoad(),
// and probably can be simplified or removed.
/*eslint-disable-next-line flights/no-unassigned-todo-comments*/
// TODO(data):
function useContextEventTracking<T extends keyof AvroSchemaMapping>(contextName: T) {
  const track = (payload: AvroSchemaMapping[T]["context"] | AvroSchemaMapping["flight_events"]["context"]) => {
    const timestamp = Date.now();
    const eventId = uuid(); // this is the current event id and also the current context id.
    const avroEvent = {
      generic: {
        epoch_ms: timestamp,
        event_id: eventId
      },
      context: Object.assign({}, payload, { context_id: eventId })
    };
    LAST_SEEN_CONTEXT_NAME = contextName;

    void sendAvroEvent(contextName, avroEvent);
  };

  return useCallback<typeof track>(track, [contextName]);
}

// This is the main method to track action events (and only). componentName is only here used to determine
// the possible action names and the types for their payload.
// requestId it was added here to distinguish between V1 and V2, and also can be removed now, since
// it always can be fetched from the global context.
/*eslint-disable-next-line flights/no-unassigned-todo-comments*/
// TODO(data):
function useEventTracking<T extends keyof ActionEventMapping>(componentName: T, requestId: string) {
  const pointOfSale = usePointOfSale();
  const adplat = getMarketingTrackingVariable("adplat");

  const track = <Event extends keyof ActionEventMapping[T]>(event: Event, payload?: ActionEventMapping[T][Event]) => {
    // the context will contain a lot of unknown values, and they will be send as they are from the client to the
    // server, where they will get replaced the actual values. which seems also quite redundant, an ideally the client
    // does not need to send all of it but only required values (request id, event name and type etc).
    /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
    // TODO(data):
    const context: AvroSchemaMapping["flight_events"]["context"] | null = InternalEventsUtils.getFlightEventsContext(
      LAST_SEEN_CONTEXT_NAME,
      requestId,
      "ACTION",
      event,
      payload,
      pointOfSale,
      adplat
    );
    const timestamp = Date.now();

    const avroEvent = {
      generic: {
        epoch_ms: timestamp,
        event_id: uuid()
      },
      context
    };

    void sendAvroEvent(LAST_SEEN_CONTEXT_NAME, avroEvent);
    return undefined;
  };

  return useCallback<typeof track>(track, [requestId, pointOfSale, adplat]);
}

const sendAvroEvent = async function <T>(contextName: string, avroEvent: T) {
  try {
    const url = "/track/internal-events";
    await clientFetch(url, {
      method: "POST",
      credentials: "include",
      referrerPolicy: "strict-origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        context_name: contextName,
        data: avroEvent
      })
    });
  } catch (err) {} // eslint-disable-line flights/no-empty-catch
};

interface TransactionDetails {
  id: string | number;
  revenue: number;
  tax: number;
  itinerary: string;
  currency: string;
  obfuscatedOtaOrderReference: string | undefined;
  partner?: string;
  maskedUserEmail?: string;
  maskedPhoneNumber?: string;
}

function useTransactionTracking() {
  const { trackExternal } = useTrackerContext();
  const {
    features,
    lang = "",
    payerId = "",
    ipCountry = "",
    marketingTrackingVariables = {},
    customerIp = ""
  } = useGlobalContext();

  return useCallback(
    async (transaction: TransactionDetails) => {
      const {
        id,
        revenue,
        currency,
        obfuscatedOtaOrderReference,
        partner = "",
        maskedPhoneNumber = "",
        maskedUserEmail = ""
      } = transaction;

      debug(`transaction: ${JSON.stringify(transaction)}`);

      //PPC
      trackExternal({
        type: "gtag",
        args: [
          "event",
          "conversion",
          {
            send_to: `${GOOGLE_TAG_MANAGER_CONTAINER_ID}/${GOOGLE_TAG_MANAGER_CONTAINER_LABEL}`,
            value: revenue,
            currency: currency,
            transaction_id: id
          }
        ]
      });

      //SkyScanner Confirmation event via GTM
      if (
        features.FLIGHTS_META_SKYSCANNER_SEND_CONFIRMATION_EVENT &&
        SKYSCANNER_EXT_ORIGIN_PARAM_VALUES.includes(partner) &&
        obfuscatedOtaOrderReference
      ) {
        trackExternal({
          type: "gtm-skyscanner-confirmation",
          args: [
            {
              event: "skyscanner-order-confirmation",
              orderReference: obfuscatedOtaOrderReference
            }
          ]
        });
      }

      const extraParams = { customerIp: "", customerEmail: "", customerPhoneNumber: "" };

      extraParams.customerIp = customerIp;
      extraParams.customerEmail = maskedUserEmail;
      extraParams.customerPhoneNumber = maskedPhoneNumber;

      // Booking Yeah marketing campaign order confirmation via GTM
      if (features.FLIGHTS_MARKETING_BYEAH_SEND_CONFIRMATION_EVENT) {
        const bYeahTrackParams: BYeahOrderConfirmationEventParams = {
          affiliateId: marketingTrackingVariables.aid || "",
          cookieId: payerId,
          label: marketingTrackingVariables.label || "",
          lang,
          userAgent: navigator?.userAgent,
          userLocation: ipCountry,
          offerCurrency: currency,
          offerPrice: revenue,
          orderReference: id,
          ...extraParams
        };
        trackExternal({
          type: "gtm-byeah-confirmation",
          args: [
            {
              event: "flights-byeah-order-confirmation",
              ...bYeahTrackParams
            }
          ]
        });
      }
    },
    [trackExternal, features, lang, payerId, ipCountry, marketingTrackingVariables, customerIp]
  );
}

export default useEventTracking;
export { useContextEventTracking, useTransactionTracking, UNKNOWN_VALUE };
