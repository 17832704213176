import { t } from "@bookingcom/lingojs-core";
import { EEA_COUNTRY_CODE_LIST } from "@flights/constants";
import { isPriceZero, priceToNumber, sumPrice } from "./price.js";
import { priceIsZero, priceToValue } from "./format-price.js";
import { getObjectKeys } from "../objects.js";
const FLIGHT_SCOPES = ["FLIGHT_ADULT", "FLIGHT_CHILD", "FLIGHT_INFANT"];
export const transformUnifiedPriceBreakdownItemForFlight = (i18n, scope, title, priceBreakdown, options) => {
    return transformUnifiedPriceBreakdownItem(i18n, [scope], scope, title, priceBreakdown, {
        ...options,
        productType: "flight"
    });
};
export const transformUnifiedPriceBreakdownItemForExtra = (i18n, extras, options) => {
    if (!extras || Object.keys(extras).length === 0) {
        return [];
    }
    const items = [];
    getObjectKeys(extras).forEach((extraKey) => {
        if (extras[extraKey]) {
            const extraInfos = [];
            if (extraKey === "cabinBaggage") {
                extraInfos.push(getGenericExtraInfo(extras[extraKey]));
            }
            if (extraKey === "cabinBaggagePerTraveller") {
                extraInfos.push(getCabinBaggagePerTravellerExtraInfo(extras[extraKey]));
            }
            if (extraKey === "cancelForAnyReason") {
                extraInfos.push(getGenericExtraInfo(extras[extraKey]));
            }
            if (extraKey === "checkedInBaggage") {
                extraInfos.push(getCheckedInBaggageExtraInfo(extras[extraKey]));
            }
            if (extraKey === "fastTrack") {
                extraInfos.push(getGenericExtraInfo(extras[extraKey]));
            }
            if (extraKey === "flexibleTicket") {
                extraInfos.push(getGenericExtraInfo(extras[extraKey]));
            }
            if (extraKey === "mealChoice") {
                extraInfos.push(getMealChoiceExtraInfo(extras[extraKey]));
            }
            if (extraKey === "mobileTravelPlan") {
                extraInfos.push(getGenericExtraInfo(extras[extraKey]));
            }
            if (extraKey === "seatMapSelection") {
                extraInfos.push(getSeatMapSelectionExtraInfo(extras[extraKey]));
            }
            if (extraKey === "seatingBesides") {
                extraInfos.push(getGenericExtraInfo(extras[extraKey]));
            }
            if (extraKey === "seatingPreference") {
                extraInfos.push(getSeatingPreferenceExtraInfo(extras[extraKey]));
            }
            if (extraKey === "specialBag") {
                extraInfos.push(...extras[extraKey].map((extra) => getSpecialBagExtraInfo(extra)));
            }
            if (extraKey === "travelInsurance") {
                extraInfos.push(getTravelInsuranceExtraInfo(extras[extraKey]));
            }
            const extraItems = extraInfos
                .map(({ totalPrice, count }) => {
                const title = getExtraCopy(i18n, extraKey, count || 0, options);
                return transformUnifiedPriceBreakdownItem(i18n, ["EXTRA", extraKey], "EXTRA", title, totalPrice, options);
            })
                .flat();
            items.push(...extraItems);
        }
    });
    // filter out extras with zero price
    return items.filter((item) => !priceIsZero(item.price));
};
export const transformUnifiedPriceBreakdownItem = (i18n, idPrefix, scope, title, priceBreakdown, options) => {
    const items = [];
    const discountItems = [];
    const baseFarePriceItem = priceBreakdown.baseFare && {
        id: generateId([...idPrefix, "baseFare"]),
        title: getBaseFareCopy(i18n, options),
        price: priceBreakdown.baseFare,
        items: []
    };
    if (priceBreakdown.fee) {
        const fee = priceBreakdown.fee;
        if (!isPriceZero(fee)) {
            const id = generateId([...idPrefix, "fee"]);
            if (priceToNumber(fee) > 0) {
                items.push({
                    id,
                    title: scope === "EXTRA"
                        ? i18n.trans(t("flights_price_details_extras_bag_expanded_third_party"))
                        : i18n.trans(t("flights_price_details_flight_expanded_third_party")),
                    price: fee,
                    items: []
                });
            }
            else {
                discountItems.push({
                    scope: "DISCOUNT",
                    id,
                    title: i18n.trans(t("flights_price_details_discounts_expanded_third_party")),
                    price: fee,
                    items: []
                });
            }
        }
    }
    if (priceBreakdown.tax) {
        const id = generateId([...idPrefix, "tax"]);
        const taxItem = {
            id,
            title: getTaxCopy(i18n, options),
            price: priceBreakdown.tax,
            items: []
        };
        if (priceBreakdown.moreTaxesAndFees) {
            taxItem.items.push(...(transformMoreTaxesAndFees(i18n, [...idPrefix, "tax"], priceBreakdown.moreTaxesAndFees) || []));
        }
        items.push(taxItem);
    }
    if (priceBreakdown.bcomPricingItems && priceBreakdown.bcomPricingItems.length > 0) {
        // charge items
        const chargeBcomPricingItems = priceBreakdown.bcomPricingItems.filter(({ amount }) => priceToValue(amount) > 0);
        transformBcomPricingItems(i18n, idPrefix, scope, chargeBcomPricingItems)?.forEach((bcomPricingItem) => {
            items.push(bcomPricingItem);
        });
        // discount items
        const discountBcomPricingItems = priceBreakdown.bcomPricingItems.filter(({ amount }) => priceToValue(amount) < 0);
        transformBcomPricingItems(i18n, ["DISCOUNT"], scope, discountBcomPricingItems)?.forEach((bcomPricingItem) => {
            discountItems.push({
                ...bcomPricingItem,
                scope: "DISCOUNT"
            });
        });
    }
    let filteredPriceItems = baseFarePriceItem ? [baseFarePriceItem] : [];
    if (FLIGHT_SCOPES.includes(scope)) {
        // filter out item with zero price except base fare
        filteredPriceItems.push(...items.filter((item) => !isPriceZero(item.price)));
    }
    else {
        // filter out item with zero price
        filteredPriceItems = [...filteredPriceItems, ...items].filter((item) => !isPriceZero(item.price));
        // return empty if there is only one sub-item
        if (filteredPriceItems.length <= 1) {
            filteredPriceItems = [];
        }
    }
    return [
        {
            scope,
            id: generateId(idPrefix),
            title,
            price: priceBreakdown.totalWithoutDiscount || priceBreakdown.total,
            items: filteredPriceItems
        },
        ...discountItems
    ];
};
export const transformMoreTaxesAndFees = (i18n, idPrefix, moreTaxesAndFees) => {
    return Object.keys(moreTaxesAndFees).reduce((acc, key) => {
        if (moreTaxesAndFees[key] && moreTaxesAndFees[key].taxesAndFeesTotals) {
            const taxesAndFeesTotals = moreTaxesAndFees[key].taxesAndFeesTotals;
            const items = Object.keys(taxesAndFeesTotals).map((key) => ({
                id: generateId([...idPrefix, key]),
                title: getMoreTaxesAndFeesCopy(i18n, key),
                price: taxesAndFeesTotals[key],
                items: []
            }));
            acc.push(...items);
        }
        return acc;
    }, []);
};
export const transformBcomPricingItems = (i18n, idPrefix, scope, bcomPricingItems) => {
    return bcomPricingItems?.reduce((acc, bcomPricingItem) => {
        // return Platform fee instead of Platform service fee for extras
        const title = scope === "EXTRA" && bcomPricingItem.itemType === "PLATFORM_SERVICE_FEE"
            ? i18n.trans(t("flights_price_details_extras_bag_expanded_platform"))
            : bcomPricingItem.name;
        acc.push({
            id: generateId([...idPrefix, bcomPricingItem.itemType]),
            title,
            price: bcomPricingItem.amount,
            items: []
        });
        return acc;
    }, []);
};
export const groupDiscountItems = (items) => {
    const chargeItems = items.filter((item) => item.scope !== "DISCOUNT");
    const discountItems = Object.values(items
        .filter((item) => item.scope === "DISCOUNT")
        .reduce((acc, item) => {
        if (acc[item.title]) {
            acc[item.title] = {
                ...acc[item.title],
                price: sumPrice(acc[item.title].price, item.price)
            };
        }
        else {
            acc[item.title] = item;
        }
        return acc;
    }, {}));
    return [...chargeItems, ...discountItems];
};
const getGenericExtraInfo = (input) => ({ totalPrice: input.price });
const getCabinBaggagePerTravellerExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.travelerBaggages.length
});
const getCheckedInBaggageExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.travelerBaggages.length
});
const getMealChoiceExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.mealPreferences.length
});
const getSeatMapSelectionExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.seats.length
});
const getSeatingPreferenceExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.seatingOptions.length
});
const getSpecialBagExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.travelerPrices.length
});
const getTravelInsuranceExtraInfo = (input) => ({
    totalPrice: input.price,
    count: input.travelerPrices.length
});
const getBaseFareCopy = (i18n, options) => {
    if (options.productType === "travelInsurance") {
        return i18n.trans(t("flights_insurance_price_breakdown"));
    }
    else if (options.productType === "flight") {
        return i18n.trans(t("flights_price_details_flight_expanded_fare"));
    }
    return i18n.trans(t("flights_price_details_extras_bag_expanded_base"));
};
const getTaxCopy = (i18n, options) => {
    if (options.productType === "travelInsurance") {
        return i18n.trans(t("flights_insurance_price_breakdown_tax"));
    }
    else if (options.isFlightFromRyanAirHungary) {
        return i18n.trans(t("flights_ryanair_hungary_price_breakdown_tax"));
    }
    else {
        return options.pointOfSale === "us"
            ? i18n.trans(t("flights_price_breakdown_airline_fees"))
            : i18n.trans(t("flights_price_details_flight_expanded_airline_taxes"));
    }
};
const getMoreTaxesAndFeesCopy = (i18n, title) => {
    switch (title) {
        case "AirTransportationCharges":
            return i18n.trans(t("flights_canada_air_charges_header"));
        case "ThirdPartyCharges":
            return i18n.trans(t("flights_canada_third_party_header"));
        case "Harmonised sale tax":
            return i18n.trans(t("flights_canada_harmonised_sale_tax"));
        case "Immigration fee (XY2)":
            return i18n.trans(t("flights_canada_xy2_fee"));
        case "Transportation tax":
            return i18n.trans(t("flights_canada_transport_tax"));
        case "U.S Flight Segment Tax":
            return i18n.trans(t("flights_canada_segment_tax"));
        case "Air Travellers Security Charge":
            return i18n.trans(t("flights_canada_security_charge"));
        case "Goods and Services Tax (XG8)":
            return i18n.trans(t("flights_canada_xg8_fee"));
        case "U.S Agriculture Fee":
            return i18n.trans(t("flights_canada_agriculture_fee"));
        case "U.S Customs Fee":
            return i18n.trans(t("flights_canada_customs_fee"));
        case "Canada Airport Improvement Fee":
            return i18n.trans(t("flights_canada_airport_improvement"));
        default:
            return title;
    }
};
export const getExtraCopy = (i18n, extraKey, count, options) => {
    switch (extraKey) {
        case "cabinBaggage":
        case "cabinBaggagePerTraveller":
            return i18n.trans(t("flights_price_details_extras_cabin", { variables: { num_cabin: count }, num_exception: count }));
        case "cancelForAnyReason":
            return i18n.trans(t("flights_price_details_extras_cfar"));
        case "checkedInBaggage":
            return i18n.trans(t("flights_price_details_extras_checked", { variables: { num_checked: count }, num_exception: count }));
        case "fastTrack":
            return i18n.trans(t("flights_price_details_extras_fasttrack"));
        case "flexibleTicket":
            return count > 1
                ? i18n.trans(t("flights_price_details_extras_flex_tickets_multi"))
                : i18n.trans(t("flights_price_details_extras_flex_tickets_solo"));
        case "mealChoice":
            return i18n.trans(t("flights_ancillary_mealplan_name"));
        case "mobileTravelPlan":
            return i18n.trans(t("flights_ancillary_mobileplan_name"));
        case "seatMapSelection":
            return i18n.trans(t("flights_price_details_extras_seats", { variables: { num_seats: count }, num_exception: count }));
        case "seatingBesides":
            return i18n.trans(t("flights_ancillary_seatstogether_name"));
        case "seatingPreference":
            return i18n.trans(t("flights_ancillary_seatlocation_name"));
        case "specialBag":
            return i18n.trans(t("flights_ancillary_baggage_special"));
        case "travelInsurance":
            if ([
                "ch", // Switzerland
                "ca" // Canada
            ].includes(options.pointOfSale)) {
                return i18n.trans(t("flights_price_details_extras_travel_insurance"));
            }
            else if ([
                ...EEA_COUNTRY_CODE_LIST, // EEA
                "br", // Brazil
                "us" // USA
            ].includes(options.pointOfSale)) {
                return i18n.trans(t("flights_price_details_extras_travel_protection"));
            }
            else if ([
                "mx", // Mexico
                "cl", // Chile
                "co", // Colombia
                "pe" // Peru
            ].includes(options.pointOfSale)) {
                return i18n.trans(t("flights_price_details_extras_travel_assistance"));
            }
            else if ([
                "au", // Australia
                "nz", // New Zeland
                "jp", // Japan
                "in" // Indonesia
            ].includes(options.pointOfSale)) {
                return i18n.trans(t("flights_price_details_extras_trip_protection"));
            }
            else if (options.pointOfSale === "kr" // Korea
            ) {
                return i18n.trans(t("flights_price_details_extras_medical_protection"));
            }
            return i18n.trans(t("flights_price_details_extras_travel_insurance"));
        default:
            return i18n.trans(t("flights_error_unknown_header"));
    }
};
export const generateId = (elements) => {
    return elements
        .map((element) => element
        // replace "-" with "_"
        .replace("-", "_")
        // replace non [0-9a-zA-Z] with "_"
        .replace(/[^0-9a-zA-Z]/g, "_")
        // remove multiple consecutive "_"
        .replace(/_+/g, "_")
        // trim the "-"
        .replace(/^_+|_+$/g, "")
        // to lowercase
        .toLowerCase())
        .join("-");
};
