/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import { useHistory } from "react-router-dom";
import { format as formatDate } from "date-fns";

import {
  SBSearchCallbackParams,
  SBDirectFlightsChangeCallbackParams,
  SBTrackExperimentCustomGoalCallbackParams,
  SBTrackExperimentCallbackParams,
  SBState,
  SBCabinClass,
  ANYWHERE_LOCATION
} from "@bookingcom/flights-searchbox";

import { trackExperiment, trackExperimentStage, trackCustomGoal } from "../utils/et";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { trackMetaExperimentStage } from "../utils/meta-et-tracking";
import useCreateUrl from "./useCreateUrl";

// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions, useStore } from "../store";
import { actions as LoaderActions } from "../store/loadingScreen/actions";
import { actions as SearchResultsActions } from "../store/searchResults/actions";
import { actions as searchActions } from "../store/search/actions";
import { actions as flexibleDateMinPriceActions } from "../store/flexibleDateMinPrice/actions";

import useEventTracking from "./useEventTracking";
import useLocaleContext from "./useLocaleContext";
import useGlobalContext from "./useGlobalContext";
import { SEARCH_DATE_FORMAT } from "store/search/state";
import useUserAgent from "./useUserAgent";
// bb_flights_prompt_travel_purpose
import { actions as TravelPurposeActions } from "../store/travelPurpose/actions";
import { UICityLocation, UITravelPurpose } from "@flights/types";
import { useCallback, useEffect, useMemo } from "react";
import useRouteName from "./useRouteName";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useFormatPrice from "./useFormatPrice";
import { appendFeatureAndExperimentParams } from "@flights/client-fetch";
import useTrackExpConnectedNavigation from "./useTrackExpConnectedNavigation";
import { flightsSessionStore } from "@flights/web-api-utils-universal";
import flights_web_mdot_sb_multi_select_chip_v2 from "utils/experiments/funnel/flights_web_mdot_sb_multi_select_chip_v2";
import {
  ANYWHERE,
  RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS,
  INFANT_MAX_AGE,
  EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK
} from "@flights/constants";
import searchBoxSelectedDates from "utils/search-box-selected-dates";
import { trackWithDefaultStage } from "@flights/et-universal";
import { useDispatch } from "react-redux";
import { actions as searchBoxStateActions } from "store/searchBoxState/actions";
import useTrackExpPaxHaulTraffic from "./useTrackExpPaxHaulTraffic";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import { cloneDeep } from "lodash";

const CA_SOURCE_PAGES = {
  home: "flights_index_sb",
  landing: "flights_landing_sb",
  "fly-anywhere": "flights_fly_anywhere_sb",
  searchresults: "flights_search_sb",
  "pb-change-flight": "flights_postbooking_sb",
  "not-found": "flights_404_sb"
};

type Props = {
  hideFareCalendar: boolean;
};

const useSearchbox = (props?: Props) => {
  const expConnectedNavigation = useTrackExpConnectedNavigation();
  const history = useHistory();
  const { locale } = useLocaleContext();
  const { createUrl, enhanceURLSearchParams } = useCreateUrl();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const loaderActions = useActions(LoaderActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const actions = useActions(searchActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const searchResultsActions = useActions(SearchResultsActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const travelPurposeActions = useActions(TravelPurposeActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const flexibleActions = useActions(flexibleDateMinPriceActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const { supplierFeatures, requestId, apiPrefix, ipCountry, searchHistory } = useGlobalContext();
  const { isMobile } = useUserAgent();
  const trackV2 = useEventTracking("search_box", requestId);
  const routeName = useRouteName();
  const isMeta = isOfMetaOrigin();
  const { formatPrice } = useFormatPrice();
  const dispatch = useDispatch();
  const { trackPaxHaulStages } = useTrackExpPaxHaulTraffic();
  const { trackFlyAnywhereWww, trackFlyAnywhereMdot } = useTrackFlyAnywhere();

  const expMultiSelectVariantDesktop = "flights_web_ddot_sb_multi_select_chip_v3";

  const searchHandler = (callBackValues: SBSearchCallbackParams) => {
    const { fromAirport, toAirport, params, isDirect, searchState } = callBackValues;

    //Tracking cross sell experiment for route change
    const urlParams = new URLSearchParams(location.search);
    const origin = urlParams.get("from")?.split(".")[0]; // on index page the param is from=AMS and on SRs from = AMS.AIRPORT
    const destination = urlParams.get("to")?.split(".")[0]; // on index page the param is from=LON and on SRs from = AMS.CITY
    const onwardDate = urlParams.get("depart");
    const returnDate = urlParams.get("return");

    if (origin !== params.get("from")?.split(".")[0]) {
      // route_changed
      trackCustomGoal("trip_relevance_flights_ml_model_test_web", 1);
      trackCustomGoal("trip_relevance_gcp_limited_data_update_2024_12_web", 1);
      // source_changed
      trackCustomGoal("trip_relevance_flights_ml_model_test_web", 4);
      // route_changed stage
      trackExperimentStage("flights_topk_recp_web", 2);
    }

    if (destination !== params.get("to")?.split(".")[0]) {
      // route_changed
      trackCustomGoal("trip_relevance_flights_ml_model_test_web", 1);
      trackCustomGoal("trip_relevance_gcp_limited_data_update_2024_12_web", 1);
      // dest_changed
      trackCustomGoal("trip_relevance_flights_ml_model_test_web", 5);
      // route_changed stage
      trackExperimentStage("flights_topk_recp_web", 2);
    }

    if (onwardDate !== params.get("depart")) {
      // date_changed
      trackCustomGoal("flight_ml_model_web", 4);
    }

    if (returnDate !== params.get("return")) {
      // desparture_date_changed
      trackCustomGoal("flight_ml_model_web", 4);
    }

    const { sort } = store.search;
    params.set("sort", sort);

    // bb_flights_prompt_travel_purpose -> maintain state on search click
    params.set("travelPurpose", store.travelPurpose.travelPurpose);

    // history.push does not work when we are doing the same search again
    // refresh the search when user request the same search again
    if (createUrl(`?${params}`) === createUrl(window.location.search)) {
      window.location.reload();
    }

    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    const caSource = CA_SOURCE_PAGES[routeName];
    const caSourceParam = caSource ? `&ca_source=${caSource}` : "";

    let newSearchURL = "";

    if (toAirport.includes(ANYWHERE)) {
      params.set("to", ANYWHERE);
      newSearchURL = `/fly-anywhere/?${params}${caSourceParam}`;
    } else {
      newSearchURL = `/flights/${fromAirport}-${toAirport}/?${params}${caSourceParam}`;
    }

    actions.setValueFromSB(searchState);
    isDirect ? actions.resetFiltersButKeep({ stops: 0 }) : actions.resetFilters();

    searchResultsActions.reset();
    flexibleActions.reset();
    loaderActions.show();

    // flights_web_desktop_sb_combine_travellers_cabin_class -- start
    const isCabinChange = searchState.cabinClass !== "ECONOMY";
    const isTravellersChange = searchState.adults > 1 || searchState.children?.length > 0;
    if (routeName === "home") {
      if (isCabinChange) trackCustomGoal("flights_web_desktop_sb_combine_travellers_cabin_class", 1);
      if (isTravellersChange) trackCustomGoal("flights_web_desktop_sb_combine_travellers_cabin_class", 3);
    } else if (routeName === "searchresults") {
      if (isCabinChange) trackCustomGoal("flights_web_desktop_sb_combine_travellers_cabin_class", 2);
      if (isTravellersChange) trackCustomGoal("flights_web_desktop_sb_combine_travellers_cabin_class", 4);
    }
    // flights_web_desktop_sb_combine_travellers_cabin_class -- end

    // flights_web_mdot_sb_multi_select_chip_v2 -- start
    // flights_web_ddot_sb_multi_select_chip_v3 -- start
    const isSolo = Number(params.get("adults") || 0) + Number(params.get("children") || 0) === 1;
    const fromCount = (params.get("fromCountry") || "").split(",").filter(Boolean).length;
    const toCount = (params.get("toCountry") || "").split(",").filter(Boolean).length;
    const fromToCount = Math.max(fromCount, toCount);
    if (fromToCount == 1) trackCustomGoal(expMultiSelectVariantDesktop, 1);
    if (fromToCount == 2) trackCustomGoal(expMultiSelectVariantDesktop, 2);
    if (fromToCount > 2) trackCustomGoal(expMultiSelectVariantDesktop, 3);
    trackExperimentStage(expMultiSelectVariantDesktop, isSolo ? 6 : 7);

    if (isMobile) {
      if (fromToCount == 1) flights_web_mdot_sb_multi_select_chip_v2.goals.airport_city_count_1();
      if (fromToCount == 2) flights_web_mdot_sb_multi_select_chip_v2.goals.airport_city_count_2();
      if (fromToCount > 2) flights_web_mdot_sb_multi_select_chip_v2.goals.airport_city_count_3();

      if (isSolo) {
        flights_web_mdot_sb_multi_select_chip_v2.stages.solo();
      } else {
        flights_web_mdot_sb_multi_select_chip_v2.stages.non_solo();
      }
    }
    // flights_web_ddot_sb_multi_select_chip_v3 -- end
    // flights_web_mdot_sb_multi_select_chip_v2 -- end

    expConnectedNavigation.trackOnSearch(searchState);
    flightsSessionStore?.set?.("flights_web_sr_filter_top_filters_checkbox", false);
    trackPaxHaulStages();

    const departureDate = callBackValues.searchState.searchSegments?.[0]?.departureDate || onwardDate || "";

    if (
      callBackValues.searchState.type !== "MULTISTOP" &&
      callBackValues.searchState.searchSegments?.[0].from.length <= 1
    ) {
      isMobile ? trackFlyAnywhereMdot(departureDate) : trackFlyAnywhereWww(departureDate);
    }

    if (!!apiPrefix) {
      window.location.assign(apiPrefix + createUrl(newSearchURL));
    } else {
      history.push(createUrl(newSearchURL));
    }
  };

  const onDirectFlightsChangeHandler = (callbackParams: SBDirectFlightsChangeCallbackParams) => {
    const { isDirect, params } = callbackParams;
    const { filtersDraft } = store.search;
    const stopsFilterFromSB = isDirect ? 0 : undefined;

    actions.setFiltersDraft({
      ...filtersDraft,
      stops: stopsFilterFromSB
    });

    enhanceURLSearchParams(params);
  };

  const onGetSBExperimentVariant = (name: string) => {
    return trackExperiment(name);
  };

  const onTrackSBExperiment = (callbackParams: SBTrackExperimentCallbackParams) => {
    const { name, stage } = callbackParams;
    trackExperimentStage(name, stage);
  };

  const onTrackSBMetaExperimentStage = (callbackParams: SBTrackExperimentCallbackParams) => {
    const { name, stage } = callbackParams;
    // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
    trackMetaExperimentStage(name, stage);
  };

  const onTrackSBExperimentCustomGoal = (callbackParams: SBTrackExperimentCustomGoalCallbackParams) => {
    const { name, customGoal } = callbackParams;
    trackCustomGoal(name, customGoal);
  };

  const onTrackSBExperimentStage = (callbackParams: SBTrackExperimentCallbackParams) => {
    const { name, stage } = callbackParams;
    trackExperimentStage(name, stage);
  };

  const search = store.search;
  const stops = search.filtersDraft.stops;

  const { searchSegments } = search;
  const initialSearchSegments = cloneDeep(searchSegments);

  if (initialSearchSegments && initialSearchSegments[0]) {
    const currSegment = initialSearchSegments[0];
    if (currSegment.departureDate === undefined && currSegment.returnDate === undefined) {
      /**
       * If the user is coming from the fly anywhere page, we will pre-fill the dates as we did in the past.
       * This will be re-visited in the future.
       */
      if (routeName === "fly-anywhere") {
        // first Saturday after 29 days
        const baseDate = new Date(new Date().getTime() + 60 * 60 * 24 * 29 * 1000);
        const calculateBaseDate = new Date(baseDate);
        // baseDate is the Saturday of that week
        calculateBaseDate.setDate(baseDate.getDate() - baseDate.getDay() + 6);
        const startDate = new Date(calculateBaseDate);
        // we choose the next Saturday in case of return flight
        const endDate = new Date(calculateBaseDate.getTime() + 60 * 60 * 24 * 7 * 1000);

        initialSearchSegments[0].departureDate = formatDate(startDate, SEARCH_DATE_FORMAT);
        initialSearchSegments[0].returnDate = formatDate(endDate, SEARCH_DATE_FORMAT);
      } else {
        const { startDate, endDate } = searchBoxSelectedDates();
        if (startDate) initialSearchSegments[0].departureDate = formatDate(startDate, SEARCH_DATE_FORMAT);
        if (endDate) initialSearchSegments[0].returnDate = formatDate(endDate, SEARCH_DATE_FORMAT);
      }
    }

    if (routeName === "fly-anywhere" && currSegment.to.length === 0) {
      currSegment.to[0] = ANYWHERE_LOCATION as UICityLocation;
    }
  }

  const searchboxInitValue: SBState = useMemo(
    () => ({
      type: search.type,
      searchSegments: initialSearchSegments,
      adults: search.adults,
      children: search.children,
      /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
      // TODO: extend the SBCabinClass type with missing keys
      cabinClass: search.cabinClass as SBCabinClass,
      isDirect: search.isDirect,
      fareCalendar: search.fareCalendar
    }),
    [
      search.type,
      search.adults,
      search.children,
      search.cabinClass,
      search.isDirect,
      search.fareCalendar,
      initialSearchSegments
    ]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const travelPurpose = (urlParams.get("travelPurpose") || "leisure") as UITravelPurpose;
    if (travelPurpose === store?.travelPurpose?.travelPurpose) return;
    setTimeout(() => travelPurposeActions.setTravelPurpose(travelPurpose), 0);
  }, [travelPurposeActions, store]);

  // bb_flights_prompt_travel_purpose
  const onTravelPurposeChange = (value: UITravelPurpose) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("travelPurpose", value);
    // use window history instead of react to prevent rerender
    window.history.replaceState(null, "", `${location.pathname}?${urlParams.toString()}`);
    travelPurposeActions.setTravelPurpose(value);
  };

  useEffect(() => {
    if (isMobile) return;
    if (routeName == "home") {
      trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", 1);
      trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", isMeta ? 3 : 2);
    }
    if (routeName == "searchresults") {
      const adults = search?.adults || 1;
      const children = search.children?.length || 0;
      if (search.type === "ROUNDTRIP") trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", 4);
      if (search.type === "ONEWAY") trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", 5);
      if (adults === 1 && children === 0) {
        trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", 6);
      }
      if (adults > 1 && children === 0) {
        trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", 7);
      }
      if (adults >= 1 && children > 0) trackExperimentStage("flights_web_desktop_sb_combine_travellers_cabin_class", 8);
    }
  }, [isMobile, routeName, isMeta, search]);

  const fixInputSpacing =
    routeName === "home"
      ? !!trackExperiment("flights_web_ddot_sb_bg_color") &&
        searchHistory.some((i) => !!Object.keys(i).some((j) => RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS.includes(j)))
      : !!trackExperiment("flights_web_ddot_sb_bg_color");

  const desktopFareCalendarEnabled =
    !props?.hideFareCalendar &&
    !isMobile &&
    routeName == "searchresults" &&
    !!trackWithDefaultStage("flights_web_fare_calendar_v3", 1);

  const mdotFareCalendarEnabled =
    !props?.hideFareCalendar &&
    isMobile &&
    routeName == "searchresults" &&
    !!trackWithDefaultStage("flights_web_fare_calendar_v3", 1);

  const fareCalendarURL = useMemo(() => {
    const prefix = apiPrefix || "";
    return appendFeatureAndExperimentParams(`${prefix}/api/search/fare-calendar`);
  }, [apiPrefix]);

  useEffect(() => {
    if (isMobile) {
      if (isMeta) {
        flights_web_mdot_sb_multi_select_chip_v2.stages.meta();
      } else {
        flights_web_mdot_sb_multi_select_chip_v2.stages.direct();
      }
    } else {
      trackExperimentStage(expMultiSelectVariantDesktop, 1);
      trackExperimentStage(expMultiSelectVariantDesktop, routeName === "searchresults" ? 3 : 2);
      trackExperimentStage(expMultiSelectVariantDesktop, isMeta ? 5 : 4);
    }
  }, [isMobile, routeName, isMeta, expMultiSelectVariantDesktop]);

  useEffect(() => {
    expConnectedNavigation.setInitState(searchboxInitValue);
  }, [searchboxInitValue, expConnectedNavigation]);

  const multiSelectVariant = useMemo(() => {
    if (isMobile && !!flights_web_mdot_sb_multi_select_chip_v2.trackWithDefaultStage()) {
      return 2;
    }

    if (!isMobile && !!trackExperiment("flights_web_ddot_sb_multi_select_chip_v3")) {
      return 3;
    }

    return 0;
  }, [isMobile]);

  const onChange = useCallback((params: SBState) => dispatch(searchBoxStateActions.set(params)), [dispatch]);

  return {
    onSearch: searchHandler,
    onChange,
    onDirectFlightsChange: onDirectFlightsChangeHandler,
    initialValue: searchboxInitValue,
    directFlightsFilterApplied: stops === 0,
    track: () => {},
    trackV2,
    onGetSBExperimentVariant,
    onTrackSBExperiment,
    onTrackSBExperimentCustomGoal,
    onTrackSBExperimentStage,
    onTrackSBMetaExperimentStage,
    dateFNSLocale: locale,
    isMultiStopEnabled: supplierFeatures?.includes("SHOW_MULTI_STOP") || false,
    fareCalendarConfig: {
      desktop: {
        enabled: desktopFareCalendarEnabled,
        enabledUI: desktopFareCalendarEnabled,
        searchTypes: desktopFareCalendarEnabled ? ["ONEWAY" as const, "ROUNDTRIP" as const] : []
      },
      mdot: {
        enabled: mdotFareCalendarEnabled,
        enabledUI: mdotFareCalendarEnabled,
        searchTypes: mdotFareCalendarEnabled ? ["ONEWAY" as const, "ROUNDTRIP" as const] : []
      }
    },
    /* start - flights_web_fare_calendar_v3 */
    isSearchResults: routeName === "searchresults",
    /* end - flights_web_fare_calendar_v3 */
    fareCalendarURL,
    infantMaxAge: INFANT_MAX_AGE,
    priceFormatter: formatPrice,
    searchAutoCompleteBaseURL: !!apiPrefix ? { url: apiPrefix, accessToken: "" } : undefined,
    isA11yEnabled: Boolean(trackExperiment("flights_web_a11y_searchbox")),
    // bb_flights_prompt_travel_purpose
    travelPurpose: store.travelPurpose?.travelPurpose,
    onTravelPurposeChange: onTravelPurposeChange,
    isShowTravelPurposeCheckbox: Boolean(trackExperiment(EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK)),
    trackLandingGoal: routeName === "home", //prop to track recommendation platform custom goal in the searchbox
    isMobile,
    isMeta,
    ipCountry,
    multiSelectName: isMobile ? "flights_web_mdot_sb_multi_select_chip_v2" : "flights_web_ddot_sb_multi_select_chip_v3",
    multiSelectVariant,
    config: {
      hideMultiCityEmptyInputs: routeName === "searchresults"
    },
    fixInputSpacing,
    isFlyAnywhereExp:
      (!isMobile && !!trackWithDefaultStage("flights_web_cat_fly_anywhere_filters_www", 1)) ||
      (isMobile && !!trackWithDefaultStage("flights_web_cat_fly_anywhere_filters_mdot", 1))
  };
};

export default useSearchbox;
