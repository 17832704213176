/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Actionable, Button, Card, Divider, Icon, Stack } from "@bookingcom/bui-react";

import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import BaseFareAttributes from "./BaseFareAttributes";
import BaseFareName from "./BaseFareName";
import FlightCardCarriers from "./FlightCardCarriers";
import FlightCardLuggage from "./FlightCardLuggage";
import FlightCardPrice from "./FlightCardPrice";
import FlightCardSegmentMobile from "./FlightCardSegment.mobile";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../../store";
import { FlightCardBoundProps } from "./FlightCardBound.desktop";
import { ArrowNavDownIcon, ArrowNavUpIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useIsInViewport from "../../../../hooks/useIsInViewport";
import FareSelector from "branded-fares/components/FareSelector/components/FareSelector";
import useFetchBrandedFares from "branded-fares/hooks/useFetchBrandedFares";
import { FlightCardHighlight, FlightCardHighlightStyle } from "./FlightCardHighlight";
import { FlightCardBadges } from "./FlightCardBadges";
import { FlightCardBadge } from "./FlightCardBadge";
import Frame from "components/elements/Frame";
import styles from "./FlightCardBound.module.css";
import { getCampaignBadgesConfig, getCampaignBestOfferHighlightConfig } from "utils/flightCampaignConfig";
import { RebookCostBreakdown } from "components/elements/RebookFlow/RebookCostBreakdown/RebookCostBreakdown";
import { FlightBadgeVariant } from "@flights/types";
import { mcn } from "../../../../utils/mergeClassnames";
import flights_apex_web_ancillaries_micro_conversion_aa from "utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import flights_merch_toolkit_offer_pinning from "utils/experiments/genius/flights_merch_toolkit_offer_pinning";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useProfiler from "hooks/useProfiler";
import { carrierUtils, isFeatureRunningClientSide } from "@flights/web-api-utils-universal";
import useGlobalContext from "hooks/useGlobalContext";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";
import flights_web_sr_card_redesign_mdot_v2 from "utils/experiments/funnel/flights_web_sr_card_redesign_mdot_v2";
import flights_apex_web_bf_on_sr_flex_badge from "utils/experiments/apex/flights_apex_web_bf_on_sr_flex_badge";
import { stage } from "@flights/et-universal";
import { useSelector } from "react-redux";
import { getSearchDisplayOptions } from "store/searchResults/selectors";
import { CardCarriersCombined } from "./elements/CardCarriersCombined/CardCarriersCombined";
import FlightCardLuggageWithCheckmarks from "./elements/FlightCardLuggageWithCheckmarks/FlightCardLuggageWithCheckmarks.mobile";

type Props = FlightCardBoundProps;

const FlightCardBound: FC<Props> = (props) => {
  const {
    flight,
    onClick,
    pricePerPerson,
    rebookProps,
    /* start - flights_alternative_fares_for_sold_out_errors_web */
    forceHideLastAvailableSeats = false,
    isAlternativeFareCard = false
    /* end - flights_alternative_fares_for_sold_out_errors_web */
  } = props;
  const [breakdownOpen, setBreakdownOpen] = useState(false);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const { searchCriteria, brandedFares } = useStore();
  const { adults, children } = searchCriteria;
  const i18n = useI18n() as I18nChildContext;
  const { isInternal } = useGlobalContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const displayOptions = useSelector(getSearchDisplayOptions);
  const hasBrandedFares = !!flight.brandedFareOffers && flight.brandedFareOffers?.length > 0;
  const hasPrefetchedBrandedFareOffers = !flight.requestableBrandedFares && hasBrandedFares;
  const showFareSelectorDefault =
    (flight.requestableBrandedFares || hasBrandedFares) && !!displayOptions?.brandedFaresShownByDefault;
  const [showFareSelector, toggleShowFareSelector] = useState(showFareSelectorDefault);
  const flightCardBoundRef = useRef<HTMLButtonElement>(null);
  const isInViewport = useIsInViewport(flightCardBoundRef.current);

  /* start - flights_web_sr_redesign_mdot_v2 */
  const canShowFareSelector = flight.requestableBrandedFares || hasPrefetchedBrandedFareOffers;
  /* end - flights_web_sr_redesign_mdot_v2 */

  const shouldPinOfferOnSr =
    flight.highlightInfo?.highlight &&
    ((Boolean(trackExperiment("flights_web_cat_pin_flight_mdot")) && isOfMetaOrigin()) ||
      isFeatureRunningClientSide("FLIGHTS_WEB_PIN_OFFER_ON_SR_FROM_REDIRECT") ||
      flight.highlightInfo.reason === "CROSS_SELL_OFFER");

  const { bestOfferHighlightStyle, bestOfferHighlightText } = getCampaignBestOfferHighlightConfig(
    flight.campaignDisplay?.bestOfferHighlight
  );
  const shouldShowBestOfferHightlight = !!(bestOfferHighlightStyle && bestOfferHighlightText);

  const campaignBadges = getCampaignBadgesConfig(flight.campaignDisplay?.badges);

  const carriers = carrierUtils.getMarketingCarriers(flight.segments);

  const brandedFaresAvailable = (brandedFares.fareOffersWithDetails[flight.token]?.brandedFareOffers?.length || 0) > 0;
  const hideBrandedFaresBeforeAvailable = isFeatureRunningClientSide("FLIGHTS_ONLY_SHOW_BF_ON_SR_CTA_WHEN_AVAILABLE");

  useFetchBrandedFares(
    flight.token,
    isInViewport,
    flight.requestableBrandedFares ?? false,
    true,
    !!flight.brandedFareInfo?.fareName,
    carriers
  );

  const flightCardLuggage = flights_web_sr_card_redesign_mdot_v2.trackWithDefaultStage() ? (
    <FlightCardLuggageWithCheckmarks
      segments={flight.segments}
      showFareName={canShowFareSelector}
      brandedFareInfo={flight.brandedFareInfo}
      align="start"
    />
  ) : (
    <Frame direction="row" alignItems="center" justifyContent="flex-start" className={styles.luggage}>
      <FlightCardLuggage
        segments={flight.segments}
        brandedFareInfo={flight.brandedFareInfo}
        isAlternativeFareCard={isAlternativeFareCard}
      />
    </Frame>
  );

  /* start - flights_web_sr_card_redesign_mdot_v2 */
  const { segments } = flight;
  const uniqueCarriers = carrierUtils.uniqueCarriersFromSegments(segments);
  const areAllSegmentsDirect = useMemo(() => segments.every(({ legs }) => legs.length === 1), [segments]);
  const areAllSegmentsNonDirect = useMemo(() => segments.every(({ legs }) => legs.length > 1), [segments]);
  const areSegmentsMixed = useMemo(
    () => segments.some(({ legs }) => legs.length === 1) && segments.some(({ legs }) => legs.length > 1),
    [segments]
  );
  const hasSoleOperatingAirline = useMemo(() => {
    return uniqueCarriers.length === 1;
  }, [uniqueCarriers]);
  const hasMultipleOperatingAirlines = useMemo(() => {
    return uniqueCarriers.length > 1;
  }, [uniqueCarriers]);
  /* end - flights_web_sr_card_redesign_mdot_v2 */

  const handleOnClick = useCallback(() => {
    !breakdownOpen && onClick(flight);
    flights_apex_web_bf_on_sr_loading_message.goals.click_on_view_details_for_main_flight();
    flights_merch_toolkit_offer_pinning.trackFeaturedOfferClick(flight.badges || []);

    /* start - flights_web_sr_card_redesign_mdot_v2 */
    areAllSegmentsDirect && flights_web_sr_card_redesign_mdot_v2.goals.tapOnCardDirectSegments();
    areAllSegmentsNonDirect && flights_web_sr_card_redesign_mdot_v2.goals.tapOnCardNonDirectSegments();
    areSegmentsMixed && flights_web_sr_card_redesign_mdot_v2.goals.tapOnCardMixedSegments();
    hasSoleOperatingAirline && flights_web_sr_card_redesign_mdot_v2.goals.tapOnCardSoleOperatingAirline();
    hasMultipleOperatingAirlines && flights_web_sr_card_redesign_mdot_v2.goals.tapOnCardMultipleOperatingAirlines();

    /* end - flights_web_sr_card_redesign_mdot_v2 */
  }, [
    breakdownOpen,
    flight,
    onClick,
    areAllSegmentsDirect,
    areAllSegmentsNonDirect,
    areSegmentsMixed,
    hasSoleOperatingAirline,
    hasMultipleOperatingAirlines
  ]);

  const showFlexBadgeOnBrandedFare =
    flight.ancillaries?.flexibleTicket &&
    flight.requestableBrandedFares &&
    !!flights_apex_web_bf_on_sr_flex_badge.trackWithDefaultStage();

  const hasFlexibleTicketBadge = flight.ancillaries?.flexibleTicket && !flight.requestableBrandedFares;

  const showFlightCardBadges =
    flight.badges?.length ||
    campaignBadges.length > 0 ||
    hasFlexibleTicketBadge ||
    showFlexBadgeOnBrandedFare ||
    flight.hasAppleCardBenefits;

  useEffect(() => {
    if (flight.requestableBrandedFares && flight.ancillaries?.flexibleTicket) {
      flights_apex_web_bf_on_sr_flex_badge.stages.mdot();
      flights_apex_web_bf_on_sr_flex_badge.stages.search_results();
    }
  }, [flight.ancillaries?.flexibleTicket, flight.brandedFareOffers, flight.requestableBrandedFares]);

  // flights_web_mdot_sr_card_single_cta -- start
  const profiler = useProfiler();
  const onClickExpand = useCallback(() => {
    trackCustomGoal("flights_web_mdot_sr_card_single_cta", 1);
    stage("flights_apex_branded_fare_request_timeout_change_web", 7);
  }, []);

  useEffect(() => {
    trackExperimentStage("flights_web_mdot_sr_card_single_cta", 1);
    if (profiler.isDirect()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 2);
    if (profiler.isMeta()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 3);
    if (profiler.isSolo()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 4);
    if (!profiler.isSolo()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 5);
  }, [flight.requestableBrandedFares, profiler]);
  // flights_web_mdot_sr_card_single_cta -- end

  const highlightFireflySR =
    isInternal &&
    flight.token.startsWith("c8e49_") &&
    new URLSearchParams(window.location.search).get("highlight_firefly") === "1";

  return (
    <Card
      fill
      className={mcn(styles.card, highlightFireflySR && styles.highlightFirefly)}
      attributes={{ ref: flightCardBoundRef }}
      variant={shouldPinOfferOnSr ? "elevated" : "neutral"}
    >
      <Actionable ref={buttonRef} onClick={handleOnClick} className={mcn(styles.button, styles.outboundButtonCard)}>
        {shouldPinOfferOnSr ? (
          <FlightCardHighlight
            style={FlightCardHighlightStyle.PRIMARY}
            text={flight.highlightInfo?.message || i18n.trans(t("flights_meta_sr_pinned_flight_label"))}
          />
        ) : shouldShowBestOfferHightlight ? (
          <FlightCardHighlight style={bestOfferHighlightStyle} text={bestOfferHighlightText} textEmphasized={true} />
        ) : null}

        <Frame pt={4} pr={4} pb={2} pl={4} direction="column" justifyContent="space-between">
          {showFlightCardBadges && (
            <FlightCardBadges className={styles.badges}>
              {campaignBadges.map((badge, idx) => (
                <FlightCardBadge key={`keyCampaignBadge-${idx}`} alternative={false} isInteractive={false} {...badge} />
              ))}

              {flight?.badges?.map(({ text, variant, type }, idx) => (
                <FlightCardBadge
                  key={text + idx}
                  text={text}
                  style={variant}
                  alternative={type != "FEATURED"}
                  type={type}
                />
              ))}

              {(hasFlexibleTicketBadge || showFlexBadgeOnBrandedFare) && (
                <FlightCardBadge
                  style={FlightBadgeVariant.CONSTRUCTIVE}
                  alternative={true}
                  text={i18n.trans(t("flights_apex_sr_flex_badge"))}
                />
              )}

              {flight.hasAppleCardBenefits && (
                <FlightCardBadge
                  style={FlightBadgeVariant.CALLOUT}
                  alternative={true}
                  text={i18n.trans(t("apple_rewards_flights_sr_badge_title"))}
                />
              )}
            </FlightCardBadges>
          )}

          <BaseFareAttributes flight={flight} />
          <Frame
            attributes={{
              style: {
                width: "100%"
              }
            }}
            justifyContent="space-around"
          >
            {flight.segments.map((segment, segmentIndex) => {
              return (
                <Frame key={segmentIndex} className={styles.segment}>
                  <FlightCardSegmentMobile segment={segment} idx={segmentIndex} />

                  {!flights_web_sr_card_redesign_mdot_v2.trackWithDefaultStage() && (
                    <Frame>
                      <FlightCardCarriers
                        segment={segment}
                        idx={segmentIndex}
                        isAlternativeFareCard={isAlternativeFareCard}
                      />
                    </Frame>
                  )}
                </Frame>
              );
            })}
            {!!flights_web_sr_card_redesign_mdot_v2.trackWithDefaultStage() && (
              <Frame>
                <CardCarriersCombined segments={flight.segments} />
              </Frame>
            )}
          </Frame>
          <Divider className={styles.divider} />
          <Frame direction="row" justifyContent="space-between" alignItems="center" className={styles.priceBlock}>
            {rebookProps ? (
              <Frame direction="row" alignItems="center" justifyContent="flex-start" className={styles.luggage}>
                <FlightCardLuggage
                  segments={rebookProps.tripRebookOption.segments}
                  isAlternativeFareCard={isAlternativeFareCard}
                />
              </Frame>
            ) : (
              flightCardLuggage
            )}

            <Frame className={styles.price}>
              <BaseFareName flight={flight} />
              {rebookProps ? (
                <RebookCostBreakdown
                  {...rebookProps}
                  breakdownOpen={breakdownOpen}
                  setBreakdownOpen={setBreakdownOpen}
                />
              ) : (
                <FlightCardPrice
                  flight={flight}
                  breakdownOpen={breakdownOpen}
                  setBreakdownOpen={setBreakdownOpen}
                  showPricePerPerson={pricePerPerson && adults + children.length > 1}
                  showAvailableSeats={!forceHideLastAvailableSeats}
                  popoverCloseAriaLabel={i18n.trans(t("flights_a11y_price_breakdown_close_sr"))}
                />
              )}
            </Frame>
          </Frame>
        </Frame>
      </Actionable>

      <Frame className={styles.buttons}>
        <Stack gap={3}>
          {flight.requestableBrandedFares &&
          !!trackExperiment("flights_web_mdot_sr_card_single_cta") &&
          !isAlternativeFareCard ? (
            <Button
              attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
              text={i18n.trans(t("flights_sr_bf_cta_ticket_options"))}
              onClick={(e) => {
                e.stopPropagation();
                onClickExpand();
                toggleShowFareSelector(!showFareSelector);
                if (!showFareSelector) flights_apex_web_bf_on_sr_flex_badge.goals.expands_offer();
                flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                flights_merch_toolkit_offer_pinning.trackFeaturedOfferClick(flight.badges || []);
              }}
              variant="secondary"
              iconPosition="end"
              icon={
                <Icon svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon} attributes={{ "aria-hidden": true }} />
              }
              className={`${styles.button} ${
                !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
              }`}
            />
          ) : (
            <>
              <Button
                ref={buttonRef}
                attributes={{
                  "aria-label": `${i18n.trans(t("flights_apex_bf_sr_cta1_view_details"))} ${
                    hasFlexibleTicketBadge || showFlexBadgeOnBrandedFare
                      ? i18n.trans(t("flights_apex_sr_flex_badge"))
                      : ""
                  }`
                }}
                text={i18n.trans(t("flights_apex_bf_sr_cta1_view_details"))}
                onClick={() => {
                  if (flight.ancillaries?.flexibleTicket && flight.requestableBrandedFares)
                    flights_apex_web_bf_on_sr_flex_badge.trackFare(!!flight?.ancillaries?.flexibleTicket, false);

                  flights_merch_toolkit_offer_pinning.trackFeaturedOfferClick(flight.badges || []);
                  flights_apex_web_bf_on_sr_loading_message.goals.click_on_view_details_for_main_flight();

                  onClick(flight);
                }}
                variant="secondary"
                className={styles.button}
              />
              {(flight.requestableBrandedFares && !isAlternativeFareCard) ||
              (!flight.requestableBrandedFares && hasPrefetchedBrandedFareOffers) ? (
                <Button
                  attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
                  text={
                    showFareSelector
                      ? i18n.trans(t("flights_apex_sr_flex_cta_hide"))
                      : i18n.trans(t("flights_apex_sr_flex_cta_explore"))
                  }
                  onClick={(e) => {
                    if (showFareSelector)
                      flights_apex_web_bf_on_sr_loading_message.goals.close_expanded_branded_fares();
                    e.stopPropagation();
                    onClickExpand();
                    flights_apex_web_bf_on_sr_flex_badge.goals.expands_offer();
                    toggleShowFareSelector(!showFareSelector);
                    flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                  }}
                  variant="tertiary"
                  iconPosition="end"
                  icon={
                    <Icon
                      svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  className={`${styles.button} ${
                    !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
                  }`}
                />
              ) : null}
            </>
          )}
        </Stack>
      </Frame>

      {showFareSelector && <FareSelector flight={flight} />}
    </Card>
  );
};

const FlightCard = React.memo(FlightCardBound);

export default FlightCard;
