import React, { useRef, useState } from "react";
import { UIAvailableExtraProducts, UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { Button, Card, Popover, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { lowerCase, startCase } from "lodash";
import { mcn } from "utils/mergeClassnames";
import useGoToFlightDetails from "branded-fares/hooks/useGoToFlightDetails";
import Frame from "components/elements/Frame";
import FareFeatures from "branded-fares/components/FareFeatures";
import FareFeaturesIcons from "branded-fares/components/FareFeatureIcons";
import FareCardPrice from "./FareCardPrice";
import styles from "./FareCard.desktop.module.css";
import {
  areExcludedFeaturesRequiredByAirline,
  areSellableFeaturesRequiredByAirline
} from "@flights/ancillaries-universal";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";
import flights_apex_web_show_all_flexibility_features from "utils/experiments/apex/flights_apex_web_show_all_flexibility_features";
import flights_apex_web_bf_on_sr_flex_badge from "utils/experiments/apex/flights_apex_web_bf_on_sr_flex_badge";
import flights_apex_web_branded_fare_icons_only from "utils/experiments/apex/flights_apex_web_branded_fare_icons_only";

type FareCardProps = {
  fareOffer: UIBrandedFareOrFlexTicket;
  baseOffer: UIFlightData;
  loadingStatus?: UIFetchStatus;
  index?: number;
  numberOfOffers?: number;
  ancillaries?: UIAvailableExtraProducts;
  isCompact?: boolean;
};

const FareCardDesktop = ({ baseOffer, fareOffer, loadingStatus, index, numberOfOffers, isCompact }: FareCardProps) => {
  const i18n = useI18n();
  const theme = useTheme();
  const popoverRef = useRef(document.body);
  const goToFlightDetails = useGoToFlightDetails();
  const sellableFeaturesRequiredByAirline = areSellableFeaturesRequiredByAirline(baseOffer.segments);
  const excludedFeaturesRequiredByAirline = areExcludedFeaturesRequiredByAirline(baseOffer.segments);
  const [popoverShown, setPopoverShown] = useState(false);

  const { brandedFareInfo, isFlexTicket } = fareOffer;

  if (popoverShown) flights_apex_web_show_all_flexibility_features.trackStages(false, "search", [{ brandedFareInfo }]);

  const renderedFeatures = (
    <FareFeatures
      brandedFareInfo={brandedFareInfo}
      sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
      excludedFeaturesRequiredByAirline={excludedFeaturesRequiredByAirline}
      showHeader={true}
      isPreCheckAndPay={true}
      areShown={popoverShown}
    />
  );

  return (
    <Popover
      triggerType="hover"
      containerRef={popoverRef}
      onOpen={() => {
        setPopoverShown(true);
        flights_apex_web_branded_fare_icons_only.stages.bf_hovered_sr();
      }}
      onClose={() => {
        setPopoverShown(false);
      }}
    >
      <Popover.Trigger className={styles.popoverTrigger}>
        {(attributes) => (
          <Card className={styles.card}>
            <div>
              <Stack
                attributes={{
                  ...attributes,
                  tabIndex: 0
                }}
                justifyContent="space-between"
              >
                <Text
                  className={!isCompact && styles.title}
                  variant={isCompact ? "strong_2" : "strong_1"}
                  attributes={{ "data-fare-card-row": "title" }}
                >
                  {loadingStatus === "loading" ? (
                    <Stack gap={6} direction="row">
                      <Stack.Item grow>
                        <SkeletonLoader variant={isCompact ? "title" : undefined} />
                      </Stack.Item>
                      <SkeletonLoader width={theme.units.spacing_12x} />
                    </Stack>
                  ) : (
                    startCase(lowerCase(brandedFareInfo?.fareName))
                  )}
                </Text>
                <div>
                  <Frame pb={2} className={!isCompact && styles.fareFeaturesIcons} data-fare-card-row="icons">
                    <FareFeaturesIcons
                      brandedFareInfo={brandedFareInfo}
                      limit={7}
                      isLarge={!isCompact}
                      isCompact={true}
                      isPreCheckAndPay={true}
                    />
                  </Frame>
                  <FareCardPrice
                    fareOffer={fareOffer}
                    baseOffer={baseOffer}
                    isFlexTicket={isFlexTicket}
                    isBold={isCompact}
                  />
                  {renderedFeatures && (
                    <div id="features-aria" className={styles.ariaInvisible}>
                      {renderedFeatures}
                    </div>
                  )}
                </div>
              </Stack>
              <Button
                onClick={() => {
                  flights_apex_web_bf_on_sr_loading_message.goals.click_on_any_fare_card();

                  if (index && index > 0) {
                    flights_apex_web_bf_on_sr_loading_message.goals.click_on_upsell_fare_card();
                  }

                  if (baseOffer.ancillaries?.flexibleTicket)
                    flights_apex_web_bf_on_sr_flex_badge.trackFare(
                      !!fareOffer.ancillaries?.flexibleTicket,
                      (index || 0) > 0
                    );

                  goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket);
                }}
                className={mcn(styles.button, isCompact && styles.btnPadding)}
                variant="secondary"
                attributes={{
                  ...(index !== undefined && {
                    "aria-label": `${index + 1} of ${numberOfOffers}`
                  })
                }}
              >
                {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
              </Button>
            </div>
          </Card>
        )}
      </Popover.Trigger>
      {renderedFeatures ? (
        <Popover.Content
          attributes={{
            style: {
              width: 240,
              whiteSpace: "pre-wrap",
              overflow: "hidden"
            }
          }}
        >
          {renderedFeatures}
        </Popover.Content>
      ) : null}
    </Popover>
  );
};

export default FareCardDesktop;
