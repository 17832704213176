import { t, useI18n } from "@bookingcom/lingojs-react";
import useSearchFiltersController from "../../useSearchFiltersController";
import { useCallback } from "react";
import { useFormatDateTime } from "@bookingcom/flights-core";
import cloneDeep from "lodash/cloneDeep";

type HandleChange = {
  type: "departure" | "arrival";
  checked: boolean;
  optionIndex: number;
  timeIndex: number;
};

export const useFlightTimesFilterController = () => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { flightTimes } = useSearchFiltersController();

  const getFormattedTime = useCallback(
    (start?: string | null, end?: string | null) => {
      if (!start || !end) return;
      const startTime = `2000-01-01T${start}:00`;
      const endTime = `2000-01-01T${end}:00`;
      return i18n.trans(
        t("flights_filters_times_range", {
          variables: {
            start_time: formats.flightTime(startTime),
            end_time: formats.flightTime(endTime)
          }
        })
      );
    },
    [i18n, formats]
  );

  const handleChange = ({ type, checked, optionIndex, timeIndex }: HandleChange) => {
    const result = cloneDeep(flightTimes.value);
    result[optionIndex][type][timeIndex].selected = checked;
    flightTimes.set(result);
  };

  return {
    isFilterAvailable: flightTimes.options.length > 0,
    title: i18n.trans(t("flights_filters_times_title")),
    resetLabel: i18n.trans(t("flights_filter_reset")),
    showReset: flightTimes.touched,
    onClickReset: flightTimes.reset,
    moreLabel: i18n.trans(t("flights_filters_more_flights_action")),
    options: flightTimes.options.map((o, optionIndex) => ({
      departure: o.departure.map((d, departureIndex) => {
        const formattedTimeLabel = getFormattedTime(d.start, d.end);
        return {
          count: typeof d.count === "number" ? d.count : undefined,
          label: formattedTimeLabel || `${d.start} - ${d.end}`,
          name: `departure-${d.start}-${d.end}`,
          disabled: d.count === 0,
          checked: flightTimes.value[optionIndex].departure[departureIndex].selected
        };
      }),
      arrival: o.arrival.map((a, arrivalIndex) => {
        const formattedTimeLabel = getFormattedTime(a.start, a.end);
        return {
          count: typeof a.count === "number" ? a.count : undefined,
          label: formattedTimeLabel || `${a.start} - ${a.end}`,
          name: `arrival-${a.start}-${a.end}`,
          disabled: a.count === 0,
          checked: flightTimes.value[optionIndex].arrival[arrivalIndex].selected
        };
      }),
      labels: {
        title: flightTimes.getTitle(optionIndex),
        departure: flightTimes.getLabel(optionIndex, "departure"),
        arrival: flightTimes.getLabel(optionIndex, "arrival")
      }
    })),
    onChange: handleChange
  };
};
