import { SBState } from "@bookingcom/flights-searchbox";
import { ActionsUnion, createAction } from "@bookingcom/flights-core";

export enum ActionTypes {
  set = "searchBoxState/set"
}

export const actions = {
  set: createAction((searchBoxState: SBState) => ({
    type: ActionTypes.set,
    payload: { searchBoxState }
  }))
};

export type Actions = ActionsUnion<typeof actions>;
