import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { UIFlightSegment } from "@flights/types";
import useGlobalContext from "hooks/useGlobalContext";
import { getLeastAvailableLuggagePerTrip } from "store/selectors/searchResultsAvailableLuggage";

export const useFlightCardLuggageWithCheckmarksController = (segments: UIFlightSegment[]) => {
  const { supportsChildrenTickets } = useGlobalContext();
  const i18n = useI18n();

  const { cabinLuggage, personalItem, checkedInLuggage } = getLeastAvailableLuggagePerTrip(
    segments,
    supportsChildrenTickets
  );

  return {
    cabinBagText: cabinLuggage ? i18n.trans(t("flights_sr_hover_cabin_bag")) : undefined,
    personalBagText: personalItem ? i18n.trans(t("flights_sr_hover_personal_item")) : undefined,
    checkedBagText: checkedInLuggage ? i18n.trans(t("flights_sr_hover_checked_bag")) : undefined,
    personalAriaLabel: i18n.trans(t("a11y_flights_mdot_sr_personal_included")),
    cabinAriaLabel: i18n.trans(t("a11y_flights_mdot_sr_cabin_included")),
    checkedAriaLabel: i18n.trans(t("a11y_flights_mdot_sr_checked_included")),
    titleText: i18n.trans(t("flights_sr_hover_included_baggage"))
  };
};
