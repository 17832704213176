import { t, useI18n } from "@bookingcom/lingojs-react";
import useSearchFiltersController from "../../useSearchFiltersController";
import { useCallback, useEffect, useMemo, useState } from "react";
import useFormatPrice from "hooks/useFormatPrice";
import flights_web_filters_budget_per_adult_2, {
  useTrackBudgetFilterPerAdultStages
} from "utils/experiments/funnel/flights_web_filters_budget_per_adult_2";
import { Entries } from "@flights/types";

/**
 * Generates an array of exponentially increasing steps between the specified minimum and maximum values.
 * The function calculates the growth factor based on the number of desired steps and iteratively computes
 * each step by multiplying the minimum value by the growth factor raised to the power of the current step index.
 * Duplicate values are automatically removed to ensure a unique set of steps.
 */
function generateSteps(min: number, max: number, steps: number) {
  const result = new Set<number>();
  const factor = Math.pow(max / min, 1 / (steps - 1));
  for (let i = 0; i < steps; i++) {
    const value = Math.round(min * Math.pow(factor, i));
    result.add(value);
  }
  return Array.from(result);
}

export const useBudgetPerAdultFilterController = () => {
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();
  const allFilters = useSearchFiltersController();
  const { budgetPerAdult } = allFilters;
  const [stepValue, setStepValue] = useState(0);
  const trackStages = useTrackBudgetFilterPerAdultStages();

  /* start - used_price_filter_plus_other_filters*/
  const [trackedSingle, setTrackedSingle] = useState(false);
  const [trackedMulti, setTrackedMulti] = useState(false);
  /* end - used_price_filter_plus_other_filters*/

  const price = useCallback(
    (units: number) => formatPrice({ nanos: 0, units, currencyCode: budgetPerAdult.options.max.currencyCode }),
    [formatPrice, budgetPerAdult.options.max.currencyCode]
  );

  const isFilterAvailable =
    budgetPerAdult.options.max.units !== 0 &&
    budgetPerAdult.options.min.units !== 0 &&
    budgetPerAdult.options.min.units !== budgetPerAdult.options.max.units;

  /* start - used_price_filter_plus_other_filters*/
  if (isFilterAvailable && budgetPerAdult.touched) {
    if (!trackedSingle) {
      flights_web_filters_budget_per_adult_2.goals.used_price_filter();
      setTrackedSingle(true);
    }

    if (!trackedMulti) {
      (Object.entries(allFilters) as Entries<typeof allFilters>).forEach(([k, v]) => {
        if (k !== "budgetPerAdult" && typeof v === "object" && "touched" in v && v.touched) {
          flights_web_filters_budget_per_adult_2.goals.used_price_filter_plus_other_filters();
          setTrackedMulti(true);
        }
      });
    }
  }
  /* end - used_price_filter_plus_other_filters*/

  if (isFilterAvailable) {
    trackStages();
  }

  const stepsValueArr = useMemo(() => {
    const min = budgetPerAdult.options.min.units;
    const max = budgetPerAdult.options.max.units;
    return generateSteps(min, max, 20);
  }, [budgetPerAdult.options.min.units, budgetPerAdult.options.max.units]);

  const getIndexByValue = useCallback(
    (value: number) => {
      const len = stepsValueArr.length;
      if (value <= stepsValueArr[0]) return 0;
      if (value >= stepsValueArr[len - 1]) return len - 1;
      let left = 0;
      let right = len - 1;
      while (left < right) {
        const mid = Math.floor((left + right) / 2);
        const valueMid = stepsValueArr[mid];
        if (valueMid === value || (valueMid > value && stepsValueArr[mid - 1] < value)) return mid;
        else if (valueMid < value) left = mid + 1;
        else right = mid;
      }
      return left;
    },
    [stepsValueArr]
  );

  useEffect(() => {
    setTimeout(() => setStepValue(getIndexByValue(budgetPerAdult.value)), 2);
  }, [getIndexByValue, budgetPerAdult.value]);

  const ariaLabel = useMemo(() => i18n.trans(t("a11y_flights_filter_price_announce")), [i18n]);

  const label = useMemo(
    () =>
      i18n.trans(
        t("flights_filter_price_subtitle", {
          variables: { num_max: price(budgetPerAdult.value) }
        })
      ),
    [i18n, price, budgetPerAdult.value]
  );

  return {
    isFilterAvailable: isFilterAvailable && flights_web_filters_budget_per_adult_2.trackWithDefaultStage(),
    title: i18n.trans(t("flights_filter_price_title")),
    resetLabel: i18n.trans(t("flights_filter_reset")),
    showReset: budgetPerAdult.touched,
    onClickReset: budgetPerAdult.reset,
    min: 0,
    max: stepsValueArr.length - 1,
    value: stepValue,
    ariaLabel,
    label,
    onChange: (idx: number) => setStepValue(idx),
    onChangeCommit: (idx: number) => budgetPerAdult.set(stepsValueArr[idx]),
    renderValue: () => null,
    interval: 1,
    renderTooltipValue: (idx: number) => price(stepsValueArr[idx])
  };
};
