import { useMemo } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { useSelector } from "react-redux";
import { getOrder } from "store/order/selectors";
import { isSirfApplied } from "store/selectors/sirf";

export function useSIRFController() {
  const i18n = useI18n();
  const order = useSelector(getOrder);

  const isSirf = useMemo(() => {
    if (!order?.airOrder?.appliedSubsidizedFares) return false;
    return isSirfApplied(order.airOrder.appliedSubsidizedFares);
  }, [order]);

  const text = useMemo(
    () => (isSirf ? i18n.trans(t("flights_price_details_sirf_applied")) : undefined),
    [isSirf, i18n]
  );
  return { text };
}
