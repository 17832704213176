import React, { FC } from "react";

import FlexibleDateBestPriceInner from "./FlexibleDateBestPriceInner";
import { useGetFlexDatesData } from "./useGetFlexDatesData";
import Frame from "../Frame";
import flights_web_positive_flexi_banner_blackout_ddot from "utils/experiments/funnel/flights_web_positive_flexi_banner_blackout_ddot";

const FlexibleDateBestPriceDesktop: FC = () => {
  const data = useGetFlexDatesData();
  if (!data) {
    return null;
  }

  flights_web_positive_flexi_banner_blackout_ddot.stages.allSr();

  const { dates, currentBestPrice, cheaperCount, isOneWay } = data;

  const isEligibleForBlackoutExp = cheaperCount === 0;
  const shouldHide =
    isEligibleForBlackoutExp && flights_web_positive_flexi_banner_blackout_ddot.trackWithDefaultStage();

  return (
    <>
      {isEligibleForBlackoutExp && <BlackoutExpTracker />}

      {shouldHide ? null : (
        <Frame mb={4}>
          <FlexibleDateBestPriceInner
            carouselSize="medium"
            dates={dates}
            currentBestPrice={currentBestPrice}
            cheaperCount={cheaperCount}
            isOneWay={isOneWay}
          />
        </Frame>
      )}
    </>
  );
};

const BlackoutExpTracker: FC = () => {
  flights_web_positive_flexi_banner_blackout_ddot.useTrackStages();
  return null;
};

export default FlexibleDateBestPriceDesktop;
