import { UIFlightData } from "@flights/types";
import { createMicroConversionTrackingUtils } from "..";
import { useEffect } from "react";
import useProfiler from "hooks/useProfiler";

const utils = createMicroConversionTrackingUtils({
  name: "flights_web_sr_card_redesign_ddot",
  stages: {
    main: 1,
    direct: 2,
    metaClosedFD: 3, // on meta, when user closes FD and lands back on SR
    metaSRinBackground: 4, // - when they are on FD, SR on background (learning only)
    oneWay: 5,
    roundTrip: 6,
    us: 7,
    families: 8
  },
  defaultStage: 1,
  mainStage: 1,
  goals: {
    usersWithCabinBagsCheckoutStart: 1,
    usersWithCheckedBagsCheckoutStart: 2,
    usersWithCabinAndCheckedBagsCheckoutStart: 3,
    popoverTriggered: 4
  },
  goalsWithValue: []
});

export const useTrackSRCardGoals = (flight: UIFlightData | undefined) => {
  const { isMobile } = useProfiler();

  useEffect(() => {
    if (isMobile()) {
      return;
    }
    if (!flight) return;
    if (!flight.ancillaries) return;
    const { cabinBaggage, checkedInBaggage } = flight.ancillaries;

    if (cabinBaggage) {
      utils.goals.usersWithCabinBagsCheckoutStart();
    }

    if (checkedInBaggage) {
      utils.goals.usersWithCheckedBagsCheckoutStart();
    }

    if (cabinBaggage && checkedInBaggage) {
      utils.goals.usersWithCabinAndCheckedBagsCheckoutStart();
    }
  }, [flight, isMobile]);
};

export default utils;
