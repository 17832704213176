import { createMicroConversionTrackingUtils } from "..";
import { useEffect } from "react";
import useProfiler from "hooks/useProfiler";

const utils = createMicroConversionTrackingUtils({
  name: "flights_web_positive_flexi_banner_blackout_mdot",
  stages: {
    allSr: 1,
    positiveBanner: 2,
    positiveBannerInViewport: 3,
    direct: 4,
    meta: 5,
    us: 6,
    solo: 7,
    nonSolo: 8
  },
  defaultStage: 2,
  mainStage: 3,
  goals: {},
  goalsWithValue: []
});

const useTrackStages = (isInViewport: boolean) => {
  const { stages } = utils;
  const { isDirect, isMeta, isAmerican, isSolo } = useProfiler();

  useEffect(() => {
    if (isInViewport) {
      stages.positiveBannerInViewport();

      isDirect() && stages.direct();
      isMeta() && stages.meta();
      isAmerican() && stages.us();
      isSolo() ? stages.solo() : stages.nonSolo();
    }
  }, [stages, isInViewport, isDirect, isMeta, isAmerican, isSolo]);
};

export default { ...utils, useTrackStages };
