import { createTrackingUtils } from "..";
import { UIFlightBadge } from "@flights/types";

const utils = createTrackingUtils({
  name: "flights_merch_toolkit_offer_pinning",
  stages: {},
  defaultStage: 1,
  goals: {
    featured_offer_click: 1,
    next_search_page_navigation: 2,
    featured_badge_hover: 3
  }
});

const trackFeaturedOfferClick = (badgesList: UIFlightBadge[]) => {
  if (badgesList && badgesList.some((badge) => badge.type === "FEATURED")) {
    utils.goals.featured_offer_click();
  }
};

export default { trackFeaturedOfferClick, ...utils };
