import { FilterSection } from "@flights/search-ui";
import React from "react";
import { useDurationFilterController } from "./useDurationFilter.controller";
import { InputSlider } from "@bookingcom/bui-react";

// https://jira.booking.com/jira/browse/DESY-5093
const style = {
  padding: "var(--bui_spacing_4x)",
  overflow: "hidden",
  margin: "calc(var(--bui_spacing_4x) * -1)"
};

export const DurationFilter = () => {
  const {
    isFilterAvailable,
    title,
    resetLabel,
    showReset,
    onClickReset,
    min,
    max,
    value,
    ariaLabel,
    label,
    renderTooltipValue,
    renderValue,
    onChange,
    onChangeCommit
  } = useDurationFilterController();

  if (!isFilterAvailable) return null;

  return (
    <FilterSection
      attributes={{ "data-testid": "duration_filter" }}
      resetAttributes={{ "data-testid": "search_filter_duration_reset" }}
      title={title}
      resetLabel={resetLabel}
      showReset={showReset}
      onClickReset={onClickReset}
    >
      <div style={style}>
        <InputSlider
          min={min}
          max={max}
          value={value}
          name="filters-duration"
          attributes={{ "data-testid": "search_filter_stops_duration_slider" }}
          ariaLabel={ariaLabel}
          label={label}
          onChange={({ value }) => onChange(value)}
          onChangeCommit={({ value }) => onChangeCommit(value)}
          renderTooltipValue={renderTooltipValue}
          renderValue={(val) => (
            <span data-testid="search_filter_stops_duration_travel_time_label">{renderValue(val)}</span>
          )}
        />
      </div>
    </FilterSection>
  );
};
