import { useI18n, t } from "@bookingcom/lingojs-react";
import useRouteName from "hooks/useRouteName";
import { useMemo } from "react";

export type HiddenFeesControllerProps = {};

export function useHiddenFeesController() {
  const i18n = useI18n();
  const routeName = useRouteName();
  const text = useMemo(
    () =>
      routeName === "checkout3"
        ? i18n.trans(t("flights_price_details_no_hidden_fees"))
        : i18n.trans(t("flights_funnel_no_hidden_fees_usp")),
    [i18n, routeName]
  );
  return {
    text
  };
}
