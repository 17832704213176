import { useCallback, useEffect, useRef } from "react";
import { createTrackingUtils } from "..";
import { useHistory } from "react-router-dom";
import getRouteName from "utils/getRouteName";
import useAvailableCheckoutSteps from "hooks/checkout/useAvailableCheckoutSteps";
import useProfiler from "hooks/useProfiler";
import useGlobalContext from "hooks/useGlobalContext";

const utils = createTrackingUtils({
  name: "flights_web_checkout_back_navigation",
  stages: {
    start_checkout: 1,
    back_from_after_second_step: 2, // main
    desktop: 3,
    mdot: 4,
    uk: 5,
    used_browser_forward: 6,
    back_from_pax: 7,
    back_from_extras: 8,
    back_from_check_and_pay: 9
  },
  defaultStage: 1,

  goals: {},
  goalsWithValue: []
});

export const CheckoutBackNavigationTracker = () => {
  const history = useHistory();
  const steps = useAvailableCheckoutSteps();
  const { isDesktop, isMobile } = useProfiler();
  const { ipCountry } = useGlobalContext();

  const stackLimit = 3;
  const routesStack = useRef([window.location.pathname]);
  const haveBeenTracked = useRef(false);

  const addToStack = (location: string) => {
    if (routesStack.current[routesStack.current.length - 1] === location) {
      return;
    }

    routesStack.current.push(location);

    if (routesStack.current.length > stackLimit) {
      routesStack.current.shift();
    }
  };

  const getPreviousRouteFromStack = () => {
    return routesStack.current[routesStack.current.length - 2];
  };

  const getCurrentRouteFromStack = () => {
    return routesStack.current[routesStack.current.length - 1];
  };

  const isPOS = useCallback(
    (pos: string) => {
      const ip = ipCountry?.toLowerCase() || "";
      return ip === pos;
    },
    [ipCountry]
  );

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      addToStack(location.pathname);

      if (action === "POP") {
        const prevRoute = getPreviousRouteFromStack();
        const prevRouteName = getRouteName(prevRoute);

        const currentRoute = getCurrentRouteFromStack();
        const currentRouteName = getRouteName(currentRoute);

        const prevRouteIndex = steps.findIndex(({ step }) => step === prevRouteName);
        const currentRouteIndex = steps.findIndex(({ step }) => step === currentRouteName);

        if (prevRouteName && steps.length > 3) {
          if (prevRouteIndex > 2) {
            utils.stages.back_from_after_second_step();
            isDesktop() && utils.stages.desktop();
            isMobile() && utils.stages.mdot();
            isPOS("gb") && utils.stages.uk();
            haveBeenTracked.current = true;
          }
        }

        if (haveBeenTracked.current && currentRouteIndex > prevRouteIndex) {
          utils.stages.used_browser_forward();
        }

        if (prevRouteName === "checkout") {
          utils.stages.back_from_pax();
        }

        if (prevRouteName === "checkout2") {
          utils.stages.back_from_extras();
        }

        if (prevRouteName === "checkout3") {
          utils.stages.back_from_check_and_pay();
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history, isDesktop, isMobile, isPOS, steps]);

  return null;
};

export default utils;
