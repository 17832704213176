import useRouteName from "../useRouteName";
import { useMemo } from "react";
import { RouteName } from "../../app/routes";
import useAvailableCheckoutSteps from "hooks/checkout/useAvailableCheckoutSteps";

const useCheckoutSteps = () => {
  const steps = useAvailableCheckoutSteps();
  const stepsName = steps.map(({ step }) => step);
  const currentRoute = useRouteName();

  const availableCheckoutSteps = useMemo(() => {
    return stepsName.map((name, index) => ({
      position: index + 1,
      name
    }));
  }, [stepsName]);

  const currentStep = useMemo(() => {
    return {
      position: stepsName.indexOf(currentRoute as ReturnType<typeof useAvailableCheckoutSteps>[number]["step"]) + 1,
      name: currentRoute
    };
  }, [stepsName, currentRoute]);

  return {
    availableCheckoutSteps,
    currentStep,
    isStartStep: currentStep.name === "checkout-start",
    isFirstStepAfterStart: isFirstStepAfterStart(stepsName, currentRoute),
    isLastStepBeforePayment: isLastStepBeforePayment(stepsName, currentRoute)
  };
};

const isFirstStepAfterStart = (steps: RouteName[], currentRoute: RouteName): boolean => {
  const stepsWithoutStart: RouteName[] = steps.filter((step) => step !== "checkout-start");
  return stepsWithoutStart.indexOf(currentRoute) === 0;
};

const isLastStepBeforePayment = (steps: RouteName[], currentRoute: RouteName): boolean => {
  const nextIndex = steps.indexOf(currentRoute) + 1;
  return steps[nextIndex] === "checkout3";
};

export default useCheckoutSteps;
