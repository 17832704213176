import { useFormatDateTime } from "@bookingcom/flights-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { CardSegmentProps } from "@flights/search-ui";
import { UIFlightCarrier, UIFlightSegment } from "@flights/types";
import { formattingUtils } from "@flights/web-api-utils-universal";
import useAirlineLogosGrouped from "hooks/useAirlineLogosGrouped";

import { useMemo } from "react";

export const useCardSegmentsCondensedController = (segments: UIFlightSegment[]) => {
  const { selectFlightCarriers: segmentsCarriers, airlinesWithGridPosition } = useAirlineLogosGrouped(segments);
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const extractAirlineCode = (logoUrl: string) => {
    const code = logoUrl.split("/").pop()?.replace(".png", "");
    return code;
  };

  const logoCodes = useMemo(() => {
    const codes: string[][] = [];
    segments.forEach((_, index) => {
      const _logos = new Set<string>();
      airlinesWithGridPosition[index].forEach((p) => {
        const code = extractAirlineCode(p.logo);
        if (code) {
          _logos.add(code);
        }
      });
      codes.push([..._logos].slice(0, 4));
    });
    return codes;
  }, [segments, airlinesWithGridPosition]);

  const getBadgeText = (stops?: number) => {
    if (!stops || stops === 0) {
      return i18n.trans(t("flights_filter_stops_none"));
    } else {
      return i18n.trans(
        t("flights_route_num_stops", {
          num_exception: stops,
          variables: { num_stops: stops }
        })
      );
    }
  };

  const getBadgeVariant = (stops?: number) => {
    if (!stops) return "constructive";
    return "outline";
  };

  const getCarrierText = (carrier: UIFlightCarrier) => {
    if (!carrier.carrierData) return "";
    if (carrier.operatingCarrierDisclosureText) {
      return i18n.trans(
        t("flights_sr_marketing_operating_airline", {
          variables: {
            marketing_carrier: carrier.carrierData.name,
            operating_carrier: carrier.operatingCarrierDisclosureText
          }
        })
      );
    } else if (carrier.operatingCarrierData) {
      return i18n.trans(
        t("flights_sr_marketing_operating_airline", {
          variables: {
            marketing_carrier: carrier.carrierData.name,
            operating_carrier: carrier.operatingCarrierData.name
          }
        })
      );
    } else {
      return carrier.carrierData.name;
    }
  };

  const getCarriersText = (flightCarriers: UIFlightCarrier[]) => {
    const uniqueFlightCarriers = [...new Set(flightCarriers.flat())];
    return uniqueFlightCarriers
      .map((carrier) => {
        return getCarrierText(carrier);
      })
      .join(", ");
  };

  const generateSegmentData = (): CardSegmentProps[] => {
    return segments.map((segment, index) => ({
      from: {
        date: formats.flightDate(segment.departureTime),
        time: formats.flightTime(segment.departureTime),
        highlightIata: segment.showWarningOriginAirport,
        iata: segment.departureAirport.code
      },
      to: {
        date: formats.flightDate(segment.arrivalTime),
        time: formats.flightTime(segment.arrivalTime),
        highlightIata: segment.showWarningDestinationAirport,
        iata: segment.arrivalAirport.code
      },
      stops: {
        stops: getBadgeText(segment.legs.length - 1),
        duration: formattingUtils.flightDuration(segment.totalTime / 60, i18n),
        variant: getBadgeVariant(segment.legs.length - 1)
      },
      airlineLogos: { codes: logoCodes[index] },
      size: "medium"
    }));
  };
  return {
    segmentsData: generateSegmentData(),
    carrierText: getCarriersText(segmentsCarriers)
  };
};
