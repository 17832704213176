import { Button, Stack, Text } from "@bookingcom/bui-react";

import React, { useEffect } from "react";

import NotAvailableIllustration from "../../../assets/illustrations/not-available.svg";
import useUserAgent from "../../../hooks/useUserAgent";
import { actions as AriaLiveActions } from "../../../store/ariaLive/actions";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions, useStore } from "../../../store";

import useModalDialog from "hooks/useModalDialog";
import styles from "./ErrorAlert.module.css";
import useGlobalContext from "hooks/useGlobalContext";
import useEventTracking from "hooks/useEventTracking";
import { mcn } from "utils/mergeClassnames";

interface Props {
  illustration?: React.ReactNode;
  title: string;
  body?: string;
  buttonLabel: string;
  loading?: boolean;
  onClick: () => void;
  experimentButton?: { buttonLabel: string; onClick: () => void };
  children?: React.ReactNode;
  size?: "large" | number;
  variant?: "neutral" | "white";
}

export const ErrorAlertInner: React.FC<Props> = ({
  illustration,
  title,
  body,
  buttonLabel,
  loading = false,
  onClick,
  children,
  size = "large",
  variant = "neutral"
}) => {
  const Modal = useModalDialog();
  const { isMobile } = useUserAgent();

  return (
    <Modal
      title=""
      subtitle=""
      closeAriaLabel="Close bottom sheet"
      active={true}
      fill={true}
      size={size}
      position={isMobile ? "fullScreen" : "center"}
      lockClose={true}
      lockCloseOnOutsideClick={false}
      hideClose={true}
      zIndex={500}
    >
      <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
        <Stack.Item
          className={mcn(styles.illustrationContainer, variant === "white" && styles.illustrationContainer_white)}
        >
          {illustration || <NotAvailableIllustration />}
        </Stack.Item>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mixin={{ width: "100%", padding: 4 }}
        >
          <Stack.Item>
            <Text tagName="h1" variant="headline_2">
              {title}
            </Text>
          </Stack.Item>
          <Stack.Item className={styles.body}>{body ? <Text variant="body_2">{body}</Text> : children}</Stack.Item>
          <Stack.Item>
            <Button className={styles.button} onClick={onClick} loading={loading} wide={true} loadingAriaLabel="">
              {buttonLabel}
            </Button>
          </Stack.Item>
        </Stack>
      </Stack>
    </Modal>
  );
};

const ErrorAlert = (props: Props) => {
  const { title } = props;
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const ariaLiveActions = useActions(AriaLiveActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const { visible } = useStore().loadingScreen;
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("page_load", requestId);

  useEffect(() => {
    trackV2("show_error_page");
  }, [trackV2]);

  useEffect(() => {
    if (visible && title) {
      ariaLiveActions.setMessage({
        message: title,
        type: "assertive"
      });
    } else {
      ariaLiveActions.reset();
    }
  }, [ariaLiveActions, visible, title]);

  return <ErrorAlertInner {...props} />;
};

export default ErrorAlert;
