import React from "react";
import { Breakdown } from "./Breakdown";
import { Stack } from "@bookingcom/bui-react";
import { Atol, HiddenFees, SIRF } from "./banners";
import {
  useUnifiedPriceBreakdownOrderController,
  type UnifiedPriceBreakdownOrderControllerProps
} from "./useUnifiedPriceBreakdown.Order.controller";

export type UnifiedPriceBreakdownOrderProps = UnifiedPriceBreakdownOrderControllerProps;

export function UnifiedPriceBreakdownOrder(props: UnifiedPriceBreakdownOrderProps) {
  const { unifiedPriceBreakdown, isHungaryDepartingRyanAir, isWithDiscount, addedItems } =
    useUnifiedPriceBreakdownOrderController(props);

  if (!unifiedPriceBreakdown) return null;

  return (
    <Stack>
      <Breakdown
        {...unifiedPriceBreakdown}
        isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
        addedItems={addedItems}
        isWithDiscount={isWithDiscount} // flights_web_unified_price_breakdown
      />
      <Atol />
      <SIRF />
      <HiddenFees />
    </Stack>
  );
}
