import { useCallback, useEffect, useState } from "react";
import { customGoal, goalWithValue, stage } from "@flights/et-universal";
import { useSelector } from "react-redux";
import { getFlexibleDateMinPriceFetchStatus, getSearchResultsFlightDeals } from "store/searchResults/selectors";
import { flightsSessionStore } from "@flights/web-api-utils-universal";
import useRouteName from "hooks/useRouteName";

export default function useTrackFlightsFlexiBannerSourceChange() {
  const sessionKey = "flexiClickedPriceValue";
  const expTagName = "flights_web_flexi_banner_source_change_ml";
  const routeName = useRouteName();
  const [isTracked, setIsTracked] = useState(false);
  const [canTrack, setCanTrack] = useState(false);
  const flexibleDateMinPriceFetchStatus = useSelector(getFlexibleDateMinPriceFetchStatus);
  const searchResultsFlightDeals = useSelector(getSearchResultsFlightDeals);

  const setClickedPrice = useCallback((price: string) => {
    flightsSessionStore.set(sessionKey, price, { ttl: "10m" });
  }, []);

  const getClickedPrice = useCallback(() => {
    return Number(flightsSessionStore.get(sessionKey) || 0);
  }, []);

  const removeClickedPrice = useCallback(() => {
    flightsSessionStore.unset(sessionKey);
  }, []);

  const trackStages = useCallback(() => {
    if (isTracked || !canTrack) return;
    stage(expTagName, 2);
    const bestOffer = searchResultsFlightDeals?.find((_) => _.key === "BEST");
    if (!bestOffer) return;
    const clickedPrice = getClickedPrice();
    const bestPrice = bestOffer.priceRounded
      ? Number(`${bestOffer.priceRounded?.units}.${bestOffer.priceRounded.nanos}`)
      : 0;
    if (!bestPrice || !clickedPrice) return;
    if (clickedPrice > bestPrice) {
      customGoal(expTagName, 1);
      goalWithValue("flights_web_flexi_banner_source_change_above_click", 1);
    }
    if (clickedPrice < bestPrice) {
      customGoal(expTagName, 2);
      goalWithValue("flights_web_flexi_banner_source_change_below_click", 1);
    }
    if (clickedPrice === bestPrice) {
      customGoal(expTagName, 3);
      goalWithValue("flights_web_flexi_banner_source_change_same_click", 1);
    }
    removeClickedPrice();
    setIsTracked(true);
  }, [isTracked, canTrack, searchResultsFlightDeals, getClickedPrice, removeClickedPrice]);

  useEffect(() => {
    if (routeName !== "searchresults") return;
    if (flexibleDateMinPriceFetchStatus !== "success") return;
    setCanTrack(true);
  }, [flexibleDateMinPriceFetchStatus, routeName, setCanTrack]);

  return {
    setClickedPrice,
    trackStages
  };
}
