/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo, FC } from "react";
import { Text, Alert } from "@bookingcom/bui-react";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import useFormatPrice from "hooks/useFormatPrice";
import {
  UIPriceBreakdown,
  UIOrderFuckedUpProductType,
  UIOrderExtras,
  UIOrderSeatMapSelection,
  UIPassenger,
  UITraveller,
  UIOrderCheckedInBaggage,
  UIOrderFlexibleTicket,
  UIOrderTravelInsurance,
  UITravellerPrice,
  UIOrderCabinBaggage,
  UIPrice,
  UICarrierTaxPerTravellerBreakdown,
  UIOrderCabinBaggagePerTraveller,
  UIOrderCancelForAnyReason,
  UIOrderFastTrack
} from "@flights/types";
import { Props, getPriceBreakdowns, BreakDownListItem } from "./BreakdownList";
import { ancillaryNames } from "../../../../ancillaries/utils/ancillaryNames";
import useUserAgent from "../../../../hooks/useUserAgent";
import TaxBreakDownList from "./TaxBreakDownList";
import { isSirfApplied } from "../../../../store/selectors/sirf";
import BcomMargin from "./BcomMargin";
import { INFANT_MAX_AGE } from "@flights/constants";
import useInsuranceServerSide from "../../../../hooks/useInsuranceServerSide";
import Frame from "components/elements/Frame";
import styles from "./DetailedBreakdownList.module.css";
import { useTicketPriceCopy } from "utils/useTicketPriceCopy";
import { mcn } from "utils/mergeClassnames";
import { pricingUtils, isFeatureRunningClientSide } from "@flights/web-api-utils-universal";

const DetailedBreakdownList: FC<Props> = ({
  totalPrice,
  travellers,
  travellerPrices,
  ancillaries,
  appliedSubsidizedFares = [],
  instalmentsFees,
  priceTaxBreakdown,
  flightData,
  orderData,
  multiProducts = [],
  isRynaAirHungaryFlight,
  isBrandedFare
}) => {
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();
  const ticketPriceCopy = useTicketPriceCopy(i18n, travellers);
  const { formatPrice } = useFormatPrice();
  const { travelInsuranceContent } = useInsuranceServerSide(!!orderData);
  const adultsList = useMemo(
    () =>
      travellers
        ?.filter((passenger: UIPassenger | UITraveller) => passenger.type === "ADULT")
        .map((passenger: any) =>
          travellerPrices?.find(
            (currentPassenger) => currentPassenger.travellerReference === passenger.travellerReference
          )
        ) as UITravellerPrice[],
    [travellers, travellerPrices]
  );

  const kidsList = useMemo(
    () =>
      travellers
        ?.filter((passenger: UIPassenger | UITraveller) => passenger.type === "KID")
        .map((passenger: UIPassenger | UITraveller) => ({
          age: passenger.age,
          priceBreakDown: travellerPrices?.find(
            (currentPassenger) => currentPassenger.travellerReference === passenger.travellerReference
          ),
          isAgeEstimated: passenger.ageIsEstimated
        })),
    [travellers, travellerPrices]
  );

  const { carrierTaxBreakdownAdult, carrierTaxBreakdownChild, carrierTaxBreakdownInfant } = useMemo(() => {
    const carrierTaxBreakdownAdult = [] as UICarrierTaxPerTravellerBreakdown[];
    const carrierTaxBreakdownChild = [] as UICarrierTaxPerTravellerBreakdown[];
    const carrierTaxBreakdownInfant = [] as UICarrierTaxPerTravellerBreakdown[];
    totalPrice.carrierTaxBreakdown?.forEach((carrierTax) => {
      const { carrier, avgPerAdult, avgPerChild, avgPerInfant } = carrierTax;
      if (avgPerAdult) carrierTaxBreakdownAdult.push({ avgPerTraveller: avgPerAdult, carrier });
      if (avgPerChild) carrierTaxBreakdownChild.push({ avgPerTraveller: avgPerChild, carrier });
      if (avgPerInfant) carrierTaxBreakdownInfant.push({ avgPerTraveller: avgPerInfant, carrier });
    });
    return { carrierTaxBreakdownAdult, carrierTaxBreakdownChild, carrierTaxBreakdownInfant };
  }, [totalPrice.carrierTaxBreakdown]);

  const isPreventSummingBcomPricingItemsEnabled = isFeatureRunningClientSide(
    "FLIGHTS_PRICING_ENABLE_PREVENT_SUMMING_BCOMPRICINGITEM_IN_PRICE_BREAKDOWN"
  );
  const bcomMargin =
    isPreventSummingBcomPricingItemsEnabled && !isBrandedFare
      ? flightData?.priceBreakdown.bcomMargin
      : totalPrice.bcomMargin;
  const bcomPricingItems =
    isPreventSummingBcomPricingItemsEnabled && !isBrandedFare
      ? flightData?.priceBreakdown.bcomPricingItems
      : totalPrice.bcomPricingItems;

  return (
    <>
      <div
        className={isMobile ? styles.mobileRoot : styles.root}
        data-testid="detailed_breakdown_list_modal"
        role="table"
      >
        {travellers && travellerPrices && ticketPriceCopy && (
          <div className={styles.itemsParent} data-testid="passengers_price_details">
            <BreakDownListHeader
              title={i18n.trans(t("flights_price_breakdown_tickets"))}
              subtitle={ticketPriceCopy}
              price={pricingUtils.sumPriceBreakdown(getPriceBreakdowns(travellerPrices))}
              dataTestId="header"
            />
            <BreakDownListItem
              title={i18n.trans(t("flights_price_breakdown_per_adult"))}
              priceBreakdown={pricingUtils.sumPriceBreakdown(getPriceBreakdowns(adultsList[0]))}
              showDetails
              dataTestId="detailed_breakdown_list_adults"
              flightData={flightData}
              orderData={orderData}
              carrierTax={carrierTaxBreakdownAdult}
              bcomPricingItems={getPriceBreakdowns(adultsList)?.[0].bcomPricingItems}
              type="flight_fare"
            />
            {kidsList?.map(
              (kid: { age: number; priceBreakDown: UITravellerPrice; isAgeEstimated: boolean }, index: number) => (
                <BreakDownListItem
                  key={index}
                  title={
                    kid.isAgeEstimated
                      ? i18n.trans(t("flights_price_breakdown_per_child"))
                      : i18n.trans(
                          t("flights_price_breakdown_price_children_age", {
                            variables: { num_exception: `${kid.age}`, num_age: `${kid.age}` }
                          })
                        )
                  }
                  priceBreakdown={kid.priceBreakDown.travellerPriceBreakdown}
                  showDetails
                  dataTestId={`detailed_breakdown_list_child_${index}`}
                  flightData={flightData}
                  orderData={orderData}
                  carrierTax={kid.age > INFANT_MAX_AGE ? carrierTaxBreakdownChild : carrierTaxBreakdownInfant}
                  bcomPricingItems={kid.priceBreakDown.travellerPriceBreakdown.bcomPricingItems}
                  type="flight_fare"
                  showZeroPrice={kidsList.length > 0}
                />
              )
            )}
          </div>
        )}

        {!!bcomMargin && !bcomPricingItems && (
          <Frame mr={4} ml={4}>
            {" "}
            <BcomMargin bcomMargin={bcomMargin} />
          </Frame>
        )}
        {priceTaxBreakdown && <TaxBreakDownList priceTaxBreakdown={priceTaxBreakdown} />}

        {ancillaries &&
          Object.keys(ancillaries).map((productType: UIOrderFuckedUpProductType, index) => {
            const product: UIOrderExtras[typeof productType] = ancillaries[productType];

            if (product && "price" in product && product.price) {
              let productSubtitle,
                title,
                count,
                p,
                showDetails = true,
                comment;

              switch (productType) {
                case "seatMapSelection":
                  p = product as UIOrderSeatMapSelection;
                  count = p.seats.length ? p.seats.length : 1;
                  productSubtitle = i18n.trans(
                    t("flights_price_breakdown_seat_count", {
                      variables: { num_exception: count, num_seats: count }
                    })
                  );
                  title = ancillaryNames(productType, { num_exception: count });
                  break;
                case "checkedInBaggage":
                  p = product as UIOrderCheckedInBaggage;
                  count = p.travelerBaggages.length ? p.travelerBaggages.length : 1;
                  productSubtitle = i18n.trans(
                    t("flights_baggage_details_count_checked", {
                      variables: { num_exception: count, num_bags: count }
                    })
                  );
                  title = ancillaryNames(productType);
                  break;
                case "cabinBaggage":
                  p = product as UIOrderCabinBaggage;
                  title = ancillaryNames(productType);
                  break;
                case "cabinBaggagePerTraveller":
                  p = product as UIOrderCabinBaggagePerTraveller;
                  count = p.travelerBaggages.length || 1;
                  title = ancillaryNames(productType);
                  productSubtitle = i18n.trans(
                    t("flights_baggage_details_count_cabin", {
                      variables: { num_exception: count, num_bags: count }
                    })
                  );
                  break;
                case "flexibleTicket":
                  p = product as UIOrderFlexibleTicket;
                  count = p.travelerPrices.length ? p.travelerPrices.length : 1;
                  productSubtitle = i18n.trans(
                    t("flights_price_breakdown_ticket_count", {
                      variables: { num_exception: count, num_tickets: count }
                    })
                  );
                  title = ancillaryNames(productType);
                  break;
                case "travelInsurance":
                  p = product as UIOrderTravelInsurance;
                  count = p.travelerPrices.length ? p.travelerPrices.length : 1;
                  if (!p.isPerTraveller) {
                    count = travellers?.length ? travellers.length : 1;
                  }
                  if (travelInsuranceContent) {
                    title = travelInsuranceContent.header || "";
                    comment = travelInsuranceContent.priceBreakdownNote || "";
                    // using generic copy as fallback
                    productSubtitle =
                      travelInsuranceContent.paxStatus ||
                      i18n.trans(
                        t("flights_cover_insurance_latam_confirmation_num_travellers", {
                          variables: {
                            num_travellers: count,
                            num_exception: count
                          }
                        })
                      );
                  } else {
                    productSubtitle = i18n.trans(
                      t("flights_price_breakdown_insured_number", {
                        variables: {
                          num_exception: count,
                          num_insured: count
                        }
                      })
                    );
                    title = ancillaryNames(productType);
                    showDetails = false;
                    comment = i18n.trans(t("flights_price_breakdown_insurance_tax"));
                  }
                  break;
                case "cancelForAnyReason":
                  p = product as UIOrderCancelForAnyReason;
                  title = ancillaryNames(productType);
                  break;
                case "fastTrack":
                  p = product as UIOrderFastTrack;
                  title = ancillaryNames(productType);
                  count = travellers?.length ? travellers.length : 1;
                  productSubtitle = i18n.trans(
                    t("flights_ace_price_breakdown_fast_track_travellers", {
                      variables: {
                        num_travellers: count,
                        num_exception: count
                      }
                    })
                  );
                  break;
              }

              const productTitle = title ? (typeof title === "string" ? title : i18n.trans(title)) : productType;
              const ancillaryBcomPricingItems = isPreventSummingBcomPricingItemsEnabled
                ? product.price.bcomPricingItems
                : undefined;

              return (
                <div key={index} className={styles.itemsParent} data-testid={`detailed_breakdown_list_${productType}`}>
                  <BreakDownListHeader
                    title={productTitle}
                    subtitle={productSubtitle}
                    price={product.price}
                    dataTestId="header"
                  />
                  <BreakDownListItem
                    priceBreakdown={product.price}
                    showDetails={showDetails}
                    comment={comment}
                    bcomPricingItems={ancillaryBcomPricingItems}
                  />
                </div>
              );
            }
            return null;
          })}

        {
          // #############################
          // ### START - MultiProducts ###
          // #############################
        }

        {multiProducts.map((item) => {
          switch (item.productType) {
            case "INSURANCE": {
              const productTranslation = t("flights_ancillary_travelinsurance_name");
              const productName = i18n.trans(productTranslation);
              const travellerCount = travellerPrices?.length ?? 1;
              const productTotalPrice = item.priceBreakdown;
              const productSubtitle = i18n.trans(
                t("flights_price_breakdown_insured_number", {
                  variables: {
                    num_exception: travellerCount,
                    num_insured: travellerCount
                  }
                })
              );
              return (
                <div key="INSURANCE" className={styles.itemsParent} data-testid="multi_product_insurance_details">
                  <BreakDownListHeader
                    title={productName}
                    subtitle={productSubtitle}
                    price={productTotalPrice}
                    dataTestId="header"
                  />
                  <BreakDownListItem priceBreakdown={productTotalPrice} showDetails={true} type="insurance_addon" />
                </div>
              );
            }
            default: {
              return null;
            }
          }
        })}

        {
          // #############################
          // ### END - MultiProducts ###
          // #############################
        }

        {instalmentsFees ? (
          <div className={styles.itemsParent} data-testid="instalments_details">
            <BreakDownListHeaderSimple
              price={instalmentsFees}
              title={i18n.trans(t("flights_price_breakdown_instalment_label"))}
            />
          </div>
        ) : null}
      </div>
      <div className={mcn(styles.listItem, isMobile && styles.listItemMobile)}>
        <div>
          <Text className={styles.grayscaleText} variant="body_2">
            {i18n.trans(
              isRynaAirHungaryFlight
                ? t("flights_ryanair_hungary_sr_price_single")
                : t("flights_price_breakdown_total_includes_taxes")
            )}
          </Text>
        </div>

        <Text variant="headline_3" attributes={{ "data-testid": "detailed_breakdown_list_footer_price" }}>
          {/* see usePaymentComponentIframeEvents */}
          {formatPrice(totalPrice.totalWithInstalments || totalPrice.total)}
        </Text>

        {isSirfApplied(appliedSubsidizedFares) && (
          <Alert
            text={i18n.trans(t("flights_spanish_residency_discount_applied"))}
            variant="success"
            inline
            className={styles.discountAlert}
          />
        )}
      </div>
    </>
  );
};

type BreakDownListHeaderProps = {
  title: string;
  subtitle?: string;
  price: UIPriceBreakdown;
  dataTestId?: string;
};

type BreakDownListHeaderSimpleProps = Omit<BreakDownListHeaderProps, "price" | "subtitle"> & {
  price: UIPrice;
};

function BreakDownListHeader(props: BreakDownListHeaderProps) {
  const { title, subtitle, price, dataTestId } = props;
  const { formatPrice } = useFormatPrice();

  if (price.total.units <= 0) {
    return null;
  }

  return (
    <div className={styles.headerItem} data-testid={`${dataTestId}`} role="row">
      <Frame direction="row" justifyContent="space-between">
        <Text
          variant="strong_2"
          attributes={{
            "data-testid": "row_one_column_one",
            role: "cell",
            "aria-label": `${subtitle || title}`
          }}
        >
          {title}
        </Text>
        <Text variant="strong_2" attributes={{ "data-testid": "row_one_column_two", role: "cell" }}>
          {formatPrice(price.total)}
        </Text>
      </Frame>
      <Frame pb={4}>
        {subtitle && (
          <Text className={styles.grayscaleText} attributes={{ "data-testid": "row_two_column_one" }} variant="body_2">
            {subtitle}
          </Text>
        )}
      </Frame>
    </div>
  );
}

function BreakDownListHeaderSimple(props: BreakDownListHeaderSimpleProps) {
  const { title, dataTestId, price } = props;
  const { formatPrice } = useFormatPrice();
  if (price.units <= 0) {
    return null;
  }
  return (
    <div className={styles.headerItem} data-testid={`${dataTestId}`}>
      <Frame direction="row" justifyContent="space-between">
        <Text variant="strong_2" attributes={{ "data-testid": "row_one_column_one" }}>
          {title}
        </Text>
        <Text variant="strong_2" attributes={{ "data-testid": "row_one_column_two" }}>
          {formatPrice(price)}
        </Text>
      </Frame>
    </div>
  );
}

export default DetailedBreakdownList;
