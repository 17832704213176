import { useMemo } from "react";
import { UIFlightData } from "@flights/types";
import { useSelector } from "react-redux";
import { getFlight } from "store/flightDetails/selectors";

export const useFlightOfferWithDiscount = (flightData?: UIFlightData) => {
  const flightStore = useSelector(getFlight);

  const flight = useMemo(() => flightData || flightStore, [flightData, flightStore]);

  const isWithDiscount = useMemo(() => {
    if (!flight) {
      return false;
    }

    const {
      appliedDiscounts,
      priceBreakdown: { fee }
    } = flight;

    return !!appliedDiscounts?.length || (fee && (fee.units < 0 || fee.nanos < 0));
  }, [flight]);

  return {
    isWithDiscount
  };
};
