import { FilterSection } from "@flights/search-ui";
import React from "react";
import { useStopsFilterController } from "./useStopsFilter.controller";
import { Stack } from "@bookingcom/bui-react";
import { StopsFilterItem } from "./StopsFilterItem";

export const StopsFilterMobile = () => {
  const { title, resetLabel, showReset, onClickReset, options, value, onChange, isFilterAvailable } =
    useStopsFilterController();

  if (!isFilterAvailable) {
    return null;
  }

  return (
    <FilterSection
      attributes={{ "data-testid": "stops_filter" }}
      resetAttributes={{ "data-testid": "search_filter_stops_reset" }}
      title={title}
      resetLabel={resetLabel}
      showReset={showReset}
      onClickReset={onClickReset}
    >
      <Stack gap={4}>
        {options.map((option, i) => (
          <StopsFilterItem
            key={`${option.label}-${i}-${option.count}`}
            label={option.label}
            priceLabel={option.priceLabel}
            count={option.count}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
          />
        ))}
      </Stack>
    </FilterSection>
  );
};
