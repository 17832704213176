/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, Profiler, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions, useStore } from "../store";
import { getSearchCriteria } from "../store/getSearchCriteria";
import { actions as LoaderActions } from "../store/loadingScreen/actions";
import { actions as searchResultsActions } from "../store/searchResults/actions";
import { actions as SearchCriteriaActions } from "../store/searchCriteria/actions";
import { actions as flexibleDateMinPriceActions } from "../store/flexibleDateMinPrice/actions";
import { isSirfAvailable } from "../store/selectors/sirf";
import { useClientFetch } from "../hooks/useClientFetch";
import { useSearchAPIParams } from "../hooks/useSearchAPIParams";
import useClientMetrics from "../hooks/useClientMetrics";
import { trackCustomGoal, trackExperimentStage, trackGoal } from "../utils/et";
import AppShell from "../components/elements/AppShell";
import SearchHeader from "../components/elements/SearchHeader";
import SearchResults from "../components/elements/SearchResults";
import {
  UIClientFetchError,
  UILocation,
  UISearchResults,
  UISearchResultsBanner,
  UISearchSegment
} from "@flights/types";
import { FlightDetails, Home } from "../app/routes";
import useTrackPageLoad from "../hooks/useTrackPageLoad";
import SirfInfoBanner from "../components/elements/SirfInfoBanner";
import useReloadIfSearchStoreMissing, { isSearchStoreInitial } from "hooks/useReloadIfSearchStoreMissing";
import { DestinationUkraineWarning } from "components/elements/TravelAlert";
import { useFlightSearchResultsGTMTag } from "hooks/useGTMFlightTags";
import useATOL from "hooks/useATOL";
import { flightsSessionStore } from "@flights/web-api-utils-universal";
import StaticContext from "app/StaticContext/StaticContext";
import {
  isOfGoogleFlightsOrigin,
  isOfKayakOrigin,
  isOfMetaOrigin,
  isOfSkyScannerOrigin
} from "utils/marketing-url-params";
import useMeasureSearchToFlightDetails from "hooks/useMeasureSearchToFlightDetails";
import DSABanner from "components/elements/DSABanner/DSABanner";
import useRemoveExternalParamsFromUrl from "hooks/useRemoveExternalParamsFromUrl";
import { trackMetaChannelStagesV2 } from "utils/experiments/track-meta-landings-v2";
import Frame from "components/elements/Frame";
import scrollToTop from "utils/scrollToTop";
import { useFlexDatesRectrictedPOS } from "components/elements/FlexibleDateBestPrice/useFlexDatesRectrictedPOS";
import SearchResultBanner from "components/elements/SearchResultBanner/SearchResultBanner";
import { actions as brandedFaresActions } from "../store/brandedFareOffers/actions";
import { useDispatch, useSelector } from "react-redux";
import { getSearchSegments } from "store/search/selectors";
import { customGoal, goal, stage } from "@flights/et-universal";
import usePrefetchFlightDetails from "hooks/usePrefetchFlightDetails";
import useTrackExpPaxHaulTraffic from "hooks/useTrackExpPaxHaulTraffic";
import flights_web_cat_fly_anywhere_filters_mdot from "utils/experiments/customer-aquisition/flights_web_cat_fly_anywhere_filters_mdot";

const Search = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const i18n = useI18n();
  const location = useLocation<{ hideLoader: boolean; forceRefetch: boolean }>();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const actions = useActions(searchResultsActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const flexibleActions = useActions(flexibleDateMinPriceActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const loaderActions = useActions(LoaderActions);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const searchCriteriaActions = useActions(SearchCriteriaActions);
  const trackSRContextEventV2 = useTrackPageLoad("search_results");
  const trackSRSkeletonContextEventV2 = useTrackPageLoad("search_results_skeleton");
  const trackSRModalContextEventV2 = useTrackPageLoad("search_results_modal");
  const staticContext = useContext(StaticContext);
  const params = useParams<{ airports: string }>();
  const { onFetchedResults, onSelectFlight } = useMeasureSearchToFlightDetails();
  const { flights, fetchStatus, error: fetchError, subsidizedFaresSummary, banners } = store.searchResults;
  const { visible: loadingScreenVisible } = store.loadingScreen;
  const isRestricedFlexDatePOS = useFlexDatesRectrictedPOS();
  const dispatch = useDispatch();
  const searchSegments = useSelector(getSearchSegments);
  const queryParams = new URLSearchParams(location.search);
  const searchAPIParams = useSearchAPIParams();
  const { preFetchFlightDetails, resetFetchedFlightDetails } = usePrefetchFlightDetails(); // flights_web_prefetch_flight_details

  const url = `/api/flights/?${searchAPIParams}`;

  /* start - flights_web_flexible_date_search */
  const urlFlexibleDatePrice = useMemo(() => {
    const params = new URLSearchParams(searchAPIParams);
    params.set("useAggregateCache", "1");
    params.set("compareBestPrice", "1");
    return `/api/search/min-price/?${params.toString()}`;
  }, [searchAPIParams]);
  /* end - flights_web_flexible_date_search */

  const { trackRenderTime } = useClientMetrics();
  const fireByeahSearchResultsTag = useFlightSearchResultsGTMTag();
  const { isATolProtectedSR } = useATOL();
  const [lastTrackedPageLoad, setLastTrackedPageLoad] = useState("");
  const { trackPaxHaulStages } = useTrackExpPaxHaulTraffic();
  const humanRightBanner = banners?.find((banner: UISearchResultsBanner) => banner.id === "LEGAL_NOTICE_HUMAN_RIGHTS");

  if (process.env.BUILD_TARGET === "server" && staticContext.set) {
    const { getInitialState } = require("../server/screens/search");
    staticContext.set({ getInitialState: getInitialState(queryParams) });
  }

  useRemoveExternalParamsFromUrl();

  useEffect(scrollToTop, [url]);

  useEffect(() => {
    isOfMetaOrigin() && trackExperimentStage("flights_meta_land_on_cheapest_flights", 1); // all meta landings seen SR
    isOfMetaOrigin() && trackExperimentStage("flights_meta_land_on_cheapest_flights", 3); // all meta landings seen SR:mdot
    isOfSkyScannerOrigin() && trackExperimentStage("flights_meta_land_on_cheapest_flights", 7); // all sky mobile
    isOfGoogleFlightsOrigin() && trackExperimentStage("flights_meta_land_on_cheapest_flights", 8); // all gfs mobile
    isOfKayakOrigin() && trackExperimentStage("flights_meta_land_on_cheapest_flights", 9); // all kayak mobile

    trackMetaChannelStagesV2("mdot");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    trackGoal("flights_search_results_page_view");
    goal("tbu_vertical_search_results_view");
    trackCustomGoal("sss_web_airport_crosslang_autocomplete", 2);

    const caSource = new URLSearchParams(window.location.search).get("ca_source")?.toLowerCase();
    if (caSource === "flyanywhere") {
      flights_web_cat_fly_anywhere_filters_mdot.goals.clicked_card_2();
    } else if (caSource === "flights_index_cf") {
      customGoal("flights_web_cat_popular_nodate_mdot", 1);
    }

    stage("mvsf_whs_launchpad_nav_products_reorder_mdot", 7); //mvsf_whs_launchpad_nav_products_reorder_mdot
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const hideActiveLoader = useCallback(() => {
    loaderActions.hide();

    // Focus the first anchor in #app-shell (probably "Booking.com" logo)
    // after closing loading modal.
    const appShell = document.getElementById("app-shell");
    const firstAnchor = appShell?.querySelector("a");
    if (firstAnchor && document.activeElement === document.body) firstAnchor.focus();
  }, [loaderActions]);

  useClientFetch(url, {
    condition: () => {
      return !isSearchStoreInitial(store.search);
    },
    fetch: () => {
      // only show loading screen when coming from index / searchbox
      if (!location?.state?.hideLoader) {
        history.replaceState({ hideLoader: false }, document.title);
        loaderActions.show();
      }
      actions.fetch();
      resetFetchedFlightDetails(); // flights_web_prefetch_flight_details
    },
    success: (data: UISearchResults) => {
      searchCriteriaActions.setSearchCriteria(getSearchCriteria(queryParams, store.search));
      if (data.flightOffers.length > 0) {
        data.flightOffers[0].highlightInfo?.highlight &&
          flightsSessionStore.set("highlightedOfferToken", data.flightOffers[0].token);

        data.flightOffers.forEach((offer) => {
          if (offer && !offer.requestableBrandedFares && offer.brandedFareOffers) {
            dispatch(brandedFaresActions.fetchSuccess(offer.brandedFareOffers, offer.token));
          }
        });
      }
      actions.fetchSuccess(data);
      void preFetchFlightDetails(data.flightOffers); // flights_web_prefetch_flight_details
      fireByeahSearchResultsTag(); // fire GTM flight search results tag
      hideActiveLoader();
      onFetchedResults();
      trackCustomGoal("crosssell_solutions_connected_navigation_poc_web", 3);
    },
    error: (data: UIClientFetchError) => {
      trackGoal("flights_search_results_loading_error");
      hideActiveLoader();
      actions.fetchError(data);
    },
    forceRefetch: location?.state?.forceRefetch
  });

  /* start - flights_web_flexible_date_search */
  useClientFetch(urlFlexibleDatePrice, {
    fetch: () => flexibleActions.fetch(),
    success: (data) => flexibleActions.fetchSuccess(data),
    error: (data) => flexibleActions.fetchError(data),
    condition: () => {
      if (store.flexibleDateMinPrice.fetchStatus !== "success") {
        return store.search.cabinClass === "ECONOMY" && !isRestricedFlexDatePOS;
      }
      return false;
    },
    forceRefetch: location?.state?.forceRefetch
  });
  /* end - flights_web_flexible_date_search */

  const header = useMemo(() => {
    return <SearchHeader />;
  }, []);

  const [preloadDetails, toggle] = useState(false);
  if (!preloadDetails && fetchStatus === "success") {
    toggle(true);
    FlightDetails.preload();
    Home.preload();
  }

  useEffect(() => {
    setTimeout(() => {
      hideActiveLoader();
    }, 6000);
  }, [loaderActions, hideActiveLoader]);

  useEffect(() => {
    if (["initial", "loading"].includes(fetchStatus)) {
      if (loadingScreenVisible && lastTrackedPageLoad !== "search_results_modal") {
        trackSRModalContextEventV2();
        setLastTrackedPageLoad("search_results_modal");
      } else if (lastTrackedPageLoad !== "search_results_skeleton") {
        trackSRSkeletonContextEventV2();
        setLastTrackedPageLoad("search_results_skeleton");
      }
    } else if (lastTrackedPageLoad !== "search_results") {
      trackSRContextEventV2();
      setLastTrackedPageLoad("search_results");
    }
  }, [fetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchStatus !== "success") return;
    trackPaxHaulStages();
  }, [trackPaxHaulStages, fetchStatus]);

  if (useReloadIfSearchStoreMissing(store.search)) {
    return null;
  }

  isATolProtectedSR(store?.searchResults?.atolProtectedStatus); // to track stages

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader segments={searchSegments} />
      <AppShell>
        {header}
        <Frame mr={4} mb={4} ml={4}>
          <SearchResultBanner banner={humanRightBanner} />
          <DSABanner />
          <DestinationUkraineWarning />
          {isSirfAvailable(subsidizedFaresSummary) && (
            <SirfInfoBanner
              bannerName="search-results"
              bannerContent={i18n.trans(t("flights_sr_spanish_discount_banner"))}
            />
          )}

          <SearchResults
            airports={params?.airports}
            params={location.search}
            flights={flights}
            fetchStatus={fetchStatus}
            fetchError={fetchError}
            onSelectFlight={onSelectFlight}
          />
        </Frame>
      </AppShell>
    </Profiler>
  );
};

export const PageHeader: FC<{ segments: UISearchSegment[]; children?: React.ReactNode }> = ({ segments, children }) => {
  const title = useMemo(() => {
    return segments.map(({ from, to }) => `${getCitiesListForTitle(from)} – ${getCitiesListForTitle(to)}`).join(" | ");
  }, [segments]);

  return (
    <Helmet>
      <title>{title} - Booking.com</title>
      <meta property="webview:header" content="regular" />
      <meta property="webview:title" content={""} />
      {children}
    </Helmet>
  );
};

const getCitiesListForTitle = (locations: UILocation[]): string => {
  return locations
    .map((location) => (location.type === "AIRPORT" ? location.cityName : location.name || location.code))
    .join(", ");
};

export default Search;
