export function appendFeatureAndExperimentParams(url) {
    try {
        const urlObj = pathToUrl(url);
        const params = new URLSearchParams(window.location.search);
        params.forEach((value, name) => {
            if (name.startsWith("b_feature_") ||
                name.startsWith("exp_") ||
                name === "my_ip" ||
                name === "i_am_from" ||
                name === "show_tags" ||
                name === "debug" ||
                name === "mock-scenario-name" ||
                name === "mock-scenario-enabled") {
                urlObj.searchParams.set(name, value);
            }
        });
        return urlToPath(urlObj);
    }
    catch (e) {
        return url;
    }
}
export function appendLanguageOptionParam(url) {
    try {
        const params = new URLSearchParams(window.location.search);
        const lang = params.get("lang");
        if (!lang)
            return url;
        const urlObj = pathToUrl(url);
        urlObj.searchParams.set("lang", lang);
        return urlToPath(urlObj);
    }
    catch (e) {
        return url;
    }
}
function pathToUrl(path) {
    return new URL(path, "http://x");
}
function urlToPath(urlObj) {
    return urlObj.toString().replace(/^http:\/\/x/, "");
}
