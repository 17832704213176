import { UIBrandedFareInfo, UIBrandedFareFeature } from "@flights/types";
import { createTrackingUtils } from "..";
import { getFeatureConfigsByName } from "@flights/branded-fares";

type BrandedFareLike = { brandedFareInfo: UIBrandedFareInfo };

const utils = createTrackingUtils({
  name: "flights_apex_web_show_all_flexibility_features",
  defaultStage: 1,
  stages: {
    affected_users: 1,
    main: 2,
    mdot: 3,
    desktop: 4,
    search: 5,
    branded_fares: 6,
    check_pay: 7,
    post_book: 8
  },
  goals: {}
});

const trackStages = (
  isMobile: boolean,
  page: "search" | "branded_fares" | "check_pay" | "post_book",
  brandedFareOffers: BrandedFareLike[]
) => {
  if (!hasSellableOrNonIncludedFlexibility(brandedFareOffers)) return;
  if (page === "post_book") return utils.stages.post_book();
  utils.stages.main();
  if (!isMobile) utils.stages.desktop();
  if (isMobile) utils.stages.mdot();
  utils.stages[page]();
};

const hasSellableOrNonIncludedFlexibility = (brandedFareOffers: BrandedFareLike[]) => {
  for (const offer of brandedFareOffers) {
    if (
      hasFlexibility(offer.brandedFareInfo.sellableFeatures) ||
      hasFlexibility(offer.brandedFareInfo.nonIncludedFeatures)
    )
      return true;
  }

  return false;
};

const hasFlexibility = (features: UIBrandedFareFeature[] = []) => {
  const configs = getFeatureConfigsByName();

  for (const feature of features) {
    if (configs[feature.featureName]?.group === "FLEXIBILITY") return true;
  }

  return false;
};

export default { ...utils, trackStages };
