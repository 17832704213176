import { useCallback, useMemo } from "react";
import useUserAgent from "./useUserAgent";
import useProfiler from "./useProfiler";
import { useSelector } from "react-redux";
import { getAncillaries, getFlight } from "store/flightDetails/selectors";
import { UIFlightData } from "@flights/types";
import useVirutalInterlining from "./useVirutalInterlining";
import useBaggages from "components/elements/Baggages/useBaggages";
import { customGoal, stage, trackWithDefaultStage } from "@flights/et-universal";

export default function usePaxWithBaggages() {
  const { isMobile } = useUserAgent();
  const profiler = useProfiler();
  const flight = useSelector(getFlight);
  const ancillaries = useSelector(getAncillaries);
  const { getBaggageCopyForVI } = useVirutalInterlining();
  const { getBaggagesStatus } = useBaggages(flight);

  const isPaxWithBaggages = useMemo(
    () => !isMobile || (isMobile && !!trackWithDefaultStage("flights_web_mdot_pax_with_baggages", 1)),
    [isMobile]
  );

  const expname = useMemo(() => (isMobile ? "flights_web_mdot_pax_with_baggages" : undefined), [isMobile]);

  const isAnyExtraBaggages = useCallback(() => {
    const { personalItem, cabin, checkedIn } = getBaggagesStatus();
    return [personalItem, cabin, checkedIn].some((bag) => bag?.inclusionType !== "not_available");
  }, [getBaggagesStatus]);

  const isPartiallyIncluded = useCallback(() => {
    const { personalItem, cabin, checkedIn } = getBaggagesStatus();
    return [personalItem, cabin, checkedIn].some((bag) => {
      return bag?.inclusionType === "partial";
    });
  }, [getBaggagesStatus]);

  const isVIBanner = useCallback((f: UIFlightData) => !!getBaggageCopyForVI(f?.segments || []), [getBaggageCopyForVI]);

  const noAvailableBaggages = useMemo(() => {
    if (!ancillaries) return false;
    return !ancillaries.cabinBaggagePerTraveller && !ancillaries.checkedInBaggage;
  }, [ancillaries]);

  const trackPaxWithBaggagessStages = useCallback(() => {
    if (!expname) return;
    if (isAnyExtraBaggages()) stage(expname, 2);
    if (profiler.isDirect()) stage(expname, 3);
    if (profiler.isMeta()) stage(expname, 4);
    if (profiler.isSolo()) stage(expname, 5);
    if (!profiler.isSolo()) stage(expname, 6);
    if (isPartiallyIncluded()) stage(expname, 7);
    if (profiler.isWithInfants()) stage(expname, 8);
    if (flight && isVIBanner(flight)) stage(expname, 9);
    if (noAvailableBaggages) customGoal(expname, 3);
  }, [profiler, flight, expname, isAnyExtraBaggages, isPartiallyIncluded, isVIBanner, noAvailableBaggages]);

  const trackPaxWithBaggagessGoal = useCallback(
    (n: number) => {
      if (!expname) return;
      customGoal(expname, n);
    },
    [expname]
  );

  return { isPaxWithBaggages, trackPaxWithBaggagessStages, trackPaxWithBaggagessGoal };
}
