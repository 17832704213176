import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "ddot_wl_save_flights",
  stages: {
    main: 1
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

export default utils;
