import { createListenerMiddleware } from "@reduxjs/toolkit";
import { ActionTypes as ExtraProductsActionTypes, actions } from "ancillaries/store/extraProducts/actions";
import { ActionTypes as FlightDetailsActionTypes, actions as flightDetailsActions } from "./flightDetails/actions";
import { ActionTypes as OrderActionsTypes, actions as orderActions } from "./order/actions";
import { ActionTypes as SearchReasultsActionTypes, actions as searchResultsActions } from "./searchResults/actions";
import { RootState } from "store";
import { offerExtraProduct } from "utils/checkoutOfferPersistedStore";
import { flightsLocalStore, flightsSessionStore } from "@flights/web-api-utils-universal";
import {
  ALTERNATIVE_FARES_REQUEST_DATA_TIMEOUT,
  ALTERNATIVE_FARES_FLIGHT_OFFER_OBJECT_KEY,
  CURRENT_ORDER_ID_KEY,
  SIRF_AVAILABLE_KEY
} from "../constants";
import { isSirfAvailable } from "./selectors/sirf";

export const listenerMiddleware = createListenerMiddleware();

// When we add/remove products, we update the persistent store.
const addExtraProductEffect = (
  { payload: { productType } }: ReturnType<typeof actions.addProduct | typeof actions.setProducts>,
  listenerApi: unknown
) => {
  const rootState = (listenerApi as { getState: () => RootState }).getState();
  const offerToken = rootState.flightDetails.flight?.token || "";
  const selectedExtraProducts = rootState.extraProducts.selected;

  if (!offerToken) {
    throw new Error("No offerToken found while updating persisted store");
  }

  offerExtraProduct.add({
    offerToken,
    key: productType,
    value: selectedExtraProducts[productType]
  });
};

const removeExtraProductEffect = (
  { payload: { productType } }: ReturnType<typeof actions.removeProduct | typeof actions.removeAllProducts>,
  listenerApi: unknown
) => {
  const rootState = (listenerApi as { getState: () => RootState }).getState();
  const offerToken = rootState.flightDetails.flight?.token || "";

  if (!offerToken) {
    throw new Error("No offerToken found while updating persisted store");
  }

  offerExtraProduct.remove({
    offerToken,
    key: productType
  });
};

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.addProduct,
  effect: (action: ReturnType<typeof actions.addProduct>, listenerApi) => addExtraProductEffect(action, listenerApi)
});

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.removeProduct,
  effect: (action: ReturnType<typeof actions.removeProduct>, listenerApi) =>
    removeExtraProductEffect(action, listenerApi)
});

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.setProducts,
  effect: (action: ReturnType<typeof actions.setProducts>, listenerApi) => addExtraProductEffect(action, listenerApi)
});

listenerMiddleware.startListening({
  type: ExtraProductsActionTypes.removeAllProducts,
  effect: (action: ReturnType<typeof actions.removeAllProducts>, listenerApi) =>
    removeExtraProductEffect(action, listenerApi)
});

// Write flight to local storage to persist flight data if user reloads and tab switches during alternative fares flow
listenerMiddleware.startListening({
  type: FlightDetailsActionTypes.fetchSuccess,
  effect: (action: ReturnType<typeof flightDetailsActions.fetchSuccess>) => {
    const flight = action.payload.flight;

    flightsLocalStore.set(ALTERNATIVE_FARES_FLIGHT_OFFER_OBJECT_KEY, flight, {
      ttl: ALTERNATIVE_FARES_REQUEST_DATA_TIMEOUT
    });
  }
});

// Write the current order ID to local storage to persist it through reloads and tab switches during alternative fares flow
listenerMiddleware.startListening({
  type: OrderActionsTypes.fetchSuccess,
  effect: (action: ReturnType<typeof orderActions.fetchSuccess>) => {
    const orderId = action.payload.order.orderId;

    flightsLocalStore.set(CURRENT_ORDER_ID_KEY, orderId, { ttl: ALTERNATIVE_FARES_REQUEST_DATA_TIMEOUT });
  }
});

// Write SIRF availability to session to persist through reloads during alternative fares flow
listenerMiddleware.startListening({
  type: SearchReasultsActionTypes.fetchSuccess,
  effect: (action: ReturnType<typeof searchResultsActions.fetchSuccess>) => {
    const subsidizedFareSummary = action.payload.subsidizedFaresSummary || [];
    const sirfAvailable = isSirfAvailable(subsidizedFareSummary);

    flightsSessionStore.set(SIRF_AVAILABLE_KEY, sirfAvailable);
  }
});
