import { createMicroConversionTrackingUtils } from "..";
import useProfiler from "hooks/useProfiler";
import { useEffect } from "react";

const utils = createMicroConversionTrackingUtils({
  name: "flights_web_positive_flexi_banner_blackout_ddot",
  stages: {
    allSr: 1,
    positiveBanner: 2,
    direct: 3,
    meta: 4,
    us: 5,
    solo: 6,
    nonSolo: 7
  },
  defaultStage: 2,
  mainStage: 2,
  goals: {},
  goalsWithValue: []
});

const useTrackStages = () => {
  const { stages } = utils;
  const { isDirect, isMeta, isAmerican, isSolo } = useProfiler();

  useEffect(() => {
    isDirect() && stages.direct();
    isMeta() && stages.meta();
    isAmerican() && stages.us();
    isSolo() ? stages.solo() : stages.nonSolo();
  }, [stages, isDirect, isMeta, isAmerican, isSolo]);
};

export default { ...utils, useTrackStages };
