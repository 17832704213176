import localstory from "localstory";
/**
 * Store for managing flight data in session storage when running in browser
 * with special handling for Jest test environment
 */
const flightsSessionStore = localstory(process.env.BUILD_TARGET == "client" ? window.sessionStorage : undefined, "flights", process.env.JEST_WORKER_ID !== undefined ? { vacuum: false } : undefined);
/**
 * Store for managing flight data in local storage when running in browser
 */
const flightsLocalStore = localstory(process.env.BUILD_TARGET == "client" ? window.localStorage : undefined, "flights", process.env.JEST_WORKER_ID !== undefined ? { vacuum: false } : undefined);
export { flightsSessionStore, flightsLocalStore };
