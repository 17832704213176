import { ActionsUnion, createAction } from "@bookingcom/flights-core";
import { UISearchResults, UIClientFetchError } from "@flights/types";

export enum ActionTypes {
  fetch = "searchResults/fetch",
  fetchSuccess = "searchResults/fetchSuccess",
  fetchError = "searchResults/fetchError",
  reset = "searchResults/reset"
}

export const actions = {
  fetch: createAction(() => ({
    type: ActionTypes.fetch,
    payload: {}
  })),

  fetchSuccess: createAction((data: UISearchResults) => ({
    type: ActionTypes.fetchSuccess,
    payload: data
  })),

  fetchError: createAction((error: UIClientFetchError) => ({
    type: ActionTypes.fetchError,
    payload: error
  })),

  reset: createAction(() => ({
    type: ActionTypes.reset,
    payload: {}
  }))
};

export type Actions = ActionsUnion<typeof actions>;
