import { useCallback, useMemo } from "react";
import useRouteName from "./useRouteName";
import useUserAgent from "./useUserAgent";
import { stage as trackStage } from "@flights/et-universal";

const useTrackExpPagesTraffic = () => {
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();

  const expName = useMemo(() => {
    return isMobile ? "flights_web_mdot_aa_pages_traffic" : "flights_web_ddot_aa_pages_traffic";
  }, [isMobile]);

  const trackStageNumber = useCallback(
    (stage?: number) => {
      if (stage) {
        trackStage(expName, stage);
      }
    },
    [expName]
  );

  const trackStages = useCallback(() => {
    trackStageNumber();
    switch (routeName) {
      case "home":
        trackStageNumber(1);
        break;
      case "searchresults":
        trackStageNumber(2);
        break;
      case "flightdetails":
        trackStageNumber(3);
        break;
      case "checkout-ticket-type":
        trackStageNumber(4);
        break;
      case "checkout":
        trackStageNumber(5);
        break;
      case "checkout2":
        trackStageNumber(6);
        break;
      case "checkout-fare":
        trackStageNumber(7);
        break;
      case "checkout-seat-selection":
        trackStageNumber(8);
        break;
      case "checkout3":
        trackStageNumber(9);
        break;
      default:
        break;
    }
  }, [routeName, trackStageNumber]);

  return {
    trackStages
  };
};

export default useTrackExpPagesTraffic;
