import { useCallback, useMemo, useState } from "react";
import { stage as trackStage } from "@flights/et-universal";
import useProfiler from "./useProfiler";
import useRouteName from "./useRouteName";

export default function useTrackExpPaxHaulTraffic() {
  const [tracked, setTracked] = useState(false);
  const routeName = useRouteName();
  const {
    isMobile,
    isDesktop,
    isDirect,
    isMeta,
    isSolo,
    isCouple,
    isFamily,
    isGroup,
    isShortHaul,
    isMediumHaul,
    isLongHaul
  } = useProfiler();

  const expName = useMemo(() => {
    if (isDesktop() && isDirect() && routeName === "home") return "Flights_web_ddot_direct_aa_pax_haul";
    if (isMobile() && isDirect() && routeName === "home") return "Flights_web_mdot_direct_aa_pax_haul";
    if (isDesktop() && isMeta() && routeName === "searchresults") return "Flights_web_ddot_meta_aa_pax_haul";
    if (isMobile() && isMeta() && routeName === "searchresults") return "flights_web_mdot_meta_aa_pax_haul";
    return undefined;
  }, [routeName, isMobile, isDesktop, isDirect, isMeta]);

  const trackStageNumber = useCallback(
    (stage: number) => {
      if (!expName || tracked) return;
      trackStage(expName, stage);
    },
    [expName, tracked]
  );

  const trackPaxHaulStages = useCallback(() => {
    if (!expName || tracked) return;
    setTracked(true);
    trackStageNumber(1);
    if (isSolo()) trackStageNumber(2);
    if (isCouple()) trackStageNumber(3);
    if (isFamily()) trackStageNumber(4);
    if (isGroup()) trackStageNumber(5);
    if (isShortHaul()) trackStageNumber(6);
    if (isMediumHaul()) trackStageNumber(7);
    if (isLongHaul()) trackStageNumber(8);
  }, [expName, tracked, trackStageNumber, isSolo, isCouple, isFamily, isGroup, isShortHaul, isMediumHaul, isLongHaul]);

  return { trackPaxHaulStages };
}
