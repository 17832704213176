import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { pricingUtils } from "@flights/web-api-utils-universal";
import { UISearchAiSummary } from "@flights/types";

import { actions as searchAiSummaryActions } from "store/searchAiSummary/actions";
import { getSearchAvailableFilters } from "store/searchResults/selectors";
import { getSearchCriteria } from "store/searchCriteria/selectors";
import { clientFetch } from "@flights/client-fetch";
import useContextName from "hooks/useContextName";

const useAiSummaryController = () => {
  const contextName = useContextName();
  const dispatch = useDispatch();
  const { stops = [] } = useSelector(getSearchAvailableFilters);
  const { adults, children } = useSelector(getSearchCriteria);

  const requestPayload = useMemo(() => {
    return {
      passengers: adults + children.length,
      stops: stops.map(({ numberOfStops, count, minPrice }) => {
        return {
          numberOfStops,
          count,
          minPrice: pricingUtils.priceToNumber(minPrice)
        };
      }),
      currencyCode: stops[0]?.minPrice.currencyCode || ""
    };
  }, [adults, children, stops]);

  useEffect(() => {
    if (requestPayload.stops.length === 0 || requestPayload.passengers === 0) {
      dispatch(searchAiSummaryActions.fetchError({ message: "Empty search results" }));
    } else {
      dispatch(searchAiSummaryActions.fetch());

      clientFetch("/api/search/ai-summary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Flights-Context-Name": contextName
        },
        body: JSON.stringify(requestPayload)
      })
        .then((data: UISearchAiSummary) => {
          dispatch(searchAiSummaryActions.fetchSuccess(data));
        })
        .catch((error) => {
          dispatch(searchAiSummaryActions.fetchError(error));
        });
    }
  }, [JSON.stringify(requestPayload)]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useAiSummaryController;
