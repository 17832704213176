/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { ReactNode } from "react";

import { Actionable, Tooltip } from "@bookingcom/bui-react";
import flights_merch_toolkit_offer_pinning from "utils/experiments/genius/flights_merch_toolkit_offer_pinning";

type Props = {
  hover?: {
    title: string;
    text: string;
  };
  children: ReactNode;
};

export const FlightCardBadgeTooltipWrapper = ({ hover, children }: Props) => {
  if (!hover) return <>{children}</>;
  return (
    <Tooltip
      position="top-start"
      text={hover.text}
      onOpen={async () => {
        flights_merch_toolkit_offer_pinning.goals.featured_badge_hover();
      }}
    >
      {(attributes) => <Actionable attributes={attributes}>{children}</Actionable>}
    </Tooltip>
  );
};
