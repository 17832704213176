import React, { FC, useState, useMemo, useCallback } from "react";
import {
  Banner,
  Icon,
  Card,
  Button,
  SheetContainer,
  SheetContainerProps,
  Text,
  Spinner,
  Stack
} from "@bookingcom/bui-react";
import { SparklesIcon } from "@bookingcom/bui-assets-react/streamline";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import styles from "./GenAiSearchSummary.module.css";

type Props = {
  modalText: string;
  variant?: "banner" | "card";
  className?: string;
  modalPosition?: SheetContainerProps["position"];
  isLoading?: boolean;
  isError?: boolean;
  onCtaClick?: VoidFunction;
  onBannerClose?: VoidFunction;
  onModalClose?: VoidFunction;
};

const GenAiSearchSummary: FC<Props> = ({
  variant = "banner",
  className,
  modalPosition = "center",
  modalText,
  isLoading,
  isError,
  onCtaClick,
  onBannerClose,
  onModalClose
}) => {
  const i18n = useI18n();
  const [modalVisible, setModalVisible] = useState(false);

  const onButtonClick = useCallback(() => {
    setModalVisible(true);
    onCtaClick?.();
  }, [onCtaClick]);

  return (
    <div className={className}>
      {variant === "banner" ? (
        <GenAiBanner onCtaClick={onButtonClick} onBannerClose={onBannerClose} />
      ) : (
        <GenAiCard onCtaClick={onButtonClick} />
      )}

      <SheetContainer
        active={modalVisible}
        position={modalPosition}
        title={i18n.trans(t("flights_sr_genai_modal_search_summary_heading"))}
        onCloseTrigger={() => {
          setModalVisible(false);
          onModalClose?.();
        }}
        closeAriaLabel={i18n.trans(t("a11y_flights_sr_genai_summary_close"))}
      >
        <div className={styles.modalContent}>
          {isError ? <ModalError /> : isLoading ? <ModalLoading /> : <ModalContent text={modalText} />}
        </div>
      </SheetContainer>
    </div>
  );
};

const GenAiBanner: FC<{
  className?: string;
  onCtaClick?: VoidFunction;
  onBannerClose?: VoidFunction;
}> = ({ className, onCtaClick, onBannerClose }) => {
  const i18n = useI18n();
  const [bannerVisible, setBannerVisible] = useState(true);

  if (!bannerVisible) {
    return null;
  }

  return (
    <Banner
      className={className}
      title={i18n.trans(t("flights_sr_genai_search_summary_title"))}
      titleTagName="h2"
      startIcon={
        <div className={styles.bannerIconHolder}>
          <Icon svg={SparklesIcon} size="medium" color="action" />
        </div>
      }
      actions={[
        {
          text: i18n.trans(t("flights_sr_genai_search_summary_cta")),
          onClick: onCtaClick
        }
      ]}
      dismissible={true}
      onClose={() => {
        setBannerVisible(false);
        onBannerClose?.();
      }}
      closeAriaLabel={i18n.trans(t("a11y_flights_sr_genai_search_summary_close"))}
    >
      <Text variant="body_2">{i18n.trans(t("flights_sr_genai_search_summary_subtitle"))}</Text>
    </Banner>
  );
};

const GenAiCard: FC<{
  className?: string;
  onCtaClick?: VoidFunction;
}> = ({ className, onCtaClick }) => {
  const i18n = useI18n();

  return (
    <Card className={className}>
      <Text variant="strong_1" tagName="h2" className={styles.cardTitle}>
        {i18n.trans(t("flights_sr_genai_search_summary_title"))}
      </Text>

      <Text variant="body_2" className={styles.cardSubtitle}>
        {i18n.trans(t("flights_sr_genai_search_summary_subtitle"))}
      </Text>

      <Button variant="secondary" icon={SparklesIcon} wide={true} onClick={onCtaClick}>
        {i18n.trans(t("flights_sr_genai_search_summary_cta"))}
      </Button>
    </Card>
  );
};

const ModalContent: FC<{ text: string }> = ({ text }) => {
  // This is temporary workaround, and must be revisited after GenAI search summary MVP.
  // We should find a proper way to template this string from ML - an alternative approach is to be discussed by Web funnel team.
  const textPieces = useMemo(() => {
    return text.split(/{start_bold}|{end_bold}/);
  }, [text]);

  return (
    <div>
      {textPieces.map((piece, index) => {
        const isItBoldPiece = index % 2 === 1;
        return isItBoldPiece ? <strong>{piece}</strong> : piece;
      })}
    </div>
  );
};

const ModalLoading: FC = () => {
  const i18n = useI18n();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Spinner size="small" />
      <Text variant="body_2">{i18n.trans(t("flights_sr_genai_search_summary_loading"))}</Text>
    </Stack>
  );
};

const ModalError: FC = () => {
  const i18n = useI18n();

  return <Text variant="body_2">{i18n.trans(t("flights_sr_genai_modal_search_summary_loading_error"))}</Text>;
};

export default GenAiSearchSummary;
