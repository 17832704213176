import { useMemo } from "react";
import { UIFlightData } from "@flights/types";
import useRouteName from "hooks/useRouteName";
import { useSelector } from "react-redux";
import { getAncillaries, getFlightDetailsFetchStatus } from "store/flightDetails/selectors";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { priceBreakdownUtils, localizationUtils } from "@flights/web-api-utils-universal";
import { useFlightOfferWithDiscount } from "hooks/useFlightOfferWithDiscount";
import usePointOfSale from "hooks/usePointOfSale";
import { getPreOptedAncillaries } from "ancillaries/store/extraProducts/selectors";
import { transformPreOptedExtras } from "./transforms/preOptedExtras";

export type UnifiedPriceBreakdownFlightCardPriceControllerProps = {
  flight?: UIFlightData;
};

type CopyFuncParams = Parameters<typeof priceBreakdownUtils.getExtraCopy>;
type POSType = CopyFuncParams[3]["pointOfSale"];

export function useUnifiedPriceBreakdownFlightCardPriceController(
  props: UnifiedPriceBreakdownFlightCardPriceControllerProps
) {
  const { flight } = props;
  const i18n = useI18n();
  const ipCountry = usePointOfSale();
  const routeName = useRouteName();
  const ancillaries = useSelector(getAncillaries);
  const preOptedAncillaries = useSelector(getPreOptedAncillaries);
  const fetchStatus = useSelector(getFlightDetailsFetchStatus);
  const segments = useMemo(() => flight?.segments, [flight]);
  const isHungaryDepartingRyanAir = useMemo(
    () => localizationUtils.isHungaryDepartingRyanAirFlight(segments),
    [segments]
  );
  const baseOptions = useMemo(
    () => ({
      pointOfSale: (ipCountry || "") as POSType,
      isFlightFromRyanAirHungary: isHungaryDepartingRyanAir
    }),
    [ipCountry, isHungaryDepartingRyanAir]
  );
  const unifiedPriceBreakdown = useMemo(() => flight?.unifiedPriceBreakdown, [flight]);

  const allAncillaries = useMemo(() => {
    return ancillaries || flight?.ancillaries || {};
  }, [flight, ancillaries]);

  const preOptedExtras = useMemo(
    () => transformPreOptedExtras(preOptedAncillaries, allAncillaries),
    [preOptedAncillaries, allAncillaries]
  );

  const addedItems = useMemo(() => {
    // show pre-opted ancillaries only in flight details
    if (routeName !== "flightdetails") return [];

    return priceBreakdownUtils.transformUnifiedPriceBreakdownItemForExtra(i18n, preOptedExtras, baseOptions);
  }, [routeName, i18n, baseOptions, preOptedExtras]);

  const isFlightDetailsLoading = useMemo(() => {
    if (routeName !== "flightdetails") return false;
    return fetchStatus === "loading";
  }, [fetchStatus, routeName]);

  const flightDetailsLoadingText = useMemo(() => {
    return i18n.trans(t("flights_price_breakdown_loading_details"));
  }, [i18n]);

  const { isWithDiscount } = useFlightOfferWithDiscount(flight); // flights_web_unified_price_breakdown

  return {
    unifiedPriceBreakdown,
    isHungaryDepartingRyanAir,
    isFlightDetailsLoading,
    isWithDiscount, // flights_web_unified_price_breakdown
    flightDetailsLoadingText,
    addedItems
  };
}
