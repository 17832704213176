import useGetUrlParams from "hooks/useGetUrlParams";
import useAvailableBrandedFares from "branded-fares/hooks/useAvailableBrandedFares";

const useIsBrandedFare = () => {
  const brandedFareOffers = useAvailableBrandedFares();
  const { getUrlParam } = useGetUrlParams();
  const isClient = process.env.BUILD_TARGET === "client";
  const hasBaseOffer = isClient && !!getUrlParam("baseOfferToken");
  const hasSelectedFareIndex = isClient && !!getUrlParam("selectedFareIndex");
  return brandedFareOffers.length > 0 || hasBaseOffer || hasSelectedFareIndex;
};

export default useIsBrandedFare;
