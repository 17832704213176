/**
 * Constants for the Frontend application
 *
 * Guidelines for adding constants:
 * 1. Frontend application-specific constants stay here.
 * 2. Constants used across multiple apps go in the shared @flights/constants package.
 * 3. Consider the constant's scope and potential for reuse when deciding.
 *
 * For detailed rationale, refer to the constants management documentation
 * @see packages/constants/README.md
 * @see apps/frontend/docs/constants-management.md
 */

import { HELP_CENTER_URL, PLATFORM_ENV } from "@flights/constants";

export const FLIGHTS_HOSTNAME = "flights.booking.com";

export const BOOKING_SSO_COOKIE_NAME = PLATFORM_ENV === "prod" ? "bkng_sso_session" : "dqs_bkng_sso_session";
export const ACCOUNTS_PORTAL_URL =
  PLATFORM_ENV === "prod" ? "https://account.booking.com" : "https://account.dqs.booking.com";

export const ANALYTICS_SESSION_COOKIE_NAME = PLATFORM_ENV === "prod" ? "fasc" : "dqs_fasc";

//for running it on dev, put the oauth-client.json next to your repo
export const OAUTH_CONFIG_FILE_PATH = PLATFORM_ENV
  ? "/var/run/secrets/booking.com/oauth-client.json"
  : `${require("path").resolve(__dirname, "../")}/oauth-client.json`;

export const LOCAL_SECRETS_FILE_PATH = `${require("path").resolve(__dirname, "../")}/local-keys.json`;

export const PB_URL_PARAM_SHOW_FARE_RULES = "showFareRules";

export const DESKTOP_SIDEBAR_WIDTH = 360;
export const FLOATING_PANEL_MARGIN = 64;

// for Non-Live countries we want to authorise them if they have been authorised in the last 30 minutes
// via a meta landing that has the same aid the current request has. We will use session service data for this.
export const NON_LIVE_IP_AUTHORISATION_DURATION = 30 * 60 * 1000; // 30 minutes in ms

export const STICKY_FOOTER_SPACER_ID = "sticky-footer-spacer";

export const preserveURLParams = [
  "aid",
  "label",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "lang",
  "locale",
  "ext-tr",
  "ext-src",
  "ext-origin",
  "ext-fwd",
  "adplat",
  "offerInstanceId"
];

export const FLIGHTS_SHARE_DIRECT_LABEL = "flights-share-direct";
export const FLIGHTS_SHARE_META_LABEL = "flights-share-meta";
export const FLIGHTS_IOS_SHARE_DIRECT_LABEL = "flights-ios-share-direct";
export const FLIGHTS_IOS_SHARE_META_LABEL = "flights-ios-share-meta";
export const FLIGHTS_ANDROID_SHARE_DIRECT_LABEL = "flights-android-share-direct";
export const FLIGHTS_ANDROID_SHARE_META_LABEL = "flights-android-share-meta";

//PPC conversion tracking: https://jira.booking.com/jira/browse/FLIGHTS-497
export const GOOGLE_TAG_MANAGER_CONTAINER_ID = "AW-1070314322";
export const GOOGLE_TAG_MANAGER_CONTAINER_LABEL = "7zaICNHurMQBENLmrv4D";

export const SMALL_SCREEN_375 = "@media (max-width: 375px)";

export const FILTERS = {
  duration: "DURATION",
  airlines: "AIRLINES",
  stops: "NUMBER_OF_STOPS",
  journeyTime: "JOURNEY_TIME_FILTER",
  flightTimes: "FLIGHT_TIMES_FILTER",
  shortLayoverConnection: "LAYOVER_CONNECTION",
  budget: "MAX_BUDGET",
  budgetPerAdult: "MAX_BUDGET_PER_ADULT",
  includedBaggage: "INCLUDED_BAGGAGE",
  layoverDuration: "LAYOVER_DURATION",
  airports: "AIRPORTS_FILTER",
  preferSameAirport: "PREFER_SAME_AIRPORT"
};

/*eslint-disable-next-line flights/no-unassigned-todo-comments*/
/* TODO(asamilyak): remove `mobileTravelPlan` and `checkoutFlexibleTicket` here once we fully migrate to Checkout 2.0
        and get rid of pre opted ancillaries during checkout process.
        By then we won't need one-ancillary to many-query-params mapping (for flexible ticket),
        and this mapping can be converted to `{ [key in UIPreOptInProductType]: string }` type
*/
export const PRE_OPTED_ANCILLARIES_QUERY_PARAMS = {
  mobileTravelPlan: "sms",
  flexibleTicket: "flexibleTicket",
  checkedInBaggage: "checkedInBaggage",
  checkoutFlexibleTicket: "checkoutFlexibleTicket",
  cancelForAnyReason: "cancelForAnyReason",
  standardTicket: "standardTicket"
};

export const BRANDED_FARES_QUERY_PARAMS = {
  baseOfferToken: "baseOfferToken",
  selectedFareIndex: "selectedFareIndex",
  bfPageLoadTimeStart: "bfPageLoadTimeStart"
};
export const PRE_FETCHED_FARES_QUERY_PARAMS = {
  hasPrefetchedBrandedFareOffers: "hasPrefetchedBrandedFareOffers"
};

export const ALL_EU_COUNTRIES = [
  "BE",
  "EL",
  "LT",
  "PT",
  "BG",
  "ES",
  "LU",
  "RO",
  "CZ",
  "FR",
  "HU",
  "SI",
  "DK",
  "HR",
  "MT",
  "SK",
  "DE",
  "IT",
  "NL",
  "FI",
  "EE",
  "CY",
  "AT",
  "SE",
  "IE",
  "LV",
  "PL",
  "GR"
];

export const ALL_EFTA_COUNTRIES = ["IS", "LI", "NOR", "CH"]; //European Free Trade Association

export const FLOW_TYPE_SUPPLIER_3DS = "supplier3ds";

export const validDateStringLength = 10; //"yyyy-mm-dd" format

export const GOOGLE_FLIGHTS_EXT_ORIGIN_PARAM_VALUES = ["gfs", "gfsapi"];
export const GOOGLE_SEARCH_WIDGET_EXT_ORIGIN_PARAM_VALUES = ["google-widget"];
export const SKYSCANNER_EXT_ORIGIN_PARAM_VALUES = ["skyscanner"];
export const KAYAK_EXT_ORIGIN_PARAM_VALUES = ["kayak"];
export const WEGO_EXT_ORIGIN_PARAM_VALUES = ["wego"];
export const FLYGRESOR_EXT_ORIGIN_PARAM_VALUES = ["flygresor.se"];

export const COVID_INFORMATION_PAGE_URL = "https://www.booking.com/covid-19.html";

export const VI_TERMS_AND_CONDITIONS_LINK = `${HELP_CENTER_URL}/faq/40/RmxpZ2h0cyAtIEZBUSAtIFNlbGYgVHJhbnNmZXI?category=flights_faq_topic_booking&source=flights_vi&render_html=1`;

export const FLIGHTS_INDEX_BASE_URL = "https://www.booking.com/flights/index";

export const ACCOMMODATIONS_DOMAIN_URL = "https://www.booking.com";
export const ACCOMMODATIONS_DOMAIN_URL_DQS = "https://www.dqs.booking.com";

export const ETG_VALIDATION_INVALID_PHONE = "CART_MANAGEMENT_INITIALIZEORDER_ETG_VALIDATION_INVALID_PHONE";
export const ETG_VALIDATION_INVALID_EMAIL_DOMAIN =
  "CART_MANAGEMENT_INITIALIZEORDER_ETG_VALIDATION_INVALID_EMAIL_DOMAIN";
export const ETG_VALIDATION_INVALID_EMAIL_SYNTAX =
  "CART_MANAGEMENT_INITIALIZEORDER_ETG_VALIDATION_INVALID_EMAIL_SYNTAX";

export const CART_RESERVATION_AIR_PRODUCT_NOT_AVAILABLE = "CART_RESERVATION_AIR_PRODUCT_NOT_AVAILABLE";

export const COMPANY_HOSTNAME = "www.booking.com";

export const ETG_SALES_CHANNEL_PPC_VALUE = "ppc";

export const SESSION_STORAGE_KEY_LAST_SUCCESSFUL_SEARCH = "last_successful_search_params";
export const SESSION_STORAGE_KEY_SEARCH_AI_SUMMARY_DISMISSED = "search_ai_summary_dismissed";

//const MANDATORY_SEARCH_PARAMS_ROUNDTRIP = [...MANDATORY_SEARCH_PARAMS_ONEWAY, "return"];

export const INSURANCE_PROVIDER_NAME = "SOLID Insurance";
export const INSURANCE_PROVIDER_ADDRESS = "Box 22068, 250 22 Helsingborg, Sweden, Corp ID No 516401-8482";

export const SEO_ROUTES = ["home", "landing"];

export const SEATMAP_EXTRA_TYPE = "SEATING_SEATMAP";

export const LANDING_OFFER_FROM_META_KEY = "landing_offer_from_meta";
export const LANDING_BRANDED_OFFER_FROM_META = "landing_branded_offer_from_meta";
export const PRICE_ACCURACY_TAG_FIRED_OFFER = "price_accuracy_tag_fired_offer";
export const PRICE_DISCREPANCY_LOGGED_OFFER = "price_discrepancy_logged_offer";
export const RETRY_NETWORK_ERROR_FOR_GAPD = "flight_details_api_network_error";
export const META_LANDING_URL_KEY = "meta_landing_url";

export const PUBLIC_PATH = "https://cf.bstatic.com/psb/flights/";

export const SAVED_PAX_FORM_KEY_NAME = "pax_saved";

export const FLIGHT_DETAILS_RETRYABLE_ERROR_CODES = ["UPSTREAM_SUPPLIER_NOT_AVAILABLE"];
export const FARE_RULES_AVAILABLE_CARRIERS = ["U2", "DS", "HV", "TO", "FR", "RK"];

export const FILTERS_STOPS_MAX = 1;

export const SCHENGEN_COUNTRY_CODE_LIST = [
  "at",
  "be",
  "hr",
  "cz",
  "dk",
  "ee",
  "fi",
  "fr",
  "de",
  "gr",
  "hu",
  "is",
  "it",
  "lv",
  "li",
  "lt",
  "lu",
  "mt",
  "no",
  "pl",
  "pt",
  "sk",
  "si",
  "es",
  "se",
  "ch",
  "nl"
];

export const UNSUPPORTED_PDF_LOCALES = ["hi", "ja", "ko", "th", "zh-cn", "zh-tw", "he", "ar"];

export const RYANAIR_HC_LINK = `${HELP_CENTER_URL}/faq/40/RmxpZ2h0cyAtIEZBUXMgLSBSeWFuYWlyIDEwOTQ1Mw?category=flights_faq_topic_make_booking`;

export const ACC_REMOTE_COMPONENT_HOST = "accommodationsapi";
export const ACC_REMOTE_COMPONENT_DQS_BASE_URL = "https://accommodations.dqs.booking.com";
export const ACC_REMOTE_COMPONENT_PROD_BASE_URL = "https://accommodations.booking.com";
export const ACC_REMOTE_COMPONENT_ENDPOINT = "/orca/translate-components";
export const ORCA_REMOTE_COMPONENT_HOST = "b-capla-orca";
export const ORCA_REMOTE_COMPONENT_DQS_BASE_URL = "https://orca.dqs.booking.com";
export const ORCA_REMOTE_COMPONENT_PROD_BASE_URL = "https://orca.prod.booking.com";
export const ORCA_REMOTE_COMPONENT_ENDPOINT = "/translate/v1/remote-components";
export const REMOTE_COMPONENT_PROXY_ENDPOINT = "/rc/translate-components";

export const EUROPEAN_COUNTRIES = [
  "be",
  "bg",
  "cz",
  "dk",
  "de",
  "ee",
  "ie",
  "el",
  "es",
  "fr",
  "hr",
  "it",
  "cy",
  "lv",
  "lt",
  "lu",
  "hu",
  "mt",
  "nl",
  "at",
  "pl",
  "pt",
  "ro",
  "si",
  "sk",
  "fi",
  "se"
];

export const FLIGHTS_UNKNOWN_URL_REGEX = /^\/flights\/[^\/?]+$/;

export const KAYAK_ADS = "kayak_ads";

export const TRIP_TYPE = {
  ROUNDTRIP: "ROUND_TRIP",
  ONEWAY: "ONE_WAY"
} as const;

export const LP_CITY_TO_CITY_URL = "/flights/route/city-to-city";
export const LP_CITY_URL = "/flights/destination/city";
export const LP_COUNTRY_URL = "/flights/route/to-country";
export const LP_REGION_URL = "/flights/destination/to-region";
export const LP_AIRPORT_URL = "/flights/destination/to-airport";

export const BLACK_FRIDAY_IMAGE_URL =
  "https://xx.bstatic.com/xdata/images/xphoto/283x280/412494956.png?k=22eed7c17675616e5703a99ea8fe121dde997ea34ecdfac5e8b71384d9cac6ce&o=";
export const BLACK_FRIDAY_BG_COLOR = "#041B44";

export const WINTER_DEALS_IMAGE_MDOT =
  "https://r-xx.bstatic.com/xdata/images/xphoto/900x900/438093166.jpeg?k=4a45a5ad633b661595fe5d0789a8c4379e672c1ad6a3c36948cd0c4e0ba52c64&o=";
export const WINTER_DEALS_EXLUDED_COUNTRIES = ["gb", "id", "qa"];

export const WEEKEND_GETAWAYS_DAYS = 30;
export const WEEKEND_GETAWAYS_EXCLUDED_COUNTRIES = ["at", "sg", "hk", "th", "my", "kr"];

export const ALTERNATIVE_FARES_FLIGHT_OFFER_OBJECT_KEY = "alternative_fares_flight_offer";
export const ALTERNATIVE_FARES_RESULTS = "alternative_fares_results";
export const CURRENT_ORDER_ID_KEY = "current_order_id";
export const SIRF_AVAILABLE_KEY = "sirf_available";
export const REFRESH_RESULTS_TIMEOUT = 1000 * 60 * 5;
export const ALTERNATIVE_FARES_REQUEST_DATA_TIMEOUT = 1000 * 60 * 60;

export const ET_MICROCONVERSION_EXP_NAME = "flights_web_2025_q1_blackout_aa";
export const ET_MICROCONVERSION_COOKIE_NAME = "3e36068374047a73"; // hashed name
