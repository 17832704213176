import { UIBrandedFare, UIFlightData } from "@flights/types";
import { t } from "@bookingcom/lingojs-core";
import React, { useMemo } from "react";
import { Text, Title } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
import useFormatPrice from "../../../../hooks/useFormatPrice";
import getLowestAdultTravellerPrice from "../../../../utils/lowest-adult-traveller-price";
import Frame from "components/elements/Frame";
import styles from "./FareCardPrice.module.css";
import {
  getCfarTicketFullPrice,
  getCfarTicketPricePerPerson,
  getFlexibleTicketFullPrice,
  getFlexibleTicketPricePerPerson
} from "../../CheckoutTicketTypeInner/ticketTypeUtils";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useUserAgent from "hooks/useUserAgent";
import useRouteName from "hooks/useRouteName";

type FareCardPriceProps = {
  fareOffer: UIBrandedFare;
  baseOffer: UIFlightData;
  isFlexTicket?: boolean;
  isCfarTicket?: boolean;
  isBold?: boolean;
};

const FareCardPrice = (props: FareCardPriceProps) => {
  const { fareOffer, baseOffer, isFlexTicket: isFlexTicket, isCfarTicket, isBold } = props;
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();

  const adultTravellerPrice = getLowestAdultTravellerPrice(fareOffer.travellerPrices);
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();

  const isRoundPrice = !isOfMetaOrigin() && isMobile;

  const totalPrice = useMemo(() => {
    if (isFlexTicket && baseOffer.offerExtras?.flexibleTicket) {
      return formatPrice(getFlexibleTicketFullPrice(baseOffer).total);
    }
    if (isCfarTicket && baseOffer && baseOffer.ancillaries?.cancelForAnyReason) {
      const cfarTotal = getCfarTicketFullPrice(baseOffer);
      return cfarTotal ? formatPrice(cfarTotal.total) : formatPrice(fareOffer.priceBreakdown.total);
    }
    return isRoundPrice && fareOffer.priceBreakdown.totalRounded
      ? formatPrice(fareOffer.priceBreakdown.totalRounded, { decimalPlaces: 0 })
      : formatPrice(fareOffer.priceBreakdown.total);
  }, [
    fareOffer.priceBreakdown.total,
    fareOffer.priceBreakdown.totalRounded,
    formatPrice,
    isRoundPrice,
    isFlexTicket,
    isCfarTicket,
    baseOffer
  ]);
  const ariaAttributes = useMemo(() => {
    const attributes = {};

    const perPersonPrice = adultTravellerPrice
      ? formatPrice(adultTravellerPrice.travellerPriceBreakdown.total)
      : formatPrice(fareOffer.priceBreakdown.total);

    const totalPriceLabel = i18n.trans(t("a11y_flights_apex_price_per_traveller"));

    const perTravellerPriceLabel = `${totalPriceLabel}, ${i18n.trans(
      t("flights_sr_total_inc_price", { variables: { total_price: totalPrice } })
    )}`;

    const label = fareOffer.travellerPrices.length > 1 ? perTravellerPriceLabel : totalPriceLabel;

    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    attributes["aria-label"] = `${perPersonPrice} ${label}`;

    return attributes;
  }, [
    adultTravellerPrice,
    formatPrice,
    fareOffer.priceBreakdown.total,
    fareOffer.travellerPrices.length,
    i18n,
    totalPrice
  ]);

  const eachTravellerPrice = useMemo(() => {
    if (adultTravellerPrice) {
      if (isFlexTicket) {
        const flexibleTicket = baseOffer.offerExtras?.flexibleTicket;
        const adultTravellerPrice = getLowestAdultTravellerPrice(baseOffer.travellerPrices)?.travellerPriceBreakdown;
        const pricePerPerson = getFlexibleTicketPricePerPerson(flexibleTicket?.priceBreakdown, adultTravellerPrice);
        if (isRoundPrice && pricePerPerson.totalRounded) {
          return formatPrice(pricePerPerson.totalRounded, { decimalPlaces: 0 });
        }
        return formatPrice(pricePerPerson.total);
      }
      if (isCfarTicket) {
        const cfarTicket = baseOffer.ancillaries?.cancelForAnyReason;
        const adultTravellerPrice = getLowestAdultTravellerPrice(baseOffer.travellerPrices)?.travellerPriceBreakdown;
        const pricePerPerson =
          cfarTicket && getCfarTicketPricePerPerson(cfarTicket.priceBreakdown, adultTravellerPrice);
        if (cfarTicket && pricePerPerson) {
          if (isRoundPrice && pricePerPerson.totalRounded) {
            return formatPrice(pricePerPerson.totalRounded, { decimalPlaces: 0 });
          }
        }
        return pricePerPerson && formatPrice(pricePerPerson.total);
      }
      return isRoundPrice && adultTravellerPrice.travellerPriceBreakdown.totalRounded
        ? formatPrice(adultTravellerPrice.travellerPriceBreakdown.totalRounded, { decimalPlaces: 0 })
        : formatPrice(adultTravellerPrice.travellerPriceBreakdown.total);
    } else {
      return (
        <div className={styles.priceContainer}>
          {isRoundPrice && fareOffer.priceBreakdown.totalRounded
            ? formatPrice(fareOffer.priceBreakdown.totalRounded, { decimalPlaces: 0 })
            : formatPrice(fareOffer.priceBreakdown.total)}
        </div>
      );
    }
  }, [
    adultTravellerPrice,
    isFlexTicket,
    isCfarTicket,
    isRoundPrice,
    formatPrice,
    baseOffer.offerExtras?.flexibleTicket,
    baseOffer.ancillaries?.cancelForAnyReason,
    baseOffer.travellerPrices,
    fareOffer.priceBreakdown.totalRounded,
    fareOffer.priceBreakdown.total
  ]);

  const shouldHidePriceExplanation = useMemo<boolean>(() => {
    const isSolo = [...fareOffer.travellerPrices].length === 1;
    if (!["searchresults", "flightdetails"].includes(routeName) || !isSolo) {
      return false;
    }
    return true;
  }, [fareOffer.travellerPrices, routeName]);

  return (
    <div>
      <Frame direction="row" justifyContent="flex-start" attributes={ariaAttributes}>
        <Title
          variant={isBold ? "strong_2" : "strong_1"}
          attributes={{ "aria-hidden": true, "data-testid": "flight_card_price_main_price" }}
          className={styles.mainPrice}
          titleTagName="div"
          title={eachTravellerPrice}
        />
      </Frame>

      {!shouldHidePriceExplanation && (
        <Text
          variant="small_1"
          className={styles.totalPriceLabel}
          attributes={{ "aria-hidden": true, "data-testid": "flight_card_price_total_price" }}
        >
          <div className={styles.priceContainer}>
            {fareOffer.travellerPrices.length > 1
              ? i18n.trans(
                  t("flights_sr_total_inc_price", {
                    variables: { total_price: totalPrice }
                  })
                )
              : i18n.trans(t("flights_price_qualifier_total_for_all"))}
          </div>
        </Text>
      )}
    </div>
  );
};

export default FareCardPrice;
