import React from "react";
import { Actionable, Card, Icon, Stack, Text } from "@bookingcom/bui-react";
import { ArrowNavRightIcon, CheckmarkSelectedIcon, WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./styles.module.css";
import cxx from "../../__utils/cxx.js";
export function PaxInputCardHorizontal(props) {
    const { title, isError, isValid, icon, children, className, ...rest } = props;
    return (React.createElement(Actionable, { className: cxx(className, styles.fixSize), ...rest },
        React.createElement(Card, { variant: "neutral", className: cxx(styles.fixSize, isError && styles.cardError) },
            React.createElement(Stack, { direction: "row", gap: 4 },
                React.createElement(Stack.Item, null,
                    React.createElement(Icon, { attributes: { style: { alignSelf: "start" } }, size: "large", svg: icon })),
                React.createElement(Stack.Item, { grow: true },
                    React.createElement(Stack, { gap: 1 },
                        title ? React.createElement(Text, { variant: "strong_2" }, title) : undefined,
                        children ? React.createElement(Stack.Item, null, children) : undefined)),
                isError ? (React.createElement(Stack.Item, { alignSelf: "center" },
                    React.createElement(Icon, { size: "medium", color: "destructive", svg: WarningIcon }))) : undefined,
                !isError && isValid ? (React.createElement(Stack.Item, { alignSelf: "center" },
                    React.createElement(Icon, { size: "medium", color: "constructive", svg: CheckmarkSelectedIcon }))) : undefined,
                React.createElement(Stack.Item, { alignSelf: "center" },
                    React.createElement(Icon, { size: "large", svg: ArrowNavRightIcon }))))));
}
