import {
  CHANGEABLE_TICKET_FEATURES,
  REFUNDABLE_TICKET_FEATURES,
  BRANDED_FARE_FEATURES_CONFIG,
  BAGGAGE_FEATURE_NAMES
} from "@flights/branded-fares";
import {
  UIBrandedFareFeature,
  UIBrandedFareFeatureAvailability,
  UIBrandedFareInfo,
  BrandedFareClientFeature,
  BrandedFareFeatureConfig,
  BrandedFareFeatureGroup
} from "@flights/types";

type RenderFeaturesOptions = {
  includeSellableFeatures?: boolean;
  includeExcludedFeatures?: boolean;
  includeAllFlexFeatures?: boolean;
  isPreCheckAndPay?: boolean;
};

const boardingAndFlightCategories = ["CHECK_IN", "CS", "FEE", "DETAILS", "BAGGAGE"];
const baggageFeatureNames = Object.values(BAGGAGE_FEATURE_NAMES);

export const getBoardingAndFlightFeatures = (features: UIBrandedFareFeature[]) =>
  features.filter(
    (feature) =>
      feature.category &&
      boardingAndFlightCategories.includes(feature.category) &&
      // There are features that have category "BAGGAGE" but are not actual baggage ancillaries, like priority baggage.
      // We want to show those baggage related benefits, but we don't want to show the actual baggage ancillaries here.
      !baggageFeatureNames.includes(feature.featureName)
  );

export const getFeaturesToRender = (brandedFareInfo: UIBrandedFareInfo, options: RenderFeaturesOptions = {}) => {
  const sellable = getWithAvailability(brandedFareInfo.sellableFeatures, "SELLABLE");
  const nonIncluded = getWithAvailability(brandedFareInfo.nonIncludedFeatures, "NOT_INCLUDED");
  const included = getWithAvailability(brandedFareInfo.features, "INCLUDED");
  const includedFeatures = filterRenderableFeatures(included, sellable, options);
  const nonIncludedFeatures = filterRenderableFeatures(nonIncluded, [], options);

  return { includedFeatures, nonIncludedFeatures };
};

const filterRenderableFeatures = (
  features: UIBrandedFareFeature[],
  alternativeFeatures: UIBrandedFareFeature[] = [],
  options: RenderFeaturesOptions
) => {
  const compressed = compressSimilarFeatures(features);
  const alternativeCompressed = compressSimilarFeatures(alternativeFeatures);
  const renderableFeatures: BrandedFareClientFeature[] = [];

  for (const config of BRANDED_FARE_FEATURES_CONFIG) {
    const { name, group, icon } = config;

    let foundFeatures = findFeatures(config, compressed);
    if (foundFeatures.length === 0) foundFeatures = findFeatures(config, alternativeCompressed);

    for (const found of foundFeatures) {
      if (!found?.label) continue;

      const { label, availability, description, subtitle } = found;

      if (availability === "SELLABLE" && !shouldShowSellableFeature(options, group)) continue;
      if (availability === "NOT_INCLUDED" && !shouldShowExcludedFeature(options, group)) continue;

      renderableFeatures.push({ name, label, group, icon, availability, description, subtitle });
    }
  }

  return renderableFeatures;
};

const shouldShowSellableFeature = (options: RenderFeaturesOptions, group?: BrandedFareFeatureGroup) => {
  if (options.includeAllFlexFeatures && group === "FLEXIBILITY") return true;
  if (options.includeSellableFeatures && (group === "FLEXIBILITY" || options.isPreCheckAndPay)) return true;
  return false;
};

const shouldShowExcludedFeature = (options: RenderFeaturesOptions, group?: BrandedFareFeatureGroup) => {
  if (options.includeAllFlexFeatures && group === "FLEXIBILITY") return true;
  if (options.includeExcludedFeatures && (group !== "BAGGAGE" || options.isPreCheckAndPay)) return true;
  return false;
};

const getWithAvailability = <T extends Pick<UIBrandedFareFeature, "availability">>(
  features: T[] | undefined,
  availability: UIBrandedFareFeatureAvailability
) => (features || []).filter((f) => f.availability === availability);

const findFeatures = (config: BrandedFareFeatureConfig, features: UIBrandedFareFeature[]) =>
  features.filter((f) => f.featureName === config.name);

const compressSimilarFeatures = (features: UIBrandedFareFeature[]): UIBrandedFareFeature[] => {
  const compressedFeatures: UIBrandedFareFeature[] = [];
  let changeable = false;
  let refundable = false;

  features.forEach((feature) => {
    const { featureName } = feature;
    if (CHANGEABLE_TICKET_FEATURES.includes(featureName)) {
      if (!changeable) {
        compressedFeatures.push(feature);
        changeable = true;
      }
    } else if (REFUNDABLE_TICKET_FEATURES.includes(featureName)) {
      if (!refundable) {
        compressedFeatures.push(feature);
        refundable = true;
      }
    } else {
      compressedFeatures.push(feature);
    }
  });

  return compressedFeatures;
};
