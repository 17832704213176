import { RouteName } from "../../app/routes";
import {
  getIsSpanishIslandsResidentFareAvailable,
  getIsFlexibleTicketAvailable,
  getIsFlexibleTicketPreSelectedAtMeta,
  getIsSeatMapAvailable,
  getIsCfarAvailable
} from "../../store/selectors/checkoutSteps";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import useIsBrandedFare from "branded-fares/hooks/useIsBrandedFare";
import { getAncillaries, getCanSkipExtrasPage } from "store/flightDetails/selectors";
import flights_web_hide_meals_from_extras from "utils/experiments/funnel/flights_web_hide_meals_from_extras";
import { useTrackFlightsWebFlexTicketToExtras } from "utils/experiments/funnel/flights_web_flex_ticket_to_extras";

type Step = {
  step: Extract<
    RouteName,
    | "checkout-start"
    | "checkout-fare"
    | "checkout-ticket-type"
    | "checkout"
    | "checkout2"
    | "checkout-seat-selection"
    | "checkout-sirf"
    | "checkout3"
  >;
  when: () => boolean;
  requiresAncillaries?: boolean;
  requiresOrder?: boolean;
};

const useAvailableCheckoutSteps = () => {
  const { isFlightsWebFlexTicketToExtrasVariant } = useTrackFlightsWebFlexTicketToExtras();
  const isBrandedFare = useIsBrandedFare();
  const isSpanishIslandsResidentFareAvailable = useSelector(getIsSpanishIslandsResidentFareAvailable);
  const isFlexibleTicketPreSelectedAtMeta = useSelector(getIsFlexibleTicketPreSelectedAtMeta);
  const isCfarAvailable = useSelector(getIsCfarAvailable);
  const isFlexibleTicketAvailable = useSelector(getIsFlexibleTicketAvailable);
  const isSeatMapAvailable = useSelector(getIsSeatMapAvailable);
  const skipExtrasPage = useSelector(getCanSkipExtrasPage);
  const areTicketTypesAvailable = isFlexibleTicketAvailable || isCfarAvailable;
  const showTicketTypePage =
    areTicketTypesAvailable &&
    !isBrandedFare &&
    !isFlexibleTicketPreSelectedAtMeta &&
    !isFlightsWebFlexTicketToExtrasVariant();
  const showBrandedFarePage = isBrandedFare && !isFlexibleTicketPreSelectedAtMeta;
  const ancillaries = useSelector(getAncillaries);
  let showExtrasPage = ancillaries && !!skipExtrasPage ? Object.keys(ancillaries).length > 0 : true;

  if (!!ancillaries && ancillaries.mealPreference && flights_web_hide_meals_from_extras.trackWithDefaultStage()) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mealPreference, ...ancillariesRest } = ancillaries;
    showExtrasPage = ancillariesRest && !!skipExtrasPage ? Object.keys(ancillariesRest).length > 0 : true;
  }

  const steps: Array<Step> = useMemo(
    () => [
      { step: "checkout-start", when: () => true },
      { step: "checkout-fare", when: () => showBrandedFarePage },
      { step: "checkout-ticket-type", when: () => showTicketTypePage },
      { step: "checkout", when: () => true },
      { step: "checkout2", when: () => showExtrasPage },
      { step: "checkout-seat-selection", when: () => isSeatMapAvailable },
      {
        step: "checkout-sirf",
        when: () => isSpanishIslandsResidentFareAvailable,
        requiresAncillaries: true
      },
      {
        step: "checkout3",
        when: () => true,
        requiresAncillaries: true,
        requiresOrder: true
      }
    ],
    [isSeatMapAvailable, isSpanishIslandsResidentFareAvailable, showBrandedFarePage, showExtrasPage, showTicketTypePage]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const activeSteps = useMemo(() => steps.filter(({ when }) => when()).map(({ when, ...rest }) => rest), [steps]);

  return activeSteps;
};

export default useAvailableCheckoutSteps;
