import React from "react";
import { Icon, Stack, Text } from "@bookingcom/bui-react";
import CheckmarkIcon from "@bookingcom/bui-assets-react/streamline/CheckmarkIcon";
import { useSIRFController } from "./useSIRF.controller";

export function SIRF() {
  const { text } = useSIRFController();
  if (!text) return null;
  return (
    <Stack direction="row" gap={2}>
      <Stack.Item>
        <Icon svg={CheckmarkIcon} color="constructive" mixin={{ marginBlockStart: 0.5 }} />
      </Stack.Item>
      <Stack.Item grow>
        <Text color="constructive">{text}</Text>
      </Stack.Item>
    </Stack>
  );
}
