import { createTrackingUtils } from "../index";
import { UIFlightSegment } from "@flights/types";

const utils = createTrackingUtils({
  name: "flights_apex_web_collapse_fd_itinerary_mdot",
  stages: {
    main: 1,
    flex_or_ins: 2,
    meta: 3,
    only_flex: 4,
    only_ins: 5,
    flex_and_ins: 6,
    one_way: 7,
    round_trip: 8,
    has_stopover: 9
  },
  goals: {
    expands_both: 1,
    expands_any: 2
  },
  goalsWithValue: [],
  defaultStage: 1
});

const trackFlightDetailsAncillaries = (hasFlexibility: boolean, hasInsurance: boolean) => {
  if (hasFlexibility && hasInsurance) utils.stages.flex_and_ins();
  if (hasFlexibility || hasInsurance) utils.stages.flex_or_ins();
  if (hasFlexibility && !hasInsurance) utils.stages.only_flex();
  if (!hasFlexibility && hasInsurance) utils.stages.only_ins();
};

const trackSegments = (updatedSegmentStates: boolean[]) => {
  utils.goals.expands_any();

  if (updatedSegmentStates.every((state) => state)) {
    utils.goals.expands_both();
  }
};

const trackTripType = (isMeta: boolean, tripType?: string, segments?: UIFlightSegment[]) => {
  if (isMeta) utils.stages.meta();
  if (tripType === "ONEWAY") utils.stages.one_way();
  if (tripType === "ROUNDTRIP") utils.stages.round_trip();
  if (segments?.some((segment) => segment.legs.length > 1)) utils.stages.has_stopover();
};

export default { trackFlightDetailsAncillaries, trackSegments, trackTripType, ...utils };
