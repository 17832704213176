import { FilterSection } from "@flights/search-ui";
import React from "react";
import { useBudgetPerAdultFilterController } from "./useBudgetPerAdultFilter.exp.controller";
import { InputSlider } from "@bookingcom/bui-react";

// https://jira.booking.com/jira/browse/DESY-5093
const style = {
  padding: "var(--bui_spacing_4x)",
  overflow: "hidden",
  margin: "calc(var(--bui_spacing_4x) * -1)"
};

export const BudgetPerAdultFilter = () => {
  const {
    isFilterAvailable,
    title,
    resetLabel,
    showReset,
    onClickReset,
    min,
    max,
    value,
    ariaLabel,
    label,
    renderValue,
    renderTooltipValue,
    onChange,
    onChangeCommit,
    interval
  } = useBudgetPerAdultFilterController();

  if (!isFilterAvailable) return null;

  return (
    <FilterSection
      attributes={{ "data-testid": "budget_per_adult_filter" }}
      resetAttributes={{ "data-testid": "search_filter_budget_per_adult_reset" }}
      title={title}
      resetLabel={resetLabel}
      showReset={showReset}
      onClickReset={onClickReset}
    >
      <div style={style}>
        <InputSlider
          min={min}
          max={max}
          value={value}
          interval={interval}
          name="filters-budget-per-adult"
          attributes={{ "data-testid": "search_filter_stops_budget_per_adult_slider" }}
          ariaLabel={ariaLabel}
          label={label}
          onChange={({ value }) => onChange(value)}
          onChangeCommit={({ value }) => onChangeCommit(value)}
          renderValue={renderValue}
          renderTooltipValue={renderTooltipValue}
          ariaValuetext={renderTooltipValue}
        />
      </div>
    </FilterSection>
  );
};
