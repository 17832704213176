import React from "react";
import { useTheme } from "@bookingcom/bui-react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import Container from "components/elements/Container";
import Frame from "components/elements/Frame";
import useUserAgent from "hooks/useUserAgent";

const GeniusBenefitsCarousel = () => {
  const { isMobile } = useUserAgent();
  const theme = useTheme();

  const { Component, error } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/FlightsGeniusBenefitsCarousel",
    type: "capla"
  });

  return isMobile ? (
    <Frame
      attributes={{
        style: {
          width: "100%",
          padding: !error ? theme.units.spacing_4x : 0
        }
      }}
    >
      <Component />
    </Frame>
  ) : (
    <Container pt={!error ? 6 : 0}>
      <Component />
    </Container>
  );
};

export default GeniusBenefitsCarousel;
