import { createMicroConversionTrackingUtils } from "..";

const utils = createMicroConversionTrackingUtils({
  name: "flights_web_reduce_spacing_sr_ddot",
  stages: {
    skeleton: 1,
    main: 2, // search results fetched + non-empty results
    direct: 3,
    meta: 4,
    isWithKids: 5,
    usPointOfSale: 6,
    roundTrip: 7,
    oneWay: 8
  },
  defaultStage: 1,
  mainStage: 2,
  goals: {},
  goalsWithValue: []
});

export default utils;
