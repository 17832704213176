import { useMemo } from "react";
import { UIFlightData, UIOrder, UIUnifiedPriceBreakdownItem } from "@flights/types";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { localizationUtils } from "@flights/web-api-utils-universal";
import { useFlightOfferWithDiscount } from "hooks/useFlightOfferWithDiscount";

export type UnifiedPriceBreakdownOrderControllerProps = {
  order?: UIOrder;
  flight?: UIFlightData; // flights_web_unified_price_breakdown
};

export function useUnifiedPriceBreakdownOrderController(props: UnifiedPriceBreakdownOrderControllerProps) {
  const { order, flight } = props;
  const i18n = useI18n();
  const unifiedPriceBreakdown = useMemo(() => order?.unifiedPriceBreakdown, [order?.unifiedPriceBreakdown]);
  const segments = useMemo(() => order?.airOrder?.flightSegments, [order?.airOrder?.flightSegments]);
  const instalmentsFees = order?.totalPrice?.instalmentsFees;

  const isHungaryDepartingRyanAir = useMemo(
    () => localizationUtils.isHungaryDepartingRyanAirFlight(segments),
    [segments]
  );

  const addedItems: UIUnifiedPriceBreakdownItem[] = useMemo(() => {
    if (!instalmentsFees) return [];
    const installmentItem: UIUnifiedPriceBreakdownItem = {
      id: "dynamic-instalments-fees",
      scope: "FEE",
      title: i18n.trans(t("flights_price_breakdown_instalment_label")),
      price: instalmentsFees,
      items: []
    };
    return [installmentItem];
  }, [i18n, instalmentsFees]);

  const { isWithDiscount } = useFlightOfferWithDiscount(flight); // flights_web_unified_price_breakdown

  return {
    unifiedPriceBreakdown,
    isHungaryDepartingRyanAir,
    isWithDiscount, // flights_web_unified_price_breakdown
    addedItems
  };
}
