import useClientMetrics from "hooks/useClientMetrics";
import { useEffect } from "react";
import { getBrandedFarePriceMismatchMetricName } from "branded-fares/components/FareSelector/utils";
import { UIBrandedFare, UIFlightData } from "@flights/types";
import useEvents from "hooks/useEvents";

export default function useTrackFirstFarePriceMismatch(
  flight: UIFlightData,
  brandedFareOffers: UIBrandedFare[] | undefined
) {
  const { trackClientMetric } = useClientMetrics();
  const { grumble } = useEvents();

  useEffect(() => {
    if (brandedFareOffers && brandedFareOffers.length > 0) {
      const firstFare = brandedFareOffers[0];

      trackClientMetric(
        getBrandedFarePriceMismatchMetricName(flight.priceBreakdown.total, firstFare.priceBreakdown.total, grumble)
      );
    }
  }, [brandedFareOffers, flight, trackClientMetric, grumble]);
}
