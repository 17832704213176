import { SBSearchType } from "@bookingcom/flights-searchbox";
import { UIFlightSegment, UIOrder } from "@flights/types";

interface OrderUtilsReturnType {
  isOneWay: boolean;
  isRoundTrip: boolean;
  isMultiStop: boolean;
  isAirlineReferenceReceived: boolean;
  tripType: SBSearchType | null;
  getHcUniqueOrderId(flightSegment: UIFlightSegment): string;
}

export const getOrderUtils = (order: UIOrder): OrderUtilsReturnType => {
  const { airOrder } = order;
  const { flightSegments, airlineReferencesByLeg } = airOrder;

  const isOneWay = flightSegments.length === 1;
  const isRoundTrip =
    flightSegments.length === 2 && flightSegments[0].departureAirport.code === flightSegments[1].arrivalAirport.code;
  const isMultiStop =
    (flightSegments.length === 2 &&
      flightSegments[0].departureAirport.code !== flightSegments[1].arrivalAirport.code) ||
    flightSegments.length > 2;

  // Generates unique id for the segment (for example 40-495495495_AMS-BCN) that is being used to
  // navigate user from order details page to specific segment in help center
  const getHcUniqueOrderId = (flightSegment: UIFlightSegment) => {
    return `${order.publicReference.formattedReference}_${flightSegment.departureAirport.code}-${flightSegment.arrivalAirport.code}`;
  };

  const isAirlineReferenceReceived = !!airlineReferencesByLeg;

  const tripType = isOneWay ? "ONEWAY" : isRoundTrip ? "ROUNDTRIP" : isMultiStop ? "MULTISTOP" : null;

  return {
    isOneWay,
    isRoundTrip,
    isMultiStop,
    isAirlineReferenceReceived,
    tripType,
    getHcUniqueOrderId
  };
};
