import { createTrackingUtils } from "../index";
import useProfiler from "hooks/useProfiler";
import { useEffect } from "react";

const utils = createTrackingUtils({
  name: "flights_web_sr_gen_ai_summary_v2_mdot",
  stages: {
    visibleInViewport: 2,
    direct: 3,
    meta: 4,
    us: 5,
    solo: 6,
    nonSolo: 7,
    children: 8
  },
  goals: {
    bannerCtaClick: 1,
    loading: 2,
    success: 3,
    error: 4,
    bannerClose: 5
  },
  defaultStage: 1
});

const useTrackStages = (isEligible: boolean) => {
  const { stages } = utils;
  const { isDirect, isMeta, isAmerican, isSolo, isWithKids } = useProfiler();

  useEffect(() => {
    if (!isEligible) {
      return;
    }

    stages.visibleInViewport();

    isDirect() && stages.direct();
    isMeta() && stages.meta();
    isAmerican() && stages.us();
    isSolo() ? stages.solo() : stages.nonSolo();
    isWithKids() && stages.children();
  }, [isEligible, stages, isDirect, isMeta, isAmerican, isSolo, isWithKids]);
};

export default { ...utils, useTrackStages };
