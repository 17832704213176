import React, { FC, ComponentProps, useCallback, useMemo } from "react";

import { Carousel, Stack, Image, Text, Card, Actionable } from "@bookingcom/bui-react";
import useFormatPrice from "hooks/useFormatPrice";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core";
import { UIPrice, UISearchMinPriceInfo } from "@flights/types";
import { useCreateFlexUrl } from "./useCreateFlexUrl";
import styles from "./FlexibleDateBestPriceInner.module.css";
import useFlexPriceDiffTracker from "hooks/useFlexPriceDiffTracker";
import useTrackFlightsFlexiBannerSourceChange from "utils/experiments/funnel/flights_web_flexi_banner_source_change_ml";

type UISearchMinPriceInfoWithPrice = UISearchMinPriceInfo &
  Required<Pick<UISearchMinPriceInfo, "priceRounded">> & {
    isExpensive: boolean;
  };

const FlexibleDateBestPriceInner: FC<{
  dates: Array<UISearchMinPriceInfoWithPrice>;
  cheaperCount: number;
  isOneWay: boolean;
  currentBestPrice: UIPrice;
  onItemClick?: VoidFunction;
  carouselSize: ComponentProps<typeof Carousel>["size"];
}> = ({ dates, cheaperCount, isOneWay, currentBestPrice, onItemClick, carouselSize }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { formatPrice } = useFormatPrice();
  const createUrl = useCreateFlexUrl();
  const { setOriginPrice } = useFlexPriceDiffTracker();

  // flights_web_flexi_banner_source_change_ml
  const { setClickedPrice } = useTrackFlightsFlexiBannerSourceChange();

  const cardDate = useCallback(
    (departureDate: string, returnDate?: string) => {
      if (isOneWay) {
        return formats.flightDate(departureDate);
      }

      return i18n.trans(
        t("flights_sr_flex_dates_alt_date_rt_multi", {
          variables: {
            begin_date: formats.flightDate(departureDate),
            end_date: returnDate ? formats.flightDate(returnDate) : ""
          }
        })
      );
    },
    [formats, i18n, isOneWay]
  );

  return (
    <Carousel
      size={carouselSize}
      title={<Title cheaperCount={cheaperCount} />}
      nextButtonAriaLabel={i18n.trans(t("a11y_flights_sr_flex_dates_carousel_forward"))}
      previousButtonAriaLabel={i18n.trans(t("a11y_flights_sr_flex_dates_carousel_back"))}
      ariaLabel={i18n.trans(t("a11y_flights_sr_flex_dates_carousel_entry"))}
    >
      {dates.map((date) => (
        <Actionable
          key={date.departureDate}
          href={createUrl(date.offsetDays, date.departureDate, date.returnDate)}
          className={styles.anchor}
          onClick={() => {
            if (date.priceRounded) setClickedPrice(`${date.priceRounded.units}.${date.priceRounded.nanos}`);
            onItemClick?.();
            setOriginPrice(currentBestPrice.units);
          }}
        >
          <Card variant="elevated" className={styles.card}>
            <Text color="neutral" align="center" variant="body_2">
              {cardDate(date.departureDate, date.returnDate)}
            </Text>
            <Text
              align="center"
              variant="strong_2"
              color={date.isCheapest ? "constructive" : date.isExpensive ? "destructive" : "neutral"}
            >
              {formatPrice(date.priceRounded, { decimalPlaces: 0 })}
            </Text>
          </Card>
        </Actionable>
      ))}
    </Carousel>
  );
};

const Title: FC<{ cheaperCount: number }> = ({ cheaperCount }) => {
  const i18n = useI18n();

  const title = useMemo(() => {
    if (cheaperCount === 0) {
      return i18n.trans(t("flights_sr_flex_dates_best_price"));
    }

    if (cheaperCount === 1) {
      return i18n.trans(t("flights_sr_flex_date_better_price_one"));
    }

    return i18n.trans(t("flights_sr_flex_date_better_prices_multi"));
  }, [cheaperCount, i18n]);

  return (
    <Stack direction="row" alignItems="center">
      <Image
        width="40px"
        height="40px"
        asset={{
          setName: "illustrations-traveller",
          assetName: "FlightsSearch"
        }}
      />
      <Stack.Item shrink={true} grow={true}>
        <Stack gap={0}>
          <Text variant="strong_1" tagName="h2">
            {title}
          </Text>
          <Text variant="body_2">{i18n.trans(t("flights_sr_flexi_search_subtitle_latest_prices"))}</Text>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default FlexibleDateBestPriceInner;
