import { getInitialState, State } from "ancillaries/store/esim/state";
import { Actions, ActionTypes } from "./actions";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetchIndex:
      return {
        ...state,
        index: {
          esim: {},
          fetchStatus: "loading",
          error: undefined
        }
      };
    case ActionTypes.fetchIndexSuccess:
      return {
        ...state,
        index: {
          esim: action.payload.esim,
          fetchStatus: "success",
          error: undefined
        }
      };
    case ActionTypes.fetchIndexError:
      return {
        ...state,
        index: {
          fetchStatus: "failed",
          error: action.payload.error,
          esim: undefined
        }
      };

    case ActionTypes.fetchSearch:
      return {
        ...state,
        search: {
          esim: {},
          fetchStatus: "loading",
          error: undefined
        }
      };
    case ActionTypes.fetchSearchSuccess:
      return {
        ...state,
        search: {
          esim: action.payload.esim,
          fetchStatus: "success",
          error: undefined
        }
      };
    case ActionTypes.fetchSearchError:
      return {
        ...state,
        search: {
          fetchStatus: "failed",
          error: action.payload.error,
          esim: undefined
        }
      };

    case ActionTypes.fetchFlight:
      return {
        ...state,
        flight: {
          esim: {},
          fetchStatus: "loading",
          error: undefined
        }
      };
    case ActionTypes.fetchFlightSuccess:
      return {
        ...state,
        flight: {
          esim: action.payload.esim,
          fetchStatus: "success",
          error: undefined
        }
      };
    case ActionTypes.fetchFlightError:
      return {
        ...state,
        flight: {
          fetchStatus: "failed",
          error: action.payload.error,
          esim: undefined
        }
      };

    case ActionTypes.fetchPreOrder:
      return {
        ...state,
        preOrder: {
          esim: {},
          fetchStatus: "loading",
          error: undefined
        }
      };
    case ActionTypes.fetchPreOrderSuccess:
      return {
        ...state,
        preOrder: {
          esim: action.payload.esim,
          fetchStatus: "success",
          error: undefined
        }
      };
    case ActionTypes.fetchPreOrderError:
      return {
        ...state,
        preOrder: {
          fetchStatus: "failed",
          error: action.payload.error,
          esim: undefined
        }
      };

    case ActionTypes.fetchOrder:
      return {
        ...state,
        order: {
          esim: {},
          fetchStatus: "loading",
          error: undefined
        }
      };
    case ActionTypes.fetchOrderSuccess:
      return {
        ...state,
        order: {
          esim: action.payload.esim,
          fetchStatus: "success",
          error: undefined
        }
      };
    case ActionTypes.fetchOrderError:
      return {
        ...state,
        order: {
          fetchStatus: "failed",
          error: action.payload.error,
          esim: undefined
        }
      };
    default:
      return {
        ...state
      };
  }
};

export { reducer, getInitialState };
