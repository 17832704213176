import { useCallback } from "react";
import useRouteName from "hooks/useRouteName";
import { RouteName } from "app/routes";
import parse from "date-fns/parse";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import expWww from "utils/experiments/customer-aquisition/flights_web_cat_fly_anywhere_filters_www";
import expMdot from "utils/experiments/customer-aquisition/flights_web_cat_fly_anywhere_filters_mdot";

const EXTRA_PAGES: Array<RouteName> = ["not-found", "pb-change-flight"];

export const useTrackFlyAnywhere = () => {
  const routeName = useRouteName();

  const trackFlyAnywhereWww = useCallback(
    (departureDate: string) => {
      if (!["home", "searchresults", ...EXTRA_PAGES].includes(routeName)) {
        return;
      }

      expWww.trackWithDefaultStage();

      if (routeName === "home") {
        expWww.stages.home_page_2();
        expWww.goalsWithValue.flights_web_cat_fly_anywhere_filters_www_clicked_from(2);
      } else if (routeName === "searchresults") {
        expWww.stages.search_page_3();
        expWww.goalsWithValue.flights_web_cat_fly_anywhere_filters_www_clicked_from(3);
      } else if (EXTRA_PAGES.includes(routeName)) {
        expWww.stages.rebook_notfound_4();
        expWww.goalsWithValue.flights_web_cat_fly_anywhere_filters_www_clicked_from(4);
      }

      const departureDateParsed = parse(departureDate, "yyyy-MM-dd", new Date());
      const bookingWindow = differenceInCalendarDays(departureDateParsed, new Date());

      if (bookingWindow && bookingWindow <= 60) {
        expWww.stages.booking_window_lt_60_5();
      } else if (bookingWindow && bookingWindow > 60) {
        expWww.stages.booking_window_gt_60_6();
      }
    },
    [routeName]
  );

  const trackFlyAnywhereMdot = useCallback(
    (departureDate: string) => {
      if (!["home", "searchresults", ...EXTRA_PAGES].includes(routeName)) {
        return;
      }

      expMdot.trackWithDefaultStage();

      if (routeName === "home") {
        expMdot.stages.home_page_2();
        expMdot.goalsWithValue.flights_web_cat_fly_anywhere_filters_mdot_clicked_from(2);
      } else if (routeName === "searchresults") {
        expMdot.stages.search_page_3();
        expMdot.goalsWithValue.flights_web_cat_fly_anywhere_filters_mdot_clicked_from(3);
      } else if (EXTRA_PAGES.includes(routeName)) {
        expMdot.stages.rebook_notfound_4();
        expMdot.goalsWithValue.flights_web_cat_fly_anywhere_filters_mdot_clicked_from(4);
      }

      const departureDateParsed = parse(departureDate, "yyyy-MM-dd", new Date());
      const bookingWindow = differenceInCalendarDays(departureDateParsed, new Date());

      if (bookingWindow && bookingWindow <= 60) {
        expMdot.stages.booking_window_lt_60_5();
      } else if (bookingWindow && bookingWindow > 60) {
        expMdot.stages.booking_window_gt_60_6();
      }
    },
    [routeName]
  );

  return { trackFlyAnywhereWww, trackFlyAnywhereMdot };
};
