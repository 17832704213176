import { useMemo } from "react";
import { UICart, UIFlightData, UIOrderExtras, UIUnifiedPriceBreakdownItem } from "@flights/types";
import { useI18n } from "@bookingcom/lingojs-react";
import usePointOfSale from "hooks/usePointOfSale";
import useRouteName from "hooks/useRouteName";
import { useSelector } from "react-redux";
import { getAncillaries } from "store/flightDetails/selectors";
import { getPreOptedAncillaries, getSelectedExtraProducts } from "ancillaries/store/extraProducts/selectors";
import { priceBreakdownUtils, pricingUtils, localizationUtils } from "@flights/web-api-utils-universal";
import { selectedAncillariesToAddedAncillaries } from "store/selectors/getAddedAncillariesExtraProduct";
import { type MpProduct } from "@bookingcom/mp-flights";
import { useFlightOfferWithDiscount } from "hooks/useFlightOfferWithDiscount";

export type UnifiedPriceBreakdownCheckoutControllerProps = {
  cart?: UICart;
  flight?: UIFlightData;
  multiProducts?: MpProduct[];
};

type CopyFuncParams = Parameters<typeof priceBreakdownUtils.getExtraCopy>;
type POSType = CopyFuncParams[3]["pointOfSale"];

const createAncillariesFromMultiProducts = (multiProducts?: MpProduct[]): UIOrderExtras => {
  const ancillaries: UIOrderExtras = {};
  if (!multiProducts || multiProducts.length === 0) return ancillaries;
  multiProducts.forEach((multiProduct) => {
    switch (multiProduct.productType) {
      case "INSURANCE":
        ancillaries.travelInsurance = {
          reference: "",
          price: multiProduct.priceBreakdown,
          travelerPrices: [],
          documents: {},
          isPerTraveller: false,
          version: 0
        };
        break;
      default:
        break;
    }
  });
  return ancillaries;
};

export function useUnifiedPriceBreakdownCheckoutController(props: UnifiedPriceBreakdownCheckoutControllerProps) {
  const { cart, flight, multiProducts } = props;
  const i18n = useI18n();
  const ipCountry = usePointOfSale();
  const routeName = useRouteName();
  const ancillaries = useSelector(getAncillaries);
  const preOptedAncillaries = useSelector(getPreOptedAncillaries);
  const selectedExtraProducts = useSelector(getSelectedExtraProducts);
  const segments = useMemo(() => cart?.offer.segments || flight?.segments, [cart, flight]);
  const isHungaryDepartingRyanAir = useMemo(
    () => localizationUtils.isHungaryDepartingRyanAirFlight(segments),
    [segments]
  );

  const baseOptions = useMemo(
    () => ({
      pointOfSale: (ipCountry || "") as POSType,
      isFlightFromRyanAirHungary: isHungaryDepartingRyanAir
    }),
    [ipCountry, isHungaryDepartingRyanAir]
  );

  const unifiedPriceBreakdown = useMemo(
    () => cart?.unifiedPriceBreakdown || flight?.unifiedPriceBreakdown,
    [cart, flight]
  );

  const allAncillaries = useMemo(() => {
    return ancillaries || flight?.ancillaries || {};
  }, [flight, ancillaries]);

  const preOptedFlexibleTicket = useMemo(
    () => preOptedAncillaries.flexibleTicket,
    [preOptedAncillaries.flexibleTicket]
  );

  const flexibleTicketAncillary = useMemo(() => {
    return allAncillaries.flexibleTicket;
  }, [allAncillaries.flexibleTicket]);

  const extras = useMemo(() => {
    const data = selectedAncillariesToAddedAncillaries(selectedExtraProducts, allAncillaries) || {};
    // If the user has selected a flexible ticket, we need to add it to the list of selected extras.
    if (
      preOptedFlexibleTicket &&
      !data.flexibleTicket &&
      flexibleTicketAncillary &&
      ["checkout", "checkout2", "checkout-ticket-type"].includes(routeName)
    ) {
      const len = flexibleTicketAncillary.travellers.length;
      const price = pricingUtils.sumPriceBreakdown(new Array(len).fill(flexibleTicketAncillary.priceBreakdown));
      data.flexibleTicket = {
        price,
        reference: flexibleTicketAncillary.airProductReference,
        travelerPrices: []
      };
    }
    const ancillariesFromMultiProducts = createAncillariesFromMultiProducts(multiProducts);
    return { ...data, ...ancillariesFromMultiProducts };
  }, [
    selectedExtraProducts,
    allAncillaries,
    preOptedFlexibleTicket,
    flexibleTicketAncillary,
    routeName,
    multiProducts
  ]);

  const addedItems = useMemo(() => {
    const allItems = priceBreakdownUtils.transformUnifiedPriceBreakdownItemForExtra(i18n, extras, baseOptions);
    // remove duplicates from already added & cart
    const allItemsFiltred = allItems.filter((item: UIUnifiedPriceBreakdownItem) => {
      return !cart?.unifiedPriceBreakdown?.items?.some((cartItem: UIUnifiedPriceBreakdownItem) => {
        return (
          cartItem.id === item.id &&
          cartItem.scope === item.scope &&
          cartItem.title === item.title &&
          cartItem.price.units === item.price.units &&
          cartItem.price.nanos === item.price.nanos
        );
      });
    });
    return allItemsFiltred;
  }, [i18n, extras, baseOptions, cart?.unifiedPriceBreakdown?.items]);

  const { isWithDiscount } = useFlightOfferWithDiscount(flight); // flights_web_unified_price_breakdown

  return {
    unifiedPriceBreakdown,
    isHungaryDepartingRyanAir,
    addedItems,
    isWithDiscount // flights_web_unified_price_breakdown
  };
}
