import { StoreState } from "../index";
import differenceInYears from "date-fns/differenceInYears";
import { UICartDetails, UIFlightSegment } from "@flights/types";

export const getCartSupplyType = (state: StoreState) => state.cartDetails.cartDetails?.cart?.supplyType;
export const getCartDetailsFetchStatus = (state: StoreState) => state.cartDetails.fetchStatus;
export const getCartDetails = (state: StoreState) => state.cartDetails.cartDetails;

const getFinalDepartureDate = (segments?: UIFlightSegment[]) => {
  if (!segments || segments.length === 0) {
    return undefined;
  }
  const lastSegment = segments[segments.length - 1];
  return lastSegment.departureTime;
};

const calculateAge = (refDate?: string, birthDate?: string): number => {
  if (!birthDate || !refDate) {
    return -1;
  }

  const transformedRefDate = new Date(refDate);
  const transformedBirthDate = new Date(birthDate);
  if (isNaN(transformedRefDate.valueOf()) || isNaN(transformedBirthDate.valueOf())) {
    return -1;
  }

  return differenceInYears(transformedRefDate, transformedBirthDate);
};

const getPassengerAgeList = (cartDetails?: UICartDetails) => {
  const passengers = cartDetails?.passengers;
  const departureDate = getFinalDepartureDate(cartDetails?.cart.offer.segments);
  if (!passengers || passengers.length === 0) {
    return [-1];
  }
  return passengers.map((passenger) => calculateAge(departureDate, passenger.birthDate));
};

export const getCartPassengerMaxAge = (state: StoreState) => {
  return Math.max(...getPassengerAgeList(state.cartDetails.cartDetails));
};

export const getCartPassengerMinAge = (state: StoreState) => {
  return Math.min(...getPassengerAgeList(state.cartDetails.cartDetails));
};
