import { useCallback, useMemo } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useProfiler from "hooks/useProfiler";
import { stage, trackWithDefaultStage } from "@flights/et-universal";
import { UIFlightData } from "@flights/types";

export type DSATraceabilityControllerProps = {
  flight?: UIFlightData;
};

export function useDSATraceabilityController(props: DSATraceabilityControllerProps) {
  const { flight } = props;
  const i18n = useI18n();
  const { isDirect, isEEA, isMobile } = useProfiler();

  const text = useMemo(() => i18n.trans(t("flights_fd_powered_by_gotogate")), [i18n]);
  const title = useMemo(() => i18n.trans(t("flights_fd_modal_powered_by_gotogate")), [i18n]);
  const a11yTooltip = useMemo(() => i18n.trans(t("a11y_flights_fd_gotogate_tooltip")), [i18n]);
  const a11yClose = useMemo(() => i18n.trans(t("a11y_flights_gotogate_bottomsheet_close")), [i18n]);
  const content = useMemo(
    () => [
      i18n.trans(t("flights_fd_modal_gotogate_legal_entity")),
      i18n.trans(t("flights_fd_modal_gotogate_trade_reg_name")),
      i18n.trans(t("flights_fd_modal_gotogate_reg_num")),
      " ", // spacer
      i18n.trans(t("flights_fd_modal_gotogate_address_one")),
      i18n.trans(t("flights_fd_modal_gotogate_phone")),
      " ", // spacer
      i18n.trans(t("flights_fd_modal_gotogate_products_and_services"))
    ],
    [i18n]
  );

  // flights_web_partner_traceability_eea -- start
  const trackExp = useCallback(() => {
    stage(
      isMobile() ? "flights_web_partner_traceability_eea_mdot" : "flights_web_partner_traceability_eea_desktop",
      isDirect() ? 2 : 3
    );
    return isMobile()
      ? !!trackWithDefaultStage("flights_web_partner_traceability_eea_mdot", 1)
      : !!trackWithDefaultStage("flights_web_partner_traceability_eea_desktop", 1);
  }, [isDirect, isMobile]);
  // flights_web_partner_traceability_eea -- end

  const shouldRender = useMemo(() => {
    if (isMobile() && !flight) return false;
    if (!isEEA()) return false;
    return trackExp();
  }, [flight, isEEA, isMobile, trackExp]);

  return {
    a11yClose,
    a11yTooltip,
    content,
    shouldRender,
    text,
    title
  };
}
