import { getInitialState, State } from "./state";
import { Actions, ActionTypes } from "./actions";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        fetchStatus: "loading"
      };
    case ActionTypes.fetchSuccess:
      const newState = {
        ...state,
        fetchStatus: "success",
        aggregations: action.payload.aggregations,
        results: action.payload.results,
        initialPayload: action.payload.initialPayload,
        fromCity: action.payload.fromCity
      };
      return newState;
    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error
      };
    case ActionTypes.reset:
      return initialState;
    default:
      return state;
  }
};

export { reducer, getInitialState };
