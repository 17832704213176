import React from "react";
import { InputRadio, Text } from "@bookingcom/bui-react";
import { FilterCount } from "@flights/search-ui";

type Props = {
  label: string;
  priceLabel: string;
  count: number;
  value: number;
  checked: boolean;
  onChange: (value: number) => void;
};

export const StopsFilterItem = ({ label, priceLabel, count, value, checked, onChange }: Props) => {
  return (
    <FilterCount count={count} data-testid={`search_filter_stops_radio_values_${label}`}>
      <InputRadio
        value={String(value)}
        name="filters-stops-option"
        onChange={({ value }) => onChange(Number(value))}
        checked={checked}
        attributes={{ "data-testid": `search_filter_stops_radio_${label}` }}
        label={
          <>
            <Text variant="body_2">{label}</Text>
            <Text tagName="span" color="neutral_alt" variant="body_2">
              {priceLabel}
            </Text>
          </>
        }
      />
    </FilterCount>
  );
};
