import { createTrackingUtils } from "utils/experiments";

const utils = createTrackingUtils({
  name: "flights_web_cat_fly_anywhere_filters_mdot",
  defaultStage: 1,
  stages: {
    opened_where_to_1: 1,
    home_page_2: 2,
    search_page_3: 3,
    rebook_notfound_4: 4,
    booking_window_lt_60_5: 5,
    booking_window_gt_60_6: 6
  },
  goals: {
    landed_on_fly_anywhere_1: 1,
    clicked_card_2: 2,
    changed_sorting_3: 3,
    used_filters_4: 4,
    changed_page_5: 5
  },
  goalsWithValue: ["flights_web_cat_fly_anywhere_filters_mdot_clicked_from"]
});

export default utils;
