import Frame from "../../../../components/elements/Frame";
import React, { useCallback, useEffect } from "react";
import { Button, Card, Divider, Image, Stack, Text } from "@bookingcom/bui-react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./WhitelabelAncillaryBanner.module.css";
import { StoreState } from "store";
import useContextName from "hooks/useContextName";
import { AppDispatch } from "store";
import { whitelabelAncillaryService } from "ancillaries/service/whitelabelAncillariesService";
import useGlobalContext from "hooks/useGlobalContext";
import usePointOfSale from "hooks/usePointOfSale";
import { getGeniusLevel } from "store/user/selectors";
import { getSearchSegments } from "store/search/selectors";
import { getFlightSegments, getOrder, getOrderStatus } from "store/order/selectors";
import { pastBookingUtils } from "@flights/web-api-utils-universal";
import { SkeletonListMultiLine } from "components/elements/Skeleton/SkeletonListMultiLine";
import flights_ace_web_whitelabel_ancillaries_pb_tech from "utils/experiments/ace/flights_ace_web_whitelabel_ancillaries_pb_tech";
import flights_ace_web_whitelabel_ancillaries_funnel_tech from "utils/experiments/ace/flights_ace_web_whitelabel_ancillaries_funnel_tech";
import { getFlightDetailsSegments } from "store/flightDetails/selectors";
interface IWhitelabelAncillaryBannerProps {
  ancillary: "loungeAccess";
  showHeader?: boolean;
}

export const WhitelabelAncillaryBanner = ({ ancillary, showHeader }: IWhitelabelAncillaryBannerProps) => {
  const whitelabelAncillariesSelector = (state: StoreState) => state.whitelabelAncillaries;
  const whitelabelAncillariesLoadingSelector = (state: StoreState) =>
    state.whitelabelAncillaries.whitelabelAncillariesLoading;
  const whitelabelAncillariesLoading = useSelector(whitelabelAncillariesLoadingSelector);
  const whitelabelAncillaries = useSelector(whitelabelAncillariesSelector);
  const ancillaryData = whitelabelAncillaries?.whitelabelAncillaries?.find((current) => {
    return current.ancillary === ancillary;
  });
  const isAncillaryEligible = !!ancillaryData;
  const heading = ancillaryData?.heading;
  const title = ancillaryData?.title;
  const description = ancillaryData?.description;
  const information = ancillaryData?.information;
  const link = ancillaryData?.cta?.link;
  const ctaText = ancillaryData?.cta?.text;
  const ctaA11yText = ancillaryData?.cta?.a11yText;
  const footer = ancillaryData?.footer;
  const showButton = !!link;

  const handleButtonClick = () => {
    window.open(link, "_blank", "noopener noreferrer");
  };

  // Call the service to fetch the whitelabel ancillary data
  const dispatch = useDispatch<AppDispatch>();
  const { features } = useGlobalContext();
  const contextName = useContextName();
  const geniusLevel = useSelector(getGeniusLevel)?.toString();
  const pointOfSale = usePointOfSale();
  const searchSegments = useSelector(getSearchSegments);
  const order = useSelector(getOrder);
  const orderSegments = useSelector(getFlightSegments);
  const flightDetailsSegments = useSelector(getFlightDetailsSegments);
  const orderStatus = useSelector(getOrderStatus);

  const whitelabelAncillaryFeature = !!features["FLIGHTS_ACE_WHITELABEL_ANCILLARY_API"];
  const whitelabelAncillaryDisplayFeature = !!features["FLIGHTS_ACE_WHITELABEL_ANCILLARY_DISPLAY"];
  const isPostBooking = contextName === "confirmation" || contextName === "pb_order_details";
  const isPastBooking = pastBookingUtils.isPastBooking(order);
  const eligibleBooking = !isPostBooking || orderStatus === "CONFIRMED" || orderStatus === "PENDING";

  const getSegments = () => {
    if (contextName === "search_results") {
      return searchSegments;
    } else if (isPostBooking) {
      return orderSegments;
    } else {
      return flightDetailsSegments;
    }
  };

  const segments = getSegments();

  useEffect(() => {
    const fetchWhitelabels = async () => {
      if (!segments) return;
      await dispatch(
        whitelabelAncillaryService.getWhitelabels({ geniusLevel, pointOfSale, segments, contextName, orderStatus })
      );
    };
    if (whitelabelAncillaryFeature && pointOfSale && eligibleBooking && !isPastBooking) {
      void fetchWhitelabels();
    }
  }, [
    contextName,
    dispatch,
    whitelabelAncillaryFeature,
    geniusLevel,
    pointOfSale,
    segments,
    orderStatus,
    eligibleBooking,
    isPastBooking
  ]);

  const getIllustrationAsset = useCallback(() => {
    switch (ancillary) {
      case "loungeAccess":
        return "FlightsLoungeAccess";
      default:
        return "NotificationsEmptyState";
    }
  }, [ancillary]);

  useEffect(() => {
    const tracker = isPostBooking
      ? flights_ace_web_whitelabel_ancillaries_pb_tech.goals
      : flights_ace_web_whitelabel_ancillaries_funnel_tech.goals;

    if (isAncillaryEligible) {
      tracker.lounge_access_eligible();
    }

    if (whitelabelAncillaries.status === "FAILED") {
      tracker.whitelabel_api_failed();
    }
  }, [isAncillaryEligible, whitelabelAncillaries, isPostBooking]);

  // Hide the banner if the feature is disabled
  if (!whitelabelAncillaryDisplayFeature) return null;

  return whitelabelAncillariesLoading ? (
    <Card>
      <Stack>
        <SkeletonListMultiLine amount={1} />
      </Stack>
    </Card>
  ) : isAncillaryEligible ? (
    <React.Fragment>
      <Stack direction={"column"} gap={4}>
        {showHeader ? <Text variant={"headline_3"}>{heading}</Text> : null}
        <Card>
          <Stack>
            <Frame mb={1} direction="row" alignItems="start">
              <Frame pr={3} pt={1}>
                <Image
                  width="64px"
                  height="64px"
                  alt={title}
                  asset={{
                    setName: "illustrations-traveller",
                    assetName: getIllustrationAsset()
                  }}
                  contentMode="fit"
                />
              </Frame>
              <Stack>
                <Text variant="strong_1">{title}</Text>
                <Text variant="body_1">{description}</Text>
                <Stack>
                  <Text variant="small_1">{information}</Text>
                  {showButton ? (
                    <Button
                      attributes={{ "aria-label": ctaA11yText }}
                      variant="secondary"
                      size="medium"
                      onClick={handleButtonClick}
                      className={styles.ctaButton}
                    >
                      {ctaText}
                    </Button>
                  ) : null}
                </Stack>
              </Stack>
            </Frame>
            {footer ? (
              <React.Fragment>
                <Divider />
                <Frame mt={1} direction="row" justifyContent="space-between" alignItems="center">
                  <Text variant="small_2" color="neutral_alt">
                    {footer}
                  </Text>
                  <Image
                    asset={{
                      setName: "images-genius",
                      assetName: "GeniusLogo"
                    }}
                    height="14px"
                    width="54px"
                    alt="Genius Logo"
                    contentMode="fit"
                  />
                </Frame>
              </React.Fragment>
            ) : null}
          </Stack>
        </Card>
      </Stack>
    </React.Fragment>
  ) : null;
};
