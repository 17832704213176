import { useCallback, useEffect, useRef } from "react";
import { UIClientFetchError } from "@flights/types";
import { clientFetch, appendLanguageOptionParam, appendFeatureAndExperimentParams } from "@flights/client-fetch";
import useContextName from "./useContextName";
import usePointOfSale from "./usePointOfSale";
import { trackWithDefaultStage } from "@flights/et-universal";

const debug = require("debug")("useClientFetch");

const CACHE_TIMEOUT = 1000 * 60 * 3;

type FetchOptions = {
  fetch?: (url?: string) => void;
  success: (data: any) => void;
  error: (error: UIClientFetchError) => void;
  condition?: () => void | boolean;
  headers?: Record<string, string>;
  method?: string;
  body?: string;
  cache?: boolean;
  delayFetchBy?: number;
  forceRefetch?: boolean;
  // Determines whether node should generate new clientside payload from context.
  // Every time node fetches order, it extracts soylent email id and registers it as a new identifier.
  // We use shouldGenerateNewClientsidePayload to get new payload and track users with soylent email
  shouldGenerateNewClientsidePayload?: boolean;
  signal?: AbortSignal;
};

type ForceWithCacheOptions = Pick<
  FetchOptions,
  "headers" | "method" | "body" | "signal" | "cache" | "shouldGenerateNewClientsidePayload"
> & {
  forceRefetch?: boolean;
};

const cache = {};

// NOTE: remember this will be mounted/unmounted when navigation happens, so the
// useEffect memoization is only valid for duration of the component's lifecycle
export const useClientFetch = (_url: string, options: FetchOptions) => {
  const { clientFetchCallback } = useClientFetchCallback();

  /**
   * Must NOT be provided as a dependency to useEffect,
   * because it can change its' value while navigating from Home to SR and retrigger search request, leading to an infinite loop
   */
  useEffect(() => {
    if (process.env.BUILD_TARGET !== "client") return;

    const url = trackWithDefaultStage("flights_apex_web_move_overrides_to_client_fetch", 1)
      ? _url
      : appendFeatureAndExperimentParams(appendLanguageOptionParam(_url));

    const {
      fetch: fetchStart,
      success: fetchSuccess,
      error: fetchError,
      condition,
      delayFetchBy = 0,
      forceRefetch
    } = options;

    // Note: we don't add actions to dependencies to allow defining them as inline functions
    // without requiring useCallback / useMemo
    const fetchData = () => {
      fetchStart?.(url);
      debug(`Fetching ${absoluteURL}`);

      clientFetchCallback(url, { ...options, cache: !forceRefetch })
        .then(fetchSuccess)
        .catch((error) => {
          fetchError(error.code ? error : { message: error.toString() });
        });
    };

    const absoluteURL = /^http/.test(url) ? url : `${location.protocol}//${location.host}${url}`;

    if (typeof condition === "function" && condition() != true) {
      debug(`Skipping fetch for ${absoluteURL}`);
      return;
    }

    if (delayFetchBy) {
      setTimeout(() => {
        fetchData();
      }, delayFetchBy);
    } else {
      fetchData();
    }

    return () => {
      debug(`Cleaning up after ${absoluteURL}`);
    };
  }, [_url, options.method, options.forceRefetch]); // eslint-disable-line
};

function pushToCache(url: string, obj: any) {
  const keys = Object.keys(cache);
  if (keys.length > 5) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    delete cache[keys[0]];
  }
  // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
  cache[url] = obj;
  setTimeout(() => {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    cache[url] && delete cache[url];
  }, CACHE_TIMEOUT);
}

function fetchWithCache(url: string, options: ForceWithCacheOptions) {
  const { headers, method, body, signal } = options;
  // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
  const cachedData = cache[url];

  if (options.cache !== false && cachedData) {
    return Promise.resolve(cachedData);
  }

  return clientFetch(url, { headers, method, body, signal }).then((data) => {
    pushToCache(url, data);
    return data;
  });
}

export const useClientFetchCallback = () => {
  const contextName = useContextName();
  const posCountry = useRef(usePointOfSale());
  const clientFetchCallback = useCallback(
    (url: string, options: ForceWithCacheOptions) => {
      const { headers = {}, shouldGenerateNewClientsidePayload, forceRefetch } = options;
      headers["X-Flights-Context-Name"] = contextName;
      if (shouldGenerateNewClientsidePayload) headers["X-Booking-Experiment-Prepare-Clientside-Payload"] = "1";
      if (posCountry.current) headers["X-Flights-Context-POS"] = posCountry.current;
      return fetchWithCache(url, { ...options, cache: !forceRefetch, headers });
    },
    [posCountry, contextName]
  );

  return { clientFetchCallback };
};
