import React, { useMemo } from "react";
import { useI18n } from "@bookingcom/lingojs-react";
import { Divider } from "@bookingcom/bui-react";
import { UIBrandedFareInfo } from "@flights/types";
import { getFeaturesToRender } from "branded-fares/utils/features";
import { t } from "@bookingcom/lingojs-core";
import FareFeature from "branded-fares/components/FareFeature";
import styles from "./FareFeatures.module.css";
import Frame from "components/elements/Frame";
import FareFeatureHeading from "./FareFeatureHeading";
import flights_apex_web_show_all_flexibility_features from "utils/experiments/apex/flights_apex_web_show_all_flexibility_features";
import flights_apex_web_branded_fare_icons_only from "utils/experiments/apex/flights_apex_web_branded_fare_icons_only";
import useCheckoutSteps from "hooks/checkout/useCheckoutSteps";
import useUserAgent from "hooks/useUserAgent";

type Props = {
  brandedFareInfo: UIBrandedFareInfo;
  sellableFeaturesRequiredByAirline?: boolean;
  excludedFeaturesRequiredByAirline?: boolean;
  isPreCheckAndPay?: boolean;
  showDivider?: boolean;
  showHeader?: boolean;
  showDescriptions?: boolean;
  showSubtitles?: boolean;
  compactHeight?: boolean;
  showExcludedFeatures?: boolean;
  areShown?: boolean;
};

const FareFeatures = ({
  brandedFareInfo,
  sellableFeaturesRequiredByAirline,
  excludedFeaturesRequiredByAirline,
  showHeader,
  showDivider,
  isPreCheckAndPay,
  showDescriptions,
  showSubtitles,
  compactHeight = false,
  showExcludedFeatures = true,
  areShown
}: Props) => {
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const { currentStep } = useCheckoutSteps();
  const isBrandedFarePage = currentStep.name === "checkout-fare";

  if (!brandedFareInfo) throw new Error("FareSwitcher: missing branded fare info");

  const featuresToRender = useMemo(() => {
    return getFeaturesToRender(brandedFareInfo, {
      includeSellableFeatures: sellableFeaturesRequiredByAirline,
      includeExcludedFeatures: excludedFeaturesRequiredByAirline,
      includeAllFlexFeatures: !!flights_apex_web_show_all_flexibility_features.trackWithDefaultStage(),
      isPreCheckAndPay
    });
  }, [brandedFareInfo, sellableFeaturesRequiredByAirline, isPreCheckAndPay, excludedFeaturesRequiredByAirline]);

  const includedAndSellableFeatures = featuresToRender.includedFeatures;
  const excludedFeatures = showExcludedFeatures ? featuresToRender.nonIncludedFeatures : [];

  const hasIncludedOrSellableFeatures = includedAndSellableFeatures.length > 0;
  const hasExcludedFeatures = excludedFeatures.length > 0;

  flights_apex_web_branded_fare_icons_only.trackFeatures(
    !!areShown,
    hasExcludedFeatures,
    includedAndSellableFeatures,
    isBrandedFarePage,
    isMobile
  );

  if (!hasIncludedOrSellableFeatures && !hasExcludedFeatures) return null;

  return (
    <>
      {showHeader && hasIncludedOrSellableFeatures && (
        <FareFeatureHeading>{i18n.trans(t("flight_fare_included"))}</FareFeatureHeading>
      )}

      {includedAndSellableFeatures.map((feature) => (
        <FareFeature
          key={feature.name}
          id={feature.name}
          IconSVG={feature.icon}
          content={feature.label}
          description={showDescriptions ? feature.description : undefined}
          subtitle={showSubtitles ? feature.subtitle : undefined}
          availability={feature.availability}
          compactHeight={compactHeight}
        />
      ))}

      {showDivider && hasExcludedFeatures && hasIncludedOrSellableFeatures && (
        <Frame mb={4}>
          <Divider className={styles.divider} attributes={{ "data-testid": "fareFeatureDivider" }} />
        </Frame>
      )}

      {excludedFeatures.map((feature) => (
        <FareFeature
          key={feature.name}
          content={feature.label}
          availability={feature.availability}
          IconSVG={feature.icon}
          compactHeight={compactHeight}
        />
      ))}
    </>
  );
};

export default FareFeatures;
