import { FilterSection } from "@flights/search-ui";
import React from "react";
import { useAirlinesFilterController } from "./useAirlinesFilter.controller";
import AirlinesFilterItem from "./AirlinesFilterItem";
import { AirlinesFilterOptions } from "./AirlinesFilterOptions";

export const AirlinesFilterDesktop = () => {
  const {
    isFilterAvailable,
    title,
    resetLabel,
    showReset,
    onClickReset,
    options,
    value,
    onChange,
    onSelectSingleOption,
    optionsLimit,
    areOptionsTrucanted,
    optionsTogglerLabel,
    optionsTogglerIcon,
    onTogglerClick,
    singleOptionLabel
  } = useAirlinesFilterController();

  if (!isFilterAvailable) return null;

  return (
    <FilterSection
      attributes={{ "data-testid": "airlines_filter" }}
      resetAttributes={{ "data-testid": "search_filter_airline_reset" }}
      title={title}
      resetLabel={resetLabel}
      showReset={showReset}
      onClickReset={onClickReset}
    >
      <AirlinesFilterOptions
        gap={2}
        togglerLabel={optionsTogglerLabel}
        togglerIcon={optionsTogglerIcon}
        onTogglerClick={onTogglerClick}
        options={options}
        optionsLimit={optionsLimit}
        areOptionsTrucanted={areOptionsTrucanted}
        renderOption={({ option, isHidden, index }) => (
          <AirlinesFilterItem
            key={`${index}-${option.value}`}
            value={option.value}
            label={option.label}
            count={option.count}
            checked={value.includes(option.value)}
            onChange={onChange}
            isHidden={isHidden}
            canSelectSingleOption={true}
            onSelectSingleOption={onSelectSingleOption}
            singleOptionLabel={singleOptionLabel}
          />
        )}
      />
    </FilterSection>
  );
};
