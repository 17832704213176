import React, { useRef, useState } from "react";
import { useDSATraceabilityController, DSATraceabilityControllerProps } from "./useDSATraceability.controller";
import { Button, Popover, Stack, Text } from "@bookingcom/bui-react";
import { QuestionMarkCircleIcon } from "@bookingcom/bui-assets-react/streamline/index.js";

export function DSATraceabilityPopover(props: DSATraceabilityControllerProps) {
  const { a11yClose, a11yTooltip, content, shouldRender, text, title } = useDSATraceabilityController(props);
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  if (!shouldRender) return null;

  return (
    <>
      <Stack gap={2} mixin={{ marginBlockStart: 2, marginBlockEnd: 6 }}>
        <Stack attributes={{ style: { display: "inline-flex" } }} gap={0} direction="row" alignItems="center">
          <Stack.Item>
            <Text variant="body_2">{text}</Text>
          </Stack.Item>
          <Stack.Item>
            <Button
              ref={ref}
              attributes={{ "aria-label": a11yTooltip }}
              icon={QuestionMarkCircleIcon}
              variant="tertiary-neutral"
              onClick={() => setActive(true)}
            />
          </Stack.Item>
        </Stack>
      </Stack>
      <Popover
        position="top-start"
        triggerRef={ref}
        closeAriaLabel={a11yClose}
        active={active}
        onClose={() => setActive(false)}
        onAfterClose={() => setActive(false)}
      >
        <Popover.Content title={title}>
          <Stack>
            {content.map((c) => (
              <Stack.Item key={c}>
                <Text variant="body_2">{c}</Text>
              </Stack.Item>
            ))}
          </Stack>
        </Popover.Content>
      </Popover>
    </>
  );
}
