import { FilterSection } from "@flights/search-ui";
import React from "react";
import { Stack, Tab } from "@bookingcom/bui-react";
import { useFlightTimesFilterController } from "./useFlightTimesFilter.controller";
import { OptionsList } from "./OptionsList";

export const FlightTimesFilterDesktop = () => {
  const { isFilterAvailable, title, resetLabel, showReset, onClickReset, moreLabel, options } =
    useFlightTimesFilterController();

  if (!isFilterAvailable) return null;

  return (
    <FilterSection
      attributes={{ "data-testid": "flight_times_filter" }}
      resetAttributes={{ "data-testid": "search_filter_flight_times_reset" }}
      title={title}
      resetLabel={resetLabel}
      showReset={showReset}
      onClickReset={onClickReset}
    >
      {options.length === 1 ? (
        <OptionsList optionIndex={0} />
      ) : (
        <Tab vertical={false} moreLabel={moreLabel} fillEqually={true}>
          <Stack>
            <Tab.TriggerList>
              {options.map(({ labels }, i) => {
                return (
                  <Tab.Trigger
                    id={labels.title}
                    text={labels.title}
                    key={labels.title + i}
                    linkAttributes={{ "data-testid": `flight_times_filter_v2_flight_times_button_${i}` }}
                  />
                );
              })}
            </Tab.TriggerList>
            {options.map(({ labels }, optionIndex) => {
              return (
                <Tab.Panel key={labels.title + optionIndex} id={labels.title}>
                  <OptionsList optionIndex={optionIndex} />
                </Tab.Panel>
              );
            })}
          </Stack>
        </Tab>
      )}
    </FilterSection>
  );
};
