import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";

import FlightCardCarriers, { CarrierNames } from "./FlightCardCarriers";
import FlightCardCarriersV2 from "./FlightCardCarriersV2";
import FlightCardPrice from "./FlightCardPrice";
import { Button, Card, Icon, Stack } from "@bookingcom/bui-react";
import { FlightBadgeVariant, UIFlightData, UIFlightSegment, UIOrder, UITripRebookOption } from "@flights/types";

import BaseFareAttributes from "./BaseFareAttributes";
import FlightCardLuggage from "./FlightCardLuggage";

import useLocaleContext from "hooks/useLocaleContext";
import { trackExperiment } from "utils/et";
import useAirlineLogos from "../../../../hooks/useAirlineLogos";
import { SBSearchType } from "@bookingcom/flights-searchbox";
import { ArrowNavDownIcon, ArrowNavUpIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { FareSelectorDesktop } from "branded-fares/components/FareSelector/components/FareSelector.desktop";
import useIsInViewport from "hooks/useIsInViewport";
import useFetchBrandedFares from "branded-fares/hooks/useFetchBrandedFares";
import { RebookCostBreakdown } from "components/elements/RebookFlow/RebookCostBreakdown/RebookCostBreakdown";
import { FlightCardHighlight, FlightCardHighlightStyle } from "./FlightCardHighlight";
import { FlightCardBadges } from "./FlightCardBadges";
import { FlightCardBadge } from "./FlightCardBadge";
import Frame, { FrameProps } from "components/elements/Frame";
import { getCampaignBadgesConfig, getCampaignBestOfferHighlightConfig } from "utils/flightCampaignConfig";
import styles from "./FlightCardBound.desktop.module.css";
import flights_apex_web_ancillaries_micro_conversion_aa from "utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import FlightCardSegmentDesktop from "./FlightCardSegment.desktop";
import flights_apex_web_compact_bf_on_sr_button from "utils/experiments/apex/flights_apex_web_compact_bf_on_sr_button";
import { carrierUtils, isFeatureRunningClientSide } from "@flights/web-api-utils-universal";
import useGlobalContext from "hooks/useGlobalContext";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";
import { useSelector } from "react-redux";
import { getSearchCriteria } from "store/searchCriteria/selectors";
import { getSearchDisplayOptions, getSearchResultsFlights } from "store/searchResults/selectors";
import flights_apex_web_bf_on_sr_flex_badge from "utils/experiments/apex/flights_apex_web_bf_on_sr_flex_badge";
import flights_web_sr_card_redesign_ddot from "utils/experiments/funnel/flights_web_sr_card_redesign_ddot";
import FlightCardLuggageWithCheckmarks from "./elements/FlightCardLuggageWithCheckmarks/FlightCardLuggageWithCheckmarks.mobile";
import { CardSegmentsCondensed } from "./elements/CardSegmentCondensed/CardSegmentsCondensed";
import flights_merch_toolkit_offer_pinning from "utils/experiments/genius/flights_merch_toolkit_offer_pinning";
import { stage } from "@flights/et-universal";

export type FlightCardBoundProps = {
  flight: UIFlightData;
  onClick: (flight: UIFlightData) => void;
  pricePerPerson?: boolean;
  index: number;
  bigLogo?: boolean;
  searchType?: SBSearchType;
  rebookProps?: {
    tripRebookOption: UITripRebookOption;
    initialOrder: UIOrder;
    align: "start" | "end";
  };
  mt?: FrameProps["mt"];
  mb?: FrameProps["mb"];
  /* start - flights_alternative_fares_for_sold_out_errors_web */
  isAlternativeFareCard?: boolean;
  forceHideLastAvailableSeats?: boolean;
  /* end - flights_alternative_fares_for_sold_out_errors_web */
};

const FlightCardBound: FC<FlightCardBoundProps> = (props) => {
  const { flight, onClick, pricePerPerson, index, bigLogo, rebookProps } = props;
  const i18n = useI18n() as I18nChildContext;
  const { isRTL } = useLocaleContext();
  const { isInternal } = useGlobalContext();
  const flightCardBoundRef = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(flightCardBoundRef.current);
  const { adults, children } = useSelector(getSearchCriteria);
  const displayOptions = useSelector(getSearchDisplayOptions);
  const campaignBadges = getCampaignBadgesConfig(flight.campaignDisplay?.badges);
  const carriers = carrierUtils.getMarketingCarriers(flight.segments);

  const showFlexBadgeOnBrandedFare =
    flight.ancillaries?.flexibleTicket &&
    flight.requestableBrandedFares &&
    !!flights_apex_web_bf_on_sr_flex_badge.trackWithDefaultStage();

  const hasFlexibleTicketBadge = flight.ancillaries?.flexibleTicket && !flight.requestableBrandedFares;

  const showFlightCardBadges =
    flight?.badges?.length ||
    campaignBadges.length > 0 ||
    hasFlexibleTicketBadge ||
    showFlexBadgeOnBrandedFare ||
    flight.hasAppleCardBenefits;

  useEffect(() => {
    if (flight.requestableBrandedFares && flight.ancillaries?.flexibleTicket) {
      flights_apex_web_bf_on_sr_flex_badge.stages.ddot();
      flights_apex_web_bf_on_sr_flex_badge.stages.search_results();
    }
  }, [flight.ancillaries?.flexibleTicket, flight.brandedFareOffers, flight.requestableBrandedFares]);

  const { bestOfferHighlightStyle, bestOfferHighlightText } = getCampaignBestOfferHighlightConfig(
    flight.campaignDisplay?.bestOfferHighlight
  );
  const shouldShowBestOfferHightlight = !!(bestOfferHighlightStyle && bestOfferHighlightText);

  const shouldPinOfferOnSr =
    flight.highlightInfo?.highlight &&
    ((Boolean(trackExperiment("flights_web_cat_pin_flight_www")) && isOfMetaOrigin()) ||
      isFeatureRunningClientSide("FLIGHTS_WEB_PIN_OFFER_ON_SR_FROM_REDIRECT") ||
      flight.highlightInfo.reason === "CROSS_SELL_OFFER");

  const hasPrefetchedBrandedFareOffers = !!flight.brandedFareOffers && flight.brandedFareOffers?.length > 0;
  const canShowFareSelector = flight.requestableBrandedFares || hasPrefetchedBrandedFareOffers;
  const showFareSelectorDefault = canShowFareSelector && displayOptions?.brandedFaresShownByDefault;
  const showCompactFareSelector = !!displayOptions?.brandedFaresShownByDefault;

  /* start- flights_apex_web_compact_bf_on_sr_button */
  const showCompactFareSelectorButton =
    showCompactFareSelector || !!flights_apex_web_compact_bf_on_sr_button.trackWithDefaultStage();
  const viewDetailsRef = useRef<HTMLButtonElement>(null);
  const viewDetailsVisible = useIsInViewport(viewDetailsRef);
  const flights = useSelector(getSearchResultsFlights);
  if (viewDetailsVisible && canShowFareSelector)
    flights_apex_web_compact_bf_on_sr_button.trackSearchResultsStages(flights);
  /* end - flights_apex_web_compact_bf_on_sr_button */

  const [breakdownOpen, setBreakdownOpen] = useState(false);
  const [showFareSelector, setShowFareSelector] = useState(showFareSelectorDefault);
  const [showDivider, setShowDivider] = useState(false);
  const [hoveringBadge, setHoveringBadge] = useState(false);
  const hoverTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (hoveringBadge) {
      hoverTimerRef.current = setTimeout(() => {
        flights_apex_web_bf_on_sr_flex_badge.goals.hovers_badge();
      }, 500);
    } else if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = null;
    }

    return () => {
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
      }
    };
  }, [hoveringBadge]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeDivider = useCallback(() => setShowDivider(false), []);

  useFetchBrandedFares(
    flight.token,
    isInViewport,
    !!flight.requestableBrandedFares,
    true,
    !!flight.brandedFareInfo?.fareName,
    carriers
  );

  const viewDetailsButtonLabel = t("flights_apex_sr_flex_cta_main");

  const travellersCount = adults + children.length;

  const flightPriceComponent = rebookProps ? (
    <RebookCostBreakdown {...rebookProps} breakdownOpen={breakdownOpen} setBreakdownOpen={setBreakdownOpen} />
  ) : (
    <FlightCardPrice
      flight={flight}
      breakdownOpen={breakdownOpen}
      setBreakdownOpen={setBreakdownOpen}
      showPricePerPerson={pricePerPerson && adults + children.length > 1}
      showAvailableSeats={isOfMetaOrigin() && adults + children.length <= 2}
      totalPriceLabel={i18n.trans(
        travellersCount > 1 ? t("flights_sr_rt_price_per") : t("flights_sr_price_round_trip")
      )}
      popoverCloseAriaLabel={i18n.trans(t("flights_a11y_price_breakdown_close_sr"))}
    />
  );

  const highlightFireflySR =
    isInternal &&
    flight.token.startsWith("c8e49_") &&
    new URLSearchParams(window.location.search).get("highlight_firefly") === "1";

  const toggleShowFareSelector = useCallback(() => {
    if (showFareSelector) {
      flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
      flights_apex_web_compact_bf_on_sr_button.goals.collapses_fares();
    } else {
      flights_apex_web_bf_on_sr_loading_message.goals.close_expanded_branded_fares();
      flights_apex_web_compact_bf_on_sr_button.goals.expands_fares();
      flights_apex_web_bf_on_sr_flex_badge.goals.expands_offer();
    }

    setShowFareSelector(!showFareSelector);
  }, [showFareSelector, setShowFareSelector]);

  const toggleFareSelectorIcon = useMemo(
    () => <Icon svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon} attributes={{ "aria-hidden": true }} />,
    [showFareSelector]
  );

  const viewDetailsButton = (
    <Button
      attributes={{
        "data-testid": "flight_card_bound_select_flight",
        "aria-describedby": `flight-card-${index}`,
        "aria-label": `${i18n.trans(viewDetailsButtonLabel)} ${
          hasFlexibleTicketBadge ? i18n.trans(t("flights_apex_sr_flex_badge")) : ""
        }`,
        ref: viewDetailsRef
      }}
      text={i18n.trans(viewDetailsButtonLabel)}
      onClick={() => {
        flights_apex_web_bf_on_sr_loading_message.goals.click_on_view_details_for_main_flight();
        flights_merch_toolkit_offer_pinning.trackFeaturedOfferClick(flight.badges || []);
        onClick(flight);
      }}
      variant="secondary"
      className={flights_web_sr_card_redesign_ddot.trackWithDefaultStage() ? styles.noMarginButton : styles.button}
    />
  );

  return (
    <div id={`flight-card-${index}`} ref={flightCardBoundRef}>
      <Card
        fill
        className={highlightFireflySR && styles.highlightFirefly}
        variant={shouldPinOfferOnSr ? "elevated" : "neutral"}
      >
        {shouldPinOfferOnSr ? (
          <FlightCardHighlight
            style={FlightCardHighlightStyle.PRIMARY}
            text={flight.highlightInfo?.message || i18n.trans(t("flights_meta_sr_pinned_flight_label"))}
          />
        ) : shouldShowBestOfferHightlight ? (
          <FlightCardHighlight style={bestOfferHighlightStyle} text={bestOfferHighlightText} textEmphasized={true} />
        ) : null}

        <Frame p={4} direction="row" className={showDivider ? styles.divider : undefined}>
          <Frame
            attributes={{
              style: {
                width: "64%"
              }
            }}
            justifyContent="flex-start"
          >
            {showFlightCardBadges && (
              <FlightCardBadges
                className={
                  flights_web_sr_card_redesign_ddot.trackWithDefaultStage() ? styles.badgesWithBottomMargin : undefined
                }
              >
                {campaignBadges.map((badge, idx) => (
                  <FlightCardBadge
                    key={`keyCampaignBadge-${idx}`}
                    alternative={false}
                    isInteractive={false}
                    {...badge}
                  />
                ))}

                {flight?.badges?.map(({ text, variant, type }, idx) => (
                  <FlightCardBadge
                    key={text + idx}
                    text={text}
                    style={variant}
                    alternative={type != "FEATURED"}
                    type={type}
                    hover={
                      type === "FEATURED"
                        ? {
                            title: i18n.trans(t("flights_sr_featured_label")),
                            text: i18n.trans(t("flights_sr_featured_tooltip"))
                          }
                        : undefined
                    }
                  />
                ))}

                {(hasFlexibleTicketBadge || showFlexBadgeOnBrandedFare) && (
                  <span onMouseEnter={() => setHoveringBadge(true)} onMouseLeave={() => setHoveringBadge(false)}>
                    <FlightCardBadge
                      style={FlightBadgeVariant.CONSTRUCTIVE}
                      alternative={true}
                      text={i18n.trans(t("flights_apex_sr_flex_badge"))}
                    />
                  </span>
                )}

                {flight.hasAppleCardBenefits && (
                  <FlightCardBadge
                    style={FlightBadgeVariant.CALLOUT}
                    alternative={true}
                    text={i18n.trans(t("apple_rewards_flights_sr_badge_title"))}
                  />
                )}
              </FlightCardBadges>
            )}

            <BaseFareAttributes flight={flight} />

            {!!flights_web_sr_card_redesign_ddot.trackWithDefaultStage() ? (
              <CardSegmentsCondensed segments={flight.segments} bigLogo={bigLogo} />
            ) : (
              flight.segments.map((segment, segmentIndex) => (
                <FlightCardSegmentDetails key={segmentIndex} idx={segmentIndex} segment={segment} bigLogo={bigLogo} />
              ))
            )}
          </Frame>

          <Frame
            direction="column"
            justifyContent={flights_web_sr_card_redesign_ddot.trackWithDefaultStage() ? "center" : undefined}
            className={styles.priceBlock}
          >
            {rebookProps?.tripRebookOption ? (
              <FlightCardLuggage segments={rebookProps.tripRebookOption.segments} />
            ) : flights_web_sr_card_redesign_ddot.trackWithDefaultStage() ? (
              <FlightCardLuggageWithCheckmarks
                segments={flight.segments}
                showFareName={canShowFareSelector}
                brandedFareInfo={flight.brandedFareInfo}
              />
            ) : (
              <FlightCardLuggage segments={flight.segments} brandedFareInfo={flight.brandedFareInfo} />
            )}

            <div style={{ textAlign: isRTL ? "left" : "right" }}>
              <Frame mt={flights_web_sr_card_redesign_ddot.trackWithDefaultStage() ? 2 : 0}>
                {flightPriceComponent}
              </Frame>

              {canShowFareSelector && showCompactFareSelectorButton ? (
                <Stack direction="row" grow>
                  <Stack.Item alignSelf="end">
                    <Button
                      attributes={{
                        "aria-expanded": !!showFareSelector,
                        "aria-controls": "flights-fare-selector",
                        "aria-label": showFareSelector
                          ? i18n.trans(t("a11y_flights_apex_bf_sr_hide_options"))
                          : i18n.trans(t("a11y_flights_apex_bf_sr_expand_options"))
                      }}
                      icon={toggleFareSelectorIcon}
                      variant="secondary"
                      onClick={toggleShowFareSelector}
                      className={
                        flights_web_sr_card_redesign_ddot.trackWithDefaultStage()
                          ? styles.showFaresButtonWithMargin
                          : styles.showFaresButton
                      }
                    />
                  </Stack.Item>
                  <Stack.Item grow>{viewDetailsButton}</Stack.Item>
                </Stack>
              ) : (
                <>
                  {viewDetailsButton}
                  {canShowFareSelector ? (
                    <Button
                      attributes={{ "aria-expanded": !!showFareSelector, "aria-controls": "flights-fare-selector" }}
                      text={
                        showFareSelector
                          ? i18n.trans(t("flights_apex_sr_flex_cta_hide"))
                          : i18n.trans(t("flights_apex_sr_flex_cta_explore"))
                      }
                      onClick={() => {
                        toggleShowFareSelector();
                        flights_apex_web_bf_on_sr_flex_badge.goals.expands_offer();
                        stage("flights_apex_branded_fare_request_timeout_change_web", 7);
                      }}
                      variant="tertiary"
                      iconPosition="end"
                      wide={true}
                      icon={
                        <Icon
                          svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                          attributes={{ "aria-hidden": true }}
                        />
                      }
                      className={
                        flights_web_sr_card_redesign_ddot.trackWithDefaultStage()
                          ? styles.showFaresButtonWithMargin
                          : styles.showFaresButton
                      }
                    />
                  ) : null}
                </>
              )}
            </div>
          </Frame>
        </Frame>

        {showFareSelector && <FareSelectorDesktop flight={flight} isCompact={showCompactFareSelector} />}
      </Card>
    </div>
  );
};

const FlightCardSegmentDetails: FC<{ segment: UIFlightSegment; idx: number; bigLogo?: boolean }> = ({
  segment,
  idx,
  bigLogo
}) => {
  const { selectFlightCarriers: carriers } = useAirlineLogos(segment);
  const segmentCarriersClass = bigLogo ? styles.segmentCarriersV2 : styles.segmentCarriers;
  const segmentDetailsClass = bigLogo ? styles.segmentDetailsV2 : styles.segmentDetails;

  return (
    <>
      <Frame direction="row" className={styles.segment} justifyContent="space-between" alignItems="center">
        <Frame className={segmentCarriersClass} justifyContent="space-between" alignItems="flex-start">
          {bigLogo ? (
            <FlightCardCarriersV2 segment={segment} idx={idx} />
          ) : (
            <FlightCardCarriers segment={segment} idx={idx} />
          )}
        </Frame>
        <Frame className={segmentDetailsClass}>
          <FlightCardSegmentDesktop segment={segment} idx={idx} />
        </Frame>
      </Frame>

      <Frame mt={2}>
        <CarrierNames carriers={carriers} idx={idx} />
      </Frame>
    </>
  );
};

const FlightCardDesktop = React.memo(FlightCardBound);
export default FlightCardDesktop;
