import React from "react";
import { useSelector } from "react-redux";
import { getSearchAvailableFilters } from "store/searchResults/selectors";
import { Stack } from "@bookingcom/bui-react";
import { StopsFilterDesktop } from "./FiltersSplit/StopsFilter";
import { AirlinesFilterDesktop } from "./FiltersSplit/AirlinesFilter";
import { FlightTimesFilterDesktop } from "./FiltersSplit/FlightTimesFilter";
import { DurationFilter } from "./FiltersSplit/DurationFilter";
import { BudgetPerAdultFilter } from "./FiltersSplit/BudgetPerAdultFilter";

const SearchFiltersDesktop = () => {
  const availableFilters = useSelector(getSearchAvailableFilters);

  return (
    <div>
      {Object.keys(availableFilters).length > 0 ? (
        <Stack gap={8}>
          <StopsFilterDesktop />
          <BudgetPerAdultFilter />
          <AirlinesFilterDesktop />
          <FlightTimesFilterDesktop />
          <DurationFilter />
        </Stack>
      ) : null}
    </div>
  );
};

export default SearchFiltersDesktop;
