/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { ReactNode, useState } from "react";

import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import { SheetContainer, Text } from "@bookingcom/bui-react";

type Props = {
  hover?: {
    title: string;
    text: string;
  };
  children: ReactNode;
};

export const FlightCardBadgeBottomSheetWrapper = ({ hover, children }: Props) => {
  const i18n = useI18n() as I18nChildContext;
  const [isBottomSheetActive, setBottomSheetActive] = useState<boolean>(false);

  if (!hover) return <>{children}</>;

  return (
    <span
      role="presentation"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div role="button" tabIndex={0} onClick={() => setBottomSheetActive(true)}>
        {children}
      </div>
      <SheetContainer
        active={isBottomSheetActive}
        onCloseTrigger={() => setBottomSheetActive(false)}
        closeAriaLabel={i18n.trans(t("close"))}
        position="bottom"
        title={hover.title}
      >
        <Text>{hover.text}</Text>
      </SheetContainer>
    </span>
  );
};
