import { useCallback } from "react";
import { createMicroConversionTrackingUtils } from "..";
import { useSelector } from "react-redux";
import useProfiler from "hooks/useProfiler";
import { getFlight } from "store/flightDetails/selectors";
import useIsBrandedFare from "branded-fares/hooks/useIsBrandedFare";

const utils = createMicroConversionTrackingUtils({
  defaultStage: 2,
  mainStage: 2,
  name: "flights_web_flex_ticket_to_extras",
  goals: {},
  stages: {
    all: 1,
    main: 2,
    desktop: 3,
    mdot: 4,
    direct: 5,
    meta: 6,
    solo: 7,
    couple: 8,
    group: 9
  }
});

export function useTrackFlightsWebFlexTicketToExtras() {
  const flight = useSelector(getFlight);
  const { isDirect, isDesktop, isSolo, isCouple, isGroup } = useProfiler();
  const isBrandedFare = useIsBrandedFare();

  const track = useCallback(() => {
    if (isDesktop()) utils.stages.desktop();
    if (!isDesktop()) utils.stages.mdot();
    if (isDirect()) utils.stages.direct();
    if (!isDirect()) utils.stages.meta();
    if (isSolo()) utils.stages.solo();
    if (isCouple()) utils.stages.couple();
    if (isGroup()) utils.stages.group();
    return !!utils.trackWithDefaultStage();
  }, [isDirect, isDesktop, isSolo, isCouple, isGroup]);

  const isFlightsWebFlexTicketToExtrasVariant = useCallback(() => {
    utils.stages.all();
    const a = flight?.ancillaries;
    if (!!isBrandedFare) return false; // exclude branded fares
    if (!!a?.cancelForAnyReason?.priceBreakdown) return false; // exclude CFAR
    if (a?.flexibleTicket?.recommendation?.confidence !== "LOW") return false; // exclude non-low confidence
    return track();
  }, [flight, isBrandedFare, track]);

  return {
    isFlightsWebFlexTicketToExtrasVariant
  };
}
