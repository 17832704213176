import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "store/index";

export const getSearchSegments = (state: StoreState) => state.search.searchSegments;
export const getSearchType = (state: StoreState) => state.search.type;
export const getNoOfAdults = (state: StoreState) => state.search.adults;
export const getNoOfChildren = (state: StoreState) => state.search.children.length;
export const getChildrensAges = (state: StoreState) => state.search.children;
export const getFilters = (state: StoreState) => state.search.filters;
export const getPage = (state: StoreState) => state.search.page;

export const getCabinClass = (state: StoreState) => state.search.cabinClass;

export const getSort = (state: StoreState) => state.search.sort;

export const getArrivalPlace = createSelector(
  (state: StoreState) => state.search.searchSegments[0]?.to,
  (arrivalSegments) => {
    const arrivalPlace = arrivalSegments?.[arrivalSegments?.length - 1];
    return { code: arrivalPlace?.code, type: arrivalPlace?.type };
  }
);

export const getLastDepartureDate = (state: StoreState) =>
  state.search.type === "ROUNDTRIP"
    ? state.search.searchSegments?.[state.search.searchSegments.length - 1]?.returnDate
    : state.search.searchSegments[0]?.departureDate;

export const getArrivalCountryCode = (state: StoreState) =>
  state.search.searchSegments[0]?.to?.[state.search.searchSegments[0]?.to?.length - 1]?.country;

export const getFiltersDraft = (state: StoreState) => state.search.filtersDraft;

export const getIsFiltersApplied = (state: StoreState) => {
  return [
    !!state.search?.filters?.airlines?.length,
    !!state.search?.filters?.airports?.length,
    !!state.search?.filters?.arrivalIntervals?.length,
    !!state.search?.filters?.departureIntervals?.length,
    !!state.search?.filters?.duration,
    !!state.search?.filters?.flightTimes?.length,
    !!state.search?.filters?.includedBaggage?.length,
    !!state.search?.filters?.maxBudget,
    !!state.search?.filters?.maxBudgetPerAdult,
    !!state.search?.filters?.maxLayoverDuration,
    state.search?.filters?.shortLayoverConnection !== false &&
      state.search?.filters?.shortLayoverConnection !== undefined,
    state.search?.filters?.stops !== 0 && state.search?.filters?.stops !== undefined
  ].every(Boolean);
};
