import React, { useEffect } from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import { isEmpty } from "lodash";
import useEvents from "hooks/useEvents";

const WishlistWidgetLoader = () => {
  const { Component, isLoading, error } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/Wishlists/WidgetRemote",
    type: "capla"
  });

  const { grumble } = useEvents();

  useEffect(() => {
    if (!isLoading && error && !isEmpty(error)) {
      grumble("RC error: WishlistWidget", { error: error.toString() });
    }
  }, [isLoading, error, grumble]);

  return <Component />;
};

export default WishlistWidgetLoader;
