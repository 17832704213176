import { STICKY_FOOTER_SPACER_ID } from "../constants";
import { useEffect, RefObject } from "react";
import { useTheme } from "@bookingcom/bui-react";

/**
 * Creates vertical space for a fixed element by using the height of a ref, uses the root level spacer by default.
 */
const useStickFooterSpacerHeight = (
  elementRef: RefObject<HTMLDivElement>,
  spacerId: string = STICKY_FOOTER_SPACER_ID
) => {
  const theme = useTheme();

  useEffect(() => {
    const createSpace = () => {
      if (elementRef.current) {
        setSpacerHeight(spacerId, `calc(${elementRef.current.offsetHeight}px + ${theme.units.spacing_4x})`);
      }
    };

    createSpace();
    window.addEventListener("resize", createSpace);

    return () => {
      setSpacerHeight(spacerId, "");
      window.removeEventListener("resize", createSpace);
    };
  }, [elementRef, theme.units.spacing_4x, spacerId]);
};

const setSpacerHeight = (id: string, height: string) => {
  const spacer = document.getElementById(id);

  if (spacer) {
    spacer.style.height = height;
  }
};

export default useStickFooterSpacerHeight;
