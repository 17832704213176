import { Stack, Link, Text } from "@bookingcom/bui-react";
import React from "react";
export const FilterSection = ({ children, showReset = false, onClickReset, title, attributes, resetAttributes, resetLabel }) => {
    return (React.createElement(Stack, { attributes: attributes },
        React.createElement(Stack, { gap: 3, direction: "row" },
            React.createElement(Stack.Item, { grow: true },
                React.createElement(Text, { variant: "emphasized_1" }, title)),
            showReset && (React.createElement(Stack.Item, null,
                React.createElement(Link, { attributes: resetAttributes, onClick: onClickReset }, resetLabel)))),
        children));
};
