/**
 * Flight search default date selection (FUNNEL-113) has an edge case:
 * When two consecutive months start on the same weekday (e.g., both months
 * start on a Sunday), users may accidentally select dates in the wrong month
 * due to calendar UI ambiguity.
 *
 * Solution:
 * If the current month, previous month and next month start on the same weekday,
 * we don't set any default dates. Instead, the calendar opens to the current month
 * and requires manual date selection. This prevents accidental wrong-month
 * selections.
 */

function doMonthsStartOnSameDay(startDate: Date) {
  // Get the first day of the current month
  const firstDayCurrentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  // Get the first day of the next month
  const firstDayNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
  const firstPrevMonth = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
  // Compare the days of the week (0-6, where 0 is Sunday)
  return (
    firstDayCurrentMonth.getDay() === firstDayNextMonth.getDay() ||
    firstDayCurrentMonth.getDay() === firstPrevMonth.getDay()
  );
}

export default function searchBoxSelectedDates() {
  // As per request in ticket FUNNEL-113, we select first Saturday after 29 days
  const baseDate = new Date(new Date().getTime() + 60 * 60 * 24 * 29 * 1000);
  const calculateBaseDate = new Date(baseDate);
  // baseDate is the Saturday of that week
  calculateBaseDate.setDate(baseDate.getDate() - baseDate.getDay() + 6);
  const startDate = new Date(calculateBaseDate);
  // we choose the next Saturday in case of return flight
  const endDate = new Date(calculateBaseDate.getTime() + 60 * 60 * 24 * 7 * 1000);
  // we want to skip the dates if the month start as the same date of the next month
  if (doMonthsStartOnSameDay(startDate)) return {};
  return { startDate, endDate };
}
