import { UIProductSelection } from "@flights/types";
import { getOfferCheckoutData, SessionStorageOfferProperties, setOfferCheckoutData } from "./checkoutOfferStorage";

export const offerExtraProduct = {
  add: <T extends keyof UIProductSelection>({
    offerToken,
    key,
    value
  }: {
    offerToken: string;
    key: T;
    value: UIProductSelection[T];
  }) => {
    setOfferCheckoutData(offerToken, (prevState) => {
      if (prevState.ancillaries) {
        prevState.ancillaries[key] = value;
      } else {
        prevState.ancillaries = { [key]: value };
      }

      return prevState;
    });
  },
  remove: <T extends keyof UIProductSelection>({ offerToken, key }: { offerToken: string; key: T }) => {
    setOfferCheckoutData(offerToken, (prevState) => {
      if (prevState.ancillaries) {
        delete prevState.ancillaries[key];
      }

      return prevState;
    });
  },
  get: (offerToken: string) => getOfferCheckoutData(offerToken)
};

/* start - flights_web_checkout_back_navigation */
export const offerPAXData = {
  get: (offerToken: string) => getOfferCheckoutData(offerToken)?.paxData,
  update: ({ offerToken, data }: { offerToken: string; data: SessionStorageOfferProperties["paxData"] }) =>
    setOfferCheckoutData(offerToken, (prevState) => ({ ...prevState, paxData: data })),
  clear: (offerToken: string) =>
    setOfferCheckoutData(offerToken, (prevState) => {
      delete prevState.paxData;
      return prevState;
    })
};
/* end - flights_web_checkout_back_navigation */
