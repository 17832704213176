/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { Suspense, useEffect } from "react";

import { BUIProvider } from "@bookingcom/bui-react";
import travellerTheme from "@bookingcom/bui-react/themes/traveller_ex";
import ErrorReporter from "@bookingcom/react-error-reporter";
import { FlightsErrorReporter } from "../utils/report-error";

import AriaLive from "../components/elements/AriaLive";
import GenericErrorReport from "../components/elements/GenericErrorReport";
import Trackers from "../components/elements/Trackers";

import useGlobalContext from "../hooks/useGlobalContext";
import useRouteChangeTracking from "../hooks/useRouteChangeTracking";
import usePageviewIsNotABounce from "../hooks/usePageviewIsNotABounce";
import useUserAgent from "../hooks/useUserAgent";

import { setMarketingTrackingVariables } from "../utils/marketing-url-params";
import Layout from "./Layout";
import useC360Tracker from "hooks/useC360Tracker";
import { RemoteHeader } from "components/remote";
import useWebShellRouteChange from "hooks/useWebShellRouteChange";
import NoSSR from "./NoSSR";
import { localizationUtils, isFeatureRunningClientSide } from "@flights/web-api-utils-universal";
import { stage } from "@flights/et-universal";
import useEvents from "hooks/useEvents";
import { SupportedLocales } from "@bookingcom/lingojs-locales";

const InternalTools = React.lazy(
  () => import(/* webpackChunkName: "internal-tools" */ "../components/elements/InternalTools")
);

const App: React.FunctionComponent = function () {
  const { isMobile } = useUserAgent();
  const { isInternal, marketingTrackingVariables, lang, userAgent, shouldNotTrack, isKnownBot, locale } =
    useGlobalContext();
  const { grumble } = useEvents();
  const c360Tracker = useC360Tracker();
  const jsErrorReporter = FlightsErrorReporter.getInstance().errorReporter;

  c360Tracker.initC360();

  //send route changes for marketing tracking
  useRouteChangeTracking();

  //send route changes for web performance tracking
  useWebShellRouteChange();

  //pageview is not a bounce ET goals
  usePageviewIsNotABounce();

  //store marketing params in session store
  setMarketingTrackingVariables(marketingTrackingVariables);

  /** start: Bot traffic AA */
  useEffect(() => {
    const isRobot = userAgent.isBot || isKnownBot;

    if (isMobile) {
      stage("flights_web_cat_bot_exp_traffic_mdot_aa", 7);
      isRobot
        ? stage("flights_web_cat_bot_exp_traffic_mdot_aa", 9)
        : stage("flights_web_cat_bot_exp_traffic_mdot_aa", 8);
    } else {
      stage("flights_web_cat_bot_exp_traffic_desktop_aa", 7);
      isRobot
        ? stage("flights_web_cat_bot_exp_traffic_desktop_aa", 9)
        : stage("flights_web_cat_bot_exp_traffic_desktop_aa", 8);
    }

    if (
      isRobot &&
      !shouldNotTrack && // experiment is tracked for the request
      isFeatureRunningClientSide("FLIGHTS_WEB_CAT_LOG_BOT_INFO_ALL_PAGES")
    ) {
      grumble("Tracked bot on Client:", {
        platform: isMobile ? "Mobile" : "Desktop",
        userAgent: navigator.userAgent
      });
    }
  }, [isMobile, userAgent, shouldNotTrack, isKnownBot, grumble]);
  /** end: Bot traffic AA */

  return (
    <BUIProvider
      theme={travellerTheme}
      defaultViewportSize={isMobile ? "small" : "large"}
      defaultRTL={!!(lang && localizationUtils.isRTL(lang))}
      locale={locale as SupportedLocales}
    >
      <ErrorReporter errorDisplayComponent={GenericErrorReport} reporter={jsErrorReporter}>
        <RemoteHeader />
        <Layout />
        <Trackers />
        <AriaLive />
        {/* {isWebview && <ScrollToTopOnNavigation />} */}
      </ErrorReporter>
      <NoSSR>
        {isInternal && (
          <Suspense fallback={null}>
            <InternalTools />
          </Suspense>
        )}
      </NoSSR>
    </BUIProvider>
  );
};

export { App as default };
