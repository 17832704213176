import React, { useEffect, useMemo } from "react";
import { Actionable, Icon, Stack, Text } from "@bookingcom/bui-react";
import { WarningIcon, InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import usePrevious from "@bookingcom/bui-react/hooks/usePrevious";
import { UIFlightSegment, UIPassenger, UIPrice, UIPriceBreakdown, UITraveller, UITravellerPrice } from "@flights/types";
import useFormatPrice from "hooks/useFormatPrice";
import { trackExperiment } from "utils/et";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions } from "../../../../store";
import { actions as AriaLiveActions } from "store/ariaLive/actions";
import useUserAgent from "hooks/useUserAgent";
import Frame from "components/elements/Frame";
import useRouteName from "hooks/useRouteName";
import { localizationUtils } from "@flights/web-api-utils-universal";

interface Props {
  breakdown: UIPriceBreakdown;
  travellers?: (UITraveller | UIPassenger)[];
  show: () => void;
  hide: () => void;
  allowOpen: boolean;
  children?: React.ReactNode;
  segments?: UIFlightSegment[];
  travellerPrices?: UITravellerPrice[];
  showPricePerPerson?: boolean;
  pricePerPerson?: UIPrice;
  paymentCollectionToPay?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  paymentCollectionPaid?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  isCTALoading?: boolean;
}

export default function BreakdownTrigger(props: Props) {
  const {
    breakdown,
    travellers = [],
    children,
    allowOpen,
    show,
    segments,
    travellerPrices,
    showPricePerPerson,
    paymentCollectionToPay, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    pricePerPerson,
    isCTALoading
  } = props;
  const i18n = useI18n() as I18nChildContext;
  const { formatPrice } = useFormatPrice();
  const { isMobile } = useUserAgent();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const ariaLiveActions = useActions(AriaLiveActions);

  const routeName = useRouteName();
  const currentlyOnCheckAndPayPage = routeName === "checkout3";

  const priceChangeLoadingTextExperimentOn = !!trackExperiment("flights_price_change_loading_text");

  const currentPrice = useMemo(() => {
    if (showPricePerPerson && travellerPrices && pricePerPerson) {
      return formatPrice(pricePerPerson);
    } else {
      return formatPrice(breakdown.totalWithInstalments || breakdown.total);
    }
  }, [breakdown, formatPrice, showPricePerPerson, travellerPrices, pricePerPerson]);

  const previousPrice = usePrevious(currentPrice);

  // Announce price changes to screen readers
  useEffect(() => {
    if (isMobile && previousPrice !== currentPrice) {
      ariaLiveActions.setMessage({
        message: i18n.trans(
          t("a11y_flights_aria_live_checkout_new_total_price", {
            variables: { price: currentPrice }
          })
        ),
        type: "polite"
      });
    }
  }, [ariaLiveActions, currentPrice, i18n, isMobile, previousPrice]);

  const priceExplanation = useMemo(() => {
    if (localizationUtils.isHungaryDepartingRyanAirFlight(segments)) {
      return i18n.trans(
        t("flights_ryanair_hungary_bp_total_plural", {
          num_exception: travellers.length,
          variables: { num_travellers: travellers.length }
        })
      );
    } else if (showPricePerPerson && travellerPrices && travellerPrices.length > 1) {
      return i18n.trans(
        t("flights_meta_fd_price_qualifier_all_travellers", {
          variables: {
            currency_price: formatPrice(breakdown.total),
            num_travellers: travellerPrices.length
          },
          num_exception: travellerPrices.length
        })
      );
    } else {
      return i18n.trans(
        t("flights_search_passenger_count_mix", {
          num_exception: travellers.length,
          variables: { num_travellers: travellers.length }
        })
      );
    }
  }, [i18n, segments, showPricePerPerson, travellers, breakdown, formatPrice, travellerPrices]);

  return (
    <>
      {isCTALoading && currentlyOnCheckAndPayPage && priceChangeLoadingTextExperimentOn ? (
        <Frame direction="row" alignItems={"center"} pt={2} pb={2}>
          <Frame pr={2}>
            <Icon svg={WarningIcon} />
          </Frame>
          <Text align={"start"}>{i18n.trans(t("flights_checkpay_cta_payment_loading"))}</Text>
        </Frame>
      ) : undefined}
      <Frame direction="row">
        <Frame
          alignItems="start"
          direction={children ? "row" : "column"}
          attributes={{ style: children ? { width: "50%" } : {} }}
          pt={children ? 3 : 4}
          pb={children ? 3 : 4}
        >
          <Actionable
            onClick={() => {
              allowOpen && show();
            }}
          >
            <Frame>
              {breakdown.showPriceStrikethrough && breakdown.totalWithoutDiscount && (
                <Text variant="small_1" color="destructive" decoration="line-through">
                  {formatPrice(breakdown.totalWithoutDiscount)}
                </Text>
              )}
              <Stack alignItems="center" direction="row">
                <Text
                  variant={!!trackExperiment("flights_web_price_per_person_4") ? "strong_1" : "strong_2"}
                  tagName="h2"
                  attributes={{ "data-testid": "breakdown_trigger_price" }}
                >
                  {paymentCollectionToPay || currentPrice}
                </Text>
                {allowOpen && (
                  <Stack
                    attributes={{
                      "data-testid": "breakdown_trigger_popover_trigger"
                    }}
                  >
                    <Icon svg={InfoSignIcon} size="smaller" />
                  </Stack>
                )}
              </Stack>
              <Frame attributes={{ "data-testid": "breakdown_trigger_text" }}>
                {travellers && travellers.length > 0 && (
                  <>
                    {!!trackExperiment("flights_web_price_per_person_4") ? (
                      <Text color="neutral_alt" variant="body_2">
                        {priceExplanation}
                      </Text>
                    ) : (
                      <Text variant="body_2">{priceExplanation}</Text>
                    )}
                  </>
                )}
              </Frame>
            </Frame>
          </Actionable>
        </Frame>
        {children && (
          <Frame grow={1} alignItems="end" direction="row" pt={3} pb={3}>
            {children}
          </Frame>
        )}
      </Frame>
    </>
  );
}
