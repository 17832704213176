import { UIWhitelabelAncillary } from "@flights/ancillaries-universal";
import { createAction, createSlice } from "@reduxjs/toolkit";
import { whitelabelAncillaryService } from "ancillaries/service/whitelabelAncillariesService";

interface UIWhitelabelAncillaries {
  whitelabelAncillariesLoading: boolean;
  status: "INIT" | "LOADING" | "FULLFILLED" | "FAILED";
  whitelabelAncillaries?: UIWhitelabelAncillary[];
}

export const whitelabelAncillariesInitialState = (): UIWhitelabelAncillaries => {
  return {
    whitelabelAncillariesLoading: false,
    status: "INIT",
    whitelabelAncillaries: []
  };
};

export const RESET = createAction("RESET");

export const whitelabelAncillariesSlice = createSlice({
  name: "whitelabelAncillariesEligibility",
  initialState: whitelabelAncillariesInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    const getWhitelabels = whitelabelAncillaryService.getWhitelabels;

    // To RESET state on unmounting
    builder.addCase(RESET, () => whitelabelAncillariesInitialState());

    builder.addCase(getWhitelabels.pending, (state) => {
      state.whitelabelAncillariesLoading = true;
      state.status = "LOADING";
    });
    builder.addCase(getWhitelabels.rejected, (state) => {
      state.whitelabelAncillariesLoading = false;
      state.status = "FAILED";
    });
    builder.addCase(getWhitelabels.fulfilled, (state, action) => {
      state.whitelabelAncillariesLoading = false;
      state.status = "FULLFILLED";
      state.whitelabelAncillaries = action.payload.white_labels;
    });
  }
});

export default whitelabelAncillariesSlice.reducer;
