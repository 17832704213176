import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Text, ActionBar, Stack, SkeletonLoader } from "@bookingcom/bui-react";
import { CalendarIcon, CalendarDatesIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { ShellButton, Modal } from "../Shell/index.js";
import { t } from "@bookingcom/lingojs-core";
import { Calendar, CalendarSelect } from "../Calendar/index.js";
import useDate from "./useDate.js";
import styles from "./DateModal.module.css";
import { useFareCalendar } from "../../hooks/useFareCalendar/index.js";
import FareCalendarSelect from "../Calendar/FareCalendarSelect.js";
import useProfiler from "../../hooks/useFareCalendar/useProfiler.js";
import FareCalendarA11yAnnouncer from "../Calendar/FareCalendarA11yAnnouncer.js";
const errorId = "date_input_error";
const DateModal = (props) => {
    const { segmentIndex } = props;
    const { errorMessage, from, getTextNights, i18n, isDateSelected, isRoundTrip, isMultiStop, setFromTo, text, textPlaceholder, to, trackV2, isFareCalendarEnabled, isFareCalendarUIEnabled, sheetcontainerTitle, onTrackSBExperimentStage, onTrackSBExperiment, 
    /* start - flights_web_fare_calendar_v3 */
    isMobile, isSearchResults
    /* end - flights_web_fare_calendar_v3 */
     } = useDate({ segmentIndex });
    const buttonRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [fromKeayboard, setFromKeayboard] = useState(false);
    const [selectedDate, setSelectedDate] = useState({ from: from, to: to });
    const { data, boundsData, isLoading, selectedPrice, selectedPriceText, currencyText, selectedDatesText, a11yAnnounceData, etTrackingHelper, getSelectedFareRange, onChangeSelectedDates, onOpenCalendar, onCloseCalendar, onCalendarNavigate, onCalendarKeyboardNavigate } = useFareCalendar(segmentIndex);
    const { isSolo, isCouple, isFamily } = useProfiler();
    const handleOnSelectDateChange = useCallback(({ from, to }) => {
        setSelectedDate({ from, to });
    }, []);
    const handleOnDateChange = useCallback((data) => {
        const { from, to } = data;
        isFareCalendarEnabled && onChangeSelectedDates({ departureDate: from, returnDate: to });
        setSelectedDate({ from, to });
    }, [isFareCalendarEnabled, onChangeSelectedDates]);
    const closeModal = useCallback(() => {
        setShowModal(false);
        isFareCalendarEnabled && onCloseCalendar();
        buttonRef.current?.focus();
    }, [isFareCalendarEnabled, onCloseCalendar]);
    const handleOnClick = useCallback((e) => {
        e.stopPropagation();
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        const isFromKeyboard = e.nativeEvent.isTrusted && e.nativeEvent.detail === 0 && !e.nativeEvent["pointerType"];
        setFromKeayboard(isFromKeyboard);
        setShowModal(true);
        isFareCalendarEnabled && onOpenCalendar({ departureDate: from, returnDate: to });
        trackV2("click_calendar");
        isSearchResults && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 2 });
        isSearchResults &&
            !isRoundTrip &&
            !isMultiStop &&
            onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 3 });
        isSearchResults && isRoundTrip && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 4 });
        isSearchResults && isSolo() && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 5 });
        isSearchResults && isCouple() && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 6 });
        isSearchResults && isFamily() && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 7 });
        isSearchResults && !isMobile && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 8 });
        isSearchResults && isMobile && onTrackSBExperiment({ name: etTrackingHelper.etName, stage: 9 });
    }, [
        etTrackingHelper.etName,
        from,
        isCouple,
        isFamily,
        isFareCalendarEnabled,
        isMultiStop,
        isRoundTrip,
        isSolo,
        onOpenCalendar,
        onTrackSBExperiment,
        to,
        trackV2,
        isMobile
    ]);
    const handleOnClose = useCallback(() => {
        closeModal();
        setFromKeayboard(false);
    }, [closeModal]);
    const handleOnDoneClick = useCallback(() => {
        closeModal();
        setFromKeayboard(false);
        trackV2("click_calendar_done");
        const fareRange = getSelectedFareRange(selectedDate.from, selectedDate.to);
        setFromTo({ from: selectedDate.from, to: selectedDate.to, fareRange });
    }, [closeModal, getSelectedFareRange, selectedDate.from, selectedDate.to, setFromTo, trackV2]);
    const disabledCTA = useMemo(() => {
        const { from, to } = selectedDate;
        return isRoundTrip ? !from || !to : !from;
    }, [isRoundTrip, selectedDate]);
    useEffect(() => {
        setSelectedDate({ from: from, to: to });
    }, [from, to, setSelectedDate]);
    const currencyContent = useMemo(() => {
        if (isFareCalendarUIEnabled && !currencyText && isLoading) {
            return React.createElement(SkeletonLoader, { variant: "title", width: "50%" });
        }
        if (currencyText) {
            return currencyText;
        }
        return undefined;
    }, [currencyText, isFareCalendarUIEnabled, isLoading]);
    const footerContent = useMemo(() => {
        if (!isFareCalendarUIEnabled)
            return;
        if (selectedPrice && selectedPriceText) {
            return (React.createElement(Stack, { gap: 0 },
                React.createElement(Text, { variant: "body_2", color: "neutral_alt" }, selectedPriceText),
                React.createElement(Text, { variant: "strong_1" }, selectedPrice)));
        }
        if (selectedDatesText) {
            return (React.createElement(Text, { variant: "body_2", color: "neutral_alt" }, selectedDatesText));
        }
        return undefined;
    }, [isFareCalendarUIEnabled, selectedDatesText, selectedPrice, selectedPriceText]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellButton, { icon: isDateSelected ? CalendarDatesIcon : CalendarIcon, errorMessage: errorMessage, errorVariant: "inline", ref: buttonRef, onClick: handleOnClick, placeholder: textPlaceholder, "data-ui-name": `button_date_segment_${segmentIndex}`, "data-ui-error": Boolean(errorMessage), size: "large", errorId: errorId }, text ? (React.createElement(Text, { tagName: "span", variant: "body_2" }, text)) : (React.createElement(Text, { tagName: "span", color: "neutral_alt", variant: "body_2" }, textPlaceholder))),
        React.createElement(Modal, { active: showModal, keepMounted: false, onAfterClose: handleOnClose, onClose: handleOnClose, onCloseTrigger: handleOnClose, onAfterOpen: () => {
                onTrackSBExperimentStage({ name: "flights_web_mdot_solo_sb_dates_input_copy", stage: 1 });
            }, position: "fullScreen", title: sheetcontainerTitle, subtitle: isFareCalendarUIEnabled ? currencyContent : undefined, className: styles.modalFix, footer: React.createElement(ActionBar, { topContent: isFareCalendarUIEnabled ? undefined : getTextNights(selectedDate), button: {
                    wide: true,
                    onClick: handleOnDoneClick,
                    disabled: disabledCTA,
                    text: isFareCalendarUIEnabled
                        ? isRoundTrip
                            ? i18n.trans(t("flights_mdot_fare_calendar_rt_cta"))
                            : i18n.trans(t("flights_mdot_fare_calendar_one_way_cta"))
                        : i18n.trans(t("flights_action_done")),
                    size: "large",
                    attributes: {
                        "data-ui-name": "button_calendar_action_bar_done"
                    }
                } }, footerContent) },
            fromKeayboard ? (isFareCalendarEnabled ? (React.createElement(FareCalendarSelect, { segmentIndex: segmentIndex, onDateChange: handleOnSelectDateChange, onFareCalendarDateChange: onChangeSelectedDates, fareCalendarData: boundsData, onCalendarNavigate: onCalendarKeyboardNavigate })) : (React.createElement(CalendarSelect, { onDateChange: handleOnDateChange, segmentIndex: segmentIndex }))) : (React.createElement(Calendar, { mode: "vertical", onDateChange: handleOnDateChange, segmentIndex: segmentIndex, className: styles.calendar, fareCalendarData: isFareCalendarEnabled ? data : undefined, fareCalendarLoading: isFareCalendarEnabled ? isLoading : undefined, onCalendarNavigate: isFareCalendarEnabled ? onCalendarNavigate : undefined })),
            a11yAnnounceData && (React.createElement(FareCalendarA11yAnnouncer, { status: a11yAnnounceData.status, lowestPricesData: a11yAnnounceData.lowestPricesData })))));
};
export default DateModal;
