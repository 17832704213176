import React from "react";
import { Stack } from "@bookingcom/bui-react";
import { Breakdown } from "./Breakdown";
import { Installments, HiddenFees, Atol } from "./banners";
import {
  useUnifiedPriceBreakdownCheckoutController,
  type UnifiedPriceBreakdownCheckoutControllerProps
} from "./useUnifiedPriceBreakdown.Checkout.controller";

export type UnifiedPriceBreakdownCheckoutProps = UnifiedPriceBreakdownCheckoutControllerProps;

export function UnifiedPriceBreakdownCheckout(props: UnifiedPriceBreakdownCheckoutProps) {
  const { addedItems, isHungaryDepartingRyanAir, isWithDiscount, unifiedPriceBreakdown } =
    useUnifiedPriceBreakdownCheckoutController(props);

  if (!unifiedPriceBreakdown) return null;

  return (
    <Stack gap={4}>
      <Breakdown
        {...unifiedPriceBreakdown}
        addedItems={addedItems}
        isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
        isWithDiscount={isWithDiscount} // flights_web_unified_price_breakdown
      />
      <Atol />
      <HiddenFees />
      <Installments />
    </Stack>
  );
}
