import React from "react";
import { Stack } from "@bookingcom/bui-react";
import { TimelineLegLocation } from "../TimelineLegLocation/index.js";
import styles from "./styles.module.css";
export const TimelineLeg = ({ departure, arrival, contentBetweenDepartureAndArrival, contentOnSide, className, attributes }) => {
    return (React.createElement(Stack, { direction: "row", gap: 8, alignItems: "center", className: className, attributes: attributes },
        React.createElement("div", { className: styles.mainColumn },
            React.createElement("div", { className: styles.departureHolder },
                React.createElement("div", { className: styles.connector }),
                React.createElement(TimelineLegLocation, { ...departure }),
                contentBetweenDepartureAndArrival),
            React.createElement("div", { className: styles.arrivalHolder },
                React.createElement(TimelineLegLocation, { ...arrival }))),
        contentOnSide && React.createElement("div", { className: styles.sideColumn }, contentOnSide)));
};
