import { createTrackingUtils } from "../index";
import useProfiler from "hooks/useProfiler";
import { useEffect } from "react";

const utils = createTrackingUtils({
  name: "flights_web_sr_gen_ai_summary_v2_ddot",
  stages: {
    direct: 2,
    meta: 3,
    us: 4,
    solo: 5,
    nonSolo: 6,
    children: 7
  },
  goals: {
    ctaClick: 1,
    loading: 2,
    success: 3,
    error: 4
  },
  defaultStage: 1
});

const useTrackStages = (isEligible: boolean) => {
  const { stages } = utils;
  const { isDirect, isMeta, isAmerican, isSolo, isWithKids } = useProfiler();

  useEffect(() => {
    if (!isEligible) {
      return;
    }

    isDirect() && stages.direct();
    isMeta() && stages.meta();
    isAmerican() && stages.us();
    isSolo() ? stages.solo() : stages.nonSolo();
    isWithKids() && stages.children();
  }, [isEligible, stages, isDirect, isMeta, isAmerican, isSolo, isWithKids]);
};

export default { ...utils, useTrackStages };
