import React, { useEffect, useRef } from "react";
import { Spinner, Stack, Text } from "@bookingcom/bui-react";
import { Breakdown } from "./Breakdown";
import { Installments, Atol } from "./banners";
import {
  useUnifiedPriceBreakdownFlightCardPriceController,
  type UnifiedPriceBreakdownFlightCardPriceControllerProps
} from "./useUnifiedPriceBreakdown.FlightCardPrice.controller";

export type UnifiedPriceBreakdownFlightCardPriceProps = UnifiedPriceBreakdownFlightCardPriceControllerProps;

export function UnifiedPriceBreakdownFlightCardPrice(props: UnifiedPriceBreakdownFlightCardPriceProps) {
  const {
    isHungaryDepartingRyanAir,
    isWithDiscount,
    isFlightDetailsLoading,
    unifiedPriceBreakdown,
    flightDetailsLoadingText,
    addedItems
  } = useUnifiedPriceBreakdownFlightCardPriceController(props);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // force BUI popover to re-calculate position
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      window.dispatchEvent(new Event("resize"));
    });
    resizeObserver.observe(wrapperRef.current);
    return () => resizeObserver.disconnect();
  }, [wrapperRef]);

  if (!unifiedPriceBreakdown) return null;

  return (
    <Stack gap={4} attributes={{ ref: wrapperRef }}>
      {isFlightDetailsLoading ? (
        <>
          <Stack direction="row">
            <Spinner size="small" />
            <Text>{flightDetailsLoadingText}</Text>
          </Stack>
          <Breakdown
            addedItems={[]}
            items={[]}
            footerSections={{}}
            isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
            price={unifiedPriceBreakdown.price}
            isWithDiscount={isWithDiscount} // flights_web_unified_price_breakdown
          />
        </>
      ) : (
        <Breakdown
          {...unifiedPriceBreakdown}
          isHungaryDepartingRyanAir={isHungaryDepartingRyanAir}
          isWithDiscount={isWithDiscount} // flights_web_unified_price_breakdown
          addedItems={addedItems}
        />
      )}
      <Atol />
      <Installments />
    </Stack>
  );
}
