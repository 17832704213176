import { t, useI18n } from "@bookingcom/lingojs-react";
import useSearchFiltersController from "../../useSearchFiltersController";
import { useState } from "react";
import { ArrowNavUpIcon, ArrowNavDownIcon } from "@bookingcom/bui-assets-react/streamline/index.js";

const limit = 5;

export const useAirlinesFilterController = () => {
  const i18n = useI18n();
  const { airlines } = useSearchFiltersController();
  const [trucanteOptions, setTruncateOptions] = useState(true);

  const handleOnChange = (checked: boolean, value: string) => {
    airlines.set(checked ? [...airlines.value, value] : airlines.value.filter((v) => v !== value));
  };

  const handleSelectSingleOption = (value: string) => {
    airlines.set([value]);
  };

  return {
    isFilterAvailable: airlines.options.length > 0,
    title: i18n.trans(t("flights_filter_airlines")),
    resetLabel: i18n.trans(t("flights_filter_reset")),
    options: airlines.options,
    showReset: airlines.touched,
    onClickReset: airlines.reset,
    value: airlines.value,
    onChange: handleOnChange,
    onSelectSingleOption: handleSelectSingleOption,
    optionsLimit: limit,
    optionsTogglerLabel: trucanteOptions
      ? i18n.trans(t("flights_filters_show_more_action"))
      : i18n.trans(t("flights_filters_show_less_action")),
    optionsTogglerIcon: trucanteOptions ? ArrowNavDownIcon : ArrowNavUpIcon,
    singleOptionLabel: i18n.trans(t("flights_filters_airline_only_select")),
    onTogglerClick: () => setTruncateOptions((s) => !s),
    areOptionsTrucanted: trucanteOptions,
    selectAllTogglerLabel: i18n.trans(t("flights_select_all_toggle")),
    deselectAllTogglerLabel: i18n.trans(t("flights_deselect_all_toggle")),
    onSelectAll: () => airlines.set(airlines.options.map((a) => a.value)),
    isSelectAllDisabled: !airlines.touched,
    onDeselectAll: () => airlines.set([]),
    isDeselectAllDisabled: airlines.value.length === 0
  };
};
