import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import useTrackViAA from "hooks/useTrackViAA";
import useIsInViewport from "hooks/useIsInViewport";
import flights_mdotvalue_sr_retain_scroll_on_close_flight_details from "utils/experiments/mdotvalue/flights_mdotvalue_sr_retain_scroll_on_close_flight_details";
import { trackCustomGoal } from "utils/et";
import { UIFlightData } from "@flights/types";
import { SBSearchType } from "@bookingcom/flights-searchbox";
import { FrameProps } from "../Frame";
import flights_web_reduce_spacing_sr_ddot from "utils/experiments/funnel/flights_web_reduce_spacing_sr_ddot";
import { useStickySearchBoxExp } from "utils/experiments/mdotvalue/flights_mdotvalue_sr_sticky_search_box";

export type FlightCardControllerProps = {
  flight: UIFlightData;
  onClick: (flight: UIFlightData) => void;
  pricePerPerson: boolean;
  index: number;
  bigLogo?: boolean;
  searchType?: SBSearchType;
  mt?: FrameProps["mt"];
  mb?: FrameProps["mb"];
  /* start - flights_alternative_fares_for_sold_out_errors_web */
  isAlternativeFareCard?: boolean;
  forceHideLastAvailableSeats?: boolean;
  /* end - flights_alternative_fares_for_sold_out_errors_web */
};

export default function useFlightCardController(props: FlightCardControllerProps) {
  const { bigLogo, flight, forceHideLastAvailableSeats, index, isAlternativeFareCard, mb, mt, onClick, searchType } =
    props;
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const { trackScrollToThird, trackScrollToFifth } = useStickySearchBoxExp(); //@exp flights_mdotvalue_sr_sticky_search_box

  //@start flights_mdotvalue_sr_sticky_search_box flights_web_aa_virtual_interlining_tracking
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(ref.current);
  //@end flights_mdotvalue_sr_sticky_search_box flights_web_aa_virtual_interlining_tracking

  // flights_web_aa_virtual_interlining_tracking -- start
  const trackViAA = useTrackViAA();
  useEffect(() => {
    trackViAA.ViAAInStage1();
    if (!isInViewport) return;
    trackViAA.ViAAInStage2(flight);
  }, [isInViewport, trackViAA, flight]);
  // flights_web_aa_virtual_interlining_tracking -- end

  // start - flights_mdotvalue_sr_retain_scroll_on_close_flight_details
  const [isPastFirstCard, setPastFirstCard] = useState(false);
  useEffect(() => {
    if (index > 0 || isInViewport || isPastFirstCard) {
      return;
    }

    // We are detecting if the element is out of the viewport after scrolling.
    // useIsInViewport returns false by default,
    // thus on page load it tells us it is not in the viewport.
    const elementBoundingRect = ref.current?.getBoundingClientRect();
    const _isPastFirstCard = Boolean(elementBoundingRect && elementBoundingRect.bottom < 0);
    setPastFirstCard(_isPastFirstCard);
  }, [isInViewport, index, isPastFirstCard]);
  const trackMdotValueSrRetainScrollOnCloseFlightDetails =
    flights_mdotvalue_sr_retain_scroll_on_close_flight_details.useTracking(index, isPastFirstCard);
  // end - flights_mdotvalue_sr_retain_scroll_on_close_flight_details

  //@start flights_mdotvalue_sr_sticky_search_box
  useEffect(() => {
    if (isInViewport && index === 3) {
      trackScrollToThird();
    } else if (isInViewport && index === 5) {
      trackScrollToFifth();
    }
  }, [index, isInViewport, trackScrollToFifth, trackScrollToThird]);
  //@end flights_mdotvalue_sr_sticky_search_box

  const handleOnClick = useCallback(() => {
    if (isMobile) {
      trackCustomGoal("flight_web_sr_traffic_aa_mdot", 1);
      trackMdotValueSrRetainScrollOnCloseFlightDetails(); // flights_mdotvalue_sr_retain_scroll_on_close_flight_details
    }
    trackViAA.ViAAInStage3(flight);
    trackViAA.ViAAInStage4(flight);
    onClick(flight);
  }, [flight, isMobile, onClick, trackViAA, trackMdotValueSrRetainScrollOnCloseFlightDetails]);

  const ariaLabel = useMemo(() => i18n.trans(t("a11y_flights_search_card_begin")), [i18n]);

  /** start - flights_web_reduce_spacing_sr_ddot */
  const calculateMargins = () => {
    if (!isMobile && flights_web_reduce_spacing_sr_ddot.trackWithDefaultStage()) {
      return {
        mt: 0,
        mb: 0
      };
    }

    return {
      mt: 2,
      mb: 4
    };
  };

  const margins = calculateMargins();
  /** end - flights_web_reduce_spacing_sr_ddot */

  return {
    ariaLabel,
    bigLogo,
    flight,
    forceHideLastAvailableSeats,
    handleOnClick,
    index,
    isAlternativeFareCard,
    mb: mb ?? margins.mb,
    mt: mt ?? margins.mt,
    ref,
    searchType
  };
}
