import { useSelector } from "react-redux";
import { createTrackingUtils } from "..";
import { getUserInfo } from "store/user/selectors";
import { useEffect } from "react";
import { grumble } from "utils/flog";
import { isFeatureRunningClientSide } from "@flights/web-api-utils-universal";

/**
 * We are using these utils so that we can test the default stage 1 tracking
 */
const utils = createTrackingUtils({
  name: "flights_web_cat_genius_campaign_www",
  stages: {
    allGenius: 1,
    geniusLevelOne: 2,
    geniusLevelTwo: 3,
    geniusLevelThree: 4
  },
  defaultStage: 1,
  goals: {
    open_modal_1: 1,
    all_genius_sr_2: 2,
    genius_level_one_sr_3: 3,
    genius_level_two_sr_4: 4,
    genius_level_three_sr_5: 5
  },
  goalsWithValue: []
});

export function useTrackGeniusIndexStages(eligibleForExperiment: boolean) {
  const userInfo = useSelector(getUserInfo);

  useEffect(() => {
    if (!eligibleForExperiment) return;
    utils.stages.allGenius();

    switch (userInfo?.geniusLevel) {
      case 1:
        utils.stages.geniusLevelOne();
        break;
      case 2:
        utils.stages.geniusLevelTwo();
        break;
      case 3:
        utils.stages.geniusLevelThree();
        break;
      default:
        if (isFeatureRunningClientSide("FLIGHTS_WEB_CAT_STRANGE_GENIUS_LEVEL_INDEX_CAMPAIGN_LOG")) {
          grumble("Strange Genius level in flights_web_cat_genius_campaign_www experiment FE: ", {
            genuisLevel: userInfo?.geniusLevel
          });
        }
        break;
    }
  }, [userInfo, eligibleForExperiment]);
}

/**
 * Goals for checking intersection with Index page and Search Results page traffic
 */
export function useTrackGeniusSRGoals(eligibleForExperiment: boolean) {
  const userInfo = useSelector(getUserInfo);

  useEffect(() => {
    // Track intersection with Index page variant
    if (!eligibleForExperiment || !utils.trackWithDefaultStage()) return;
    utils.goals.all_genius_sr_2();

    switch (userInfo?.geniusLevel) {
      case 1:
        utils.goals.genius_level_one_sr_3();
        break;
      case 2:
        utils.goals.genius_level_two_sr_4();
        break;
      case 3:
        utils.goals.genius_level_three_sr_5();
        break;
      default:
        break;
    }
  }, [userInfo, eligibleForExperiment]);
}

export { utils as flights_web_cat_genius_campaign_www };
