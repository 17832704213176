import Debug from "debug";
import { ExperimentEngine } from "@bookingcom/experiment-tracking-clientside";
import * as watchEt from "./watch-et.js";
// import { grumble } from "./flog";
const debug = Debug("utils:etV2");
const stageTrackedExperiments = new Set();
const loggedExperimentsWithoutStage = new Set();
let getAsyncRequestContext;
// Be aware of process.env.BUILD_TARGET usage here. We import turbine which is a server side package only inside BUILD_TARGET check.
// Top level await should work here, and this should be a simple await import without async function. But this gives error in server for some reason.
// TODO: https://jira.booking.com/jira/browse/FLFETA-466 investigate top-level await
if (process.env.BUILD_TARGET === "server") {
    (async () => {
        const { getAsyncRequestContext: getContext } = await import("@flights/turbine");
        getAsyncRequestContext = getContext;
    })().catch(() => {
        throw new Error("Error while importing @flights/turbine");
    });
}
const etV2Fallback = {
    track: () => 0,
    stage: () => false,
    customGoal: () => false,
    goal: () => false,
    goalWithValue: () => false,
    updateStateBlobAndVariants: () => { },
    sinkData: () => { },
    trackWithDefaultStage: () => 0
};
const getExperimentEngineInstance = () => {
    if (process.env.BUILD_TARGET === "client") {
        return ExperimentEngine.getInstance({
            // Library implements debounce and sinks batches of tracking data.
            sinkUrl: `${window?.__GLOBAL_CONTEXT__?.apiPrefix || ""}/track/sink`,
            clientsidePayload: (typeof window !== "undefined" && window?.__GLOBAL_CONTEXT__?.clientsidePayload) || "",
            sinkTimeoutMs: 500
        });
    }
    else {
        return etV2Fallback;
    }
};
/**
 * @deprecated
 * Deprecated in favour of the trackWithDefaultStage method.
 * More info in the wiki https://gitlab.booking.com/flights/main/-/wikis/Migrating-to-new-client-side-tracking-library
 */
export const track = (hashedTagname) => {
    // reading variant from et daemon on the server to render correct version on frontend during SSR
    if (process.env.BUILD_TARGET === "server") {
        return getAsyncRequestContext()?.request.et?.trackExperiment(hashedTagname) || 0;
    }
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Tracking experiment", hashedTagname);
    watchEt.dispatchName(hashedTagname);
    // This is a temporary metric to see how many old experiments had tracking issues.
    setTimeout(() => {
        if (!stageTrackedExperiments.has(hashedTagname) && !loggedExperimentsWithoutStage.has(hashedTagname)) {
            //TODO: https://jira.booking.com/jira/browse/FLFETA-203 enable grumble back after migrating grumble
            //grumble(`Experiment ${hashedTagname} was tracked without a stage.`);
            loggedExperimentsWithoutStage.add(hashedTagname);
        }
    }, 100); // Small timeout to let effects resolve.
    return experimentEngineInstance.track(hashedTagname);
};
/**
 * Tracks a stage for a given experiment.
 * @param hashedTagname - The hashed experiment name
 * @param id - The stage ID to track
 * @returns Result of tracking the stage
 */
export const stage = (hashedTagname, id) => {
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Tracking stage", id, "for experiment", hashedTagname);
    watchEt.log([
        {
            type: "stage",
            name: hashedTagname,
            value: id
        }
    ]);
    stageTrackedExperiments.add(hashedTagname);
    return experimentEngineInstance.stage(hashedTagname, id);
};
/**
 * Tracks an experiment with a default stage.
 * @param hashedTagname - The hashed experiment name
 * @param defaultId - The default stage ID to track
 */
export const trackWithDefaultStage = (hashedTagname, defaultId) => {
    if (process.env.BUILD_TARGET === "server") {
        const et = getAsyncRequestContext()?.request.et;
        et?.trackExperimentStage(hashedTagname, defaultId);
        return et?.trackExperiment(hashedTagname) || 0;
    }
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Tracking experiment", hashedTagname, " with default stage ", defaultId);
    watchEt.log([
        {
            type: "stage",
            name: hashedTagname,
            value: defaultId
        }
    ]);
    experimentEngineInstance.stage(hashedTagname, defaultId);
    watchEt.dispatchName(hashedTagname);
    return experimentEngineInstance.track(hashedTagname);
};
/**
 * Tracks a custom goal for an experiment.
 * @param hashedTagname - The hashed experiment name
 * @param goal - The goal value to track
 */
export const customGoal = (hashedTagname, goal) => {
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Tracking custom goal", goal, "for experiment", hashedTagname);
    watchEt.log([
        {
            type: "customGoal",
            name: hashedTagname,
            value: goal
        }
    ]);
    return experimentEngineInstance.customGoal(hashedTagname, goal);
};
/**
 * Tracks a goal for an experiment.
 * @param goalName - The goal name to track
 */
export const goal = (goalName) => {
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Tracking goal", goalName);
    watchEt.log([
        {
            type: "goal",
            name: goalName
        }
    ]);
    return experimentEngineInstance.goal(goalName);
};
/**
 * Tracks a goal with a value for an experiment.
 * @param goalName - The goal name to track
 * @param value - The numeric value to track with the goal
 */
export const goalWithValue = (goalName, value) => {
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Tracking goal with value", goalName, "with value", value);
    watchEt.log([
        {
            type: "goalWithValue",
            name: goalName,
            value
        }
    ]);
    return experimentEngineInstance.goalWithValue(goalName, value);
};
/**
 * Updates the state blob and variants with a clientside payload.
 * @param clientsidePayload - The payload string to update with
 */
export const updateStateBlobAndVariants = (clientsidePayload) => {
    const experimentEngineInstance = getExperimentEngineInstance();
    debug("Updating state blob and variants with clientside payload", clientsidePayload);
    return experimentEngineInstance.updateStateBlobAndVariants(clientsidePayload);
};
export const sinkData = () => {
    const experimentEngineInstance = getExperimentEngineInstance();
    return experimentEngineInstance.sinkData();
};
