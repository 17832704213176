import { Actions, ActionTypes } from "./actions";
import { State, getInitialState } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.set:
      return {
        ...state,
        ...action.payload.searchBoxState
      };

    default:
      return state;
  }
};

export { reducer, getInitialState };
