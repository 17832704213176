import { UIFetchStatus, UIFlightData, UIClientFetchError } from "@flights/types";

export type State = {
  fetchStatus: UIFetchStatus;
  flights: UIFlightData[];
  error?: UIClientFetchError;
};

export const getInitialState = (): State => ({
  fetchStatus: "initial",
  flights: [],
  error: undefined
});
