import { UIPointOfSale, UIPrice } from "@flights/types";
import useGlobalContext from "./useGlobalContext";
import { useCallback } from "react";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import useTrackFlightsWebUseOfferPos from "./useTrackFlightsWebUseOfferPosExp";
import usePointOfSale from "./usePointOfSale";
import { pricingUtils, FormatOptions, isFeatureRunningClientSide } from "@flights/web-api-utils-universal";
import getRouteName from "utils/getRouteName";

const useFormatPrice = (locale?: string) => {
  const { ipCountry, locale: contextLocale } = useGlobalContext();
  const i18n = useI18n() as I18nChildContext;
  const flightsWebUseOfferPosExp = useTrackFlightsWebUseOfferPos();
  const useNativeFormatting = isFeatureRunningClientSide("FLIGHTS_WEB_USE_NATIVE_CURRENCY_FORMATTING");
  let localeCountry = ipCountry;
  const routeName = getRouteName(window.location.pathname);

  const pointOfSale = usePointOfSale();

  // Exclude Postbooking and Confirmation pages when formatting prices
  // based on locale selection.
  const shouldFormatWithLocale = !routeName.includes("pb-") && routeName !== "confirmation";

  if (flightsWebUseOfferPosExp.isVariant && pointOfSale) {
    localeCountry = pointOfSale.toLowerCase() as UIPointOfSale;
  }

  const formatPrice = useCallback(
    (price: UIPrice, options?: FormatOptions) => {
      const localeValue = shouldFormatWithLocale ? locale || contextLocale || "en-gb" : localeCountry || locale;

      return pricingUtils.formatPrice(price, localeValue, { ...options, useNativeFormatting });
    },
    [contextLocale, locale, localeCountry, shouldFormatWithLocale, useNativeFormatting]
  );

  const priceOrFree = (price: UIPrice) => {
    return pricingUtils.priceIsZero(price) ? i18n.trans(t("flights_free_price")) : formatPrice(price);
  };

  const startingPrice = (price: UIPrice) => {
    return pricingUtils.priceIsZero(price)
      ? i18n.trans(t("flights_free_price"))
      : i18n.trans(t("flights_starting_price", { variables: { lowest_price: formatPrice(price) } }));
  };

  const plusPrice = (price: UIPrice) => {
    return pricingUtils.priceIsZero(price)
      ? i18n.trans(t("flights_free_price"))
      : i18n.trans(t("flights_ancillary_price_total", { variables: { total_price: formatPrice(price) } }));
  };

  return {
    priceToValue: pricingUtils.priceToValue,
    priceIsZero: pricingUtils.priceIsZero,
    formatPrice,
    priceOrFree,
    startingPrice,
    plusPrice,
    formatAbbreviatedPrice: pricingUtils.formatAbbreviatedPrice
  };
};

export default useFormatPrice;
