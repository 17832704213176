import { t, useI18n } from "@bookingcom/lingojs-react";
import useSearchFiltersController from "../../useSearchFiltersController";
import { useEffect, useState } from "react";

export const useDurationFilterController = () => {
  const i18n = useI18n();
  const { duration } = useSearchFiltersController();
  const [value, setValue] = useState(duration.value);

  useEffect(() => setValue(duration.value), [duration.value]);

  return {
    isFilterAvailable: duration.options.max !== duration.options.min,
    title: i18n.trans(t("flights_filters_duration_title")),
    resetLabel: i18n.trans(t("flights_filter_reset")),
    showReset: duration.touched,
    onClickReset: duration.reset,
    min: duration.options.min,
    max: duration.options.max,
    value,
    ariaLabel: i18n.trans(t("flights_filters_duration_travel_time_title")),
    label: i18n.trans(t("flights_filters_duration_travel_time_title")),
    onChange: (value: number) => setValue(value),
    onChangeCommit: (value: number) => duration.set(value),
    renderTooltipValue: (value: number) =>
      i18n.trans(
        t("flights_filters_duration_hours", { variables: { num_exception: value, num_hours: value.toString() } })
      ),
    renderValue: (value: number) =>
      i18n.trans(
        t("flights_filters_duration_hours", {
          variables: { num_exception: value, num_hours: value.toString() }
        })
      )
  };
};
