import React, { forwardRef, HTMLAttributes, Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import SearchToolbar from "../SearchToolbar";
import { useI18n } from "@bookingcom/lingojs-react";
import useLocaleContext from "hooks/useLocaleContext";
import useSearchbox from "hooks/useSearchbox";
import { Container } from "@bookingcom/bui-react";
import {
  SBSearchCallbackParams,
  SearchboxController,
  SearchBoxVerticalCollapsible
} from "@bookingcom/flights-searchbox";
import styles from "./SearchHeader.module.css";
import { useStickySearchBoxExp } from "../../../utils/experiments/mdotvalue/flights_mdotvalue_sr_sticky_search_box";
import useUserAgent from "../../../hooks/useUserAgent";
import throttle from "../../../utils/throttle";
import { mcn } from "utils/mergeClassnames";

const SearchHeader = ({
  hideToolbar = false,
  onSearch
}: {
  hideToolbar?: boolean;
  onSearch?: (params: SBSearchCallbackParams) => void;
}) => {
  const i18n = useI18n();
  const { isRTL } = useLocaleContext();
  const searchBoxProps = useSearchbox();

  //@start flights_mdotvalue_sr_sticky_search_box
  const { isMobile } = useUserAgent();
  const searchHeaderRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const collapseSearchBox = useRef<() => void>();
  const { getVariant, trackFlightsSearchBoxOpen } = useStickySearchBoxExp(isSticky);

  const isStickySearchBoxExpRunning = getVariant() > 0;

  const Wrapper = useMemo(() => {
    return forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(wrapper);

    function wrapper({ children, ...props }: HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) {
      return (
        <>
          <div ref={ref}></div>
          {isStickySearchBoxExpRunning ? <div {...props}>{children}</div> : children}
        </>
      );
    }
  }, [isStickySearchBoxExpRunning]);

  useEffect(() => {
    if (!isMobile) return;

    const scrollHandler = throttle(() => {
      const element = searchHeaderRef.current;
      const top = element?.getBoundingClientRect().top;
      const isPastSearchHeader = top !== undefined && top <= 0;

      setIsSticky(isPastSearchHeader);
    }, 100);

    addEventListener("scroll", scrollHandler);

    return () => {
      removeEventListener("scroll", scrollHandler);
    };
  }, [isMobile]);

  useEffect(() => {
    if (isSticky && collapseSearchBox.current && isStickySearchBoxExpRunning) {
      collapseSearchBox.current();
    }
  }, [isSticky, isStickySearchBoxExpRunning]);

  const onExpand = useCallback(() => {
    trackFlightsSearchBoxOpen();
    if (isStickySearchBoxExpRunning && isSticky) {
      scrollTo(0, 0);
    }
  }, [isStickySearchBoxExpRunning, isSticky, trackFlightsSearchBoxOpen]);

  const setCollapseFn = useCallback(
    (collapseFn: () => void) => {
      if (isStickySearchBoxExpRunning) {
        collapseSearchBox.current = collapseFn;
      }
    },
    [isStickySearchBoxExpRunning]
  );
  //@end flights_mdotvalue_sr_sticky_search_box

  const verticalCollapsibleSearchbox = useCallback(
    () => (
      <SearchBoxVerticalCollapsible
        //@start flights_mdotvalue_sr_sticky_search_box
        onExpand={onExpand}
        setCollapseFn={setCollapseFn}
        isMobile={isMobile} // only for tracking stage
        //@end flights_mdotvalue_sr_sticky_search_box
      />
    ),
    [onExpand, setCollapseFn, isMobile]
  );

  return (
    <Wrapper
      className={mcn(
        styles.searchHeader,
        //@start flights_mdotvalue_sr_sticky_search_box
        isStickySearchBoxExpRunning ? styles.searchHeaderSticky : "",
        isStickySearchBoxExpRunning && isSticky ? styles.searchHeaderIsStuck : ""
        //@end flights_mdotvalue_sr_sticky_search_box
      )}
      ref={searchHeaderRef}
    >
      <div className={styles.minifiedSearchBox}>
        <div className={styles.background} />
        <Container>
          <SearchboxController
            i18n={i18n}
            {...searchBoxProps}
            isRTL={isRTL}
            onSearch={(searchParams) => {
              if (onSearch && typeof onSearch === "function") {
                onSearch(searchParams);
              } else {
                searchBoxProps.onSearch(searchParams);
              }
            }}
          >
            {verticalCollapsibleSearchbox}
          </SearchboxController>
        </Container>
      </div>
      {hideToolbar ? null : <SearchToolbar />}
    </Wrapper>
  );
};
export default SearchHeader;
