import { useMemo } from "react";
import { UIBrandedFare, UIFlightData } from "@flights/types";
import { localizationUtils } from "@flights/web-api-utils-universal";
import { useFlightOfferWithDiscount } from "hooks/useFlightOfferWithDiscount";

export type UnifiedPriceBreakdownFarePriceControllerProps = {
  fareOffer?: UIBrandedFare;
  baseOffer?: UIFlightData;
};

export function useUnifiedPriceBreakdownFarePriceController(props: UnifiedPriceBreakdownFarePriceControllerProps) {
  const { baseOffer, fareOffer } = props;
  const segments = useMemo(() => baseOffer?.segments, [baseOffer]);
  const isHungaryDepartingRyanAir = useMemo(
    () => localizationUtils.isHungaryDepartingRyanAirFlight(segments),
    [segments]
  );
  const unifiedPriceBreakdown = useMemo(
    () => fareOffer?.unifiedPriceBreakdown || baseOffer?.unifiedPriceBreakdown,
    [baseOffer, fareOffer]
  );

  const { isWithDiscount } = useFlightOfferWithDiscount(baseOffer); // flights_web_unified_price_breakdown

  return {
    unifiedPriceBreakdown,
    isHungaryDepartingRyanAir,
    isWithDiscount // flights_web_unified_price_breakdown
  };
}
