import React from "react";
import styles from "./SearchFilters.module.css";
import { useSelector } from "react-redux";
import { getSearchAvailableFilters } from "store/searchResults/selectors";
import { Stack } from "@bookingcom/bui-react";
import { StopsFilterMobile } from "./FiltersSplit/StopsFilter";
import { AirlinesFilterMobile } from "./FiltersSplit/AirlinesFilter";
import { FlightTimesFilterMobile } from "./FiltersSplit/FlightTimesFilter";
import { DurationFilter } from "./FiltersSplit/DurationFilter";
import { BudgetPerAdultFilter } from "./FiltersSplit/BudgetPerAdultFilter";

const SearchFiltersMobile = () => {
  const availableFilters = useSelector(getSearchAvailableFilters);

  return (
    <div className={styles.contentWrapper}>
      {Object.keys(availableFilters).length > 0 ? (
        <Stack gap={8}>
          <StopsFilterMobile />
          <BudgetPerAdultFilter />
          <AirlinesFilterMobile />
          <FlightTimesFilterMobile />
          <DurationFilter />
        </Stack>
      ) : null}
    </div>
  );
};

export default SearchFiltersMobile;
