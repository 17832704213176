import React from "react";
import FlightCardBoundMobile from "./components/FlightCardBound";
import FlightCardBoundDesktop from "./components/FlightCardBound.desktop";
import Frame from "../Frame";
import useFlightCardController, { FlightCardControllerProps } from "./useFlightCard.controller";

const _FlightCard = (props: FlightCardControllerProps) => {
  const {
    ariaLabel,
    bigLogo,
    flight,
    forceHideLastAvailableSeats,
    handleOnClick,
    index,
    isAlternativeFareCard,
    mb,
    mt,
    ref,
    searchType
  } = useFlightCardController(props);
  const FlightCard = isAlternativeFareCard ? FlightCardBoundMobile : FlightCardBoundDesktop;

  return (
    <Frame
      mt={mt !== undefined ? mt : 2}
      mb={mb !== undefined ? mb : 4}
      elementRef={ref}
      attributes={{ role: "group", "aria-label": ariaLabel }}
    >
      <FlightCard
        flight={flight}
        onClick={handleOnClick}
        pricePerPerson
        index={index}
        bigLogo={bigLogo}
        searchType={searchType}
        /* start - flights_alternative_fares_for_sold_out_errors_web */
        forceHideLastAvailableSeats={forceHideLastAvailableSeats}
        isAlternativeFareCard={isAlternativeFareCard}
        /* end - flights_alternative_fares_for_sold_out_errors_web */
      />
    </Frame>
  );
};

const FlightCard = React.memo(_FlightCard);

export default FlightCard;
