import React, { FC } from "react";

import { useSelector } from "react-redux";

import { getFetchStatus, getSearchAiSummaryText } from "store/searchAiSummary/selectors";
import GenAiSearchSummary from "components/elements/GenAiSearchSummary";

import useAiSummaryController from "./useAiSummary.controller";
import styles from "./AiSummary.desktop.module.css";
import flights_web_sr_gen_ai_summary_v2_ddot from "utils/experiments/funnel/flights_web_sr_gen_ai_summary_v2_ddot";

const AiSummaryDesktop: FC = () => {
  useAiSummaryController();
  const fetchStatus = useSelector(getFetchStatus);
  const summaryText = useSelector(getSearchAiSummaryText);

  return (
    <GenAiSearchSummary
      modalText={summaryText || ""}
      variant="card"
      className={styles.root}
      isLoading={fetchStatus === "loading"}
      isError={fetchStatus === "failed"}
      onCtaClick={() => {
        flights_web_sr_gen_ai_summary_v2_ddot.goals.ctaClick();
        fetchStatus === "loading" && flights_web_sr_gen_ai_summary_v2_ddot.goals.loading();
        fetchStatus === "success" && flights_web_sr_gen_ai_summary_v2_ddot.goals.success();
        fetchStatus === "failed" && flights_web_sr_gen_ai_summary_v2_ddot.goals.error();
      }}
    />
  );
};

export default AiSummaryDesktop;
