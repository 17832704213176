import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "../index";
import { AvroSchemaMapping } from "@flights/internal-events-types";

const utils = createTrackingUtils({
  name: "flights_ace_web_post_booking_et_goals_aa",
  defaultStage: 1,
  stages: {
    post_booking_pages: 1, // main
    confirmation_page: 2,
    order_details_page: 3,
    desktop: 4,
    mobile: 5
  },
  goals: {
    confirmation_page_viewed: 1,
    confirmation_page_loaded: 2,
    order_details_page_viewed: 3,
    order_details_page_loaded: 4,
    order_details_page_loading_error: 5
  },
  goalsWithValue: []
});

export const useTrackPostBookingETGoals = (contextName: keyof AvroSchemaMapping) => {
  const { isMobile } = useProfiler();

  utils.stages.post_booking_pages();

  if (contextName === "pb_order_details") {
    utils.stages.order_details_page();
  } else if (contextName === "confirmation") {
    utils.stages.confirmation_page();
  }

  if (isMobile()) {
    utils.stages.mobile();
  } else {
    utils.stages.desktop();
  }
};
export default utils;
