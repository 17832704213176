import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_flex_and_cfar_incremental_price_only",
  stages: {
    main: 1,
    ddot: 2,
    mdot: 3,
    meta: 4,
    has_cfar: 5,
    no_cfar: 6,
    multiple_travellers: 7,
    single_traveller: 8,
    flex_recommendation_badge: 9
  },
  goals: {
    clicks_card_price_info: 1, // flex or cfar only
    clicks_price_breakdown: 2,
    back_from_pax: 3
  },
  goalsWithValue: [],
  defaultStage: 1
});

const trackStages = (
  isMobile: boolean,
  isMetaUser: boolean,
  showCfar: boolean,
  isRecommended: boolean,
  travellers: number
) => {
  isMobile ? utils.stages.mdot() : utils.stages.ddot();
  if (isMetaUser) utils.stages.meta();
  showCfar ? utils.stages.has_cfar() : utils.stages.no_cfar();
  if (isRecommended) utils.stages.flex_recommendation_badge();
  if (travellers === 1) utils.stages.single_traveller();
  if (travellers > 1) utils.stages.multiple_travellers();
};

export default { trackStages, ...utils };
