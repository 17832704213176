import React, { memo, useRef } from "react";
import FareCardDesktop from "../../../../components/elements/FlightCard/components/FareCard.desktop";
import { t } from "@bookingcom/lingojs-core";
import { Carousel, Divider, Grid, Spinner, Stack } from "@bookingcom/bui-react";
import { UIFlightData } from "@flights/types";
import { SkeletonFlightCard } from "../../../../components/elements/Skeleton/SkeletonFlightCard";
import { useI18n } from "@bookingcom/lingojs-react";
import { convertFlightToFare } from "../utils";
import useMeasureBrandedFaresWaitTime from "../../../hooks/useMeasureBrandedFaresWaitTime";
import useBrandedFares from "../../../hooks/useBrandedFares";
import Frame from "components/elements/Frame";
import useTrackFirstFarePriceMismatch from "branded-fares/hooks/useTrackFirstFarePriceMismatch";
import useEqualRowHeight from "hooks/useEqualRowHeight";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";

type Props = {
  flight: UIFlightData;
  isCompact?: boolean;
};

const _FareSelectorDesktop = ({ flight, isCompact }: Props) => {
  const i18n = useI18n();
  const { fetchStatus, brandedFareOffers } = useBrandedFares(flight.token);
  const hasBrandedFareOffers = !!brandedFareOffers && brandedFareOffers?.length > 0;
  const hasPrefetchedBrandedFareOffers =
    !flight.requestableBrandedFares && !!flight.brandedFareOffers && flight.brandedFareOffers?.length > 0;
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasSeenLoading, setHasSeenLoading] = React.useState(false);

  useEqualRowHeight(containerRef, "data-fare-card-row", [brandedFareOffers, fetchStatus]);
  useMeasureBrandedFaresWaitTime(fetchStatus, hasBrandedFareOffers);
  useTrackFirstFarePriceMismatch(flight, brandedFareOffers);

  if (fetchStatus === "loading" && !hasBrandedFareOffers && !hasSeenLoading) {
    setHasSeenLoading(true);
    flights_apex_web_bf_on_sr_loading_message.stages.ddot();
  }

  if (fetchStatus === "success" && hasSeenLoading) {
    if (!hasBrandedFareOffers) {
      flights_apex_web_bf_on_sr_loading_message.stages.false_positive();
    } else flights_apex_web_bf_on_sr_loading_message.stages.not_false_positive();
    flights_apex_web_bf_on_sr_loading_message.goals.waited_until_loading_is_done();
  }

  const brandedFareOffersToRender = hasBrandedFareOffers ? brandedFareOffers : flight.brandedFareOffers;

  return (
    <div ref={containerRef} id="flights-fare-selector">
      <Divider />
      <Frame
        direction="row"
        m={4}
        attributes={{
          style: { maxWidth: "682px" }
        }}
      >
        {hasBrandedFareOffers || hasPrefetchedBrandedFareOffers ? (
          <div style={{ width: "100%" }}>
            <Carousel
              size={"larger"}
              nextButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_next_button_aria_label"))}
              previousButtonAriaLabel={i18n.trans(t("a11y_flights_fare_switcher_carousel_previous_button_aria_label"))}
            >
              {brandedFareOffersToRender?.map((brandedFareOffer, i) => (
                <FareCardDesktop
                  key={brandedFareOffer.token}
                  baseOffer={flight}
                  fareOffer={brandedFareOffer}
                  index={i}
                  numberOfOffers={brandedFareOffersToRender?.length}
                  ancillaries={flight.ancillaries}
                  isCompact={isCompact}
                />
              ))}
            </Carousel>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <Grid>
              <Grid.Column size={4}>
                <FareCardDesktop
                  fareOffer={convertFlightToFare(flight, i18n)}
                  baseOffer={flight}
                  loadingStatus={fetchStatus}
                  isCompact={isCompact}
                />
              </Grid.Column>
              {fetchStatus === "loading" && (
                <>
                  <Grid.Column size={4}>
                    <SkeletonFlightCard shorterCard={isCompact} />
                  </Grid.Column>
                  {!!flights_apex_web_bf_on_sr_loading_message.trackWithDefaultStage() && (
                    <Grid.Column size={4}>
                      <Stack direction="row" wrap="nowrap">
                        <Frame pt={0.5}>
                          <Spinner size="small" color="action" />
                        </Frame>
                        <Stack.Item shrink={true}>{i18n.trans(t("flights_apex_sr_branded_fares_loading"))}</Stack.Item>
                      </Stack>
                    </Grid.Column>
                  )}
                </>
              )}
            </Grid>
          </div>
        )}
      </Frame>
    </div>
  );
};

export const FareSelectorDesktop = memo(_FareSelectorDesktop);
