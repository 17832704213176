import { StoreState } from "store";

export const getSearchFetchStatus = (state: StoreState) => state.searchResults.fetchStatus;
export const getFlexibleDateMinPriceFetchStatus = (state: StoreState) => state.flexibleDateMinPrice.fetchStatus;
export const getSearchResultsFlights = (state: StoreState) => state.searchResults.flights;
export const getSearchPointOfSale = (state: StoreState) => state.searchResults?.flights?.[0]?.pointOfSale;
export const getSearchResultsFetchedAt = (state: StoreState) => state.searchResults.fetchedAt;
export const getSearchResultsFlightOffers = (state: StoreState) => state.searchResults.flights;
export const getSearchResultsFlightDeals = (state: StoreState) => state.searchResults.flightDeals;
export const getSearchId = (state: StoreState) => state.searchResults.searchId;
export const getSearchAvailableFilters = (state: StoreState) => state.searchResults.availableFilters;
export const getSearchDisplayOptions = (state: StoreState) => state.searchResults.displayOptions;
export const getAvailableFilters = (state: StoreState) => state.searchResults.availableFilters || {};
