import React from "react";
import { Stack, Text } from "@bookingcom/bui-react";
import cxx from "../../__utils/cxx.js";
import styles from "./styles.module.css";
export function CardPoint(props) {
    const { time, date, daysDiff, iata, size = "medium", align = "start", highlightIata, attributes } = props;
    return (React.createElement(Stack, { alignItems: align, gap: 0, attributes: attributes },
        React.createElement(Stack, { direction: "row", gap: 0.5 },
            React.createElement(Text, { variant: size === "small" ? "strong_2" : "strong_1", className: styles.point__text }, time),
            daysDiff ? (React.createElement(Stack.Item, { alignSelf: "center" },
                React.createElement(Text, { variant: "small_1", className: styles.point__text }, daysDiff))) : undefined),
        React.createElement(Stack, null,
            React.createElement(Text, { variant: "small_1", color: "neutral_alt", className: styles.point__text },
                React.createElement("span", { className: cxx(highlightIata && styles.point__highlight) }, iata),
                date && ` · ${date}`))));
}
