import { BrandedFareClientFeature } from "@flights/types";
import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_branded_fare_icons_only",
  stages: {
    main: 1,
    ddot: 2,
    mdot: 3,
    bf_page: 4,
    bf_hovered_sr: 5,
    has_exclusions: 6,
    has_sellable_baggage: 7,
    sellable_flex_or_refund: 8,
    upsell_modal: 9
  },
  goals: {
    proceed_with_higher_fare: 1,
    proceed_with_lower_fare: 2,
    preselects_upsell_fare: 3
  },
  goalsWithValue: [],
  defaultStage: 1
});

const refundableAndFlexibleFeatures = [
  "REFUNDABLE_TICKET",
  "CHANGEABLE_TICKET",
  "CHANGEABLE_TICKET_SR",
  "CHANGE_AFTER_DEPARTURE",
  "CHANGE_BEFORE_DEPARTURE",
  "CHANGE_ANYTIME",
  "CHANGE_FEE"
];

const baggageFeatures = [
  "PRIORITY_BAGGAGE",
  "CARRY_ON_HAND_BAGGAGE",
  "CARRY_ON_BAGGAGE",
  "CHECK_BAGGAGE",
  "CABIN_BAGGAGE",
  "PERSONAL_BAGGAGE"
];

const trackFeatures = (
  areShown: boolean,
  hasExcludedFeatures: boolean,
  includedAndSellableFeatures: BrandedFareClientFeature[],
  brandedFarePage: boolean,
  isMobile: boolean
) => {
  const seesFeature = areShown || brandedFarePage;

  isMobile ? utils.stages.mdot() : utils.stages.ddot();

  if (brandedFarePage) utils.stages.bf_page();

  if (hasExcludedFeatures && seesFeature) utils.stages.has_exclusions();

  const sellableFlexRefundFeatureFound = includedAndSellableFeatures.some((feature) => {
    return feature.availability === "SELLABLE" && refundableAndFlexibleFeatures.includes(feature.name) && seesFeature;
  });

  const sellableBaggageFeatureFound = includedAndSellableFeatures.some((feature) => {
    return feature.availability === "SELLABLE" && baggageFeatures.includes(feature.name) && seesFeature;
  });

  if (sellableFlexRefundFeatureFound) utils.stages.sellable_flex_or_refund();
  if (sellableBaggageFeatureFound) utils.stages.has_sellable_baggage();
};

export default { trackFeatures, ...utils };
