import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import { pricingUtils } from "@flights/web-api-utils-universal";
import { UIUnifiedPriceBreakdown, UIUnifiedPriceBreakdownItemScope, UIUnifiedPriceItem } from "@flights/types";
import { useTrackUnifiedBreakdown } from "utils/experiments/funnel/flights_web_unified_price_breakdown";
import useRouteName from "hooks/useRouteName";
import useProfiler from "hooks/useProfiler";

// group FLIGHT_ADULT, FLIGHT_CHILD, FLIGHT_INFANT into FLIGHT
export type BreakdownScopeId =
  | Exclude<UIUnifiedPriceBreakdownItemScope, "FLIGHT_ADULT" | "FLIGHT_CHILD" | "FLIGHT_INFANT">
  | "FLIGHT";

export type BreakdownScope = {
  id: BreakdownScopeId;
  name: string;
  items: UIUnifiedPriceItem[];
};

export type BreakdownScopeItems = UIUnifiedPriceItem[];

export type BreakdownControllerProps = UIUnifiedPriceBreakdown & {
  footerSections?: Partial<Record<BreakdownScopeId, ReactNode | string>>;
  isHungaryDepartingRyanAir: boolean;
  isWithDiscount: boolean; // flights_web_unified_price_breakdown
};

export function useBreakdownController(props: BreakdownControllerProps) {
  const { price, items, addedItems, footerSections, isHungaryDepartingRyanAir, isWithDiscount } = props;
  const i18n = useI18n();
  const { isSolo, travellersCount } = useProfiler();

  const getSectionTitle = useCallback(
    (id?: BreakdownScopeId) => {
      switch (id) {
        case "FEE":
          return i18n.trans(t("flights_price_details_fees_title"));
        case "EXTRA":
          return i18n.trans(t("flights_price_details_heading_extras"));
        case "DISCOUNT":
          return i18n.trans(t("flights_price_details_heading_discounts"));
        default: {
          const travellerCount = travellersCount();

          return isSolo()
            ? i18n.trans(t("flights_price_details_heading_flight_solo"))
            : i18n.trans(
                t("flights_price_details_heading_flight_multi", {
                  variables: { num_travellers: travellerCount },
                  num_exception: travellerCount
                })
              );
        }
      }
    },
    [i18n, isSolo, travellersCount]
  );

  const { flightAdultItems, flightChildItems, flightInfantItems, extraItems, discountItems, feeItems } = useMemo(() => {
    const feeItems: BreakdownScopeItems = items.filter((item) => item.scope === "FEE") || [];
    const extraItems: BreakdownScopeItems = items.filter((item) => item.scope === "EXTRA") || [];
    const discountItems: BreakdownScopeItems = items.filter((item) => item.scope === "DISCOUNT") || [];
    const flightAdultItems: BreakdownScopeItems = items.filter((item) => item.scope === "FLIGHT_ADULT") || [];
    const flightChildItems: BreakdownScopeItems = items.filter((item) => item.scope === "FLIGHT_CHILD") || [];
    const flightInfantItems: BreakdownScopeItems = items.filter((item) => item.scope === "FLIGHT_INFANT") || [];
    return { flightAdultItems, flightChildItems, flightInfantItems, extraItems, discountItems, feeItems };
  }, [items]);

  const { extraAddedItems, discountAddedItems, feeAddedItems } = useMemo(() => {
    const feeAddedItems: BreakdownScopeItems = addedItems.filter((item) => item.scope === "FEE") || [];
    const extraAddedItems: BreakdownScopeItems = addedItems.filter((item) => item.scope === "EXTRA") || [];
    const discountAddedItems: BreakdownScopeItems = addedItems.filter((item) => item.scope === "DISCOUNT") || [];
    return { extraAddedItems, discountAddedItems, feeAddedItems };
  }, [addedItems]);

  const sections = useMemo(() => {
    const allSections: Partial<Record<BreakdownScopeId, BreakdownScope>> = {};
    const fee = [...feeItems, ...feeAddedItems];
    const extra = [...extraItems, ...extraAddedItems];
    const discount = [...discountItems, ...discountAddedItems];
    const flight = [...flightAdultItems, ...flightChildItems, ...flightInfantItems];
    if (fee.length) allSections.FEE = { id: "FEE", name: getSectionTitle("FEE"), items: fee };
    if (extra.length) allSections.EXTRA = { id: "EXTRA", name: getSectionTitle("EXTRA"), items: extra };
    if (flight.length) allSections.FLIGHT = { id: "FLIGHT", name: getSectionTitle(), items: flight };
    if (discount.length) allSections.DISCOUNT = { id: "DISCOUNT", name: getSectionTitle("DISCOUNT"), items: discount };
    return allSections;
  }, [
    feeItems,
    extraItems,
    feeAddedItems,
    discountItems,
    extraAddedItems,
    flightAdultItems,
    flightChildItems,
    flightInfantItems,
    discountAddedItems,
    getSectionTitle
  ]);

  const totalPriceTitle = useMemo(() => i18n.trans(t("flights_price_details_total_title")), [i18n]);

  const totalPriceFooter = useMemo(
    () =>
      isHungaryDepartingRyanAir
        ? i18n.trans(t("flights_ryanair_hungary_sr_price_single"))
        : i18n.trans(t("flights_price_details_includes_taxes")),
    [isHungaryDepartingRyanAir, i18n]
  );

  const totalPrice = useMemo(() => {
    const items = [...extraAddedItems, ...discountAddedItems, ...feeAddedItems].map((item) => item.price);
    return items.reduce((prev, current) => pricingUtils.sumPrice(prev, current), price);
  }, [price, feeAddedItems, extraAddedItems, discountAddedItems]);

  // flights_web_unified_price_breakdown -- start
  // ==========================================================================
  const routeName = useRouteName();
  const { trackUnifiedPriceBreakdownGoals, trackUnifiedPriceBreakdownStages } = useTrackUnifiedBreakdown();
  const onExpand = useCallback(
    (scope: string) => {
      return (id: string) => {
        if (scope === "FLIGHT") {
          if (id === "flight_adult") trackUnifiedPriceBreakdownGoals.accordionAdult();
          if (id === "flight_child") trackUnifiedPriceBreakdownGoals.accordionNonAdult();
          if (id === "flight_infant") trackUnifiedPriceBreakdownGoals.accordionNonAdult();
        }
        if (scope === "EXTRA") {
          trackUnifiedPriceBreakdownGoals.accordionAncillaries();
        }
      };
    },
    [trackUnifiedPriceBreakdownGoals]
  );
  useEffect(() => {
    const isFunnel = [
      "searchresults",
      "flightdetails",
      "checkout",
      "checkout-fare",
      "checkout-seat-selection",
      "checkout-sirf",
      "checkout-start",
      "checkout-ticket-type",
      "checkout2",
      "checkout3"
    ].includes(routeName);
    if (!isFunnel) return;

    trackUnifiedPriceBreakdownStages({ withDiscount: isWithDiscount });
  }, [trackUnifiedPriceBreakdownStages, routeName, i18n, sections.DISCOUNT, isWithDiscount]);
  useEffect(() => {
    const ancillariesBreakdownExists = sections.EXTRA?.items.some((item) => item.items.length > 0);
    if (ancillariesBreakdownExists) {
      trackUnifiedPriceBreakdownGoals.ancillariesBreakdownExists();
    }
  }, [trackUnifiedPriceBreakdownGoals, sections.EXTRA]);
  // ==========================================================================
  // flights_web_unified_price_breakdown -- end

  return {
    sections,
    totalPrice,
    footerSections,
    totalPriceTitle,
    totalPriceFooter,
    onExpand // flights_web_unified_price_breakdown
  };
}
