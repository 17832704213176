// ext-origin is the param used to identify meta landings
// we remove it as soon as we land on flight details page on mdot and Search results on desktop(Flight details is a modal on SR in Desktop)
// so subsequent page views, page reloads are not identified as meta landings
//ref:server/modules/report-meta-landing.ts
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { flightsSessionStore } from "@flights/web-api-utils-universal";
import { META_LANDING_URL_KEY } from "../constants";

const useRemoveExternalParamsFromUrl = () => {
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let needsUpdate = false;

    // used from reportCaPriceEntrypointAccuracy
    const caPrice = urlParams.get("ca_price") || "";
    if (caPrice) {
      urlParams.delete("ca_price");
      needsUpdate = true;
    }

    const metaChannel = urlParams.get("ext-origin") || "";
    if (metaChannel) {
      // Save meta landing url for grumbleDiscrepancy @see frontend/src/hooks/useReportPriceDiscrepancy.ts
      flightsSessionStore.set(META_LANDING_URL_KEY, window.location.href);

      urlParams.delete("ext-origin");
      urlParams.delete("ext-fwd");
      urlParams.delete("salesCountry");

      const metaChannelCustomerLocale = urlParams.get("customerLanguage") || "";
      // META-1053: for meta landings, we currently receive locale and customerLanguage param from ETG. We are going to start accepting customer's meta site selected language and replacing our locale with it.
      // refer request-locale.ts:19
      if (metaChannelCustomerLocale) {
        urlParams.set("locale", metaChannelCustomerLocale.toLowerCase());
        urlParams.delete("customerLanguage");
      }
      needsUpdate = true;
    }

    if (needsUpdate) {
      history.replace(`${window.location.pathname}?${urlParams.toString()}${window.location.hash}`);
    }
  }, [history]);
};

export default useRemoveExternalParamsFromUrl;
