/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { ReactNode } from "react";

import { Popover, Text } from "@bookingcom/bui-react";

type Props = {
  hover?: {
    title: string;
    text: string;
  };
  children: ReactNode;
};

export const FlightCardBadgePopoverWrapper = ({ hover, children }: Props) => {
  if (!hover) return <>{children}</>;

  return (
    <Popover triggerType="hover" position="end">
      <Popover.Trigger>{(attributes) => <span {...attributes}>{children}</span>}</Popover.Trigger>
      <Popover.Content>
        <Text>{hover.text}</Text>
      </Popover.Content>
    </Popover>
  );
};
