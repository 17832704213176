import { UIFlightData } from "@flights/types";
import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_compact_bf_on_sr_button",
  stages: {
    affected_users: 1,
    main: 2,
    gte_3: 3
  },
  goals: {
    expands_fares: 1,
    collapses_fares: 2
  },
  defaultStage: 1
});

const trackSearchResultsStages = (flights: UIFlightData[]) => {
  utils.stages.main();
  const requestableFaresCount = flights.filter((flight) => flight.requestableBrandedFares).length;
  if (requestableFaresCount >= 3) utils.stages.gte_3();
};

export default { ...utils, trackSearchResultsStages };
