import { format as formatDate } from "date-fns";
import { legacyParse } from "@date-fns/upgrade/v2/index.js";
const SEARCH_DATE_FORMAT = "yyyy-MM-dd";
export const getSearchParams = (data) => {
    if (!data)
        return undefined;
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    const segments = data["segments"] || data["airOrder"]["flightSegments"] || [];
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    const travellers = data["travellers"] || data["passengers"] || [];
    const type = getType(segments);
    const adults = getAdults(travellers);
    const children = getChildren(travellers);
    const cabinClass = getCabinClass(segments);
    const _from = getFrom(segments, type);
    const _to = getTo(segments, type);
    const _date = getDates(segments, type);
    const _originDestination = getOriginDestination(segments, type);
    const result = {
        params: {
            type,
            adults,
            children,
            cabinClass,
            ..._from,
            ..._to,
            ..._date
        },
        ..._originDestination
    };
    const { params } = result;
    Object.keys(params).forEach((key) => {
        // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
        if (!params[key]) {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            delete params[key];
        }
    });
    return result;
};
const getType = (flightSegments) => {
    let type = "ROUNDTRIP";
    if (flightSegments.length === 1) {
        type = "ONEWAY";
    }
    else if (flightSegments.length === 2) {
        const departureAirport = flightSegments[0].departureAirport.code;
        const arrivalAirport = flightSegments[1].arrivalAirport.code;
        type = departureAirport === arrivalAirport ? "ROUNDTRIP" : "MULTISTOP";
    }
    else {
        type = "MULTISTOP";
    }
    return type;
};
export const getAdults = (travellers) => {
    return travellers.filter((t) => t.type === "ADULT").length.toString();
};
export const getCabinClass = (flightSegments) => {
    return flightSegments?.[0].legs?.[0].cabinClass;
};
export const getChildren = (travellers) => {
    return travellers
        .filter((tr) => tr.type === "KID")
        .map((tr) => tr.age)
        .filter((age) => age !== undefined)
        .join(",");
};
const getFrom = (flightSegments, type) => {
    const from = [], fromCountry = [], fromLocationName = [];
    flightSegments.forEach((segment, idx) => {
        if (type !== "MULTISTOP" && idx > 0)
            return;
        from.push(segment.departureAirport.code);
        fromCountry.push(segment.departureAirport.country);
        fromLocationName.push(segment.departureAirport.name);
    });
    return {
        from: from.join("|"),
        fromCountry: fromCountry.join("|"),
        fromLocationName: fromLocationName.join("|")
    };
};
const getTo = (flightSegments, type) => {
    const to = [], toCountry = [], toLocationName = [];
    if (type === "MULTISTOP") {
        flightSegments.forEach((segment) => {
            to.push(segment.arrivalAirport.code);
            toCountry.push(segment.arrivalAirport.country);
            toLocationName.push(segment.arrivalAirport.name);
        });
    }
    else {
        const lastArrival = flightSegments[0].arrivalAirport;
        to.push(lastArrival.code);
        toCountry.push(lastArrival.country);
        toLocationName.push(lastArrival.name);
    }
    return {
        to: to.join("|"),
        toCountry: toCountry.join("|"),
        toLocationName: toLocationName.join("|")
    };
};
const getDates = (flightSegments, type) => {
    let multiStopDates = [], depart, _return;
    if (type === "MULTISTOP") {
        multiStopDates = flightSegments.map((segment) => formatDate(legacyParse(segment.departureTime), SEARCH_DATE_FORMAT));
    }
    else {
        const lastSegment = flightSegments[flightSegments.length - 1];
        depart = formatDate(legacyParse(flightSegments[0].departureTime), SEARCH_DATE_FORMAT);
        _return = formatDate(legacyParse(lastSegment.arrivalTime), SEARCH_DATE_FORMAT);
    }
    return { multiStopDates: multiStopDates.join("|"), depart, return: _return };
};
const getOriginDestination = (flightSegments, type) => {
    const origin = [], destination = [];
    if (type === "MULTISTOP") {
        flightSegments.forEach((segment) => {
            origin.push(segment.departureAirport.code);
            destination.push(segment.arrivalAirport.code);
        });
    }
    else {
        const firstSegment = flightSegments[0];
        origin.push(firstSegment.departureAirport.code);
        destination.push(firstSegment.arrivalAirport.code);
    }
    return { origin: origin.join("|"), destination: destination.join("|") };
};
