import { useEffect, useState } from "react";
import { createTrackingUtils } from "../index";
import useAvailableBrandedFares from "branded-fares/hooks/useAvailableBrandedFares";
import useGetUrlParams from "hooks/useGetUrlParams";
import { useSelector } from "react-redux";
import { getCartDetails } from "store/cartDetails/selectors";
import { useHistory, useParams } from "react-router-dom";

const utils = createTrackingUtils({
  name: "flights_ace_web_prevent_double_cart_creation_for_bf",
  defaultStage: 1,
  stages: {
    main: 1,
    desktop: 2,
    mobile: 3
  },
  goals: {
    bf_page_loaded: 1,
    bf_page_load_time_under_1_second: 2,
    bf_page_initialized: 3,
    bf_page_initialized_outside_of_the_effect: 4,
    bf_page_loaded_without_cart_token: 5
  },
  goalsWithValue: ["bf_page_load_time"]
});

function trackStages(isMobile: boolean) {
  utils.stages.main();
  if (isMobile) utils.stages.mobile();
  if (!isMobile) utils.stages.desktop();
}

export const useTrackBrandedFarePageLoad = () => {
  const brandedFareOffers = useAvailableBrandedFares();
  const bfPageLoadTimeStart = useGetUrlParams().getUrlParam("bfPageLoadTimeStart");
  const cartDetails = useSelector(getCartDetails);
  const { cartToken } = useParams<{ flightToken: string; cartToken: string }>();
  const [bfPageLoadTimeEnd, setBfPageLoadTimeEnd] = useState<number | undefined>();
  const [metricSent, setMetricSent] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // base case measurement
    if (!metricSent && cartToken && !!brandedFareOffers.length && cartDetails) {
      setBfPageLoadTimeEnd(Date.now());
    }
  }, [brandedFareOffers.length, cartDetails, cartToken, metricSent]);

  useEffect(() => {
    // variant case measurement
    if (!metricSent && !cartToken && !!brandedFareOffers.length) {
      setBfPageLoadTimeEnd(Date.now());
    }
  }, [brandedFareOffers.length, bfPageLoadTimeStart, cartDetails, cartToken, metricSent]);

  useEffect(() => {
    // send metrics
    if (bfPageLoadTimeStart && bfPageLoadTimeEnd) {
      const pageLoadTime = bfPageLoadTimeEnd - Number(bfPageLoadTimeStart);

      if (pageLoadTime) {
        utils.goals.bf_page_loaded();
        utils.goalsWithValue.bf_page_load_time(pageLoadTime);
        if (pageLoadTime < 1000) {
          utils.goals.bf_page_load_time_under_1_second();
        }
        setMetricSent(true);
        const params = new URLSearchParams(location.search);

        params.delete("bfPageLoadTimeStart");

        history.replace({
          pathname: location.pathname,
          search: params.toString()
        });
      }
    }
  }, [bfPageLoadTimeEnd, bfPageLoadTimeStart, brandedFareOffers.length, history]);
};

export default { ...utils, trackStages };
