import React, { useCallback, useRef, useState, Fragment } from "react";
import useLocations from "./useLocations.js";
import { ShellButton } from "../Shell/index.js";
import { Popover, Text } from "@bookingcom/bui-react";
import { AutoComplete } from "../AutoComplete/index.js";
import { TransportAirplaneArriveIcon, TransportAirplaneDepartIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { LOCATION_DATA_UI_NAME } from "../../utils/constants.js";
import styles from "./LocationsDropDown.module.css";
export default function LocationsDropDown(props) {
    const { type, segmentIndex, position = "bottom-start", errorVariant = "tooltip", size } = props;
    const { selectedLocationsText, placeholderText, trackV2, errorMessage, multiSelectVariant, canShowFlyAnywhere } = useLocations({
        type,
        segmentIndex
    });
    const [showDropDown, setShowDropDown] = useState(false);
    const buttonRef = useRef(null);
    const handleOnFinish = useCallback(() => {
        setShowDropDown(false);
    }, []);
    const handleOnButtonClick = useCallback(() => {
        trackV2(type === "from" ? "click_origin" : "click_destination");
        setTimeout(() => setShowDropDown(true), 0);
    }, [type, trackV2]);
    const handlePopoverOnOpen = useCallback(() => {
        setShowDropDown(true);
    }, []);
    const handlePopoverOnClose = useCallback(() => {
        setShowDropDown(false);
    }, []);
    const errorId = `location_input_${type}_${segmentIndex}_error`;
    return (React.createElement(React.Fragment, null,
        React.createElement(ShellButton, { icon: type === "from" ? TransportAirplaneDepartIcon : TransportAirplaneArriveIcon, errorMessage: showDropDown ? undefined : errorMessage, errorVariant: errorVariant, errorId: errorId, tabIndex: 0, ref: buttonRef, "data-ui-name": `${LOCATION_DATA_UI_NAME[type]}_${segmentIndex}`, "data-ui-error": Boolean(errorMessage), onClick: handleOnButtonClick, size: size }, selectedLocationsText && selectedLocationsText.length > 0 ? (React.createElement(Text, { tagName: "span", variant: "body_2" }, selectedLocationsText.map((tx, idx) => (React.createElement(Fragment, { key: tx.code + idx },
            tx.code ? React.createElement("b", null,
                tx.code,
                " ") : null,
            tx.name,
            idx < selectedLocationsText.length - 1 ? (React.createElement(Text, { tagName: "span", variant: "body_2" }, " · ")) : null))))) : (React.createElement(Text, { tagName: "span", color: "neutral_alt", variant: "body_2" }, placeholderText))),
        React.createElement(Popover, { forcePosition: true, active: showDropDown, triggerRef: buttonRef, position: position, trapFocusMode: "soft", hideClose: true, keepMounted: false, navigationMode: "tab-and-arrows", triggerType: "click", disableAnimation: true, onOpen: handlePopoverOnOpen, onClose: handlePopoverOnClose },
            React.createElement(Popover.Content, null,
                React.createElement("div", { className: styles.container },
                    React.createElement(AutoComplete, { type: type, autoFocusInput: multiSelectVariant !== 2 && multiSelectVariant !== 3, segmentIndex: segmentIndex, canShowFlyAnywhere: canShowFlyAnywhere, onFinish: handleOnFinish }))))));
}
