// flights_web_unified_price_breakdown

import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";
import { useCallback } from "react";
import { trackWithDefaultStage } from "@flights/et-universal";

const utils = createTrackingUtils({
  defaultStage: 1,
  name: "flights_unified_price_breakdown",
  goals: {
    accordionAdult: 1,
    accordionNonAdult: 2,
    ancillariesBreakdownExists: 3,
    accordionAncillaries: 4
  },
  stages: {
    default: 1,
    main: 2,
    solo: 3,
    onlyAdults: 4,
    family: 5,
    desktop: 6,
    mobile: 7,
    withDiscount: 8
  }
});

export const useTrackUnifiedBreakdown = () => {
  const { isSolo, isFamily, isDesktop, isMobile, isWithInfants, isWithKids } = useProfiler();

  const trackUnifiedPriceBreakdownStages = useCallback(
    ({ withDiscount }: { withDiscount: boolean }) => {
      utils.stages.main();
      if (isSolo()) utils.stages.solo();
      if (!isSolo() && !isWithInfants() && !isWithKids()) utils.stages.onlyAdults();
      if (isFamily()) utils.stages.family();
      if (isDesktop()) utils.stages.desktop();
      if (isMobile()) utils.stages.mobile();
      if (withDiscount) utils.stages.withDiscount();
    },
    [isSolo, isFamily, isDesktop, isMobile, isWithInfants, isWithKids]
  );

  const trackUnifiedPriceBreakdown = useCallback(() => {
    return !!trackWithDefaultStage(utils.name(), 1);
  }, []);

  return {
    trackUnifiedPriceBreakdown,
    trackUnifiedPriceBreakdownStages,
    trackUnifiedPriceBreakdownGoals: utils.goals
  };
};
