import { createTrackingUtils } from "utils/experiments";
import { UIOrder } from "@flights/types";
import useUserAgent from "hooks/useUserAgent";

export const trackLoggedStatusSelfServeActions = createTrackingUtils({
  defaultStage: 1,
  name: "flights_web_pb_logged_status_self_serve_actions",
  stages: {
    AFFECTED_USERS: 1,
    LOGGED_IN_OWN: 2,
    REST: 3,
    LOGGED_IN_OWN_DESKTOP: 4,
    LOGGED_IN_OWN_MDOT: 5,
    REST_DESKTOP: 6,
    REST_MDOT: 7
  },
  goals: {
    DOWNLOAD_BOOKING_DETAILS: 1,
    DOWNLOAD_POP: 2,
    START_LIVE_CHAT: 3,
    CONFIRM_CANCELLATION: 4,
    REBOOK_LIVE_CHAT_CTA: 5
  },
  goalsWithValue: []
});

export const useTrackLoggedStatusSelfServeActions = ({ fpbs }: UIOrder) => {
  const { isMobile } = useUserAgent();

  if (fpbs) {
    trackLoggedStatusSelfServeActions.stages.LOGGED_IN_OWN();
    isMobile
      ? trackLoggedStatusSelfServeActions.stages.LOGGED_IN_OWN_MDOT()
      : trackLoggedStatusSelfServeActions.stages.LOGGED_IN_OWN_DESKTOP();
  } else {
    trackLoggedStatusSelfServeActions.stages.REST();
    isMobile
      ? trackLoggedStatusSelfServeActions.stages.REST_MDOT()
      : trackLoggedStatusSelfServeActions.stages.REST_DESKTOP();
  }
};
