import { InputCheckbox, Link, Stack } from "@bookingcom/bui-react";
import { FilterCount } from "@flights/search-ui";
import React from "react";
import styles from "./Airlines.module.css";

type BaseProps = {
  label: string;
  value: string;
  checked: boolean;
  count: number;
  onChange: (checked: boolean, value: string) => void;
  isHidden: boolean;
};

type SingleOptionProps = BaseProps & {
  canSelectSingleOption: true;
  onSelectSingleOption: (value: string) => void;
  singleOptionLabel: string;
};

type PropsRegular = BaseProps & {
  canSelectSingleOption?: never;
  onSelectSingleOption?: never;
  singleOptionLabel?: never;
};

type AirlinesFilterItemProps = PropsRegular | SingleOptionProps;

const AirlinesFilterItem = ({
  label,
  value,
  checked,
  count,
  onChange,
  isHidden,
  canSelectSingleOption,
  onSelectSingleOption,
  singleOptionLabel
}: AirlinesFilterItemProps) => {
  return (
    <div style={{ display: isHidden ? "none" : undefined }}>
      <FilterCount count={count}>
        <Stack
          attributes={{ "data-testid": "airlines_airline" }}
          className={styles.wrapper}
          direction="row"
          alignItems="start"
          gap={2}
        >
          <InputCheckbox
            label={label}
            value={value}
            name={`airlines-filter-${value}`}
            checked={checked}
            onChange={({ checked }) => onChange(checked, value)}
            attributes={{ "data-testid": `search_filter_airline_${label}` }}
          />
          {canSelectSingleOption ? (
            <Link
              onClick={() => onSelectSingleOption(value)}
              attributes={{
                "data-ui-only-this": true,
                "data-testid": `search_filter_airline_only_this_${label}`
              }}
            >
              {singleOptionLabel}
            </Link>
          ) : null}
        </Stack>
      </FilterCount>
    </div>
  );
};

export default AirlinesFilterItem;
