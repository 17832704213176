import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_bf_on_sr_flex_badge",
  stages: {
    main: 1,
    ddot: 2,
    mdot: 3,
    search_results: 4,
    flight_details: 5,
    fd_upsell_without_flex: 6,
    fd_upsell_with_flex: 7
  },
  goals: {
    hovers_badge: 1,
    clicks_badge_offer: 2,
    expands_offer: 3
  },
  goalsWithValue: [],
  defaultStage: 1
});

const trackFare = (hasFlexibility: boolean, isUpsell: boolean) => {
  utils.stages.flight_details();
  utils.goals.clicks_badge_offer();

  if (isUpsell) {
    hasFlexibility ? utils.stages.fd_upsell_with_flex() : utils.stages.fd_upsell_without_flex();
  }
};

export default { trackFare, ...utils };
