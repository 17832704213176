import React, { ComponentProps } from "react";
import { Button, Stack } from "@bookingcom/bui-react";

type AirlinesFilterOptionsProps<T> = {
  gap: ComponentProps<typeof Stack>["gap"];
  togglerLabel: string;
  togglerIcon: React.FunctionComponent;
  onTogglerClick: () => void;
  optionsLimit?: number;
  options: T[];
  renderOption: ({ option, index, isHidden }: { option: T; index: number; isHidden: boolean }) => React.ReactNode;
  areOptionsTrucanted: boolean;
};

export const AirlinesFilterOptions = <T,>({
  gap,
  togglerLabel,
  togglerIcon,
  onTogglerClick,
  options,
  renderOption,
  optionsLimit,
  areOptionsTrucanted
}: AirlinesFilterOptionsProps<T>) => {
  return (
    <Stack gap={gap}>
      {options.map((d, i) =>
        renderOption({ option: d, index: i, isHidden: optionsLimit ? i >= optionsLimit && areOptionsTrucanted : false })
      )}
      {optionsLimit && options.length > optionsLimit && (
        <Stack.Item>
          <Button.Aligner alignment="start">
            <Button
              size="medium"
              iconPosition="end"
              variant="tertiary"
              text={togglerLabel}
              icon={togglerIcon}
              onClick={onTogglerClick}
            />
          </Button.Aligner>
        </Stack.Item>
      )}
    </Stack>
  );
};
