import React, { useMemo } from "react";
import cxx from "../../__utils/cxx.js";
import styles from "./styles.module.css";
export function CardAirline(props) {
    const { code, size = 24, className, attributes } = props;
    const src = useMemo(() => `https://r-xx.bstatic.com/data/airlines_logo/${code}.png`, [code]);
    const classNames = useMemo(() => cxx(styles.logo, className), [className]);
    if (!code)
        return undefined;
    return React.createElement("img", { ...attributes, alt: "", role: "presentation", src: src, width: size, className: classNames });
}
