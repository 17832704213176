import React, { useMemo } from "react";
import { Stack, Text } from "@bookingcom/bui-react";
import { CardStops, CardAirlineGroup, CardPoint } from "../index.js";
import styles from "./styles.module.css";
export function CardSegment(props) {
    const { from, to, stops, airlineNames, airlineLogos, attributes, size = "medium" } = props;
    const isSmall = useMemo(() => size === "small", [size]);
    const logoSize = useMemo(() => (!size || size === "small" ? 32 : size === "medium" ? 32 : 44), [size]);
    const innerStackGap = useMemo(() => (!size || size === "small" ? 2 : 4), [size]);
    return (React.createElement("div", { className: styles.stopsRoot },
        React.createElement("div", { className: styles.stopsWrapper },
            React.createElement("div", { className: styles.stopsConnector },
                React.createElement(CardStops, { ...stops })),
            React.createElement(Stack, { gap: isSmall ? 0 : 1, attributes: attributes },
                React.createElement(Stack, { direction: "row", gap: innerStackGap },
                    isSmall ? undefined : (React.createElement(Stack.Item, { alignSelf: "center" },
                        React.createElement(CardAirlineGroup, { ...airlineLogos, className: airlineLogos.codes.length > 1 ? styles.multipleLogos : styles.singleLogo, variant: "grid", size: logoSize }))),
                    React.createElement(Stack.Item, null,
                        React.createElement(CardPoint, { ...from, align: "start", size: isSmall ? "small" : "medium" })),
                    React.createElement(Stack.Item, { split: true },
                        React.createElement(CardPoint, { ...to, align: "end", size: isSmall ? "small" : "medium" }))),
                React.createElement(Stack, { gap: 1, direction: "row" },
                    isSmall ? React.createElement(CardAirlineGroup, { ...airlineLogos, variant: "inline", size: 16 }) : undefined,
                    airlineNames && (React.createElement(Text, { tagName: "span", variant: "small_1" }, airlineNames)))))));
}
