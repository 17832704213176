import { createTrackingUtils } from "../index";
import { UIOrder } from "@flights/types";
import useContextName from "hooks/useContextName";

import { getOrderUtils } from "utils/getOrderUtils";

const utils = createTrackingUtils({
  name: "flights_ace_web_whitelabel_ancillaries_pb_tech",
  defaultStage: 1,
  stages: {
    all_users_on_pb: 1,
    confirmation_page: 2,
    order_details_page: 3,
    desktop: 4,
    mobile: 5,
    one_way: 6,
    round_trip: 7,
    multi_city: 8
  },
  goals: {
    lounge_access_eligible: 1,
    whitelabel_api_failed: 2
  },
  goalsWithValue: []
});

export function useTrackWhitelabelAncillariesonPB(isMobile: boolean, order: UIOrder) {
  const { isRoundTrip, isOneWay, isMultiStop } = getOrderUtils(order);

  const contextName = useContextName();
  utils.stages.all_users_on_pb();

  if (contextName === "confirmation") {
    utils.stages.confirmation_page();
  } else if (contextName === "pb_order_details") {
    utils.stages.order_details_page();
  }

  if (isMobile) {
    utils.stages.mobile();
  } else {
    utils.stages.desktop();
  }

  if (isOneWay) {
    utils.stages.one_way();
  }

  if (isRoundTrip) {
    utils.stages.round_trip();
  }
  if (isMultiStop) {
    utils.stages.multi_city();
  }
}

export default utils;
