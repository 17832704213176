import routeMap from "app/routes";
import { isFeatureRunningClientSide } from "@flights/web-api-utils-universal";
import { matchPath } from "react-router-dom";

const getRouteName = (pathname: string) => {
  if (!window || !window.location) return "";

  for (const route of routeMap({
    isMobile: window?.__GLOBAL_CONTEXT__?.userAgent?.isMobile,
    isPaymentCollectionPage: !!isFeatureRunningClientSide("FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE")
  })) {
    if (matchPath(pathname, { path: route.path, exact: true })) {
      return route.name;
    }
  }

  return "";
};

export default getRouteName;
