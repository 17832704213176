import { useMemo } from "react";
import { UIFlightData } from "@flights/types";
import { useI18n } from "@bookingcom/lingojs-react";
import usePointOfSale from "hooks/usePointOfSale";
import { useSelector } from "react-redux";
import { getAncillaries } from "store/flightDetails/selectors";
import { priceBreakdownUtils, pricingUtils, localizationUtils } from "@flights/web-api-utils-universal";
import { type TicketType } from "ancillaries/components/TicketTypeRadio/types";
import { useFlightOfferWithDiscount } from "hooks/useFlightOfferWithDiscount";

export type UnifiedPriceBreakdownTicketTypeControllerProps = {
  flight?: UIFlightData;
  type: TicketType;
};

type CopyFuncParams = Parameters<typeof priceBreakdownUtils.getExtraCopy>;
type POSType = CopyFuncParams[3]["pointOfSale"];

export function useUnifiedPriceBreakdownTicketTypeController(props: UnifiedPriceBreakdownTicketTypeControllerProps) {
  const { flight, type } = props;
  const i18n = useI18n();
  const ipCountry = usePointOfSale();
  const ancillaries = useSelector(getAncillaries);
  const segments = useMemo(() => flight?.segments, [flight]);
  const isHungaryDepartingRyanAir = useMemo(
    () => localizationUtils.isHungaryDepartingRyanAirFlight(segments),
    [segments]
  );

  const baseOptions = useMemo(
    () => ({
      pointOfSale: (ipCountry || "") as POSType,
      isFlightFromRyanAirHungary: isHungaryDepartingRyanAir
    }),
    [ipCountry, isHungaryDepartingRyanAir]
  );

  const unifiedPriceBreakdown = useMemo(() => flight?.unifiedPriceBreakdown, [flight]);

  const extras = useMemo(() => {
    if (type === "cfar" && ancillaries?.cancelForAnyReason) {
      const { priceBreakdown, airProductReference } = ancillaries.cancelForAnyReason;

      return {
        cancelForAnyReason: {
          price: priceBreakdown,
          reference: airProductReference
        }
      };
    } else if (type === "flexible" && ancillaries?.flexibleTicket) {
      const { travellers, priceBreakdown, airProductReference } = ancillaries.flexibleTicket;
      const price = pricingUtils.sumPriceBreakdown(new Array(travellers.length).fill(priceBreakdown));

      return {
        flexibleTicket: {
          price,
          reference: airProductReference,
          travelerPrices: []
        }
      };
    }

    return {};
  }, [type, ancillaries]);

  const addedItems = useMemo(() => {
    return priceBreakdownUtils.transformUnifiedPriceBreakdownItemForExtra(i18n, extras, baseOptions);
  }, [i18n, extras, baseOptions]);

  const { isWithDiscount } = useFlightOfferWithDiscount(flight); // flights_web_unified_price_breakdown

  return {
    unifiedPriceBreakdown,
    isHungaryDepartingRyanAir,
    isWithDiscount, // flights_web_unified_price_breakdown
    addedItems
  };
}
