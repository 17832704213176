import React, { useMemo, Fragment } from "react";
import { Divider, Stack, Text, Accordion, StackProps } from "@bookingcom/bui-react";
import {
  useBreakdownController,
  BreakdownControllerProps,
  type BreakdownScopeId,
  type BreakdownScopeItems,
  type BreakdownScope
} from "./useBreakdown.controller";
import styles from "./Breakdown.module.css";
import useFormatPrice from "hooks/useFormatPrice";
import { UIUnifiedPriceItem } from "@flights/types";

const extraOrder = ["flexibleticket", "cabinbag", "checkedbag", "travelinsurance", "cfar", "seats"];
const flightOrder = ["adult", "child", "infant"];

function sortItems(sortArr: string[], items: UIUnifiedPriceItem[]) {
  return items.sort((a, b) => {
    const idxA = sortArr.findIndex((v) => a.id.includes(v));
    const idxB = sortArr.findIndex((v) => b.id.includes(v));
    if (idxA === -1) return 1;
    if (idxB === -1) return -1;
    return idxA - idxB;
  });
}

function sortSectionItems(id: BreakdownScopeId, items: UIUnifiedPriceItem[]) {
  if (id === "EXTRA") {
    return sortItems(extraOrder, items);
  } else if (id === "FLIGHT") {
    return sortItems(flightOrder, items);
  } else {
    return items.sort((a, b) => a.title.localeCompare(b.title));
  }
}

function SectionItems({ items, mixin }: { items: BreakdownScopeItems; mixin?: StackProps["mixin"] }) {
  const { formatPrice } = useFormatPrice();
  return useMemo(
    () =>
      items.map(({ id, items, price, title }, idx) => (
        <Stack mixin={mixin} gap={1} key={`${id}-${idx}`}>
          <Stack gap={2} direction="row">
            <Stack.Item grow>
              <Text variant="small_1" color="neutral">
                {title}
              </Text>
            </Stack.Item>
            <Text
              variant="small_1"
              color={price.units < 0 || (price.units === 0 && price.nanos < 0) ? "constructive" : "neutral"}
            >
              {formatPrice(price)}
            </Text>
          </Stack>
          {items.length ? <SectionItems mixin={{ paddingInlineStart: 2 }} items={items} /> : undefined}
        </Stack>
      )),
    [items, mixin, formatPrice]
  );
}

function AccordionItem({
  section,
  sectionFooter,
  isLast,
  onExpand // flights_web_unified_price_breakdown
}: {
  section: BreakdownScope;
  sectionFooter?: React.ReactNode;
  isLast: boolean;
  onExpand: (scope: string) => void;
}) {
  const { formatPrice } = useFormatPrice();
  return (
    <>
      <Stack gap={2}>
        <Text variant="strong_2">{section.name}</Text>
        {useMemo(
          () =>
            sortSectionItems(section.id, section.items).map((item, idx) => (
              <Accordion
                key={`${item.id}-${idx}`}
                className={styles.accordion}
                onClose={() => onExpand(String(item.id))}
                onOpen={() => onExpand(String(item.id))}
                titleContent={
                  <Stack gap={0} direction="row">
                    <Stack.Item grow>
                      <Text variant="body_2">{item.title}</Text>
                    </Stack.Item>
                    <Text
                      variant="body_2"
                      color={
                        item.price.units < 0 || (item.price.units === 0 && item.price.nanos < 0)
                          ? "constructive"
                          : "neutral"
                      }
                    >
                      {formatPrice(item.price)}
                    </Text>
                  </Stack>
                }
              >
                {item.items.length ? (
                  <Stack gap={1} mixin={{ paddingBlockStart: 2, paddingBlockEnd: 1 }}>
                    <SectionItems mixin={{ paddingInlineEnd: 10 }} items={item.items} />
                  </Stack>
                ) : undefined}
              </Accordion>
            )),
          [formatPrice, section.items, section.id, onExpand]
        )}
      </Stack>
      {sectionFooter !== undefined ? <Stack.Item>{sectionFooter}</Stack.Item> : undefined}
      {!isLast ? <Divider /> : null}
    </>
  );
}

export function Breakdown(props: BreakdownControllerProps) {
  const {
    totalPrice,
    totalPriceTitle,
    footerSections = {},
    sections = {},
    totalPriceFooter,
    onExpand
  } = useBreakdownController(props);
  const { formatPrice } = useFormatPrice();
  const sortedIds: BreakdownScopeId[] = useMemo(() => ["FLIGHT", "EXTRA", "FEE", "DISCOUNT"], []);
  const sortedSections = useMemo<BreakdownScope[]>(
    () => sortedIds.map((id) => sections?.[id]).filter((section): section is BreakdownScope => Boolean(section)),
    [sections, sortedIds]
  );
  return (
    <Stack direction="column" gap={2}>
      <Stack gap={2}>
        {useMemo(
          () =>
            sortedSections.map((section, idx) => (
              <AccordionItem
                key={`${section.id}-${idx}`}
                section={section}
                sectionFooter={footerSections?.[section.id]}
                onExpand={onExpand(section.id)}
                isLast={idx === sortedSections.length - 1}
              />
            )),
          [sortedSections, footerSections, onExpand]
        )}
      </Stack>
      <Divider />
      <Stack gap={0} mixin={{ paddingInlineEnd: 10 }}>
        <Stack direction="row" gap={0}>
          <Stack.Item grow>
            <Text variant="headline_3">{totalPriceTitle}</Text>
          </Stack.Item>
          <Stack direction="column" gap={0}>
            <Text variant="headline_3">{formatPrice(totalPrice)}</Text>
          </Stack>
        </Stack>
        <Text variant="body_2">{totalPriceFooter}</Text>
      </Stack>
    </Stack>
  );
}
