import { UIFlightData } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions } from "../../store";
import { trackCustomGoal } from "utils/et";
import { PRE_OPTED_ANCILLARIES_QUERY_PARAMS } from "../../constants";
import { actions as extraProductActions } from "ancillaries/store/extraProducts/actions";
import useOfferCartOrderData from "hooks/useOfferCartOrderData";

export default function useGoToFlightDetails() {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const params = useParams<{ airports: string }>();
  const { createUrl } = useCreateUrl();
  const history = useHistory();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const actions = useActions(extraProductActions);
  const offerCartOrderData = useOfferCartOrderData();

  return useCallback(
    (flightToken: string, baseOffer: UIFlightData, withFlexTicket?: boolean, withCfarTicket?: Boolean) => {
      const isBrandedFare = baseOffer.token !== flightToken;

      const locationParams = location.search;

      trackCustomGoal("flights_apex_branded_fare_from_cache_web", 3); // Click fare card
      const urlParams = new URLSearchParams(locationParams);

      urlParams.set("baseOfferToken", baseOffer.token);

      if (withFlexTicket && !isBrandedFare) {
        urlParams.set(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.flexibleTicket, "true");
        actions.addPreOptInProduct("flexibleTicket", true);
      } else if (withCfarTicket && !isBrandedFare) {
        urlParams.set(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.cancelForAnyReason, "true");
        actions.addPreOptInProduct("cancelForAnyReason", true);
      } else {
        urlParams.delete(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.flexibleTicket);
        urlParams.delete(PRE_OPTED_ANCILLARIES_QUERY_PARAMS.cancelForAnyReason);
        actions.removePreOptInProduct("flexibleTicket");
        actions.removePreOptInProduct("cancelForAnyReason");
      }

      offerCartOrderData.set(flightToken, { offer: flightToken, parentOfferToken: baseOffer.token });

      history.push(createUrl(`/flights/${params.airports}/${flightToken}/?${urlParams}`));
    },
    [actions, createUrl, history, params.airports, offerCartOrderData]
  );
}
