import React, { FC } from "react";
import { Icon } from "@bookingcom/bui-react";
import {
  CheckmarkSelectedFillIcon,
  CloseCircleFillIcon,
  DollarCircleFillIcon
} from "@bookingcom/bui-assets-react/streamline/index.js";
import { Color } from "@bookingcom/bui-react/components/Icon/Icon.types";
import { UIBrandedFareFeatureAvailability } from "@flights/types";
import styles from "./FareFeatureIcon.module.css";
import flights_apex_web_branded_fare_icons_only from "utils/experiments/apex/flights_apex_web_branded_fare_icons_only";
import { mcn } from "utils/mergeClassnames";

const availabilityIcons: Record<UIBrandedFareFeatureAvailability, { color: Color; icon: FC }> = {
  INCLUDED: { color: "constructive", icon: CheckmarkSelectedFillIcon },
  SELLABLE: { color: "neutral", icon: DollarCircleFillIcon },
  NOT_INCLUDED: { color: "destructive", icon: CloseCircleFillIcon }
};

type Props = {
  availability: UIBrandedFareFeatureAvailability;
  icon: FC;
  isLarge?: boolean;
  withAvailabilityIcon?: boolean;
  inline?: boolean;
};

const FareFeatureIcon = ({ availability, icon, isLarge, inline }: Props) => {
  const withAvailabilityIcon = !!flights_apex_web_branded_fare_icons_only.trackWithDefaultStage();
  const availabilityIcon = withAvailabilityIcon && availabilityIcons[availability];
  const iconClassName = withAvailabilityIcon ? styles.iconWithAvailability : "";

  return (
    <div className={styles.featureIconWrapper}>
      <Icon
        className={mcn(iconClassName, inline ? "" : styles.fixedWidth)}
        size={isLarge ? "large" : "medium"}
        svg={icon}
        color={withAvailabilityIcon && availability !== "INCLUDED" ? "disabled" : "neutral"}
      />
      {availabilityIcon && (
        <Icon
          size="smaller"
          svg={availabilityIcon.icon}
          color={availabilityIcon.color}
          className={styles.availabilityIcon}
        />
      )}
    </div>
  );
};

export default FareFeatureIcon;
