import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";
import { useSelector } from "react-redux";
import { getFlight } from "store/flightDetails/selectors";

const utils = createTrackingUtils({
  name: "flights_web_filters_budget_per_adult_2",
  stages: {
    main: 1,
    desktop: 2,
    mdot: 3,
    direct: 4,
    meta: 5,
    solo: 6,
    non_solo_adults_only: 7,
    with_kids_or_infants: 8,
    pos_us: 9
  },
  defaultStage: 1,
  goals: {
    used_price_filter: 1,
    used_price_filter_plus_other_filters: 2
  },
  goalsWithValue: []
});

export const useTrackBudgetFilterPerAdultStages = () => {
  const { isDesktop, isMobile, isDirect, isMeta, isSolo, isAmerican, isWithInfants, isWithKids } = useProfiler();
  const flight = useSelector(getFlight);
  const adultsNumber = (flight?.travellers || []).filter((t) => t.type === "ADULT").length;

  return () => {
    isDesktop() && utils.stages.desktop();
    isMobile() && utils.stages.mdot();
    isDirect() && utils.stages.direct();
    isMeta() && utils.stages.meta();
    isSolo() && utils.stages.solo();
    adultsNumber > 1 && utils.stages.non_solo_adults_only();
    (isWithInfants() || isWithKids()) && utils.stages.with_kids_or_infants();
    isAmerican() && utils.stages.pos_us();
  };
};

export default utils;
