import { SkeletonLoader, Stack } from "@bookingcom/bui-react";
import * as React from "react";

type Props = {
  amount?: number;
};

export function SkeletonListMultiLine({ amount = 3 }: Props) {
  const items: Array<React.ReactElement> = [];

  for (let i = 0; i < amount; i++) {
    items.push(<SkeletonLoader key={i} variant="two-lines" width="80%" />);
  }

  return <Stack gap={6}>{items}</Stack>;
}
