import {
  UIAvailableExtraProducts,
  UIOrderCancelForAnyReason,
  UIOrderCheckedInBaggage,
  UIOrderExtras,
  UIOrderFlexibleTicket,
  UIOrderMobileTravelPlan,
  UIPreOptInSelected,
  UIProductCancelForAnyReason,
  UIProductCheckedInBaggage,
  UIProductFlexibleTicket,
  UIProductMobileTravelPlan
} from "@flights/types";
import { objectUtils, pricingUtils } from "@flights/web-api-utils-universal";

export const transformPreOptedMobileTravelPlan = ({
  priceBreakdown
}: UIProductMobileTravelPlan): UIOrderMobileTravelPlan => ({
  price: priceBreakdown,
  reference: "n/a"
});

export const transformPreOptedFlexibleTicket = ({
  travellers,
  priceBreakdown,
  airProductReference,
  supplierInfo
}: UIProductFlexibleTicket): UIOrderFlexibleTicket => ({
  price: travellers.length
    ? pricingUtils.sumPriceBreakdown(new Array(travellers.length).fill(priceBreakdown))
    : pricingUtils.getEmptyBreakdown(),
  reference: airProductReference,
  travelerPrices: travellers.map((traveller) => ({
    travellerPriceBreakdown: priceBreakdown,
    travellerReference: traveller
  })),
  supplierInfo
});

export const transformPreOptedCheckedInBaggage = (
  checkedInBaggage: UIProductCheckedInBaggage
): UIOrderCheckedInBaggage => {
  const { options, airProductReference } = checkedInBaggage;
  const travelerBaggages = options.flatMap(({ travellers, priceBreakdown, luggageAllowance }) =>
    travellers.map((traveller) => ({
      price: priceBreakdown,
      luggageAllowance,
      traveleRreference: traveller,
      travellerReference: traveller
    }))
  );

  return {
    price: travelerBaggages.length
      ? pricingUtils.sumPriceBreakdown(travelerBaggages.map(({ price }) => price))
      : pricingUtils.getEmptyBreakdown(),
    reference: airProductReference,
    travelerBaggages
  };
};

export const transformPreOptedCancelForAnyReason = ({
  priceBreakdown,
  airProductReference,
  content,
  market
}: UIProductCancelForAnyReason): UIOrderCancelForAnyReason => ({
  price: priceBreakdown,
  reference: airProductReference,
  content,
  market
});

export const transformPreOptedExtras = (
  preOptedAncillaries: UIPreOptInSelected,
  availableAncillaries: UIAvailableExtraProducts
): UIOrderExtras => {
  const extraKeys = objectUtils.getObjectKeys(preOptedAncillaries).filter((key) => !!preOptedAncillaries[key]);

  return extraKeys.reduce<UIOrderExtras>((acc, extraKey) => {
    if (availableAncillaries[extraKey]) {
      switch (extraKey) {
        case "mobileTravelPlan":
          acc.mobileTravelPlan = transformPreOptedMobileTravelPlan(availableAncillaries[extraKey]);
          break;
        case "flexibleTicket":
          acc.flexibleTicket = transformPreOptedFlexibleTicket(availableAncillaries[extraKey]);
          break;
        case "checkedInBaggage":
          acc.checkedInBaggage = transformPreOptedCheckedInBaggage(availableAncillaries[extraKey]);
          break;
        case "cancelForAnyReason":
          acc.cancelForAnyReason = transformPreOptedCancelForAnyReason(availableAncillaries[extraKey]);
          break;
        default:
          break;
      }
    }

    return acc;
  }, {});
};
