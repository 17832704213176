/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC } from "react";
import { UIBrandedFareInfo, UIFlightSegment } from "@flights/types";
import Frame from "components/elements/Frame";
import { useFlightCardLuggageWithCheckmarksController } from "./useFlightCardLuggageWithCheckmarksController";
import { Stack, Text } from "@bookingcom/bui-react";
import { lowerCase, startCase } from "lodash";
import { BaggageIconWithCheckmark } from "@flights/search-ui";

export const FlightCardLuggageWithCheckmarksMobile: FC<{
  segments: UIFlightSegment[];
  showFareName: boolean;
  brandedFareInfo?: UIBrandedFareInfo;
  align?: "start" | "end";
}> = ({ segments, showFareName, brandedFareInfo, align = "end" }) => {
  const { cabinBagText, personalBagText, checkedBagText, cabinAriaLabel, personalAriaLabel, checkedAriaLabel } =
    useFlightCardLuggageWithCheckmarksController(segments);

  if (!cabinBagText && !personalBagText && !checkedBagText) {
    return null;
  }

  return (
    <Stack gap={0.5} alignItems={align}>
      {showFareName && brandedFareInfo && <BrandedFareDescription {...brandedFareInfo} />}
      <Stack direction="row" gap={1}>
        {personalBagText && (
          <BaggageIconWithCheckmark baggageType="personal" ariaLabel={personalAriaLabel}></BaggageIconWithCheckmark>
        )}
        {cabinBagText && (
          <BaggageIconWithCheckmark baggageType="cabin" ariaLabel={cabinAriaLabel}></BaggageIconWithCheckmark>
        )}
        {checkedBagText && (
          <BaggageIconWithCheckmark baggageType="bag" ariaLabel={checkedAriaLabel}></BaggageIconWithCheckmark>
        )}
      </Stack>
    </Stack>
  );
};
const BrandedFareDescription = (brandedFareInfo?: UIBrandedFareInfo) => {
  if (!brandedFareInfo) {
    return null;
  }

  return (
    <Frame>
      <Text variant="small_1" align="end" color="neutral">
        {startCase(lowerCase(brandedFareInfo.fareName))}
      </Text>
    </Frame>
  );
};

export default FlightCardLuggageWithCheckmarksMobile;
