import { t, useI18n } from "@bookingcom/lingojs-react";
import { UIFlightCarrier, UIFlightSegment } from "@flights/types";
import useAirlineLogosGrouped from "hooks/useAirlineLogosGrouped";
import { useMemo } from "react";

export const useCardCarriersCombinedController = (segments: UIFlightSegment[]) => {
  const { selectFlightCarriers: segmentsCarriers, airlinesWithGridPosition } = useAirlineLogosGrouped(segments);
  const i18n = useI18n();
  const extractAirlineCode = (logoUrl: string) => {
    const code = logoUrl.split("/").pop()?.replace(".png", "");
    return code;
  };

  const logoCodes = useMemo(() => {
    const codes: string[] = [];
    segments.forEach((_, index) => {
      const _logos = new Set<string>();
      airlinesWithGridPosition[index].forEach((p) => {
        const code = extractAirlineCode(p.logo);
        if (code) {
          _logos.add(code);
        }
      });
      [..._logos].slice(0, 4).forEach((code) => codes.push(code));
    });
    return [...new Set(codes)];
  }, [segments, airlinesWithGridPosition]);

  const getCarrierText = (carrier: UIFlightCarrier) => {
    if (!carrier.carrierData) return "";
    if (carrier.operatingCarrierDisclosureText) {
      return i18n.trans(
        t("flights_sr_marketing_operating_airline", {
          variables: {
            marketing_carrier: carrier.carrierData.name,
            operating_carrier: carrier.operatingCarrierDisclosureText
          }
        })
      );
    } else if (carrier.operatingCarrierData) {
      return i18n.trans(
        t("flights_sr_marketing_operating_airline", {
          variables: {
            marketing_carrier: carrier.carrierData.name,
            operating_carrier: carrier.operatingCarrierData.name
          }
        })
      );
    } else {
      return carrier.carrierData.name;
    }
  };

  const getCarriersText = (flightCarriers: UIFlightCarrier[]) => {
    const uniqueFlightCarriers = [...new Set(flightCarriers.flat())];
    return uniqueFlightCarriers
      .map((carrier) => {
        return getCarrierText(carrier);
      })
      .join(", ");
  };

  return { carrierLogoCodes: logoCodes, carriersText: getCarriersText(segmentsCarriers) };
};
