import { UIBrandedFareInfo } from "@flights/types";
import React, { useMemo } from "react";
import { getFeaturesToRender } from "branded-fares/utils/features";
import FareFeatureIcon from "../FareFeatureIcon/FareFeatureIcon";
import { Stack } from "@bookingcom/bui-react";

type Props = {
  brandedFareInfo: UIBrandedFareInfo;
  limit?: number;
  isLarge?: boolean;
  isCompact?: boolean;
  includeSellableFeatures?: boolean;
  isPreCheckAndPay?: boolean;
};

const FareFeatureIcons = ({
  brandedFareInfo,
  limit,
  isLarge,
  isCompact,
  includeSellableFeatures,
  isPreCheckAndPay
}: Props) => {
  const { includedFeatures } = useMemo(
    () =>
      getFeaturesToRender(brandedFareInfo, {
        includeSellableFeatures,
        isPreCheckAndPay
      }),
    [brandedFareInfo, includeSellableFeatures, isPreCheckAndPay]
  );

  const fareFeaturesIcons = includedFeatures
    .slice(0, limit)
    .map(({ icon, name, availability }) => (
      <FareFeatureIcon key={name} icon={icon} isLarge={isLarge} availability={availability} inline={true} />
    ));

  if (!fareFeaturesIcons.length) {
    return null;
  }

  return (
    <Stack attributes={{ "data-testid": "FareFeaturesIcons" }} direction="row" gap={isLarge ? 0 : isCompact ? 0.5 : 2}>
      {fareFeaturesIcons}
    </Stack>
  );
};

export default FareFeatureIcons;
