// flights_price_breakdown_sr_tooltip_web

import { useCallback } from "react";
import useProfiler from "hooks/useProfiler";
import { createMicroConversionTrackingUtils } from "..";

const utils = createMicroConversionTrackingUtils({
  name: "flights_price_breakdown_sr_tooltip_web",
  defaultStage: 1,
  mainStage: 1,
  stages: {
    main: 1,
    solo: 2,
    onlyAdults: 3,
    family: 4,
    desktop: 5,
    mobile: 6,
    withDiscount: 7
  },
  goals: {
    tooltipClick: 1
  }
});

export const useUnifiedBreakdownSrTooltip = () => {
  const { isSolo, isFamily, isDesktop, isMobile, isWithInfants, isWithKids } = useProfiler();

  const trackPriceBreakdownSrTooltipStages = useCallback(
    ({ withDiscount }: { withDiscount: boolean }) => {
      if (isSolo()) utils.stages.solo();
      if (!isSolo() && !isWithInfants() && !isWithKids()) utils.stages.onlyAdults();
      if (isFamily()) utils.stages.family();
      if (isDesktop()) utils.stages.desktop();
      if (isMobile()) utils.stages.mobile();
      if (withDiscount) utils.stages.withDiscount();

      return null;
    },
    [isSolo, isFamily, isDesktop, isMobile, isWithInfants, isWithKids]
  );

  const trackPriceBreakdownSrTooltip = useCallback(() => {
    return !!utils.trackWithDefaultStage();
  }, []);

  return {
    trackPriceBreakdownSrTooltip,
    trackPriceBreakdownSrTooltipStages,
    trackPriceBreakdownSrTooltipGoals: utils.goals
  };
};
