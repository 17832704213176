import { useSelector } from "react-redux";
import { createTrackingUtils } from "..";
import { getExtraProducts } from "ancillaries/store/extraProducts/selectors";
import useProfiler from "hooks/useProfiler";
import { useEffect, useMemo } from "react";

const utils = createTrackingUtils({
  name: "flights_web_hide_meals_from_extras",
  stages: {
    hasAncillariesData: 1,
    checkout2: 2, // Extras page
    main: 3, // Users who should see meals on extras
    mdot: 4,
    desktop: 5,
    meta: 6,
    direct: 7
  },
  defaultStage: 1,
  goals: {},
  goalsWithValue: []
});

export const useTrackHideMealsStages = () => {
  const extraProducts = useSelector(getExtraProducts);
  const { isMobile, isMeta } = useProfiler();

  const hasMealInAncillaries = useMemo(() => {
    return !!extraProducts && !!extraProducts.mealPreference;
  }, [extraProducts]);

  useEffect(() => {
    utils.stages.checkout2();
    if (hasMealInAncillaries) {
      utils.stages.main();
      isMobile() ? utils.stages.mdot() : utils.stages.desktop();
      isMeta() ? utils.stages.meta() : utils.stages.direct();
    }
  }, [isMobile, isMeta, hasMealInAncillaries]);
};

export default utils;
