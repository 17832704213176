import { StoreState } from "../index";
import { isSirfAvailable } from "./sirf";
import { getAncillaries, getSubsidizedFares } from "store/flightDetails/selectors";

export const getIsFlexibleTicketPreSelectedAtMeta = (state: StoreState) =>
  !!getAncillaries(state)?.flexibleTicket?.preSelected;

export const getIsFlexibleTicketAvailable = (state: StoreState) => !!getAncillaries(state)?.flexibleTicket;
export const getIsCfarAvailable = (state: StoreState) => !!getAncillaries(state)?.cancelForAnyReason;

export const getIsSpanishIslandsResidentFareAvailable = (store: StoreState) =>
  isSirfAvailable(getSubsidizedFares(store) || []);

export const getIsSeatMapAvailable = (state: StoreState) => {
  const { fetchStatus: extraProductsStatus, extraProducts } = state.extraProducts;

  /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
  /* TODO(asamilyak): this is a protection against a theoretical situation with branded fares
      when seat map is available in base fare (for which flight details are fetched),
      but unavailable in a selected non-base fare (for which cart extra products are fetched).
      Remove it once we make sure this is not happening on prod
      (via `checkout_branded_fares_inconsistent_seatmap` client metric)
      and seat map availability is always consistent between fares.
  */
  return extraProductsStatus === "success" ? !!extraProducts?.seatMap : !!getAncillaries(state)?.seatMap;
};
