import { FilterCount } from "@flights/search-ui";
import React, { FC } from "react";
import { InputCheckbox, Stack, Text } from "@bookingcom/bui-react";
import { useFlightTimesFilterController } from "./useFlightTimesFilter.controller";

export const OptionsList: FC<{ optionIndex: number }> = ({ optionIndex }) => {
  const { isFilterAvailable, options, onChange } = useFlightTimesFilterController();
  const { departure, arrival, labels } = options[optionIndex];
  if (!isFilterAvailable) return null;

  return (
    <Stack gap={4}>
      {/* Departure */}
      <Stack.Item>
        <Stack gap={2}>
          <Text variant="emphasized_2" attributes={{ "data-testid": "flight_times_filter_v2_departure_label" }}>
            {labels.departure}
          </Text>

          {departure.map(({ count, disabled, label, checked, name }, timeIndex) => (
            <FilterCount key={`departure-${label}`} count={count}>
              <InputCheckbox
                disabled={disabled}
                label={label}
                checked={checked}
                name={name}
                attributes={{
                  "data-testid": `flight_times_filter_v2_flight_time_departure_${timeIndex}`
                }}
                onChange={({ checked }) => onChange({ type: "departure", checked, optionIndex, timeIndex })}
              />
            </FilterCount>
          ))}
        </Stack>
      </Stack.Item>

      {/* Arrival */}
      <Stack.Item>
        <Stack gap={2}>
          <Text variant="emphasized_2" attributes={{ "data-testid": "flight_times_filter_v2_arrival_label" }}>
            {labels.arrival}
          </Text>

          {arrival.map(({ count, disabled, label, checked, name }, timeIndex) => (
            <FilterCount key={`arrival-${label}`} count={count}>
              <InputCheckbox
                disabled={disabled}
                label={label}
                checked={checked}
                name={name}
                attributes={{ "data-testid": `flight_times_filter_v2_flight_time_arrival_${timeIndex}` }}
                onChange={({ checked }) => onChange({ type: "arrival", checked, optionIndex, timeIndex })}
              />
            </FilterCount>
          ))}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
