import { t, useI18n } from "@bookingcom/lingojs-react";
import useProfiler from "hooks/useProfiler";
import useSearchFiltersController from "../../useSearchFiltersController";

export const useStopsFilterController = () => {
  const i18n = useI18n();
  const { isMeta, isMobile } = useProfiler();
  const { stops } = useSearchFiltersController();

  const isRoundPrice = !isMeta() && isMobile();

  return {
    isFilterAvailable: stops.options.length > 0,
    title: i18n.trans(t("flights_filters_stops_title")),
    resetLabel: i18n.trans(t("flights_filter_reset")),
    showReset: stops.touched,
    onClickReset: stops.reset,
    value: stops.value,
    onChange: stops.set,
    gapBetweenOptions: isMobile() ? 4 : 2,
    options: stops.options.map((o) => ({
      ...o,
      priceLabel: i18n.trans(
        t("flights_filters_lowest_price", {
          variables: {
            lowest_price: isRoundPrice ? o.roundPrice : o.price
          }
        })
      )
    }))
  };
};
