import { RefObject, useCallback, useEffect } from "react";
import {
  MarketingTrackingVariables,
  UICheapFlightComponent,
  UICheapFlightInfo,
  UITrendingDestinationFlightInfo
} from "@flights/types";

import useTrackerContext from "hooks/useTrackerContext";
import useUserAgent from "hooks/useUserAgent";
import useGlobalContext from "hooks/useGlobalContext";
import useIsInViewport from "hooks/useIsInViewport";
import useRouteName from "hooks/useRouteName";

import { getExtOriginMarketingTrackingVariable } from "utils/marketing-url-params";

const ACTION_NAME = "flights__landing_page_events";
const ACTION_VERSION = "2.0.0";
const LANDING_PAGE_NAME = "flights-index";

type EventData = (UICheapFlightInfo | UITrendingDestinationFlightInfo) & {
  position: number;
  url: string;
};

type Data = {
  component: Component;
  position?: number;
  label?: string;
  from_city_iata: string;
  to_city_iata: string;
  from_airport_name: string;
  to_city_name_localised: string;
  to_country_code: string;
  trip_start_date: string;
  trip_end_date: string;
  url?: string;
};

type ClickData = Data & {
  position: number;
  label: string;
};

type Content = {
  session_id: string;
  uvi: string;
  aid: string;
  platform: "Desktop" | "Mobile";
  action_type: ActionType;
  landing_page_name: string;
  channel: string;
  data: ActionType extends "click" ? ClickData : Data;
};

type TrackingData = {
  analyticSessionId?: string;
  isMobile?: boolean;
  isPPC?: boolean;
  marketingTrackingVariables?: MarketingTrackingVariables;
  uvi?: string;
};

const types = ["click", "scroll_to", "page_view"] as const;

type ActionType = typeof types[number];

const components = [
  "popular_flights_domestic",
  "popular_flights_international",
  "popular_flights_nearby",
  "popular_flights",
  "trending_cities",
  "faq",
  "whole_page",
  "worldwide_flights",
  "homepage",
  "searchbox",
  "flights_interlinking",
  "weekend_getaways"
] as const;

type Component = UICheapFlightComponent | typeof components[number];

const useLandingPageEvents = () => {
  const { trackExternal } = useTrackerContext();
  const { isMobile } = useUserAgent();
  const { analyticSessionId, isPPC, marketingTrackingVariables, uvi } = useGlobalContext();

  return useCallback(
    (type: ActionType, component: Component, componentData?: EventData) => {
      const trackingData = {
        analyticSessionId,
        isMobile,
        isPPC,
        marketingTrackingVariables,
        uvi
      };

      if (!types.includes(type) || !components.includes(component)) {
        return;
      }

      trackExternal({
        type: "c360",
        args: [
          {
            action_name: ACTION_NAME,
            action_version: ACTION_VERSION,
            content: getContent(type, component, trackingData, componentData)
          }
        ]
      });
    },
    [analyticSessionId, isMobile, isPPC, marketingTrackingVariables, trackExternal, uvi]
  );
};

const getContent = (
  type: ActionType,
  component: Component,
  trackingData: TrackingData,
  componentData?: EventData
): Content => {
  const channel = (trackingData.isPPC ? "ppc" : getExtOriginMarketingTrackingVariable()) ?? "direct";

  const urlParams = new URLSearchParams(componentData?.url.split("?")[1]);
  const label = urlParams.get("label") || "";

  return {
    session_id: trackingData.analyticSessionId || "",
    uvi: trackingData.uvi || "",
    aid: trackingData.marketingTrackingVariables?.aid || "",
    platform: trackingData.isMobile ? "Mobile" : "Desktop",
    action_type: type,
    landing_page_name: LANDING_PAGE_NAME,
    channel,
    data: {
      component,
      position: componentData?.position,
      label,
      from_city_iata: componentData?.fromCityIata || "",
      to_city_iata: componentData?.fromCityIata || "",
      from_airport_name: componentData?.toCityIata || "",
      to_city_name_localised: componentData?.toCityNameLocalised || "",
      to_country_code: componentData?.toCountryCode || "",
      trip_start_date: componentData?.tripStartDate || "",
      trip_end_date: componentData?.tripEndDate || ""
    }
  };
};

export const useTrackLandingPageViewport = (elementRef: RefObject<HTMLDivElement>, component: Component) => {
  const trackLandingPageEvents = useLandingPageEvents();
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();
  const isInViewport = useIsInViewport(elementRef, [], {
    threshold: 0.75
  });

  useEffect(() => {
    if (component === "whole_page" && routeName !== "home") return;
    if (isInViewport) {
      trackLandingPageEvents("scroll_to", component);
    }
  }, [isInViewport, component, trackLandingPageEvents, routeName, isMobile]);
};

export default useLandingPageEvents;
