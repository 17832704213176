import { createTrackingUtils } from "../index";
import useContextName from "hooks/useContextName";
import useProfiler from "hooks/useProfiler";
import useUserAgent from "hooks/useUserAgent";

const utils = createTrackingUtils({
  name: "flights_ace_web_whitelabel_ancillaries_funnel_tech",
  defaultStage: 1,
  stages: {
    all_users_search_results_and_flight_details: 1,
    search_results_page: 2,
    flight_details_page: 3,
    checkout_payments_page: 4,
    desktop: 5,
    mobile: 6,
    one_way: 7,
    round_trip: 8,
    multi_city: 9
  },
  goals: {
    lounge_access_eligible: 1,
    whitelabel_api_failed: 2
  },
  goalsWithValue: []
});

export function useTrackWhitelabelAncillariesinFunnel() {
  const { isOneWay, isRountrip, isMultiStop } = useProfiler();
  const { isMobile } = useUserAgent();

  const contextName = useContextName();
  utils.stages.all_users_search_results_and_flight_details();

  if (contextName === "flight_details") {
    utils.stages.flight_details_page();
  } else if (contextName === "search_results") {
    utils.stages.search_results_page();
  } else if (contextName === "checkout3") {
    utils.stages.checkout_payments_page();
  }

  if (isMobile) {
    utils.stages.mobile();
  } else {
    utils.stages.desktop();
  }

  if (isOneWay()) {
    utils.stages.one_way();
  }

  if (isRountrip()) {
    utils.stages.round_trip();
  }
  if (isMultiStop()) {
    utils.stages.multi_city();
  }
}

export default utils;
