import React from "react";
import { Card, Icon, Stack, Text, Button } from "@bookingcom/bui-react";
import { CheckmarkSelectedIcon, WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./styles.module.css";
import cxx from "../../__utils/cxx.js";
export function PaxInputCardVertical(props) {
    const { title, isError, isValid, icon, className, footer, content, cta, ...rest } = props;
    return (React.createElement(Card, { ...rest, variant: "neutral", className: cxx(className, styles.fixSize, isError && styles.cardError) },
        React.createElement(Stack, { gap: 4, direction: "column" },
            !!title ? (React.createElement(Stack, { gap: 4, direction: "row" },
                React.createElement(Stack.Item, null,
                    React.createElement(Icon, { attributes: { style: { alignSelf: "start" } }, size: "large", svg: icon })),
                React.createElement(Stack.Item, { grow: true },
                    React.createElement(Stack, { gap: 1 },
                        React.createElement(Stack, { gap: 4, direction: "row" },
                            React.createElement(Stack.Item, { grow: true },
                                React.createElement(Text, { variant: "headline_3" }, title)),
                            React.createElement(Stack.Item, null,
                                isError ? React.createElement(Icon, { size: "medium", color: "destructive", svg: WarningIcon }) : undefined,
                                !isError && isValid ? (React.createElement(Icon, { size: "medium", color: "constructive", svg: CheckmarkSelectedIcon })) : undefined)),
                        !!content ? React.createElement(Stack.Item, null, content) : undefined)))) : undefined,
            React.createElement(Button, { variant: "secondary", destructive: isError, iconPosition: "start", icon: !title ? (isError ? WarningIcon : icon) : undefined, ...cta }),
            !!footer ? React.createElement(Stack.Item, null, footer) : undefined)));
}
