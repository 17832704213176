import { UIFlightSegment } from "@flights/types";
import Frame from "components/elements/Frame";
import { FC } from "react";
import React from "react";
import { useCardSegmentsCondensedController } from "./useCardSegmentsCondensed.controller";
import { CardSegment } from "@flights/search-ui";
import { Stack, Text } from "@bookingcom/bui-react";

export const CardSegmentsCondensed: FC<{ segments: UIFlightSegment[]; bigLogo?: boolean }> = ({
  segments,
  bigLogo
}) => {
  const { segmentsData, carrierText } = useCardSegmentsCondensedController(segments);

  return (
    <>
      <Frame>
        <Stack gap={2}>
          {segmentsData.map((segmentData, index) => (
            <CardSegment key={index} {...segmentData} size={bigLogo ? "large" : "medium"} />
          ))}
        </Stack>
      </Frame>
      <Frame mt={2} direction="row" wrap="wrap">
        <Text variant="small_1">{carrierText}</Text>
      </Frame>
    </>
  );
};
