import React, { useRef, FC } from "react";

import { useGetFlexDatesData } from "./useGetFlexDatesData";
import FlexibleDateBestPriceInner from "./FlexibleDateBestPriceInner";
import useIsInViewport from "hooks/useIsInViewport";
import flights_web_positive_flexi_banner_blackout_mdot from "utils/experiments/funnel/flights_web_positive_flexi_banner_blackout_mdot";

const FlexibleDateBestPrice: FC = () => {
  const data = useGetFlexDatesData();
  if (!data) {
    return null;
  }

  flights_web_positive_flexi_banner_blackout_mdot.stages.allSr();

  const { dates, currentBestPrice, cheaperCount, isOneWay } = data;

  const isEligibleForBlackoutExp = cheaperCount === 0;
  const shouldHide =
    isEligibleForBlackoutExp && flights_web_positive_flexi_banner_blackout_mdot.trackWithDefaultStage();

  return (
    <>
      {isEligibleForBlackoutExp && <BlackoutExpTracker />}

      {shouldHide ? null : (
        <FlexibleDateBestPriceInner
          carouselSize="large"
          dates={dates}
          currentBestPrice={currentBestPrice}
          cheaperCount={cheaperCount}
          isOneWay={isOneWay}
        />
      )}
    </>
  );
};

const BlackoutExpTracker: FC = () => {
  const rootRef = useRef(null);
  const isInViewport = useIsInViewport(rootRef.current);
  flights_web_positive_flexi_banner_blackout_mdot.useTrackStages(isInViewport);

  return <div ref={rootRef}></div>;
};

export default FlexibleDateBestPrice;
