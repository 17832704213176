import React, { useState } from "react";
import { UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { lowerCase, startCase } from "lodash";
import { Accordion, Button, Card, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import FareFeaturesIcons from "branded-fares/components/FareFeatureIcons/FareFeatureIcons";
import FareCardPrice from "./FareCardPrice";
import FareFeatures from "branded-fares/components/FareFeatures";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useGoToFlightDetails from "branded-fares/hooks/useGoToFlightDetails";
import { trackExperiment } from "utils/et";
import Frame from "components/elements/Frame";
import {
  areExcludedFeaturesRequiredByAirline,
  areSellableFeaturesRequiredByAirline
} from "@flights/ancillaries-universal";
import flights_apex_web_bf_on_sr_loading_message from "utils/experiments/apex/flights_apex_web_bf_on_sr_loading_message";
import flights_apex_web_show_all_flexibility_features from "utils/experiments/apex/flights_apex_web_show_all_flexibility_features";
import flights_apex_web_bf_on_sr_flex_badge from "utils/experiments/apex/flights_apex_web_bf_on_sr_flex_badge";

type FareCardProps = {
  fareOffer: UIBrandedFareOrFlexTicket;
  baseOffer: UIFlightData;
  fetchStatus: UIFetchStatus;
  index?: number;
};

const FareCard = ({ fareOffer, baseOffer, fetchStatus, index }: FareCardProps) => {
  const { brandedFareInfo, isFlexTicket } = fareOffer;
  const [showDetails, setShowDetails] = useState(false);
  const i18n = useI18n();
  const goToFlightDetails = useGoToFlightDetails();
  const theme = useTheme();
  const sellableFeaturesRequiredByAirline = areSellableFeaturesRequiredByAirline(baseOffer.segments);
  const excludedFeaturesRequiredByAirline = areExcludedFeaturesRequiredByAirline(baseOffer.segments);

  if (showDetails) flights_apex_web_show_all_flexibility_features.trackStages(true, "search", [{ brandedFareInfo }]);

  return (
    <Card>
      <div
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Accordion
          active={showDetails}
          onOpen={() => {
            setShowDetails(true);
          }}
          onClose={() => setShowDetails(false)}
          titleContent={
            <Stack gap={!!trackExperiment("flights_web_mdot_sr_card_single_cta") ? 0.5 : undefined}>
              {fetchStatus === "loading" ? (
                <Stack gap={6} direction="row">
                  <Stack.Item grow>
                    <SkeletonLoader />
                  </Stack.Item>
                  <SkeletonLoader width={theme.units.spacing_12x} />
                </Stack>
              ) : (
                <Text variant="strong_1">{startCase(lowerCase(brandedFareInfo?.fareName))}</Text>
              )}
              <FareCardPrice fareOffer={fareOffer} baseOffer={baseOffer} isFlexTicket={isFlexTicket} />
              {!showDetails && (
                <FareFeaturesIcons
                  brandedFareInfo={brandedFareInfo}
                  limit={7}
                  isLarge={true}
                  isCompact={true}
                  isPreCheckAndPay={true}
                />
              )}
            </Stack>
          }
        >
          <FareFeatures
            brandedFareInfo={brandedFareInfo}
            sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
            excludedFeaturesRequiredByAirline={excludedFeaturesRequiredByAirline}
            showHeader={true}
            isPreCheckAndPay={true}
            compactHeight={!!trackExperiment("flights_web_mdot_sr_card_single_cta")}
            areShown={showDetails}
          />

          <Frame pt={2}>
            <Button
              onClick={() => {
                flights_apex_web_bf_on_sr_loading_message.goals.click_on_any_fare_card();

                if (index && index > 0) {
                  flights_apex_web_bf_on_sr_loading_message.goals.click_on_upsell_fare_card();
                }

                if (baseOffer.ancillaries?.flexibleTicket)
                  flights_apex_web_bf_on_sr_flex_badge.trackFare(
                    !!fareOffer.ancillaries?.flexibleTicket,
                    (index || 0) > 0
                  );

                goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket);
              }}
              variant="secondary"
              wide
            >
              {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
            </Button>
          </Frame>
        </Accordion>
      </div>
    </Card>
  );
};

export default FareCard;
