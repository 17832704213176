import React from "react";
import { Badge, Text } from "@bookingcom/bui-react";
import cxx from "../../__utils/cxx.js";
import styles from "./styles.module.css";
export function CardStops(props) {
    const { stops, duration, alignDuration = "center", text, className, attributes, stopsAttributes, durationAttributes, 
    // color = "neutral",
    variant = "outline", drawConnector = true } = props;
    return (React.createElement("div", { ...attributes, className: cxx(drawConnector ? styles.stops : styles.stops_no_connector, className) },
        React.createElement("div", { className: drawConnector ? styles.stops__content : undefined },
            React.createElement("div", { className: drawConnector ? styles.stops__inner : undefined },
                React.createElement(Badge, { text: stops, variant: variant, className: styles.stopsBadge, attributes: { "data-testid": stopsAttributes?.dataTestId } }))),
        React.createElement("div", { className: styles.stops__subtext },
            duration ? (React.createElement(Text, { variant: "small_1", attributes: {
                    "aria-hidden": durationAttributes?.ariaHidden,
                    "data-testid": durationAttributes?.dataTestId
                }, align: alignDuration }, duration)) : undefined,
            text ? React.createElement(Text, { variant: "small_2" }, text) : undefined)));
}
