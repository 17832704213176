import React from "react";
import { Icon } from "@bookingcom/bui-react";
import { BagPersonalItemIcon, CabinTrolleyIcon, BagHoldIcon, CheckmarkSelectedFillIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./styles.module.css";
const baggageTypeIconMap = {
    personal: BagPersonalItemIcon,
    cabin: CabinTrolleyIcon,
    bag: BagHoldIcon
};
export function BaggageIconWithCheckmark(props) {
    const { baggageType, ariaLabel } = props;
    return (React.createElement("div", { className: styles.iconContainer, "aria-label": ariaLabel },
        React.createElement(Icon, { className: styles.bagIcon, size: "large", svg: baggageTypeIconMap[baggageType] }),
        React.createElement(Icon, { className: styles.checkmarkIcon, size: "smallest", svg: CheckmarkSelectedFillIcon, color: "constructive" })));
}
