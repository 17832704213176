import { CFAR_TARGET_COUNTRIES, CFAR_TEST_COUNTRIES, CFAR_2025_US_COUNTRY } from "./constants.js";
import { t } from "@bookingcom/lingojs-core";
import { pricingUtils } from "@flights/web-api-utils-universal";
export function getCancelForAnyReasonMarket(pointOfSale) {
    const pos = pointOfSale.toLowerCase();
    if (CFAR_TARGET_COUNTRIES.includes(pos))
        return "target";
    if (CFAR_TEST_COUNTRIES.includes(pos))
        return "test";
    if (CFAR_2025_US_COUNTRY.includes(pos))
        return "new";
    return undefined;
}
export const getCfarMaxRefundCopy = (i18n, pointOfSale) => {
    const price = String(pointOfSale).toLowerCase() === "us"
        ? { units: 2600, nanos: 0, currencyCode: "USD" }
        : { units: 2500, nanos: 0, currencyCode: "EUR" };
    return i18n.trans(t("flights_cfar_benefit_4b", {
        variables: {
            cfar_refund: pricingUtils.formatPrice(price, pointOfSale)
        }
    }));
};
/**
 * Type guard for distinguishing city and airport search locations
 */
export function isAirportSearchLocation(location) {
    return location.type === "AIRPORT";
}
export function isSearchSegmentType(segment) {
    return segment.hasOwnProperty("from");
}
