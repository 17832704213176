import { createMicroConversionTrackingUtils } from "..";

const utils = createMicroConversionTrackingUtils({
  name: "flights_web_sr_card_redesign_mdot_v2",
  stages: {
    main: 1,
    direct: 2,
    meta: 3,
    oneWay: 4,
    roundTrip: 5,
    solo: 6,
    family: 7,
    us: 8
  },
  defaultStage: 1,
  mainStage: 1,
  goals: {
    tapOnCardDirectSegments: 1, // all segments are direct
    tapOnCardNonDirectSegments: 2, // no segments are direct
    tapOnCardMixedSegments: 3, // there’re direct and nondirect segments mixed
    tapOnCardSoleOperatingAirline: 4,
    tapOnCardMultipleOperatingAirlines: 5
  },
  goalsWithValue: []
});

export default utils;
