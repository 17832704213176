import { useCallback, useEffect, useMemo, useState } from "react";
import { useClientFetchCallback } from "./useClientFetch";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../store/preFetchedFlightDetails/actions";
import { getPreFetchedFlights } from "store/preFetchedFlightDetails/selectors";
import { UIFlightData } from "@flights/types";
import { stage, trackWithDefaultStage } from "@flights/et-universal";
import useProfiler from "./useProfiler";
import { getIsFiltersApplied, getPage } from "store/search/selectors";

export default function usePrefetchFlightDetails() {
  const expName = "flights_web_prefetch_flight_details";
  const [controller] = useState(() => new AbortController());
  const dispatch = useDispatch();
  const { isDirect, isMobile, isAmerican, isSolo } = useProfiler();
  const { clientFetchCallback } = useClientFetchCallback();
  const page = useSelector(getPage);
  const preFetchedFlights = useSelector(getPreFetchedFlights);
  const isFiltersApplied = useSelector(getIsFiltersApplied);

  const canFetchDetails = useMemo(() => {
    if (!isMobile()) return false;
    return !isFiltersApplied && (page === 1 || page === undefined);
  }, [isFiltersApplied, page, isMobile]);

  const getUrls = useCallback((list: UIFlightData[]) => {
    return list?.slice(0, 3)?.map((f) => `/api/flight/${f.token}?preload=1`);
  }, []);

  const getPromises = useCallback(
    (list: string[]) => {
      return list.map((_) => {
        return clientFetchCallback(_, { signal: controller.signal });
      });
    },
    [clientFetchCallback, controller]
  );

  const preFetchFlightDetails = useCallback(
    async (list: UIFlightData[]) => {
      if (!canFetchDetails) return;
      if (!trackWithDefaultStage(expName, 1)) return;
      const urls = getUrls(list);
      const promises = getPromises(urls);
      try {
        dispatch(actions.fetch());
        promises.forEach((p) => {
          p.then((data) => {
            dispatch(actions.fetchSuccess([data]));
          }).catch((e) => {
            dispatch(actions.fetchError(e));
          });
        });
      } catch (error) {
        dispatch(actions.fetchError(error));
      }
    },
    [getUrls, getPromises, dispatch, canFetchDetails]
  );

  const getFetchedFlightDetails = useCallback(
    (token?: string) => {
      if (!token) return undefined;
      if (!trackWithDefaultStage(expName, 1)) return undefined;
      const result = preFetchedFlights.find((f) => f.token === token);
      if (!result) return undefined;
      stage(expName, 2);
      stage(expName, isDirect() ? 3 : 4);
      stage(expName, isAmerican() ? 5 : 6);
      stage(expName, isSolo() ? 7 : 8);
      if (!!result.brandedFareOffers?.length) stage(expName, 9);
      if (trackWithDefaultStage(expName, 1) === 1) return undefined;
      return result;
    },
    [preFetchedFlights, isDirect, isAmerican, isSolo]
  );

  const resetFetchedFlightDetails = useCallback(() => {
    dispatch(actions.reset());
  }, [dispatch]);

  useEffect(() => {
    return () => controller.abort({ code: "CANCELLED_DUE_TO_UNMOUTING" });
  }, [controller]);

  return { preFetchFlightDetails, getFetchedFlightDetails, resetFetchedFlightDetails };
}
