import { Actions, ActionTypes } from "./actions";
import { getInitialState, State } from "./state";

const initialState = getInitialState();

const reducer = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.fetch:
      return {
        ...state,
        flights: [],
        fetchStatus: "loading",
        error: undefined
      };
    case ActionTypes.fetchSuccess:
      return {
        ...state,
        flights: [...state.flights, ...action.payload.flights],
        fetchStatus: "success",
        error: undefined
      };
    case ActionTypes.fetchError:
      return {
        ...state,
        fetchStatus: "failed",
        error: action.payload.error
      };
    case ActionTypes.reset:
      return {
        ...state,
        flights: [],
        fetchStatus: "initial",
        error: undefined
      };
    default:
      return state;
  }
};

export { reducer, getInitialState };
